import React from 'react';
import { Row, Col } from 'antd';
import PercentCard from '../../components/Loan/cards/PercentCard';
import repaid from '../../assets/svg/Repaid.svg';
import DefaultLoans from '../../components/Loan/cards/DefaultLoans';
import AllRepaidLoans from '../../components/Loan/cards/AllRepaidLoans';
import { LoanHeader } from '../../components/Loan/Header';
import Breadcrumbs from '../../components/Loan/Breadcrumb';

function RepaidLoans(props) {


    return (
        <div>
            <Breadcrumbs link="repaid" name="Repaid" />
            <LoanHeader repaid={true} title="Repaid" />
            <Row gutter={[16, 30]}>
                <Col span={6}>
                    <PercentCard icon={repaid} amount="299,214,000" count="302,000" value="100" textColor="#11C096" heading="Repaid Loans" />
                </Col>
                <Col span={18}>
                    <DefaultLoans />
                </Col>
                <Col span={24}>
                    <AllRepaidLoans />
                </Col>
            </Row>
        </div>
    )
}

export default RepaidLoans;
