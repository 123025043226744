import React from "react";
import { Form, Button } from "antd";
import { useHistory } from "react-router-dom";

function ButtonComponent({
  loading,
  isNotSubmitBTN,
  onClick = null,
  text = `Save and Continue`,
}) {
  const history = useHistory();
  return (
    <Form.Item style={{ marginTop: "30px" }}>
      <Button
        size="large"
        style={{ marginRight: "30px" }}
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <Button
        type="primary"
        size="large"
        htmlType={isNotSubmitBTN ? "button" : "submit"}
        loading={loading}
        onClick={onClick}
      >
        {text}
      </Button>
    </Form.Item>
  );
}

export default ButtonComponent;
