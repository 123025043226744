import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Input } from "antd";
import { useDispatch } from "react-redux";
import {
  OVERVIEWCREDITORDERSEARCHVALUE,
  OVERVIEWLOANREQUESTSEARCHVALUE,
  SETOVERVIEWLOANSTATUSTOSEARCHFOR,
  SETOVERVIEWORDERSTATUSTOSEARCHFOR,
} from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";

function TableHeader() {
  const { session } = useAuth();
  const { Option } = Select;
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch({ type: OVERVIEWLOANREQUESTSEARCHVALUE, value: e.target.value });
  };

  const handleOrderSearch = (e) => {
    dispatch({ type: OVERVIEWCREDITORDERSEARCHVALUE, value: e.target.value });
  };

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETOVERVIEWLOANSTATUSTOSEARCHFOR, value: val });
  };

  const handleOrderFilterByStatus = (val) => {
    dispatch({ type: SETOVERVIEWORDERSTATUSTOSEARCHFOR, value: val });
  };

  return (
    <div className="mb-5">
      <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
        {session?.admin?.category === "merchant" ? "Credit Orders" : "Requests"}
      </h3>
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        {session?.admin?.category === "merchant"
          ? "A summary of your credit orders over a selected period of time."
          : "A summary of your loan requests over a selected period of time."}
      </p>
      <div className="flex items-center justify-between">
        <div className="flex space-x-4 flex-1">
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            size="large"
            placeholder="Filter"
            optionFilterProp="children"
            onChange={(val) =>
              session?.admin?.category === "merchant"
                ? handleOrderFilterByStatus(val)
                : handleFilterByStatus(val)
            }
          >
            <Option value="saved">Saved</Option>
            <Option value="submitted">Submitted</Option>
          </Select>
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "40%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={
              session?.admin?.category === "merchant"
                ? handleOrderSearch
                : handleSearch
            }
          />
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
