import React from "react";
import LoanApplicationsTable from "../../../Table/Borrowers/LoanApplications";
import TableHeader from "../Header";
 
const LoanApplications = ({ isLoading }) => {
  return (
    <>
      <TableHeader />
      <LoanApplicationsTable isLoading={isLoading} />
    </>
  )
}

export default LoanApplications;