import { useMutation } from "react-query";
import { createContext, useContext, useState } from "react";
import SecureLS from "secure-ls";
import Request from "../../utils/request";

const AppContext = createContext();
const securedStorage = new SecureLS();

/**
 * @description AuthProvider provides the session,signUp,signIn and signOut.
 * The session holds the user objects (example username, email, phone etc).
 * The signUp accepts a user and a callback to return the authentication status.
 *
 */

function AuthProvider({ children }) {
  const initSession = securedStorage.get("operator") || {};
  const [session, setSession] = useState(initSession);
  const [loggingOut, setLoggingOut] = useState(false);

  const mutationSignup = useMutation((userData) =>
    Request.fetchWithOutAuthToken("/lms/signup", "POST", userData)
  );

  const mutationSignout = useMutation((signoutBody) =>
    Request.fetchWithOutAuthToken("/lms/signout", "POST", signoutBody)
  );

  const mutationSignIn = useMutation((user) =>
    Request.fetchWithOutAuthToken("/lms/auth", "POST", user)
  );

  const mutationSendResetCode = useMutation((email) =>
    Request.fetchWithOutAuthToken(
      `/lms/password/request_reset_code?email=${email}`,
      "GET"
    )
  );

  const mutationResetPassword = useMutation((userData) =>
    Request.fetchWithOutAuthToken(`/lms/password/reset`, "POST", userData)
  );

  const signUp = (user, callBack) =>
    mutationSignup.mutate(user, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const signOut = (user, callBack) => {
    setLoggingOut(true);
    mutationSignout.mutate(user, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });
  };

  const signOutAux = (user, callBack) => {
    mutationSignout.mutate(user, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });
  };

  const signIn = (user, callBack) =>
    mutationSignIn.mutate(user, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const SendCode = (email, callBack) =>
    mutationSendResetCode.mutate(email, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const resetPassword = (userData, callBack) =>
    mutationResetPassword.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const value = {
    session,
    setSession,
    signUp,
    signOut,
    signOutAux,
    signIn,
    SendCode,
    loggingOut,
    setLoggingOut,
    resetPassword,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const useAuth = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };
