import { Typography } from "antd";
import styles from "./Typo.module.css";
const { Text: AntdText, Title: AntdTitle } = Typography;

export const Text = (props) => (
  <AntdText {...props} style={{ fontFamily: "Cera Pro Light", ...props.style }}>
    {props.children}
  </AntdText>
);

export const Title = (props) => (
  <AntdTitle {...props} style={{ fontFamily: "Cera Pro Bold", ...props.style }}>
    {props.children}
  </AntdTitle>
);

export const Paragraph = ({ className, style, children, size, height }) => {
  const newClassName = [className, styles.paragraph].filter(Boolean).join(" ");
  return (
    <p
      className={newClassName}
      style={{
        fontSize: size ? size : "",
        lineHeight: height ? height : "",
        ...style,
      }}
    >
      {children}
    </p>
  );
};
