import React, { useEffect } from "react";
import { Form, Row, Col, message, Tooltip, Checkbox, Divider } from "antd";
import { useAuth } from "../../../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import Request from "../../../utils/request";
import { useParams } from "react-router-dom";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Title } from "../../Typography";
import NumberOfGuarantors from "./UpdateNoOfGuarantors";

function Notification() {
  const { session } = useAuth();

  const notificationRef = React.createRef();

  const { id: product_id } = useParams();

  const { data: product_config } = useQuery(
    "product_config",
    () =>
      Request.fetchWithAuthToken(
        `/lms/product/${product_id}/config`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const UpdateConfigGeneral = useMutation((generalConfigBody) =>
    Request.fetchWithAuthToken(
      `/lms/product/${product_id}/config?reference=bool-fields&field=${
        Object.keys(generalConfigBody)[0]
      }`,
      "PUT",
      session?.session?.token,
      generalConfigBody
    )
  );

  const handleUpdateGeneral = (values) => {
    UpdateConfigGeneral.mutate(values, {
      onSuccess: (data) => onUpdateConfig(data),
      onError: () => {},
    });
  };

  const onUpdateConfig = (data) => {
    if (data.status !== "success") {
      message.error(data.message, 3);
      return;
    }
    message.success(data.message, 3);
  };

  useEffect(() => {
    notificationRef?.current?.setFieldsValue({
      email_verification: product_config?.data?.email_verification,
      phone_number_verification:
        product_config?.data?.phone_number_verification,
      bvn_verification: product_config?.data?.bvn_verification,
      auto_trigger_guarantor_email:
        product_config?.data?.auto_trigger_guarantor_email,
      use_remita_debit_mandate: product_config?.data?.use_remita_debit_mandate,
      use_late_fees: product_config?.data?.use_late_fees,
      use_mono: product_config?.data?.use_mono,
      require_guarantor: product_config?.data?.require_guarantor,
      do_mono_360_check: product_config?.data?.do_mono_360_check,
    });
    // eslint-disable-next-line
  }, [product_config]);

  return (
    <div className="my-5">
      <Form
        layout="vertical"
        ref={notificationRef}
        onFinish={handleUpdateGeneral}
      >
        <Title level={5}>This product should require:</Title>
        <Row>
          <Col span={4}>
            <Form.Item name="email_verification" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Email Verification
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="phone_number_verification" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Phone Number Verification
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="bvn_verification" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                BVN Verification
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <Form.Item name="use_mono" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Mono Bank Statement Integration
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="do_mono_360_check" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Mono 360 Check
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="use_remita_debit_mandate" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Remita Debit Mandate
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={16}>
            <NumberOfGuarantors />
          </Col>
        </Row>

        <Divider />
        <Title level={5}>Notification settings</Title>
        <Row>
          <Col span={6}>
            <Form.Item
              name="auto_trigger_guarantor_email"
              valuePropName="checked"
            >
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateGeneral({ [val.target.id]: val.target.checked })
                }
              >
                Auto Trigger Guarantor Email
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Notification;
