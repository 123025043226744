import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import Progress from "./ProgressStep";
import Header from "./Header";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Button,
  InputNumber,
  Tooltip,
} from "antd";

import { InfoCircleOutlined, QuestionCircleFilled } from "@ant-design/icons";
import ButtonComponent from "./ButtonComponent";
import {
  countries,
  currencies,
  dateFormat,
  Request,
  timezones,
} from "../../utils";
import { useAuth } from "../../context/AuthContext";
import Upload from "../Uploader";
import { useHistory } from "react-router-dom";
const profileRef = React.createRef();
const { Option } = Select;

function Profile({ isModal }) {
  let history = useHistory();
  const { session } = useAuth();

  const [isProcessing, setProcessing] = useState(false);

  const { data } = useQuery(
    "profile",
    () =>
      Request.fetchWithAuthToken(
        "/lms/profile",
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const UpdateProfile = useMutation((profileBody) =>
    Request.fetchWithAuthToken(
      "/lms/profile",
      "PUT",
      session?.session?.token,
      profileBody
    )
  );

  const handleSubmit = (values) => {
    setProcessing(true);
    UpdateProfile.mutate(values, {
      onSuccess: (data) => onUpdateProfile(data),
      onError: () => {},
    });
  };

  const onUpdateProfile = (data) => {
    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    setProcessing(false);
    message.success(data.message, 3);
    !isModal && history.push("/adminsetup/businessinfo");
  };

  useEffect(() => {
    profileRef?.current?.setFieldsValue({
      company_name: session?.admin?.name,
      country: data?.data?.country,
      incorporation_country: data?.data?.incorporation_country,
      timezone: data?.data?.timezone,
      default_currency: data?.data?.default_currency,
      date_format: data?.data?.date_format,
      logo: data?.data?.logo,
      website: data?.data?.website,
      primary_color: data?.data?.primary_color,
      logo_height: data?.data?.logo_height,
      logo_width: data?.data?.logo_width,
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {!isModal && (
        <Header
          title="Complete Company Profile"
          onSave={() => profileRef.current.submit()}
          next="/adminsetup/businessinfo"
        />
      )}
      <Row gutter={16}>
        <Col span={isModal ? 24 : 17}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <Form layout="vertical" ref={profileRef} onFinish={handleSubmit}>
              <Row gutter={16}>
                <Col span={10}>
                  <Form.Item
                    name="company_name"
                    label="Registered Company Name *"
                  >
                    <Input placeholder="Whitney Inc." />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="What is your company's legal name, as written on your incorporation documents?"
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
                <Col span={10}>
                  <Form.Item name="timezone" label="Select timezone">
                    <Select placeholder="West African Standard Time" showSearch>
                      {timezones.map((timezone) => (
                        <Option value={timezone.value} key={timezone.text}>
                          {timezone.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="We'll track all the activity on your account and generate reports using this timezone."
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="date_format"
                    label="Select Date Format"
                    tooltip={{
                      title: "Enter date",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Select placeholder="MM/DD/YYYY" showSearch>
                      {dateFormat.map((formats) => (
                        <Option
                          value={formats?.format}
                          key={`${formats?.format}${formats?.output}`}
                        >
                          {dayjs().format(formats?.format)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="We'll track all the activity on your account and generate reports using this date format."
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
                <Col span={10}>
                  <Form.Item
                    name="incorporation_country"
                    label="Incorporation Country"
                  >
                    <Select placeholder="🇳🇬 Nigera" showSearch>
                      {countries.map((country) => (
                        <Option
                          value={country.name}
                          key={`${country.code}${country.name}`}
                        >
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="Where was your company incorporated?"
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
                <Col span={10}>
                  <Form.Item name="default_currency" label="Select currency">
                    <Select placeholder="₦Nigerian Naira" showSearch>
                      {currencies.map((currency) => (
                        <Option value={currency.code} key={currency.code}>
                          {`${currency.symbol}${" - "}${currency.name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="This is the default currency we'll use to track your transactions and generate reports."
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
                <Col span={10}>
                  <Form.Item label="Add Company Logo" name="logo">
                    <Upload formRef={{ ref: profileRef, fieldName: "logo" }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="We'll use this logo to customise your frontend application forms, offer letters, reports, and email notifications."
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Logo Width" name="logo_width">
                    <InputNumber
                      placeholder="width"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Logo Height" name="logo_height">
                    <InputNumber
                      placeholder="width"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item name="website" label="Company Website">
                    <Input placeholder="www." />
                  </Form.Item>
                </Col>
                <Col md={10} xs={22}>
                  <Form.Item
                    label="Add Company Primary Color"
                    name="primary_color"
                  >
                    <Input type="color" />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title="We'll use this colour to customise your frontend application forms, offer letters, reports, and email notifications."
                    className="mt-10"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </Col>
              </Row>
              {isModal ? (
                <Button
                  htmlType="submit"
                  loading={isProcessing}
                  type="primary"
                  size="large"
                >
                  Submit
                </Button>
              ) : (
                <ButtonComponent loading={isProcessing} />
              )}
            </Form>
          </div>
        </Col>
        {!isModal && (
          <Col span={7}>
            <Progress step="Company Profile" />
          </Col>
        )}
      </Row>
    </>
  );
}

export default Profile;
