import { schema, superUser } from "../../../utils";

export const initialState = schema;

export function UpdatePriviledgeReducer(state, action) {
  switch (action.type) {
    case "init":
      return {
        cat_one_prev: action.cat_one_prev,
        cat_two_prev: action.cat_two_prev,
      };

    case "cat_one_prev":
      return {
        ...state,

        cat_one_prev: {
          ...state["cat_one_prev"],
          [action.field]: action.value,
        },
      };
    case "cat_two_prev":
      return {
        ...state,
        cat_two_prev: {
          ...state["cat_two_prev"],
          [action.field]: action.value,
        },
      };

    case "super-cat_one_prev":
      return {
        ...state,
        cat_one_prev: superUser.cat_one_prev,
      };

    case "remove_super-cat_one_prev":
      return {
        ...state,
        cat_one_prev: schema.cat_one_prev,
      };

    case "super-cat_two_prev":
      return {
        ...state,
        cat_two_prev: superUser.cat_two_prev,
      };

    case "remove_super-cat_two_prev":
      return {
        ...state,
        cat_two_prev: schema.cat_two_prev,
      };

    case "update":
      return {};

    default:
      return state;
  }
}

export default UpdatePriviledgeReducer;
