import moment from "moment";
import {
  ADDPAR,
  SETPARENDDATE,
  SETPARSTARTDATE,
  SETPARLOANOFFICER,
} from "../../Types/tableTypes";

const initialState = {
  par: [],
  officerId: 0,
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const parTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDPAR) {
    return { ...state, par: actions.value };
  }
  if (actions.type === SETPARENDDATE) {
    return { ...state, endDate: actions.value };
  }
  if (actions.type === SETPARSTARTDATE) {
    return { ...state, startDate: actions.value };
  }
  if (actions.type === SETPARLOANOFFICER) {
    return { ...state, officerId: actions.value };
  }

  return state;
};
