import {
  ADDALLTEAMS,
  ALLTEAMSEARCHVALUE,
  SETTEAMSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  teams: [],
  searchValue: "",
  status: "",
};

export const teamTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLTEAMS) {
    return { ...state, teams: actions.value };
  }

  if (actions.type === ALLTEAMSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETTEAMSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  return state;
};
