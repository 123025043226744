import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

export function PrimaryBtn({width, size, text}) {

    return (
        <Button type="primary" style={{color:'#fff', backgroundColor: '#5152A6', fontSize:'12px', width: `${width}`}} size={size}>
            {text}
        </Button>
    )
}

export function DefaultBtn({width, size, text, background}) {

    return (
    <Button style={{border:'1px solid #5152A6', color: '#5152A6', width: `${width}`, fontSize:'12px', backgroundColor:`${background}`}} size={size}>{text}</Button>
    )
}

export function DropdownBtn({color, width, size, text, border, background}){
    const menu = (
        <Menu>
          <Menu.Item key="1" icon={<UserOutlined />}>
            1st menu item
          </Menu.Item>
        </Menu>
      );

    return(
        <Dropdown overlay={menu}>
      <Button style={{color:`${color}`, backgroundColor: `${background}`, fontSize:'12px', border:`${border}`, width:`${width}`}} size={size}>
        {text} <DownOutlined />
      </Button>
    </Dropdown>
    )
}
