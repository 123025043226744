import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DISBURSEMENTSEARCHVALUE,
  FULFILMENTSEARCHVALUE,
  SETDISBURSEMENTENDDATE,
  SETDISBURSEMENTSTARTDATE,
} from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";

const { RangePicker } = DatePicker;

function TableHeader(props) {
  const { session } = useAuth();
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.disbursementsTableReducer
  );

  const handleSearch = (e) => {
    dispatch({ type: DISBURSEMENTSEARCHVALUE, value: e.target.value });
  };

  const handleOrderSearch = (e) => {
    dispatch({ type: FULFILMENTSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETDISBURSEMENTSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETDISBURSEMENTENDDATE, value: e.endDate });
  };

  return (
    <div className="mb-5">
      <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
        {session?.session?.org?.category === "merchant"
          ? "All Fulfilments"
          : "All Disbursements"}
      </h3>
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        {session?.session?.org?.category === "merchant"
          ? "Fulfilments helps you to keep track of all the order fulfilments you've made over time."
          : "Disbursements helps you to keep track of all the loan disbursements you've made over time."}
      </p>
      <div className="flex items-center justify-between">
        <div className="flex">
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "75%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={session?.admin?.category === "merchant" ? handleOrderSearch : handleSearch}
          />
        </div>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
        />
      </div>
    </div>
  );
}

export default TableHeader;
