import React from "react";
import { Table, Button, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";

function FulfilmentsTable({ isLoadingFulfilments }) {
  const { fulfilments } = useSelector(
    (state) => state.fulfilmentsTableReducer
  );

  const { profile } = useSelector((state) => state.profileReducer );
  const { currencyFormatter } = useCurrenncyFormatter();

  const history = useHistory();

  const columns = [
    {
      title: "Reference",
      dataIndex: "ref",
      key: "ref",
    },
    {
      title: "Borrower",
      dataIndex: "borrower",
      key: "fullname",
      render: (text, record) => <div>{`${record.customer?.firstname} ${record.customer?.lastname}`}</div>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <p>{currencyFormatter(text)}</p>,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: "Time Stamp",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() > new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },

    {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
    },

    {
      title: "Status",
      dataIndex: "fulfilled",
      key: "fulfilled",
      render: (text, record ) => (
        <Tag color={record.fulfilled === true ? "success" : "orange"}>
          <span className="capitalize">{record.fulfilled === true ? "Fulfilled" : "Pending"}</span>
        </Tag>
      )
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/disbursement/${record.id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoadingFulfilments}
        dataSource={fulfilments}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default FulfilmentsTable;
