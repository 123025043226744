import React from "react";
import { Title } from "../Typography";
import { useAuth } from "../../context/AuthContext";

function Heading() {
  const { session } = useAuth();

  return (
    <div className="flex justify-between">
      <Title level={2}>
        {session?.session?.org?.category === "merchant"
          ? "Fulfilments"
          : "Disbursements"}
      </Title>
    </div>
  );
}

export default Heading;
