import { Row, Col, Empty } from "antd";
import React from "react";

const Approvals = ({ approval }) => {
  return (
    <div className="mt-5">
      <Row gutter={[16, 16]}>
        {approval?.map((layer) => (
          <Col span={12}>
            <h3 className="capitalize space-x-4 text-base">
              Role:{" "}
              <span className="font-bold">{layer?.role_in_organisation}</span>
            </h3>
            <h3 className="capitalize space-x-2 text-base">
              Status: <span className="font-bold">{layer?.status}</span>{" "}
            </h3>
          </Col>
        ))}
        <Col span={24}>{!approval && <Empty />}</Col>
      </Row>
    </div>
  );
};

export default Approvals;
