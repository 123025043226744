import React, { useEffect } from "react";
import {
  NotesHeader,
  NoteTableHeader,
} from "../../components/Notes/NotesHeader";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import NoteTable from "../../components/Table/Notes/NoteTable";
import { ALLNOTES } from "../../redux/Types/tableTypes";

function Notes() {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  const { session } = useAuth();

  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) => state.notesReducer);
  const { status, searchValue } = useSelector(
    (state) => state.noteTableReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: notes, isLoading } = useQuery(
    `notes-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/note?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );
  const SearchQuery = (data) => {
    const firstQuery = data?.filter((note) => {
      const name = `${note?.created_by}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return note;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.activated === status;
      }
      return true;
    });
  };

  useEffect(() => {
    if (notes?.status === "success") {
      dispatch({
        type: ALLNOTES,
        value: SearchQuery(notes?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: ALLNOTES,
      value: SearchQuery(notes?.data),
    });

    // eslint-disable-next-line
  }, [searchValue, status]);
  return (
    <div>
      <NotesHeader />

      <div style={style}>
        <NoteTableHeader />
        <NoteTable isLoading={isLoading} />
      </div>
    </div>
  );
}

export default Notes;
