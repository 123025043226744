import React, { useState } from "react";
import { useMutation } from "react-query";
import {
  Modal,
  Checkbox,
  Form,
  Input,
  Divider,
  Button,
  Empty,
  message
} from "antd";
import { Title } from "../../Typography";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { useApp } from "../../../context/FetchContext";
const { TextArea } = Input;
function AdditionalActions({ isVisible, id, setIsVisible }) {
  const { session } = useAuth();
  const { reFetchs } = useApp();
  const [action, setAction] = useState([]);
  const [loading, setLoading] = useState(false);
  const actionMutation = useMutation((actionBody) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/update`,
      "PUT",
      session.session.token,
      actionBody
    )
  );

  const handleSubmit = (values) => {
    setLoading(true);

    const add_note = action.includes("add_note") ? { add_note: true } : {};
    const request_document_upload = action.includes("request_document_upload")
      ? { request_document_upload: true }
      : {};
    const send_notification = action.includes("send_notification")
      ? { send_notification: true }
      : {};

    const actionBody = {
      mark_pending: true,
      ...request_document_upload,
      ...add_note,
      ...send_notification,
      ...values,
    };

    actionMutation.mutate(actionBody, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setLoading(false);
          message.error(data.message, 3);
          return;
        }
        setLoading(false);
        setIsVisible(false);
        reFetchs.refetchNotes();
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
  };

  const options = [
    { label: "Leave a note for a team member", value: "add_note" },
    // { label: "Request document upload", value: "request_document_upload" },
    { label: "Contact Customer", value: "send_notification" },
  ];

  const optionsChanel = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
  ];

  function onChange(checkedValues) {
    setAction(checkedValues);
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      width="37%"
      onCancel={() => setIsVisible(false)}
    >
      <div className="">
        <Title level={4}>Select an Action</Title>
        <Checkbox.Group options={options} onChange={onChange} />
        <Divider />
        {action.length > 0 && (
          <Form layout="vertical" onFinish={handleSubmit}>
            {action.includes("add_note") && (
              <Form.Item label="Note" name="note" required>
                <TextArea size="large" />
              </Form.Item>
            )}

            {action.includes("request_document_upload") && (
              <Form.Item label="Document Name" name="document_name" required>
                <Input size="large" />
              </Form.Item>
            )}

            {action.includes("send_notification") && (
              <Form.Item
                label="How do you want to send this message?"
                name="notification_channel"
                required
              >
                <Checkbox.Group options={optionsChanel} />
              </Form.Item>
            )}

            {action.includes("send_notification") && (
              <Form.Item label="Message" name="message" required>
                <TextArea size="large" />
              </Form.Item>
            )}

            <Button
              type="primary"
              size="large"
              className="w-full"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form>
        )}

        {action.length < 1 && (
          <div>
            <Empty description={<span>No action selected</span>} />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AdditionalActions;
