import {
  ADDALLCOLLATERALS,
  COLLATERALSEARCHVALUE,
  SETCOLLATERALSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  all_collaterals: [],
  searchValue: "",
  status: "",
};

export const allCollateralsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLCOLLATERALS) {
    return { ...state, all_collaterals: actions.value };
  }

  if (actions.type === COLLATERALSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETCOLLATERALSTATUS) {
    return { ...state, status: actions.value };
  }

  return state;
};
