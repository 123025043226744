import React, { useState } from "react";
import { Table, Button, Tag, Modal } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CreateLoanApplication from "../../Borrowers/CreateLoanApplication";

function BorrowersTable({ isLoading }) {
  const history = useHistory();
  const { borrowers } = useSelector((state) => state.borrowersTableReducer);
  const [userID, setUserID] = useState(null);
  const columns = [
    {
      title: "User Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "fullname",
      render: (text, record) => (
        <div>{`${record?.firstname} ${record?.lastname}`}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    // {
    //   title: "KYC Score",
    //   dataIndex: "kyc_score",
    //   key: "kyc_score",
    //   render: (text, record) => (
    //     <Space>
    //       <Progress type="circle" width={25} percent={text} showInfo={false} /> {text}
    //     </Space>
    //   )
    // },

    // {
    //   title: "credit Score",
    //   dataIndex: "credit_score",
    //   key: "credit_score",
    //   render: (text, record) => (
    //     <Space>
    //       <ThunderCircle /> {text}
    //     </Space>
    //   )
    // },

    // {
    //   title: "Branch",
    //   dataIndex: "branch",
    //   key: "branch",
    // },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <Tag color={record.is_active === true ? "success" : "default"}>
          <span className="capitalize">
            {record.is_active === true ? "Active" : "Inactive"}
          </span>
        </Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-3">
          <Button
            onClick={() => {
              history.push(`/borrowers/${record.id}`);
            }}
          >
            View
          </Button>

          <Button
            disabled={record.is_active === true ? false : true}
            onClick={() => {
              setUserID(record.id);
            }}
          >
            Create a loan
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        className="mt-5"
        columns={columns}
        dataSource={borrowers}
        loading={isLoading}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />

      <Modal visible={userID} footer={null} onCancel={() => setUserID(null)}>
        <section className="my-12">
          <CreateLoanApplication userID={userID} />
        </section>
      </Modal>
    </>
  );
}

export default BorrowersTable;
