import React from 'react';
import PercentageChart from '../PercentageChart';
import { Text, Title } from "../../Typography";
import { Link } from 'react-router-dom';

function PercentCard({value, heading, count, icon, textColor, amount}) {
    const style = { background: '#ffffff', padding: '10px 20px', borderRadius: '12px', height: '60vh' };

    return (
        <div style={style}>
            <PercentageChart value={value} />
            <div style={{ textAlign: 'center', position: 'absolute', top: '55%', left: '20%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '30px' }}>
                    <img src={icon} alt={heading} />
                    <Text style={{ color: `${textColor}`, marginLeft: '8px' }}>{heading}</Text>
                </div>
                <Title level={4}>N{amount}</Title>
                <p>Count: {count}</p>
                <Link style={{ textDecoration: 'underline', color: '#757580' }}>View All {heading}</Link>
            </div>
        </div>
    )
}

export default PercentCard;
