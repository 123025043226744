import { Avatar, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Text } from "../Typography";
import { Link } from "react-router-dom";

function ListItem({ description, title, path, number, data }) {
  return (
    <Link to={path}>
      <div className="bg-white h-14  border-b border-solid border-0 border-gray-200 pl-3 flex items-center justify-between hover:bg-gray-100">
        <div className="flex h-full space-x-10">
          {/* Number and title */}
          <div className="flex items-center space-x-4">
            <Avatar
              size="small"
              style={{
                background: "#fff",
                border: "1px solid #9595AC",
                color: "#080810",
              }}
            >
              {number}
            </Avatar>
            <Text
              style={{
                fontFamily: "Cera Pro Medium",
                fontWeight: "900",
                color: "#080810",
                fontSize: "14px",
              }}
            >
              {title}
            </Text>
          </div>
          {/* Description */}
          <div className="flex items-center">
            <Text>{description}</Text>
          </div>
        </div>

        <Button type="link" className="flex items-center h-full  mx-5">
          <RightOutlined
            style={{
              fontWeight: "900",
              fontSize: "20px",
              color: "#5152A6",
            }}
          />
        </Button>
      </div>
    </Link>
  );
}

export default ListItem;
