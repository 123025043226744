import { DatePicker } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import {
  SETREQUESTMETRICSENDDATE,
  SETREQUESTMETRICSSTARTDATE,
} from "../../redux/Types/generalTypes";
import { Title } from "../Typography";

const { RangePicker } = DatePicker;

export function LoanHeader() {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { metricsEndDate, metricsStartDate } = useSelector(
    (state) => state.requestMetricsReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const startDateFilter = (e) => {
    dispatch({ type: SETREQUESTMETRICSSTARTDATE, value: e.metricsStartDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETREQUESTMETRICSENDDATE, value: e.metricsEndDate });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Title level={2}>
        {session?.admin?.category === "merchant"
          ? "Credit Order Requests"
          : "Loan Requests"}
      </Title>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "40%",
        }}
      >
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { metricsStartDate: value[0] },
              endDateFilter({ metricsEndDate: value[1] })
            )
          }
          defaultValue={[metricsStartDate, metricsEndDate]}
          format={dateFormat}
          on
        />
      </div>
    </div>
  );
}
