import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import WalletCard from "./WalletCard";
import { useAuth } from "../../context/AuthContext";
import Request from "../../utils/request";
import { WalletTableHeader } from "./WalletHeader";
import {
	AppstoreOutlined,
	CreditCardOutlined,
	PlusCircleOutlined,
	ToolOutlined,
} from "@ant-design/icons";
import WalletTopup from "./WalletTopup";
import {
	ADDALLTRANSACTIONS,
} from "../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Requests from "../../utils/request";
import TransactionsTable from "../Table/Transactions/TransactionsTable";

function WalletPage() {
	const { session } = useAuth();
	const [visible, setVisible] = useState(false);

	const toggleVisibility = () => {
		setVisible(!visible);
	};
	const style = {
		background: "#ffffff",
		padding: "20px 20px 35px 20px",
		borderRadius: "12px",
	};

	const dispatch = useDispatch();

	const { searchValue, startDate, endDate, status } = useSelector(
		(state) => state.transactionsTableReducer
	);
	const dateFormat = "YYYY-MM-DD";

	const { data: transactions, isLoading } = useQuery(
		`all_transactions-start=${startDate}&end=${endDate}`,
		() =>
			Requests.fetchWithAuthToken(
				`/lms/transaction?id=all&start=${moment(startDate).format(
					dateFormat
				)}&end=${moment(endDate).format(dateFormat)}`,
				"GET",
				session.session.token
			)
	);

	const SearchQuery = (data) => {
		const firstQuery = data?.filter((transaction) => {
			const name = `${transaction?.borrower}`;
			if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
				return transaction;
			}
			return null;
		});

		return firstQuery?.filter((data) => {
			if (status && status !== "all") {
				return data?.paystack?.status.toString() === status;
			}
			return true;
		});
	};

	useEffect(() => {
		if (transactions?.status === "success") {
			dispatch({
				type: ADDALLTRANSACTIONS,
				value: SearchQuery(transactions?.data),
			});
		}
		// eslint-disable-next-line
	}, [isLoading]);

	useEffect(() => {
		dispatch({
			type: ADDALLTRANSACTIONS,
			value: SearchQuery(transactions?.data),
		});

		// eslint-disable-next-line
	}, [searchValue]);

	const { data } = useQuery(
		"wallet",
		() =>
			Request.fetchWithAuthToken("/lms/wallet", "GET", session.session.token),
		{ refetchOnWindowFocus: false }
	);

	const disbursmentIcon = () => {
		return <CreditCardOutlined />;
	};

	const collectionIcon = () => {
		return <AppstoreOutlined />;
	};

	const serviceIcon = () => {
		return <ToolOutlined />;
	};

	return (
		<Row gutter={[30, 30]}>
			<Col span={24}>
				<div style={style}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<p
							style={{
								color: "#2E2C34",
								fontSize: "24px",
								fontWeight: "bold",
								marginBottom: "20px",
							}}
						>
							Wallet Balances
						</p>
						<PlusCircleOutlined
							style={{
								fontSize: "24px",
								cursor: "pointer",
								marginBottom: "20px",
							}}
							onClick={toggleVisibility}
						/>
					</div>
					<Row gutter={16}>
						<WalletCard
							amount={data?.data?.disbursement_wallet}
							name="Disbursement"
							icon={disbursmentIcon()}
							color="rgb(60, 64, 102)"
						/>
						<WalletCard
							amount={data?.data?.collection_wallet}
							name="Collection"
							icon={collectionIcon()}
							color="rgb(0, 156, 119)"
						/>
						<WalletCard
							amount={data?.data?.services_wallet}
							name="Services"
							icon={serviceIcon()}
							color="rgb(81, 82, 166)"
						/>
					</Row>
				</div>
			</Col>
			<Col>{visible ? <WalletTopup toggle={toggleVisibility} /> : ""}</Col>
			<Col span={24}>
				<div style={style}>
					<WalletTableHeader />
					<TransactionsTable isLoading={isLoading} />
				</div>
			</Col>
		</Row>
	);
}

export default WalletPage;
