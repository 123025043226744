import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, InputNumber, Input, Drawer } from "antd";
import { useAuth } from "../../../../../context/AuthContext";
import { useMutation } from "react-query";
import Requests from "../../../../../utils/request";
import TextArea from "antd/lib/input/TextArea";
import { Title } from "../../../../Typography";
import { useParams } from "react-router-dom";

const AddNextOfKin = ({ refetchNextOfKin, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const nextOfKinRef = React.createRef();

  const mutationAddNextOfKin = useMutation((next_of_kin) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=next-of-kin`,
      "POST",
      session.session.token,
      next_of_kin
    )
  );

  useEffect(() => {
    nextOfKinRef?.current?.setFieldsValue({
      firstname : data?.firstname,
      lastname: data?.lastname,
      email: data?.email,
      house_address: data?.house_address,
      phone_number: data?.phone_number && data?.phone_number.toString(),
    });
  }, [nextOfKinRef, data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationAddNextOfKin.mutate([values], {
      onSuccess: (data) => {
        refetchNextOfKin();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Next of Kin
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Next of Kin</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={nextOfKinRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="First Name"
                name="firstname"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
              >
                <Input size="large" type="email" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="House Address"
                name="house_address"
              >
                <TextArea size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Phone Number"
                name="phone"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddNextOfKin;