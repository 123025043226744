import React from "react";
import { Table, Tag } from "antd";
import { useSelector } from "react-redux";
import strip from "../../../utils/StripWords";
import timeDate from "../../../utils/TimeDate";

function LogTable({isLoading}) {
  const { logs } = useSelector((state) => state.activityLogTableReducer );
  const { profile } = useSelector((state) => state.profileReducer );
  
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <div>
          <span>{record.id}</span>
        </div>
      ),
    },
    {
      title: "Log Reference",
      dataIndex: "reference",
      key: "reference",
      render: (_, record) => (
        <div>
          <span>{record.reference}</span>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "name",
      render: (_, record) => (
        <div>
          <span>{record.who}</span>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "org_msg",
      key: "org_msg",
      render: (_, record) => (
        <div>
          <span>{record.org_msg}</span>
        </div>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() > new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },
    {
      title: "Log Type",
      dataIndex: "activated",
      key: "activated",
      render: (_, record) => {
        let text = record.action;

        return (
          <Tag key={record.action}>
            {strip(text)}
          </Tag>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => {
    //         if(record.action.includes('product')){
    //           history.push(`/product/${record.id}`);
    //         }
    //       }}
    //     >
    //       View
    //     </Button>
    //   ),
    // },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.who,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={logs}
        loading={isLoading}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
        rowKey="reference"
      />
    </>
  );
}

export default LogTable;
