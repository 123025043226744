export function Money(props) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect width={24} height={24} rx={12} fill="#FBFAFC" fillOpacity={0.1} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 8a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1H8zm3.5 6.5h1V14h.5c.275 0 .5-.225.5-.5V12c0-.275-.225-.5-.5-.5h-1.5V11h2v-1h-1v-.5h-1v.5H11c-.275 0-.5.225-.5.5V12c0 .275.225.5.5.5h1.5v.5h-2v1h1v.5z"
          fill="#fff"
        />
      </svg>
    )
  }
  
  export function SwithAccount(props) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          opacity={0.1}
          x={0.5}
          width={24}
          height={24}
          rx={12}
          fill="#11C096"
        />
        <path
          d="M8.5 9h-1v7c0 .55.45 1 1 1h7v-1h-7V9zm8-2h-6c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1zm-3 1c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5zm3 6h-6v-.75c0-.995 2-1.5 3-1.5s3 .505 3 1.5V14z"
          fill="#11C096"
        />
      </svg>
    )
  }
  
  export function Receipt(props) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect width={24} height={24} rx={12} fill="#5542F6" fillOpacity={0.1} />
        <path
          d="M15 14.5H9v-1h6v1zm0-2H9v-1h6v1zm0-2H9v-1h6v1zM7.5 17l.75-.75L9 17l.75-.75.75.75.75-.75.75.75.75-.75.75.75.75-.75.75.75.75-.75.75.75V7l-.75.75L15 7l-.75.75L13.5 7l-.75.75L12 7l-.75.75L10.5 7l-.75.75L9 7l-.75.75L7.5 7v10z"
          fill="#5542F6"
        />
      </svg>
    )
  }
  
  export function Undo(props) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={0.5}
          width={24}
          height={24}
          rx={12}
          fill="#FF4B4B"
          fillOpacity={0.1}
        />
        <path
          d="M12.75 10a5.232 5.232 0 00-3.45 1.3L7.5 9.5V14H12l-1.81-1.81c.695-.58 1.58-.94 2.56-.94a4.006 4.006 0 013.8 2.75l1.185-.39A5.257 5.257 0 0012.75 10z"
          fill="#FF4B4B"
        />
      </svg>
    )
  }
  
  