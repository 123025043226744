export const ADDALLGURANTORS = "ADDALLGURANTORS";
export const GURANTORSERACHVALUE = "GURANTORSERACHVALUE";
export const SETENDDATE = "SETENDDATE";
export const SETSTARTDATE = "SETSTARTDATE";
export const ADDALLLOANPRODUCT = "ADDALLLOANPRODUCT";
export const LOANPRODUCTSEARCHVALUE = " LOANPRODUCTSEARCHVALUE";
export const ADDALLLOANREQUEST = "ADDALLLOANREQUEST";
export const LOANREQUESTSEARCHVALUE = "LOANREQUESTSEARCHVALUE";
export const SETLOANREQUESTSTARTDATE = "SETLOANREQUESTSTARTDATE";
export const SETLOANREQUESTENDDATE = "SETLOANREQUESTENDDATE";
export const SETLOANSTATUSTOSEARCHFOR = "SETLOANSTATUSTOSEARCHFOR";
export const SETGURANTORSTARTDATE = "SETGURANTORSTARTDATE";
export const SETGURANTORENDDATE = "SETGURANTORENDDATE";
export const SETDISBURSEMENTSTARTDATE = "SETDISBURSEMENTSTARTDATE";
export const SETDISBURSEMENTENDDATE = "SETDISBURSEMENTENDDATE";
export const SETREPAYMENTSTARTDATE = "SETREPAYMENTSTARTDATE";
export const SETREPAYMENTENDDATE = "SETREPAYMENTENDDATE";
export const ADDALLDISBURSEMENTS = "ADDALLDISBURSEMENTS";
export const DISBURSEMENTSEARCHVALUE = "DISBURSEMENTSEARCHVALUE";
export const SETDISBURSEMENTSTATUSTOSEARCHFOR =
	"SETDISBURSEMENTSTATUSTOSEARCHFOR";
export const ADDALLREPAYEMENTS = "ADDALLREPAYEMENTS";
export const REPAYMENTSEARCHVALUE = "REPAYEMENTSEARCHVALUE";
export const SETALLREPAYMENTSTATUSTOSEARCHFOR =
	"SETALLREPAYMENTSTATUSTOSEARCHFOR";
export const ADDALLBANKSTATEMENTS = "ADDALLBANKSTATEMENTS";
export const ADDALLLOANS = "ADDALLLOANS";
export const SETLOANSTARTDATE = "SETLOANSTARTDATE";
export const SETLOANENDDATE = "SETLOANENDDATE";
export const ALLLOANSEARCHVALUE = "ALLLOANSEARCHVALUE";
export const SETALLLOANSTATUS = "SETALLLOANSTATUS";
export const ADDTRANSACTIONS = "ADDTRANSACTIONS";
export const SETTRANSACTIONSTATUS = "SETTRANSACTIONSTATUS";
export const ALLTRANSACTIONSEARCHVALUE = "ALLTRANSACTIONSEARCHVALUE";
export const ADDALLOVERVIEWLOANREQUEST = "ADDALLOVERVIEWLOANREQUEST";
export const OVERVIEWLOANREQUESTSEARCHVALUE = "OVERVIEWLOANREQUESTSEARCHVALUE";
export const SETOVERVIEWENDDATE = "SETOVERVIEWENDDATE ";
export const SETOVERVIEWSTARTDATE = "SETOVERVIEWSTARTDATE";
export const SETOVERVIEWLOANSTATUSTOSEARCHFOR =
	"SETOVERVIEWLOANSTATUSTOSEARCHFOR ";
export const ADDALLOVERVIEWCREDITORDER = "ADDALLOVERVIEWCREDITORDER";
export const OVERVIEWCREDITORDERSEARCHVALUE = "OVERVIEWCREDITORDERSEARCHVALUE";
export const SETOVERVIEWORDERSTATUSTOSEARCHFOR =
	"SETOVERVIEWORDERSTATUSTOSEARCHFOR ";
export const ADDALLTRANSACTIONS = "ADDALLTRANSACTIONS";
export const TRANSACTIONSEARCHVALUE = "TRANSACTIONSEARCHVALUE";
export const SETTRANSACTIONSTARTDATE = "SETTRANSACTIONSTARTDATE";
export const SETTRANSACTIONENDDATE = "SETTRANSACTIONENDDATE";
export const ADDALLTEAMS = "ADDALLTEAMS";
export const SETTEAMSTATUSTOSEARCHFOR = "SETTEAMSTATUSTOSEARCHFOR";
export const ALLTEAMSEARCHVALUE = " ALLTEAMSEARCHVALUE";
export const ADDSINGLEREPAYMENTS = "ADDSINGLEREPAYMENTS";
export const SINGLEREPAYMENTSEARCHVALUE = "SINGLEREPAYMENTSEARCHVALUE";
export const SETREPAYMENTSTATUSTOSEARCHFOR = "SETREPAYMENTSTATUSTOSEARCHFOR";
export const SETSINGLEREPAYMENTENDDATE = "SETSINGLEREPAYMENTENDDATE";
export const SETSINGLEREPAYMENTSTARTDATE = "SETSINGLEREPAYMENTSTARTDATE";
export const SETSINGLEREPAYMENTSTATUS = "SETSINGLEREPAYMENTSTATUS";
export const ADDALLPORTFOLIOS = "ADDALLPORTFOLIOS";
export const ADDWALLETTRANSACTIONS = "ADDWALLETTRANSACTIONS";
export const SETWALLETENDDATE = "SETWALLETENDDATE";
export const SETWALLETSTARTDATE = "SETWALLETSTARTDATE";
export const WALLETSEARCHVALUE = "WALLETSEARCHVALUE";
export const SETWALLETSTATUS = "SETWALLETSTATUS";
export const ADDALLCREDITORDER = "ADDALLCREDITORDER";
export const CREDITORDERSEARCHVALUE = "CREDITORDERSEARCHVALUE";
export const SETCREDITORDERENDDATE = "SETCREDITORDERENDDATE";
export const SETCREDITORDERSTARTDATE = "SETCREDITORDERSTARTDATE";
export const SETCREDITSTATUSTOSEARCHFOR = "SETCREDITSTATUSTOSEARCHFOR";
export const ADDALLFULFILMENT = "ADDALLFULFILMENT";
export const FULFILMENTSEARCHVALUE = "FULFILMENTSEARCHVALUE";
export const SETFULFILMENTENDDATE = "SETFULFILMENTENDDATE";
export const SETFULFILMENTSTARTDATE = "SETFULFILMENTSTARTDATE";
export const SETFULFILMENTSTATUSTOSEARCHFOR =
	"SETFULLFILLMENTSTATUSTOSEARCHFOR";
export const ADDACTIVITYLOG = "ADDACTIVITYLOG";
export const ALLACTIVITYLOGSEARCHVALUE = "ALLACTIVITYLOGSEARCHVALUE";
export const SETACTIVITYLOGENDDATE = "SETACTIVITYLOGENDDATE";
export const SETACTIVITYLOGSTARTDATE = "SETACTIVITYLOGSTARTDATE";
export const SETACTIVITYLOGSTATUS = "SETACTIVITYLOGSTATUS";
export const ADDALLBORROWER = "ADDALLBORROWER";
export const ALLBORROWERSEARCHVALUE = "ALLBORROWERSEARCHVALUE";
export const SETBORROWERENDDATE = "SETBORROWERENDDATE";
export const SETBORROWERSTARTDATE = "SETBORROWERSTARTDATE";
export const SETBORROWERSTATUS = "SETBORROWERSTATUS";
export const ADDALLLOANAPPLICATIONS = "ADDALLLOANAPPLICATIONS";
export const ALLLOANAPPLICATIONSEARCHVALUE = "ALLLOANAPPLICATIONSEARCHVALUE";
export const SETLOANAPPLICATIONSENDDATE = "SETLOANAPPLICATIONSENDDATE";
export const SETLOANAPPLICATIONSSTARTDATE = "SETLOANAPPLICATIONSSTARTDATE";
export const SETLOANAPPLICATIONSTATUS = "SETLOANAPPLICATIONSTATUS";
export const ADDALLBORROWERSCREDIT = "ADDALLBORROWERSCREDIT";
export const ALLBORROWERSCREDITSEARCHVALUE = "ALLBORROWERSCREDITSEARCHVALUE";
export const SETBORROWERSCREDITENDDATE = "SETBORROWERSCREDITENDDATE";
export const SETBORROWERSCREDITSTARTDATE = "SETBORROWERSCREDITSTARTDATE";
export const SETBORROWERSCREDITSTATUS = "SETBORROWERSCREDITSTATUS";
export const ALLNOTESEARCHVALUE = "ALLNOTESEARCHVALUE";
export const SETNOTESTATUSTOSEARCHFOR = "SETNOTESTATUSTOSEARCHFOR";
export const ALLNOTES = "ALLNOTES";
export const ADDALLLOANREQUESTGUARANTORS = "ADDALLLOANREQUESTGUARANTORS";
export const LOANREQUESTGUARANTORSSEARCHVALUE =
	"LOANREQUESTGUARANTORSSEARCHVALUE";
export const SETLOANREQUESTGUARANTORSENDDATE =
	"SETLOANREQUESTGUARANTORSENDDATE";
export const SETLOANREQUESTGUARANTORSSTARTDATE =
	"SETLOANREQUESTGUARANTORSSTARTDATE";
export const SETLOANREQUESTGUARANTORSSTATUS = "SETLOANREQUESTGUARANTORSSTATUS";
export const ADDALLAGENT = "ADDALLAGENT";
export const ALLAGENTSEARCHVALUE = "ALLAGENTSEARCHVALUE";
export const SETAGENTSTATUSTOSEARCHFOR = "SETAGENTSTATUSTOSEARCHFOR";
export const ADDALLLOANREQUESTCOLLATERAL = "ADDALLLOANREQUESTCOLLATERAL";
export const LOANREQUESTCOLLATERALSEARCHVALUE =
	"LOANREQUESTCOLLATERALSEARCHVALUE";
export const SETLOANREQUESTCOLLATERALSTATUS = "SETLOANREQUESTCOLLATERALSTATUS";
export const ADDALLCOLLATERALS = "ADDALLCOLLATERALS";
export const COLLATERALSEARCHVALUE = "COLLATERALSEARCHVALUE";
export const SETCOLLATERALSTATUS = "SETCOLLATERALSTATUS";
export const ADDLOANLISTING = "ADDLOANLISTING";
export const SETLOANLISTINGENDDATE = "SETLOANLISTINGENDDATE";
export const SETLOANLISTINGSTARTDATE = "SETLOANLISTINGSTARTDATE";
export const SETLISTINGLOANOFFICER = "SETLISTINGLOANOFFICER";
export const ADDPAR = "ADDPAR";
export const SETPARENDDATE = "SETPARENDDATE";
export const SETPARSTARTDATE = "SETPARSTARTDATE";
export const SETPARLOANOFFICER = "SETPARLOANOFFICER";
export const ADDDELINQUENCY = "ADDDELINQUENCY";
export const SETDELINQUENCYENDDATE = "SETDELINQUENCYENDDATE";
export const SETDELINQUENCYSTARTDATE = "SETDELINQUENCYSTARTDATE";
export const SETDELINQENCYLOANOFFICER = "SETDELINQENCYLOANOFFICER";
export const ADDALLBORROWERSLOAN = "ADDALLBORROWERSLOAN";
export const ALLBORROWERSLOANSEARCHVALUE = "ALLBORROWERSLOANSEARCHVALUE";
export const SETBORROWERSLOANSENDDATE = "SETBORROWERSLOANSENDDATE";
export const SETBORROWERSLOANSTARTDATE = "SETBORROWERSLOANSTARTDATE";
export const SETBORROWERSLOANSTATUS = "SETBORROWERSLOANSTATUS";
export const ADDALLBORROWERSREPAYMENT = "ADDALLBORROWERSREPAYMENT";
export const ALLBORROWERSREPAYMENTSEARCHVALUE =
	"ALLBORROWERSREPAYMENTSEARCHVALUE";
export const SETBORROWERSREPAYMENTSENDDATE = "SETBORROWERSREPAYMENTSENDDATE";
export const SETBORROWERSREPAYMENTSTARTDATE = "SETBORROWERSREPAYMENTSTARTDATE";
export const SETBORROWERSREPAYMENTSTATUS = "SETBORROWERSREPAYMENTSTATUS";
export const ADDALLREQUESTLOGS = "ADDALLREQUESTLOGS";