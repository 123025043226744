import { Tabs } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { SETDISBURSEMENTSTATUSTOSEARCHFOR, SETFULFILMENTSTATUSTOSEARCHFOR } from "../../redux/Types/tableTypes";
import DisbursementTable from "../Table/Disbursements/DisbursementTable";
import FulfilmentsTable from "../Table/Fulfillments/FulfillmentsTable";
import TableHeader from "./TableHeader";

const { TabPane } = Tabs;

const DisbursementsTable = ({ isLoading, isLoadingFulfilments}) => {
  const { session } = useAuth();
  const dispatch = useDispatch();

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETDISBURSEMENTSTATUSTOSEARCHFOR, value: val });
  };

  const handleOrderFilterByStatus = (val) => {
    dispatch({ type: SETFULFILMENTSTATUSTOSEARCHFOR, value: val });
  };
  return (
    <>
        <TableHeader />
        {session?.admin?.category === "merchant" ? (
          <>
            <Tabs 
              size="default"
              type="card"
              onTabClick={(key) => handleOrderFilterByStatus(key)}
              style={{ marginTop: "20px", marginBottom: "0" }}
            >
              <TabPane tab="All Fulfillments" key="">
                <FulfilmentsTable isLoading={isLoadingFulfilments} />
              </TabPane>
              <TabPane tab="Fulfilled Orders" key="true">
                <FulfilmentsTable isLoading={isLoadingFulfilments} />
              </TabPane>
              <TabPane tab="Failed Fulfillments" key="false">
                <FulfilmentsTable isLoading={isLoadingFulfilments} />
              </TabPane>
            </Tabs>
          </>
        ) : (
          <>
            <Tabs 
               size="default"
               type="card"
               onTabClick={(key) => handleFilterByStatus(key)}
               style={{ marginTop: "20px", marginBottom: "0" }}
            >
              <TabPane tab="All Disbursements" key="">
                <DisbursementTable isLoading={isLoading} />
              </TabPane>
              <TabPane tab="Successful Disbursements" key="disbursed">
                <DisbursementTable isLoading={isLoading} />
              </TabPane>
              <TabPane tab="Failed Disbursements" key="failed">
                <DisbursementTable isLoading={isLoading} />
              </TabPane>
            </Tabs>
          </>
        )}
    </>
  )
}

export default DisbursementsTable;