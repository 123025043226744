import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { Card, Form, Col, Row, Button, message } from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import useGenerateComponent from "../../../../utils/componentGenerator";
import { USERSELECTCOUNTRY } from "../../../../redux/Types/generalTypes";
import { Title } from "../../../Typography";
const profileform = React.createRef();

function Profile({ currentNumber, id, setCurrentNumber, userID }) {
  const dispatch = useDispatch();
  const { session } = useAuth();
  const { currentLoan, userCountry } = useSelector(
    (state) => state.LoanApplicationReducer
  );
  const [loading, setIsLoading] = useState(false);
  const { createProfile, updateProgress } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();
  const { data } = useQuery(
    `profile`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=profile`,
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      if (currentNumber !== "end") {
        setCurrentNumber((prev) => {
          const current = Number(prev) + 1;
          return current.toString();
        });
        updateProgress(
          { progress: String(Number(currentNumber) + 1), loan_requestID: id },
          (data) => data
        );
      }
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);
    createProfile({ value, loan_requestID: userID }, handleSubmitCallback);
  };

  const generateFormObject = (fields, profile) => {
    const formObject = {};
    fields.forEach((field) => {
      formObject[field] = profile[field];
    });

    return formObject;
  };

  useEffect(() => {
    if (data) {
      const fields = Object.keys(data?.data || {});
      const profile = data?.data;
      const formObj = generateFormObject(fields, profile);
      dispatch({
        type: USERSELECTCOUNTRY,
        value: data?.data?.citizenship,
      });
      profileform?.current?.setFieldsValue({
        ...formObj,
        dob: moment(
          profile?.dob ? profile?.dob : moment().format("YYYY-MM-DD")
        ),
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Card>
      <Title level={3}>Your Profile</Title>
      <Form ref={profileform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          {currentLoan?.segments["additional-information"]?.fields?.map(
            (field, index) =>
              field?.is_needed && (
                <Col xs={24} md={12} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(
                      field,
                      {
                        ref: profileform,
                        fieldName: field?.field,
                      },
                      dispatch,
                      { userCountry }
                    )}
                  </Form.Item>
                </Col>
              )
          )}
        </Row>

        <Button loading={loading} size="large" type="primary" htmlType="submit">
          Save and Continue
        </Button>
      </Form>
    </Card>
  );
}

export default Profile;
