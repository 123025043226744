import moment from "moment";
import {
  ADDTRANSACTIONS,
  ALLTRANSACTIONSEARCHVALUE,
  SETTRANSACTIONENDDATE,
  SETTRANSACTIONSTARTDATE,
  SETLOANSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  transactions: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"),
};

export const allTransactionsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDTRANSACTIONS) {
    return { ...state, loans: actions.value };
  }

  if (actions.type === ALLTRANSACTIONSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETLOANSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETTRANSACTIONENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETTRANSACTIONSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
