import React, { useState, useReducer, useEffect, useRef } from "react";
import Progress from "./ProgressStep";
import { initialState, reducer } from "./reducers/LoanTemplateReducer";
import { useMutation, useQuery } from "react-query";
import Request from "../../utils/request";
import { useAuth } from "../../context/AuthContext";
import { Form, Input, Select, Row, Col, Card, message, Button } from "antd";
import create from "./adminIcons/create.svg";
import Pop from "./Pop";
import ButtonComponent from "./ButtonComponent";
import { useHistory } from "react-router-dom";

const { Option } = Select;

function LoanProduct({ isModal, closeAble }) {
  const { session } = useAuth();
  const productForm = useRef();
  const currentType =
    session.admin.category === "lender" ? "business-loan" : "credit-order";
  let history = useHistory();
  const [visible, setVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [name, setName] = useState("");
  const [loanType, setLoanType] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);

  const togglePop = () => {
    if (name) {
      setVisible((prev) => !prev);
      return;
    }
    message.error("Product name is required");
  };

  const mutationCreatProduct = useMutation((product) =>
    Request.fetchWithAuthToken(
      "/lms/product",
      "POST",
      session.session.token,
      product
    )
  );

  const { data: business_loan, isLoading: isLoadingB_loan } = useQuery(
    "business-loan",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=business-loan",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: credit_order, isLoading: isLoadingC_order } = useQuery(
    "credit-order",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=credit-order",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: personal_loan } = useQuery(
    "personal-loan",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=personal-loan",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: health_loan } = useQuery(
    "health-loan",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=health-loan",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const cleanTemplateToFitState = (template) => {
    const templateObject = {};
    template.forEach((field) => {
      templateObject[field.segment] = field;
    });

    return templateObject;
  };

  const handleSetupTemplate = (type) => {
    if (type === "business-loan") {
      dispatch({
        type: "init",
        template: business_loan?.data
          ? cleanTemplateToFitState(business_loan?.data)
          : {},
      });

      return;
    }

    if (type === "personal-loan") {
      dispatch({
        type: "init",
        template: personal_loan?.data
          ? cleanTemplateToFitState(personal_loan?.data)
          : {},
      });
      return;
    }

    if (type === "health-loan") {
      dispatch({
        type: "init",
        template: health_loan?.data
          ? cleanTemplateToFitState(health_loan?.data)
          : {},
      });
      return;
    }

    if (type === "credit-order") {
      dispatch({
        type: "init",
        template: credit_order?.data
          ? cleanTemplateToFitState(credit_order?.data)
          : {},
      });
      return;
    }
  };

  const SaveTemplate = ({ preffered_url, name, type }) => {
    setProcessing(true);
    const payload = {
      name,
      preffered_url,
      type: loanType,
      segments: Object.values(state),
    };
    mutationCreatProduct.mutate(payload, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 3);
          return;
        }

        setProcessing(false);
        message.success(data.message, 3);
        setVisible(false);
        isModal
          ? closeAble()
          : history.push(`/products/${data?.data1?.product_id}`);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    productForm.current?.setFieldsValue({
      loanType: currentType,
    });
    setLoanType(currentType);
    handleSetupTemplate(currentType);
    // eslint-disable-next-line
  }, [isLoadingB_loan, isLoadingC_order]);

  return (
    <>
      <Row gutter={16}>
        <Col span={isModal ? 24 : 18}>
          <Card>
            <div
              style={{
                background: "#F5F6FF",
                border: "1px solid #E9E9F1",
                display: "flex",
                borderRadius: "10px",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "20px",
                justifyContent: "center",
                paddingTop: "10px",
                marginBottom: "50px",
              }}
            >
              <img
                src={create}
                alt="group"
                style={{ marginTop: "-15px" }}
              ></img>
              <p style={{ padding: "0 40px 0 30px", fontSize: "15px" }}>
                Create a befitting product name and then select the product type
                of your loan product
              </p>
            </div>

            <Form layout="vertical" onFinish={SaveTemplate} ref={productForm}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Product name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="EC Biz Loan"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Select a Product Type/Template"
                    name="loanType"
                    style={{ marginBottom: "10px" }}
                    rules={[
                      {
                        required: true,
                        message: "required",
                      },
                    ]}
                  >
                    <Select
                      onChange={(val) => {
                        setLoanType(val);
                        handleSetupTemplate(val);
                      }}
                    >
                      {session.admin.category === "lender" && (
                        <Option value="business-loan">Business Loan</Option>
                      )}
                      {session.admin.category === "lender" && (
                        <Option value="personal-loan">Personal Loan</Option>
                      )}

                      {session.admin.category === "lender" && (
                        <Option value="health-loan">Health Loan</Option>
                      )}

                      {session.admin.category === "merchant" && (
                        <Option value="credit-order">Credit Order</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {isModal ? (
                <div className="flex justify-end">
                  <Button
                    onClick={togglePop}
                    type="primary"
                    loading={isProcessing}
                  >
                    Proceed
                  </Button>
                </div>
              ) : (
                <ButtonComponent
                  onClick={togglePop}
                  isNotSubmitBTN={true}
                  text="Proceed"
                  loading={isProcessing}
                />
              )}
            </Form>
          </Card>
        </Col>
        {!isModal && (
          <Col span={6}>
            <Progress step="Product" />
          </Col>
        )}
      </Row>

      <Pop
        toggle={togglePop}
        visible={visible}
        loading={isProcessing}
        loanType={loanType}
        template={state}
        dispatch={dispatch}
        onClick={() => productForm.current.submit()}
      />
    </>
  );
}

export default LoanProduct;
