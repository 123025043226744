import { CaretRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";

function Header({
  title,
  next = () => null,
  onSave = () => null,
  removeSafeForLater,
}) {
  const history = useHistory();
  return (
    <>
      <Breadcrumbs />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>{title}</h2>
        <div
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          {!removeSafeForLater && (
            <Button
              onClick={onSave}
              size="large"
              style={{
                marginRight: "20px",
                background: "#fff",
              }}
            >
              Save for Later
            </Button>
          )}
          <Button
            onClick={() => history.push(next)}
            size="large"
            style={{
              marginRight: "20px",
              background: "#5152A6",
              color: "#fff",
              border: "none",
            }}
          >
            Continue <CaretRightOutlined />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Header;
