import React from "react";
import { CalenderIcon } from "../Loan/LoanIcons";
import { Title } from "../Typography";
import { Select, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  ALLACTIVITYLOGSEARCHVALUE,
  SETACTIVITYLOGENDDATE,
  SETACTIVITYLOGSTARTDATE,
  SETACTIVITYLOGSTATUS,
} from "../../redux/Types/tableTypes";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import strip from "../../utils/StripWords";

export function LogHeader() {
  const d = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[d.getMonth()];
  const day = d.getDate();
  const year = d.getFullYear();

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Title level={2}>Activity Logs</Title>
      <div
        style={{
          display: "flex",
          borderRadius: "6px",
          border: "1px solid #EBEAED",
          alignItems: "center",
          padding: "8px 20px",
          width: "25%",
          background: "#fff",
          height: "50px",
        }}
      >
        <CalenderIcon />
        <p style={{ margin: "0", marginLeft: "10px" }}>
          {month} {day}, {year}
        </p>
      </div>
    </div>
  );
}

export function LogTableHeader() {
  const { session } = useAuth();
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.activityLogTableReducer
  );

  const { data: logType } = useQuery("logType", () =>
    Requests.fetchWithAuthToken(
      "/lms/utils/log_type",
      "GET",
      session.session.token
    )
  );

  const handleSearch = (e) => {
    dispatch({ type: ALLACTIVITYLOGSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETACTIVITYLOGSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETACTIVITYLOGENDDATE, value: e.endDate });
  };

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETACTIVITYLOGSTATUS, value: val });
  };

  const { Option } = Select;

  return (
    <div>
      <h3
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
      >
        Logs
      </h3>
      <p style={{ fontSize: "15px", marginBottom: "30px", marginTop: "0" }}>
        Track & Manage your logs on Configure.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            showSearch
            allowClear
            style={{ cursor: "pointer" }}
            size="large"
            placeholder="Filter by log type"
            optionFilterProp="children"
            onChange={(val) => handleFilterByStatus(val)}
          >
            {logType?.data.map((type) => (
              <Option value={type}>{strip(type)}</Option>
            ))}
          </Select>
          <Input
            size="large"
            placeholder="Search by name..."
            style={{ width: "75%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
        </div>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
          style={{ marginRight: "10px", width: "250px" }}
          size="large"
        />
      </div>
    </div>
  );
}
