import {
  ADDALLREQUESTLOGS,
} from "../../Types/tableTypes";

const initialState = {
  activitylogs: [],
};

export const requestLogsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLREQUESTLOGS) {
    return { ...state, activitylogs: actions.value }
  }
  return state;
};
