import moment from "moment";
import {
  ADDLOANLISTING,
  SETLOANLISTINGENDDATE,
  SETLOANLISTINGSTARTDATE,
  SETLISTINGLOANOFFICER,
} from "../../Types/tableTypes";

const initialState = {
  loanListing: [],
  officerId: 0,
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const loanListingTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDLOANLISTING) {
    return { ...state, loanListing: actions.value };
  }
  if (actions.type === SETLOANLISTINGENDDATE) {
    return { ...state, endDate: actions.value };
  }
  if (actions.type === SETLOANLISTINGSTARTDATE) {
    return { ...state, startDate: actions.value };
  }
  if (actions.type === SETLISTINGLOANOFFICER) {
    return { ...state, officerId: actions.value };
  }

  return state;
};
