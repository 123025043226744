import {
  LOANPRODUCTSEARCHVALUE,
  ADDALLLOANPRODUCT,
} from "../../Types/tableTypes";

const initialState = { products: [], searchValue: "" };

export const loanProductTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANPRODUCT) {
    return { ...state, products: actions.value };
  }

  if (actions.type === LOANPRODUCTSEARCHVALUE) {
    return {
      ...state,
      searchValue: actions.value,
    };
  }
  return state;
};
