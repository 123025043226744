import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
// import BsaCard from "../../components/BSA/BsaCard";
// import Chartprofit from "../../assets/svg/Chartprofit.svg";
// import Chartinterest from "../../assets/svg/Chartinterest.svg";
import BankDetailsCard from "../../components/BSA/BankDetailsCard";
import { currencyFormatter } from "../../utils/currencyFormatter";
import ChannelBreakDownTable from "../../components/Table/BSA/ChannelBreakDown";
import MonthlyTransactionTable from "../../components/Table/BSA/MonthlyTransactionTable";
import InflowAndOutFlow from "../../components/BSA/InflowAndOutFlow";
import AllTransaction from "../../components/Table/BSA/AllTransaction";
import { TableProvider } from "../../context/useTable";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import timeDate from "../../utils/TimeDate";
import BackBtn from "../../utils/BackButton";
function Analyser(props) {
  const { id, method } = useParams();

  const { session } = useAuth();

  const { data } = useQuery(
    `total-flow`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/analysis?method=${method}&analysis=total-flow`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: account_info } = useQuery(
    `account-info`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/analysis?method=${method}&analysis=info`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="p-3">
      <div className="my-3">
        <BackBtn />
      </div>
      {/* <section className="mb-5">
        <Row gutter={[10, 10]}>
          <Col xs={24} md={24} lg={12} xl={8}>
            <BsaCard
              number={"100%"}
              subText={"Bank Statement Security"}
              subTextColor="#5152A6"
              icon={Chartinterest}
              description={
                <p>
                  This bank statement has a 0% tendency to have been tampered
                  with.
                </p>
              }
            />
          </Col>

          <Col xs={24} md={24} lg={12} xl={8}>
            <BsaCard
              number={"0%"}
              subTextColor="#179F87"
              subText={"Suspicious Transactions"}
              icon={Chartprofit}
              description={
                <p>
                  There are 0 suspicious credit transactions, 0 suspicious debit
                  transactions and 0 anomalies in total.
                </p>
              }
            />
          </Col>

          <Col xs={24} md={24} lg={12} xl={8}>
            <BsaCard
              title={"Recommendation"}
              description={
                <p>
                  If the fraud tendency is less than 20% you can ignore,
                  otherwise, please comb through the transactions. We have
                  tagged the suspicious ones for ease of identification.
                </p>
              }
            />
          </Col>
        </Row>
      </section> */}
      <section className="mb-5">
        <Row gutter={[10, 10]}>
          <Col xs={24} md={24} lg={12}>
            <BankDetailsCard
              details={[
                {
                  label: "Account Name",
                  value: account_info?.data?.account_name,
                },

                {
                  label: "Account Number",
                  value: account_info?.data?.account_number,
                },
                {
                  label: "Bank Name",
                  value: account_info?.data?.bank_name,
                },
                {
                  label: "Number of Months",
                  value: account_info?.data?.number_of_months,
                },
                {
                  label: "Start Date",
                  value: timeDate(account_info?.data?.start_date),
                },
                {
                  label: "End Date",
                  value: timeDate(account_info?.data?.end_date),
                },
                {
                  label: "Currency",
                  value: account_info?.data?.currency,
                },
              ]}
            />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <BankDetailsCard
              details={[
                {
                  label: "Total Inflow",
                  value: currencyFormatter(data?.data?.total_credits?.value),
                },

                {
                  label: "Total Outflow",
                  value: currencyFormatter(data?.data?.total_debits?.value),
                },
                {
                  label: "Average Monthly Inflow",
                  value: currencyFormatter(data?.data?.average_credit),
                },
                {
                  label: "Average Monthly Outflow",
                  value: currencyFormatter(data?.data?.average_debit),
                },
                {
                  label: "Median Debit",
                  value: currencyFormatter(0),
                },
                {
                  label: "Maximum Credit",
                  value: currencyFormatter(0),
                },
                {
                  label: "Maximum Debit",
                  value: currencyFormatter(0),
                },
              ]}
            />
          </Col>
        </Row>
      </section>

      <section className="mb-5">
        <ChannelBreakDownTable requestID={id} method={method} />
      </section>

      <section className="mb-5">
        <TableProvider>
          <AllTransaction
            requestID={id}
            method={method}
            account_name={account_info?.data?.account_name}
          />
        </TableProvider>
      </section>

      <section className="mb-5">
        <MonthlyTransactionTable
          requestID={id}
          method={method}
          account_name={account_info?.data?.account_name}
        />
      </section>

      <section className="mb-5">
        <InflowAndOutFlow requestID={id} method={method} />
      </section>
    </div>
  );
}

export default Analyser;
