import React from "react";
import { Button, Col, Tooltip } from "antd";
import { Text, Title } from "../Typography";
import { Mask } from "../Loan/LoanIcons";

function ProductCard({ icon, textColor, name, people, heading, amount }) {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };

  return (
    <Col className="gutter-row" span={8}>
      <div style={style} className="h-full">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={icon} alt={name} />
          <Text style={{ color: `${textColor}`, marginLeft: "8px" }}>
            {heading}
          </Text>
        </div>
        <Title level={3} style={{ marginLeft: "30px", marginTop: "0.8em" }}>
          {amount}
        </Title>
        <div
          style={{
            marginLeft: "30px",
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Mask />
            <Text style={{ marginLeft: "5px" }}>{people}</Text>
          </div>
          <Tooltip title="Not Available yet">
            <Button type="link">view all</Button>
          </Tooltip>
        </div>
      </div>
    </Col>
  );
}

export default ProductCard;
