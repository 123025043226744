import React from "react";
import { Row } from "antd";
import {
  Card,
  TopInsight,
  LoanProduct,
  LoanPerformance,
  RecoveryRate,
  AllLoans,
} from "../../components/Loan";
import totalLoan from "../../assets/svg/TotalLoan.svg";
import overdue from "../../assets/svg/Overdue.svg";
import approved from "../../assets/svg/Approved.svg";
import repaid from "../../assets/svg/Repaid.svg";
import { LoanHeader } from "../../components/Loan/Header";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useSelector } from "react-redux";
import moment from "moment";

function Loans(props) {
  const { session } = useAuth();
  const { endDate, startDate } = useSelector(
    (state) => state.allLoanTableReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: mainMetrics } = useQuery(
    `main-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan/metrics?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}&section=main`,
        "GET",
        session.session.token
      )
  );
  const { data: topInsightMetrics } = useQuery(
    `top-insight-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan/metrics?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}&section=top-insights`,
        "GET",
        session.session.token
      )
  );
  const { data: topLoanProductMetrics } = useQuery(
    `top-loan-product-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan/metrics?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}&section=top-loan-products`,
        "GET",
        session.session.token
      )
  );

  return (
    <div>
      <LoanHeader />
      <Row gutter={[16, 30, 30]}>
        <Card
          heading="Total Loans"
          textColor="#3C4066"
          icon={totalLoan}
          name="totalloans"
          data={mainMetrics?.data?.total_loans}
        />
        <Card
          heading="Overdue Loans"
          textColor="#FF4B4B"
          icon={overdue}
          name="overdueloans"
          data={mainMetrics?.data?.overdue_loans}
        />
        <Card
          heading="Approved Loans"
          textColor="#5152A6"
          icon={approved}
          name="approvedloans"
          data={mainMetrics?.data?.disbursed_loans}
        />
        <Card
          heading="Repaid Loans"
          textColor="#009C77"
          icon={repaid}
          name="repaidloans"
          data={mainMetrics?.data?.repaid_loans}
        />

        <TopInsight data={topInsightMetrics?.data} />
        <LoanProduct data={topLoanProductMetrics?.data} />

        <LoanPerformance />
        <RecoveryRate data={topLoanProductMetrics?.data} />
      </Row>
      <AllLoans />
    </div>
  );
}

export default Loans;
