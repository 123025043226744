import { Row, Col, Divider, Empty } from "antd";
import moment from "moment";
import React from "react";
import strip from "../../../../utils/StripWords";

const PersonalDetails = ({profile_data}) => {
    return(
        <>
            <div className="mt-5">
                <Row gutter={[16,16]}>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">Marital Status: </h3>
                            <h3 className="capitalize font-bold text-base">{profile_data?.data1?.marital_status}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                        <h3 className="text-gray-500">DOB: </h3>
                        <h3 className="capitalize font-bold text-base">{moment(profile_data?.data1?.dob).format('MMMM Do YYYY')}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">Address: </h3>
                            <h3 className="font-bold text-base">{profile_data?.data1?.home_address}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">Email: </h3>
                            <h3 className="font-bold text-base">{profile_data?.data?.email}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">State of Residence: </h3>
                            <h3 className="font-bold text-base">{profile_data?.data1?.state_of_residence}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between  h-10 ">
                            <h3 className="text-gray-500">Citizenship: </h3>
                            <h3 className="font-bold text-base">{profile_data?.data1?.citizenship}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between  h-10 ">
                            <h3 className="text-gray-500">Highest Level Of Education: </h3>
                            <h3 className="capitalize font-bold text-base">{strip(profile_data?.data1?.highest_level_of_education)}</h3>
                       </div>
                       <Divider />
                    </Col>
                    {profile_data === null && (
                        <Col span={24}>
                            <Empty />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    )
}

export default PersonalDetails;