import React from "react";
import moment from "moment";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { KoboToNaira } from "../../utils/currencyFormatter";

function BankStatementTable({ isLoading }) {
  const { bankstatements } = useSelector(
    (state) => state.bankStatementsTableReducer
  );

  const columns = [
    {
      title: "Narration",
      width: 300,
      dataIndex: "narration",
      key: "1",
      fixed: "left",
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },

    {
      title: "Credit",
      width: 150,
      dataIndex: "credit",
      key: "2",
      render: (_, record) =>
        record.type === "credit" ? KoboToNaira(record.amount) : "0.00",
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },

    {
      title: "Debit",
      width: 150,
      dataIndex: "debit",
      key: "2",
      render: (_, record) =>
        record.type === "debit" ? KoboToNaira(record.amount) : "0.00",
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={columns}
      rowKey="_id"
      dataSource={bankstatements}
    />
  );
}

export default BankStatementTable;
