import { Row } from 'antd'
import React from 'react'
import { Text } from '../../Typography';
import RepaymentCard from './RepaymentCard'
import blue from '../../../assets/svg/InsightBlue.svg';
import green from '../../../assets/svg/InsightGreen.svg';
import yellow from '../../../assets/svg/InsightYellow.svg';

function Repayment({data, repaymentDate, repaymentAmount}) {
    const style = {
      background: "#ffffff",
      padding: "20px 20px 35px 20px",
      borderRadius: "12px", 
      margin:"30px 0"
    };
    return (
        <div style={style}>
            <Text style={{marginBottom:'20px', display:'inline-block'}}>Repayments</Text>
        <Row gutter={16}>
            <RepaymentCard heading="Total Repayment" amount={data?.total_repayment?.value} logo={blue}/>
            <RepaymentCard heading="Amount Paid so far" amount={data?.paid_so_far?.value} logo={green}/>
            <RepaymentCard heading="Next Repayment" amount={repaymentAmount} text="Next Repayment Date" logo={yellow} repaymentDate={repaymentDate} data={data}/>
        </Row>
        </div>
    )
}

export default Repayment
