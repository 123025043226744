import React, { useState } from "react";
import { Title, Paragraph } from "../Typography";
import { Row, Col, Button, Table } from "antd";
import styles from "./pricing.module.css";
import LendingNav, {
	LendingContent,
	LendingLink,
} from "./NavComponent";
import PlansCard from "./PlansCard";
import {
	CheckOutlined,
	CloseOutlined,
	DownOutlined,
	UpOutlined,
} from "@ant-design/icons";
import PlanCard, { PlanContainer, PlanContent } from "./PlanComponent";

function Plans() {
	const essentialFeature = [
		"1 user",
		"1 Loan Product",
		"1,000 Customer Records",
		"N50 per additional customer",
	];
	const essentialPlusFeature = [
		"5 users",
		"3 Loan Product",
		"5,000 Customer Records",
		"N50 per additional customer",
	];
	const professionalFeature = [
		"15 users",
		"8 Loan Product",
		"20,000 Customer Records",
		"N50 per additional customer",
	];
	const enterpriseFeature = [
		"Unlimited users",
		"Unlimited Loan Product",
		"Unlimited Customer Records",
	];

	const [active, setActive] = useState(false);
	const toggle = () => {
		setActive(!active);
	};

	const icon1 = () => {
		return (
			<CheckOutlined
				style={{ color: "green", fontWeight: "700", fontSize: "20px" }}
			/>
		);
	};

	const icon2 = () => {
		return <CloseOutlined style={{ color: "red" }} />;
	};

	const icon3 = () => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<CheckOutlined
					style={{ color: "green", fontWeight: "700", fontSize: "20px" }}
				/>{" "}
				Pay as you use
			</div>
		);
	};

	const columns = [
		{
			title: "",
			dataIndex: "feature",
			// fixed: 'left',
		},
		{
			title: "Enterprise",
			dataIndex: "enterprise",
			align: "center",
		},
		{
			title: "Professional",
			dataIndex: "professional",
			align: "center",
		},
		{
			title: "Essential Plus",
			dataIndex: "essentialplus",
			align: "center",
		},
		{
			title: "Essential",
			dataIndex: "essential",
			align: "center",
		},
	];
	const data1 = [
		{
			key: "1",
			feature: "Online Application Form Templates",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "2",
			feature: "Loan Request Management",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "3",
			feature: "Lead Generation via Online Marketplace",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "4",
			feature: "White-labelled Mobile Application",
			essential: icon2(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "5",
			feature: "Auto Generate Custom Offer Letter",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "6",
			feature: "Self-service Customer Web Portal",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
	];
	const data2 = [
		{
			key: "1",
			feature: "Rules-based Credit Scoring",
			essential: icon2(),
			essentialplus: icon2(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "2",
			feature: "Link to customers bank account",
			essential: icon3(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "3",
			feature: "PDF Bank Statement Analyser",
			essential: icon3(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "4",
			feature: "Credit Bureau Integration",
			essential: icon3(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "5",
			feature: "Alternative credit history database",
			essential: icon3(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
	];
	const data3 = [
		{
			key: "1",
			feature: "View Active Loans",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "2",
			feature: "View Pending Loans",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "3",
			feature: "View Partially Paid Loans",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "4",
			feature: "View Loans with Missed Repayments",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "5",
			feature: "View Overdue Loans",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "6",
			feature: "Add and Edit New Loans",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "7",
			feature: "Auto Loan Creation from Loan Request",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
	];
	const data4 = [
		{
			key: "1",
			feature: "Manually Disburse Funds",
			essential: icon1(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "2",
			feature: "Auto Disburse Funds",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "3",
			feature: "Auto Collection via Bank Card",
			essential: icon2(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "4",
			feature: "Auto Collection via Direct Debit Mandate/Bank Account",
			essential: icon2(),
			essentialplus: icon3(),
			professional: icon3(),
			enterprise: icon3(),
		},
		{
			key: "5",
			feature: "Electronic direct debit mandate steup",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
		{
			key: "6",
			feature: "Credit Limit Management & Approvals",
			essential: icon2(),
			essentialplus: icon1(),
			professional: icon1(),
			enterprise: icon1(),
		},
	];
	return (
		<div className={styles.plans_container}>
				<div className={styles.plans_heading}>
					<Title className={styles.plans_title}>
						An all-in-one cloud lending solution to meet your business needs.
					</Title>
					<Paragraph className={styles.plans_p}>
						Build and manage your lending business now.
					</Paragraph>
				</div>
				<div>
					<LendingNav>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<LendingLink item={1}>Billed annually</LendingLink>
							<LendingLink item={2}>Billed Monthly</LendingLink>
						</div>
						<LendingContent item={1}>
							<Row gutter={10}>
								{/* <Col>
						<div>
							<Text>Pricing Table</Text>
							<ul>{featureList}</ul>
						</div>
					</Col> */}
								<PlansCard
									title="Enterprise"
									text="A full suite solution for multi-country lending"
									price="$900"
									priceNg="450,000"
									features={enterpriseFeature}
								></PlansCard>
								<PlansCard
									title="Professional"
									text="Full suite tool with custom automations and integrations"
									price="$500"
									priceNg="250,000"
									features={professionalFeature}
								></PlansCard>
								<PlansCard
									title="Essential Plus"
									text="An integrated solution for professional lenders"
									price="$300"
									priceNg="150,000"
									features={essentialPlusFeature}
								></PlansCard>
								<PlansCard
									title="Essential"
									text="An entry tool for new lenders and startups"
									price="$180"
									priceNg="90,000"
									features={essentialFeature}
								></PlansCard>
							</Row>
						</LendingContent>

						<LendingContent item={2}>
							<Row gutter={[16, 10]}>
							
								<PlansCard
									title="Enterprise"
									text="A full suite solution for multi-country lending"
									price="$1000"
									priceNg="500,000"
									features={enterpriseFeature}
									className={styles.popup}
								></PlansCard>
								<PlansCard
									title="Professional"
									text="Full suite tool with custom automations and integrations"
									price="$600"
									priceNg="300,000"
									features={professionalFeature}
								></PlansCard>
								<PlansCard
									title="Essential Plus"
									text="An integrated solution for professional lenders"
									price="$350"
									priceNg="175,000"
									features={essentialPlusFeature}
								></PlansCard>
								<PlansCard
									title="Essential"
									text="An entry tool for new lenders and startups"
									price="$200"
									priceNg="100,000"
									features={essentialFeature}
								></PlansCard>
							</Row>
						</LendingContent>
					</LendingNav>
				</div>
				<div className={styles.plans_button}>
					<Button type="primary" size="large" onClick={toggle}>
						{active ? (
							<div>
								Hide Plans <UpOutlined />
							</div>
						) : (
							<div>
								Compare Plans <DownOutlined />
							</div>
						)}
					</Button>
				</div>
				{active ? (
					<div>
						<PlanCard>
							<PlanContainer>
								<Row>
									<Col>Generate and Manage Loan Requests Better</Col>
								</Row>
							</PlanContainer>
							<PlanContent>
								<Table
									size="small"
									dataSource={data1}
									columns={columns}
									pagination={false}
									scroll={{ x: 500 }}
								/>
							</PlanContent>
						</PlanCard>
						<PlanCard>
							<PlanContainer>
								<Row>
									<Col>Assess Creditworthiness more accurately</Col>
								</Row>
							</PlanContainer>
							<PlanContent>
								<Table
									size="small"
									dataSource={data2}
									columns={columns}
									pagination={false}
									scroll={{ x: 500 }}
								/>
							</PlanContent>
						</PlanCard>
						<PlanCard>
							<PlanContainer>
								<Row>
									<Col>Manage Customer and Loan Portfolio</Col>
								</Row>
							</PlanContainer>
							<PlanContent>
								<Table
									size="small"
									dataSource={data3}
									columns={columns}
									pagination={false}
									scroll={{ x: 500 }}
								/>
							</PlanContent>
						</PlanCard>
						<PlanCard>
							<PlanContainer>
								<Row>
									<Col>Manage Loan Disbursement and Collection Better</Col>
								</Row>
							</PlanContainer>
							<PlanContent>
								<Table
									size="small"
									dataSource={data4}
									columns={columns}
									pagination={false}
									scroll={{ x: 500 }}
								/>
							</PlanContent>
						</PlanCard>
					</div>
				) : (
					""
				)}
		</div>
	);
}

export default Plans;
