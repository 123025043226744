import { Button, Divider, Empty, Form, Input, message, Popconfirm } from "antd";
import React, { useState } from "react";
import Upload from "../../Uploader";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { Link } from "react-router-dom";
import { Title } from "../../Typography";
import useLocationQuery from "../../../utils/useQuery";
import { useParams } from "react-router-dom";
const templateRef = React.createRef();

function AddTemplate() {
  const { id } = useParams();
  let query = useLocationQuery();
  const slug = query.get("slug");
  const { session } = useAuth();
  const [isProcessing, setProcessing] = useState(false);

  const { data: offer, refetch } = useQuery("offer", () =>
    Requests.fetchWithAuthToken(
      `/lms/product?slug=${slug}`,
      "GET",
      session.session.token
    )
  );

  const addTemplate = useMutation((data) =>
    Requests.fetchWithAuthToken(
      `/lms/product/offer_letter?slug=${slug}`,
      "PUT",
      session.session.token,
      data
    )
  );
  const deleteTemplate = useMutation((body) =>
    Requests.fetchWithAuthToken(
      `/lms/product/${id}/offer_letter?template-name=${body.template}`,
      "DELETE",
      session.session.token,
      body
    )
  );
  const handleDelete = (name) => {
    deleteTemplate.mutate(
      { template: name },
      {
        onSuccess: (body) => {
          message.success(body.message, 3);
          refetch();
        },
      }
    );
  };
  const handleSubmit = (values) => {
    setProcessing(true);
    addTemplate.mutate(
      {
        ...values,
      },
      {
        onSuccess: () => {
          setProcessing(false);
          refetch();
          message.success("Template Added Successfully", 3);
        },
      }
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "45%" }}>
        <div>
          {offer?.data?.offer_letter_templates === null ? (
            <Empty
              description={<span>No Templates</span>}
              style={{ marginTop: "50px" }}
            />
          ) : (
            <>
              <Title level={4} style={{ marginTop: "20px" }}>
                {offer?.data?.name} Offer Templates
              </Title>
              <ol style={{ marginLeft: "10px", listStyleType: "disc" }}>
                {offer?.data?.offer_letter_templates?.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <li style={{ width: "60%" }}>
                      <Link to={item.template_ul} target="_blank">
                        {item.template_name}
                      </Link>
                    </li>
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => handleDelete(item.template_name)}
                    >
                      <Button type="primary">Delete</Button>
                    </Popconfirm>
                  </div>
                ))}
              </ol>
            </>
          )}
        </div>
      </div>
      <Divider type="vertical" style={{ height: "auto" }} />
      <div style={{ width: "45%" }}>
        <Title level={4} style={{ marginTop: "20px" }}>
          Add New Templates
        </Title>
        <div className="mt-5">
          <Form onFinish={handleSubmit} ref={templateRef}>
            <Form.Item
              label="New Template Name"
              name="template_name"
              rules={[
                {
                  required: true,
                  message: "template name address is required!",
                },
              ]}
              style={{ width: "90%" }}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Upload Template Document"
              name="template_url"
              rules={[
                {
                  required: true,
                  message: "upload template document is required!",
                },
              ]}
            >
              <Upload
                formRef={{ ref: templateRef, fieldName: "template_url" }}
                text="Click to Browse For PDF Files"
              ></Upload>
            </Form.Item>
            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isProcessing}
              >
                Add Template
              </Button>
            </div>
          </Form>
        </div>
        <div>
          <h2 style={{ fontSize: "18px", marginTop: "50px" }}>
            View some of our sample offer letters
          </h2>
          <ul
            style={{
              marginLeft: "10px",
              fontSize: "16px",
              listStyleType: "disc",
            }}
          >
            <li>
              <Link
                to={{
                  pathname:
                    "https://cdn.filestackcontent.com/MiD8ZIrpRCSJxnIY40kz",
                }}
                target="_blank"
              >
                sample 1
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AddTemplate;
