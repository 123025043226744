import React, { useState, useReducer } from "react";
import Progress from "./ProgressStep";
import Header from "./Header";
import { Form, Row, Col, Button, message } from "antd";

import invite from "./adminIcons/invite.svg";
import ButtonComponent from "./ButtonComponent";
import AddTeamMember from "./AddTeamMember";
import { useAuth } from "../../context/AuthContext";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { initialState, reducer } from "./reducers/InviteTeamReducer";
import { Request } from "../../utils";

function Invite({ isModal, closeAble, isStaff }) {
  const { session } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [count, setCount] = useState([1]);
  const [isProcessing, setProcessing] = useState(false);
  const history = useHistory();

  //This is the query to get activation codes and references of invites, it is temporary
  // const {invites} = useQuery('invite', () => Request.fetchWithAuthToken(`/lms/team/invite`, "GET", session.session.token));

  const { data: config } = useQuery("config", () =>
    Request.fetchWithAuthToken("/lms/config", "GET", session.session.token)
  );
  const { data: defaultRole } = useQuery(
    "defaultRole",
    () =>
      Request.fetchWithAuthToken(
        "/lms/utils/def_hierarchies",
        "GET",
        session.session.token
      ),
    { enabled: config?.data?.approval_hierarchy_type === "default" }
  );
  const { data: customRole } = useQuery(
    "customRole",
    () =>
      Request.fetchWithAuthToken(
        "/lms/config/custom_role",
        "GET",
        session.session.token
      ),
    { enabled: config?.data?.approval_hierarchy_type === "custom" }
  );

  const mutationKey = useMutation((teamData) =>
    Request.fetchWithAuthToken(
      "/lms/team/invite",
      "POST",
      session.session.token,
      teamData
    )
  );

  const mutationInviteAgent = useMutation((teamData) =>
    Request.fetchWithAuthToken(
      "/lms/agent/invite",
      "POST",
      session.session.token,
      teamData
    )
  );

  const cleanStateToModelDatabaseSchema = (data) => {
    let payload = [];
    for (let team in data) {
      payload.push(data[team]);
    }
    return payload;
  };

  const handleSubmit = () => {
    let payload = cleanStateToModelDatabaseSchema(state);

    if (
      payload[0]?.invitee?.email === "" ||
      payload[0]?.invitee?.firstname === "" ||
      payload[0]?.invitee?.lastname === ""
    ) {
      message.error("please fill in the required field");
      return;
    }
    setProcessing(true);
    if (isStaff) {
      mutationInviteAgent.mutate(payload, {
        onSuccess: (data) => {
          if (data.status !== "success") {
            setProcessing(false);
            message.error(data.message, 10);
            return;
          }
          isModal ? closeAble() : history.push("/adminsetup/firstloan");
          message.success(data.message, 3);
          setProcessing(false);
        },
        onError: (err) => {},
      });

      return;
    }

    mutationKey.mutate(payload, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 10);
          return;
        }
        isModal ? closeAble() : history.push("/adminsetup/firstloan");
        message.success(data.message, 3);
        setProcessing(false);
      },
      onError: (err) => {},
    });
  };

  // const handleClick = () => {
  //   dispatch({ type: "initState", formName: `team${count.length + 1}` });
  //   setCount([...count, count.length + 1]);
  //   displayForm(true);
  // };

  const deleteForm = (countnum, fieldname) => {
    dispatch({ type: "removeForm", formName: fieldname });
    setCount((prev) => prev.filter((count) => countnum !== count));
  };

  return (
    <>
      {!isModal && (
        <Header
          title="Invite your Team Members"
          removeSafeForLater={true}
          next="/adminsetup/firstloan"
        />
      )}
      <Row gutter={16}>
        <Col span={isModal ? 24 : 17}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <div
              style={{
                background: "#F5F6FF",
                border: "1px solid #E9E9F1",
                display: "flex",
                borderRadius: "10px",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "20px",
                justifyContent: "center",
                paddingTop: "10px",
                marginBottom: "50px",
              }}
            >
              <img src={invite} alt="group"></img>
              <p style={{ padding: "0 40px 0 30px" }}>
                {isStaff
                  ? `Add your agents,  with
                just their email. Then select their  privileges`
                  : `Add anyone—team members, internal stakeholders, or clients with
                just their email. Then select their roles and permissions`}
              </p>
            </div>
            <Form layout="vertical">
              {count.map((num) => (
                <AddTeamMember
                  key={num}
                  formName={`team${num}`}
                  dispatch={dispatch}
                  state={state}
                  currentNumber={num}
                  deleteForm={deleteForm}
                  customRole={customRole}
                  defaultRole={defaultRole}
                  orgConfig={config?.data?.approval_hierarchy_type}
                  isStaff={isStaff}
                />
              ))}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "5px",
                }}
              >
                <Button
                  style={{
                    color: "#5152A6",
                    fontSize: "12px",
                  }}
                  icon={<UserAddOutlined />}
                  type="link"
                  onClick={handleClick}
                >
                  Add Team Members
                </Button>
              </div> */}
              {!isModal ? (
                <ButtonComponent
                  link="/adminsetup/firstloan"
                  onClick={handleSubmit}
                  loading={isProcessing}
                />
              ) : !isStaff ? (
                <div className="flex justify-end">
                  <Button
                    onClick={handleSubmit}
                    loading={isProcessing}
                    type="primary"
                    className="my-5 w-3/12"
                    size="large"
                  >
                    Submit
                  </Button>
                </div>
              ) : (
                <div className="flex justify-end">
                  <Button
                    onClick={handleSubmit}
                    loading={isProcessing}
                    type="primary"
                    className="my-5 w-3/12"
                    size="large"
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </Col>
        {!isModal && (
          <Col span={7}>
            <Progress step="Invite Team" />
          </Col>
        )}
      </Row>
    </>
  );
}

export default Invite;
