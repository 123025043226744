import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { Title, Paragraph } from "../../components/Typography";
import Request from "../../utils/request";
import List from "../../components/GetStarted/Lists";
import { useAuth } from "../../context/AuthContext";

import { QueryClient } from "react-query";

const queryClient = new QueryClient();
function GetStarted(props) {
  const { session } = useAuth();

  const prefetchAllRequiredData = async () => {
    // The results of this query will be cached like a normal query
    await queryClient.prefetchQuery("profile", () =>
      Request.fetchWithAuthToken("/lms/profile", "GET", session?.session?.token)
    );

    await queryClient.prefetchQuery("kyc", () =>
      Request.fetchWithAuthToken("/lms/kyc", "GET", session.session.token)
    );

    await queryClient.prefetchQuery("config", () =>
      Request.fetchWithAuthToken("/lms/config", "GET", session.session.token)
    );

    await queryClient.prefetchQuery("business-loan", () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=business-loan",
        "GET",
        session.session.token
      )
    );

    await queryClient.prefetchQuery("personal-loan", () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/template?template=personal-loan",
        "GET",
        session.session.token
      )
    );
  };

  const mockdata = [
    {
      title: "Complete Company Profile",
      description: "Add full company details, logo and timezone",
      number: "1",
      path: "/adminsetup/companyprofile",
    },

    {
      title: "Complete Company KYC",
      description: "Provide key business documents and information",
      number: "2",
      path: "/adminsetup/businessinfo",
    },

    {
      title: "Setup Approval Structure",
      description: "Select either the default or custom structure",
      number: "3",
      path: "/adminsetup/loanapproval",
    },

    {
      title: "Invite your team members",
      description: "Add team members and assign them roles",
      number: "4",
      path: "/adminsetup/teammembers",
    },

    {
      title: "Create your first loan product",
      description: "Name your loan products and categorise them accordingly",
      number: "5",
      path: "/adminsetup/firstloan",
    },
  ];

  useEffect(() => {
    prefetchAllRequiredData();
  });

  return (
    <div>
      <Title>Welcome, {session?.admin?.name}</Title>
      <Paragraph size="14px">What would you like to do today?</Paragraph>
      <Row className="my-8" gutter={[10, 30]}>
        <Col xs={24} md={20}>
          <List>
            {mockdata.map((list) => (
              <List.ListItem key={list.number} {...list} />
            ))}
          </List>
        </Col>
        {/* <Col xs={24} md={20}>
          <DormantCurrency />
        </Col> */}
      </Row>
    </div>
  );
}

export default GetStarted;
