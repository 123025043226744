import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { Paragraph } from "../../components/Typography";
import EvolveGlobe from "../../components/CustomIcons/EvolveGlobe";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
function Evolve404() {
  const { push } = useHistory();

  return (
    <section className="h-screen" style={{ backgroundColor: "#F6FFF3" }}>
      <Row align="middle" style={{ height: "100%" }}>
        <Col xs={24} lg={10}>
          <Title
            className="text-center statusCode"
            style={{ fontSize: "48px" }}
          >
            40<span className="text-primary">4</span>
          </Title>

          <Paragraph className="text-center text-base">
            Page not found
          </Paragraph>
          <div className="flex justify-center">
            <Button type="primary" size="large" onClick={() => push("/")}>
              Go back to home
            </Button>
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <div className="md:w-9/12">
            <EvolveGlobe />
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default Evolve404;
