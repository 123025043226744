import React from "react";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import moment from "moment";
import { Avatar, Button, Card, Col, Divider, Empty, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import PartialCard from "./PartialCard";
import DebitInstructions from "../../components/Repayments/DebitInstructions";
import UpdatePartialRepaymentButton from "../../components/Repayments/PartialRepayments";
import UpdateRepaymentButton from "../../components/Repayments/ManualRepayments";

const RepaymentOverview = ({
  repayment,
  partialRepayment,
  debit_instructions,
}) => {
  const { currencyFormatter } = useCurrenncyFormatter();
  let initialValue = 0;
  let RepaidAmount = partialRepayment?.data?.reduce(
    (previous, current) => previous + current.amount,
    initialValue
  );
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={12} lg={12}>
          <Card loading={!repayment}>
            <div className="flex justify-between">
              <div>
                <h2 className="font-bold text-lg"> Repayment Details</h2>
                <p className="text-gray-500 mt-2">
                  Review the transaction details
                </p>
              </div>
            </div>
            <Divider />
            <div className="flex justify-between">
              <h2 className="text-lg font-bold capitalize">summary</h2>
              <Button
                type="default"
                style={{
                  backgroundColor: repayment?.data?.paid ? "#c4f8e2" : "orange",
                  color: repayment?.data?.paid ? "#06a561" : "white",
                }}
              >
                {repayment?.data?.status}
              </Button>
            </div>
            <div className="flex justify-between mt-5">
              <div className="flex">
                <div className="mt-5">
                  <Avatar shape="square" size="large" icon={<UserOutlined />} />
                </div>
                <div className="ml-5">
                  <h2 className="font-bold">
                    Name:{" "}
                    {repayment?.data?.borrower
                      ? `${repayment?.data?.borrower?.firstname} ${repayment?.data.borrower?.lastname}`
                      : "N/A"}
                  </h2>
                  <h2 className="font-bold">
                    Number:{" "}
                    {repayment?.data?.borrower
                      ? `${repayment?.data?.borrower?.phone}`
                      : "N/A"}
                  </h2>
                  <h2 className="font-bold">
                    Email:{" "}
                    {repayment?.data?.borrower
                      ? `${repayment?.data?.borrower?.email}`
                      : "N/A"}
                  </h2>
                </div>
              </div>
            </div>
            <div className="my-3">
              <h2 className="text-gray-500 text-base">
                Due:{" "}
                {moment(repayment?.data?.due_on.split(" ")[0]).format(
                  "dddd, MMMM Do YYYY, h:mm a"
                )}
              </h2>
              {repayment?.data?.paid && (
                <h2 className="text-gray-500 text-base">
                  Paid on:{" "}
                  {moment(repayment?.data?.repaid_on.split(" ")[0]).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )}
                </h2>
              )}
            </div>
            <Divider />
            <div>
              <div className="flex justify-between">
                <h2 className="text-gray-500">Transaction ID</h2>
                <h4 className="text-gray-500">{repayment?.data?.id}</h4>
              </div>
            </div>

            <Divider />
            <div className="flex justify-between mt-5 mb-3">
              <h2 className="font-bold">Amount</h2>
              <h4 className="font-bold text-blue-700 text-lg">
                {currencyFormatter(repayment?.data?.amount)}
              </h4>
            </div>
            {repayment?.data?.status === "partialy-repaid" && (
              <div className="flex justify-between mt-2 mb-10">
                <h2 className="font-bold">Outstanding payment</h2>
                <h4 className="font-bold text-blue-700 text-lg">
                  {currencyFormatter(
                    repayment?.data?.amount - RepaidAmount || 0
                  )}
                </h4>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {repayment?.data?.status !== "partialy-repaid" && (
                <UpdateRepaymentButton paid={repayment?.data?.paid} />
              )}
              <UpdatePartialRepaymentButton paid={repayment?.data?.paid} />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={12} lg={12}>
          {partialRepayment?.data ? (
            <Card style={{ marginBottom: 20 }}>
              <div className="flex justify-between">
                <h2 className="font-bold text-lg">
                  {" "}
                  Partial Repayment Details
                </h2>
              </div>
              {partialRepayment?.data?.map((data, index) => (
                <PartialCard data={data} index={index} />
              ))}
            </Card>
          ) : (
            <Card>
              <Empty description="There are no partial repayments" />
            </Card>
          )}
        </Col>
        {debit_instructions?.data && (
          <Col xs={24} md={12} lg={12}>
            <DebitInstructions debit_instructions={debit_instructions?.data} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default RepaymentOverview;
