import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, Input, InputNumber, Select, Drawer } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";

const { Option } = Select;

const AddEmploymentInformation  = ({ refetchEmploymentInformation, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const employmentInformationRef = React.createRef();

  const mutationEmploymentInformation = useMutation((employment_information) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=employment-information`,
      "POST",
      session.session.token,
      employment_information
    )
  );

  useEffect(() => {
    employmentInformationRef?.current?.setFieldsValue({
      status: data?.status,
      company_name: data?.company_name,
      company_address: data?.company_address,
      position: data?.position,
      salary: data?.salary,
      manager: data?.manager,
      manager_phone: data?.manager_phone,
      manager_email: data?.manager_email

    });
  }, [employmentInformationRef, data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationEmploymentInformation.mutate(values, {
      onSuccess: (data) => {
        refetchEmploymentInformation();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Employment Information
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Employment Information</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={employmentInformationRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Status"
                name="status"
              >
                <Select>
                  <Option value="employed">Employed</Option>
                  <Option value="contract_staff">Contract Staff</Option>
                  <Option value="self_employed">Self Employed</Option>
                  <Option value="unemployed">Unemployed</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Comapany Name"
                name="company_name"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Comapany Address"
                name="company_address"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Position"
                name="position"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Salary"
                name="salary"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Manager"
                name="manager"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Manager's Phone"
                name="manager_phone"
              >
                  <Input size="large" style={{ width: "100%" }} />
                  </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Manager Email"
                  name="manager_email"
                >
                  <Input size="large" type="email" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddEmploymentInformation;