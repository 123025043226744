import { Col } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';
import { Text, Title } from "../Typography";

function BlacklistCard({logo, amount, title}) {
    const style = { display:'flex',
    boxShadow: '0px 0.971248px 3.88499px rgba(21, 34, 50, 0.08)',
    borderRadius: '11.4894px', padding: '20px', background:"rgb(255, 241, 241)", justifyContent:'space-between'
}
    return (
        <Col span={8}>
        <div style={style}>
            <div style={{ display: 'flex' }}>
                <img src={logo} alt={title} />
                <div style={{ marginLeft: '15px' }}>
                    <Title level={4} style={{ marginBottom: '0' }}>&#8358; {amount}</Title>
                    <Text style={{ fontSize: '12px' }}>{title}</Text>
                </div>
            </div>
            <Link style={{ display: 'flex', alignSelf: 'flex-end', color: '#757580', textDecoration: 'underline' }}>
                view
                </Link>

        </div>
        </Col>
    )
}

export default BlacklistCard
