import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { Avatar, Card, DatePicker, Empty } from "antd";
import { useHistory } from "react-router";
import {
  SETNOTIFICATIONSENDDATE,
  SETNOTIFICATIONSSTARTDATE,
} from "../../redux/Types/generalTypes";
import SwitchButton from "../../components/Notifications/SwitchBtn";
import { Title } from "../../components/Typography";

const { RangePicker } = DatePicker;

const Notifications = () => {
  const { session } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.notificationsReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: notifications } = useQuery(
    `notifications=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/notification?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const startDateFilter = (e) => {
    dispatch({ type: SETNOTIFICATIONSSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETNOTIFICATIONSENDDATE, value: e.endDate });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Title level={2}>Notifications</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            width: "40%",
          }}
        >
          <RangePicker
            onChange={(value) =>
              startDateFilter(
                { startDate: value[0] },
                endDateFilter({ endDate: value[1] })
              )
            }
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            on
          />
        </div>
      </div>
      <Card>
        {notifications?.data?.map((notification) => (
          <div key={notification?.id}>
            <div className="flex space-x-4 h-30 p-1 px-4 bg-gray-100 items-center mb-5">
              <div className="bg-none rounded-full flex items-center justify-center">
                <Avatar size={60} />
              </div>
              <div className="w-full">
                <h2
                  className="font-bold mt-2 cursor-pointer"
                  onClick={() => {
                    history.push(`/requests/${notification?.loan_request_id}`);
                  }}
                >
                  {notification?.message}
                </h2>
                <h2 className="font-bold">By: </h2>
                <h4 className="text-gray-500">
                  {moment(notification?.created_at).startOf("hour").fromNow()}
                </h4>
              </div>
              <SwitchButton notification={notification} />
            </div>
          </div>
        ))}
        {notifications?.data === null && (
          <Empty description="No new notifications yet." />
        )}
        {notifications?.data === undefined && (
          <Empty description="No notifications yet." />
        )}
      </Card>
    </>
  );
};

export default Notifications;
