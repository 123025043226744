import { Switch, Route } from "react-router-dom";
import Navbar from "./Navbar";
import { Loans, LoanRoute, SingleLoans } from "../../pages/loans";
import { Overview, OverviewRoute } from "../../pages/overview";
import Evolve404 from "../../pages/Error/404";
import Evolve405 from "../../pages/Error/Priviledge";
import SideBar from "./SideBar";
import {
  BorrowersRoute,
  Borrowers,
  SingleBorrowers,
} from "../../pages/Borrowers";
import {
  DisbursementRoute,
  SingleDisbursement,
} from "../../pages/Disbursements";
import { Blacklist, BlacklistRoute } from "../../pages/Blacklist";
import { BranchesRoute } from "../../pages/Branches";
import Disbursements from "../../pages/Disbursements/Disbursement";
import OverdueLoans from "../../pages/loans/OverdueLoans";
import RepaidLoans from "../../pages/loans/RepaidLoans";
import {
  Guarantors,
  GuarantorsRoute,
  SingleGuarantor,
} from "../../pages/Guarantors";

import { Analyser } from "../../pages/BSA";
import {
  RequestsRoute,
  LoanRequests,
  SingleRequest,
} from "../../pages/LoanRequests";
import { ProductsRoute, Products } from "../../pages/LoanProducts";
import {
  AdminSetupRoute,
  BusinessInfo,
  CompanyProfile,
  FirstLoan,
  GetStarted,
  LoanApproval,
  TeamMembers,
} from "../../pages/AdminSetup";
import SingleProducts from "../../pages/LoanProducts/SingleProducts";
import { Wallet, WalletRoute } from "../../pages/Wallet";
import { Team, TeamRoute } from "../../pages/Team";
import {
  SingleTransaction,
  Transaction,
  TransactionRoute,
} from "../../pages/Transaction";
import RepaymentRoute from "../../pages/Repayments/RepaymentsRoute";
import Repayments from "../../pages/Repayments/Repayments";
import SingleRepayment from "../../pages/Repayments/SingleRepayment";
import Settings from "../../pages/settings/Settings";
import ActivityLogRoute from "../../pages/ActivityLog/ActivityLogRoute";
import { ActivityLog } from "../../pages/ActivityLog";
import { Notifications, NotificationsRoute } from "../../pages/Notifications";
import { Notes } from "../../pages/Notes";
import SettingsRoute from "../../pages/settings/settingsRoute";
import { Report, ReportRoute } from "../../pages/Report";
import AddCustomField from "../../pages/LoanProducts/AddCustomField";
import { Agent, AgentRoute } from "../../pages/Agent";
import NewLoanRequest from "../../pages/Borrowers/loan";
import { CollateralRoute, Collateral } from "../../pages/Collateral";
import UpdateProductPage from "../../pages/LoanProducts/UpdateProductPage";
import ScrollToTop from "./ScrollRestore";

// import { Task, TaskRoute } from "../../pages/Task";

function Main(props) {
  return (
    <div>
      <ScrollToTop />
      <div className="sidebar">
        <SideBar />
      </div>

      <div>
        <Navbar />
        <section className="content pl-72 pr-12 py-10">
          <Switch>
            <OverviewRoute path="/" exact={true} children={<Overview />} />
            <AdminSetupRoute
              path="/adminsetup"
              exact={true}
              children={<GetStarted />}
            />

            <AgentRoute path="/agent" exact={true} children={<Agent />} />
            <LoanRoute path="/loan" exact={true} children={<Loans />} />
            <LoanRoute
              path="/loan/overdueloans"
              exact={true}
              children={<OverdueLoans />}
            />
            <LoanRoute
              path="/loan/repaidloans"
              exact={true}
              children={<RepaidLoans />}
            />
            <LoanRoute
              path="/loan/:id"
              exact={true}
              children={<SingleLoans />}
            />
            <AdminSetupRoute
              path="/adminsetup/companyprofile"
              exact={true}
              children={<CompanyProfile />}
            />
            <AdminSetupRoute
              path="/adminsetup/businessinfo"
              exact={true}
              children={<BusinessInfo />}
            />
            <AdminSetupRoute
              path="/adminsetup/loanapproval"
              exact={true}
              children={<LoanApproval />}
            />
            <AdminSetupRoute
              path="/adminsetup/teammembers"
              exact={true}
              children={<TeamMembers />}
            />
            <AdminSetupRoute
              path="/adminsetup/firstloan"
              exact={true}
              children={<FirstLoan />}
            />

            <BorrowersRoute
              path="/borrowers"
              exact={true}
              children={<Borrowers />}
            />
            <BorrowersRoute
              path="/borrowers/:id"
              exact={true}
              children={<SingleBorrowers />}
            />

            <BorrowersRoute
              path="/borrowers/:loanID/:slug/request"
              exact={true}
              children={<NewLoanRequest />}
            />

            <DisbursementRoute
              path="/disbursement"
              exact={true}
              children={<Disbursements />}
            />
            <DisbursementRoute
              path="/disbursement/:id"
              exact={true}
              children={<SingleDisbursement />}
            />

            <DisbursementRoute
              path="/fulfilment"
              exact={true}
              children={<Disbursements />}
            />
            <DisbursementRoute
              path="/fulfilment/:id"
              exact={true}
              children={<SingleDisbursement />}
            />

            <RepaymentRoute
              path="/repayments"
              exact={true}
              children={<Repayments />}
            />
            <RepaymentRoute
              path="/repayments/:id"
              exact={true}
              children={<SingleRepayment />}
            />
            <RequestsRoute
              path="/requests"
              exact={true}
              children={<LoanRequests />}
            />
            <GuarantorsRoute
              path="/guarantors"
              exact={true}
              children={<Guarantors />}
            />
            <GuarantorsRoute
              path="/guarantors/:id"
              exact={true}
              children={<SingleGuarantor />}
            />
            <CollateralRoute
              path="/collaterals"
              exact={true}
              children={<Collateral />}
            />
            <RequestsRoute
              path="/requests/:id"
              exact={true}
              children={<SingleRequest />}
            />

            <RequestsRoute
              path="/requests/:id/bsa/:method"
              exact={true}
              children={<Analyser />}
            />
            <BlacklistRoute
              path="/blacklist"
              exact={true}
              children={<Blacklist />}
            />

            <BranchesRoute path="/branches" exact={true} />
            <ProductsRoute
              path="/products"
              exact={true}
              children={<Products />}
            />

            <ProductsRoute
              path="/products/:id"
              exact={true}
              children={<SingleProducts />}
            />

            <ProductsRoute
              path="/products/:slug/update"
              exact={true}
              children={<UpdateProductPage />}
            />

            <ProductsRoute
              path="/products/:slug/custom_fields/:segment"
              exact={true}
              children={<AddCustomField />}
            />

            <ProductsRoute
              path="/products/:slug/custom_fields/"
              exact={true}
              children={<AddCustomField />}
            />

            <SettingsRoute
              path="/settings"
              exact={true}
              children={<Settings />}
            />
            <WalletRoute path="/wallet" exact={true} children={<Wallet />} />
            <TeamRoute path="/team" exact={true} children={<Team />} />
            <TransactionRoute
              path="/transaction"
              exact={true}
              children={<Transaction />}
            />
            <TransactionRoute
              path="/transaction/:id"
              exact={true}
              children={<SingleTransaction />}
            />
            <ActivityLogRoute
              path="/activity_logs"
              exact={true}
              children={<ActivityLog />}
            />
            <NotificationsRoute
              path="/notifications"
              exact={true}
              children={<Notifications />}
            />
            <Route path="/notes" exact={true} children={<Notes />} />
            <ReportRoute path="/report" exact={true} children={<Report />} />

            {/* <TaskRoute path="/task" exact={true} children={<Task />} /> */}
            {/* NVM */}
            <Route
              path="/access_denied"
              exact={true}
              children={<Evolve405 />}
            />
            <Route path="*">
              <Evolve404 />
            </Route>
          </Switch>
        </section>
      </div>
    </div>
  );
}

export default Main;
