import React from "react";
import { DatePicker, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  SETNOTESTARTDATE,
  SETNOTEENDDATE,
} from "../../redux/Types/generalTypes";
import { ALLNOTESEARCHVALUE } from "../../redux/Types/tableTypes";
import { Title } from "../Typography";

export function NotesHeader() {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) => state.notesReducer);

  const startDateFilter = (e) => {
    dispatch({ type: SETNOTESTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETNOTEENDDATE, value: e.endDate });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Title level={2}>Notes</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignContent: "center",
          width: "40%",
        }}
      >
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
        />
      </div>
    </div>
  );
}

export function NoteTableHeader({ refetch }) {
  // const { Option } = Select;
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    dispatch({ type: ALLNOTESEARCHVALUE, value: e.target.value });
  };

  // const handleFilterByStatus = (val) => {
  //   dispatch({ type: SETNOTESTATUSTOSEARCHFOR, value: val });
  // };

  return (
    <div>
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        Manage your notes on configure.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div className="flex justify-between w-full">
          <div className="flex space-x-2">
            {/* <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              size="large"
              placeholder="Filter"
              onChange={(val) => handleFilterByStatus(val)}
            >
              <Option value="no">Pending</Option>
              <Option value="yes">Activated</Option>
            </Select> */}
            <Input
              size="large"
              placeholder="Search..."
              style={{ width: "300px" }}
              prefix={<SearchOutlined />}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
