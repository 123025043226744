import React from "react";
import { useQuery } from "react-query";
import { Card } from "antd";
import Requests from "../../utils/request";
import { VictoryChart, VictoryLine, VictoryTheme } from "victory";
import { useAuth } from "../../context/AuthContext";

function InflowAndOutFlow({ requestID, method }) {
  const { session } = useAuth();
  const { data } = useQuery(
    `monthly-template`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${requestID}/analysis?method=${method}&analysis=monthly-flow`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const cleanDataCredit = (data) =>
    data?.map((datum, index) => ({
      x: `${datum.month}-${datum.year}`,
      y: Math.floor(datum.total_credit),
    }));

  const cleanDataDebit = (data) =>
    data?.map((datum, index) => ({
      x: `${datum.month}-${datum.year}`,
      y: Math.floor(datum.total_debit),
    }));

  const cleanCategories = (data) =>
    data?.map((datum, index) => `${datum.month}-${datum.year}`);

  return (
    <Card style={{ borderRadius: "12px" }}>
      <p
        className="py-1 px-2 text-lg"
        style={{ fontFamily: "Cera Pro Bold", color: "#2E2C34" }}
      >
        Total Inflow vs Outflow
      </p>
      <div className="flex space-x-3 items-center">
        <div
          className="h-3 w-3 rounded-full"
          style={{ background: "#11C096" }}
        ></div>
        <span>Inflow</span>

        <div
          className="h-3 w-3 rounded-full"
          style={{ background: "#5152A6" }}
        ></div>
        <span>Ouflow</span>
      </div>
      <VictoryChart width={700} theme={VictoryTheme.material}>
        <VictoryLine
          interpolation="natural"
          style={{
            data: {
              stroke: "#11C096",
            },
          }}
          categories={{
            x: cleanCategories(data?.data),
          }}
          data={cleanDataCredit(data?.data)}
        />

        <VictoryLine
          interpolation="natural"
          categories={{
            x: cleanCategories(data?.data),
          }}
          data={cleanDataDebit(data?.data)}
          style={{
            data: {
              stroke: "#5152A6",
            },
          }}
        />
      </VictoryChart>
    </Card>
  );
}

export default InflowAndOutFlow;
