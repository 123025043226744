import { SETOVERVIEWMETRICSENDDATE, SETOVERVIEWMETRICSSTARTDATE } from "../Types/generalTypes";
import moment from "moment";

const initialState = { 
  metricsEndDate: moment().endOf("month"),
  metricsStartDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const overviewMetricsReducer = (state = initialState, actions) => {
  if (actions.type === SETOVERVIEWMETRICSENDDATE) {
    return { ...state, metricsEndDate: actions.value };
  }

  if (actions.type === SETOVERVIEWMETRICSSTARTDATE) {
    return { ...state, metricsStartDate: actions.value };
  }

  return state;
}

export default overviewMetricsReducer;