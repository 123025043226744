import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function BranchesRoute({ children, ...rest }) {
  const { session } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        session.access_to.branches ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access_denied",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
