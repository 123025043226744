import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import SecureLS from "secure-ls";
import { isMobile } from "react-device-detect";
import Main from "./components/Layout/Main";
import { Login, ResetPassword } from "./pages/auth";
import Mobile from "./components/MobileView";
import SignUp from "./pages/Registration/SignUp";
import "./styles/global.less";
import { useQuery } from "react-query";
import InviteSignUp from "./pages/Registration/InviteSignUp";
import { Spin } from "antd";
import { useAuth } from "./context/AuthContext";
import Requests from "./utils/request";
import { useEffect } from "react";
import { Pricing } from "./pages/Pricing";
import SendEmail from "./pages/auth/SendEmail.js";
import { useDispatch } from "react-redux";
import { SETPROFILE } from "./redux/Types/generalTypes";
import ScrollToTop from "./components/Layout/ScrollRestore";

const securedStorage = new SecureLS();

function App() {
  const { loggingOut, session } = useAuth();
  const dispatch = useDispatch();

  const { data } = useQuery("user", () =>
    Requests.fetchWithAuthToken(
      `/lms/signin?reference=${session?.session?.sign_in_ref}`,
      "GET",
      session?.session?.token
    )
  );

  const { data: profile, isLoading: isProfileLoading } = useQuery(
    "profile",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/profile`,
        "GET",
        session?.session?.token
      )
  );

  useEffect(() => {
    if (data?.data && data?.data?.is_active === false) {
      securedStorage.remove("operator");
      window.location.replace("/sign-in");
    }
  }, [data]);

  useEffect(() => {
    if (profile?.data) {
      dispatch({
        type: SETPROFILE,
        value: profile?.data,
      });
    }
    // eslint-disable-next-line
  }, [profile]);

  if (isMobile) {
    return <Mobile />;
  }

  return (
    <Router>
      <ScrollToTop />
      {securedStorage.get("operator") ? (
        <Spin size="large" spinning={loggingOut || isProfileLoading}>
          <Main />
        </Spin>
      ) : (
        <>
          <Switch>
            <Route
              path="/"
              exact={true}
              children={<Redirect to="/sign-in" />}
            />

            <Route path="/sign-in" exact={true} children={<Login />} />
            <Route path="/sign-up" exact={true} children={<SignUp />} />
            <Route path="/plans" exact={true} children={<Pricing />} />
            <Route
              path="/invite-sign-up"
              exact={true}
              children={<InviteSignUp />}
            />

            <Route
              path="/agent-sign-up"
              exact={true}
              children={<InviteSignUp isAgent={true} />}
            />
            <Route
              path="/password-reset"
              exact={true}
              children={<ResetPassword />}
            />

            <Route path="/recover" exact={true} children={<SendEmail />} />

            <Route path="*">
              <Login />
            </Route>
          </Switch>
        </>
      )}
    </Router>
  );
}

export default App;
