import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import { Row, Col, Form, Button, Input, Select, message, Card } from "antd";
import { useMutation, useQuery } from "react-query";
import { Request } from "../../utils";
import Logo from "../../assets/svg/logo.svg";
import Pattern from "../../assets/images/patterns.png";
import countries from "../../utils/Country";
import useLocationQuery from "../../utils/useQuery";
import PhoneInput from "react-phone-number-input";

const securedStorage = new SecureLS();

const { Option } = Select;

function InviteSignUp({ isAgent }) {
  const inviteRef = React.createRef();
  const [isProcessing, setProcessing] = useState(false);
  let query = useLocationQuery();
  const reference = query.get("reference");
  const activationCode = query.get("activationCode");

  const { isLoading, data } = useQuery("invite", () =>
    Request.fetchWithOutAuthToken(
      isAgent
        ? `/lms/agent/invite/${reference}`
        : `/lms/team/invite/${reference}`,
      "GET"
    )
  );

  const inviteMutation = useMutation((ref) =>
    Request.fetchWithOutAuthToken(
      isAgent
        ? `/lms/agent/invitee/activate?reference=${reference}&activationCode=${activationCode}`
        : `/lms/team/invitee/activate?reference=${reference}&activationCode=${activationCode}`,
      "POST",
      ref
    )
  );

  const handleSubmit = (values) => {
    const user = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email.toLowerCase(),
      password: values.password,
      phone: String(values.phone),
      country: values.country,
      role_in_organisation: values.role_in_organistaion,
    };

    setProcessing(true);
    inviteMutation.mutate(user, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }

        message.success(data.message, 3);
        securedStorage.set("operator", {
          allowed_to: data.data4,
          access_to: data.data3,
          session: data.data2,
          user: data.data1,
          admin: data.data,
        });

        window.location.replace("/");
      },
      onError: (err) => {},
    });
  };

  useEffect(() => {
    inviteRef?.current?.setFieldsValue({
      firstname: data?.data?.firstname,
      lastname: data?.data?.lastname,
      phone: data?.data?.phone,
      email: data?.data?.email,
      role_in_organisation: data?.data?.role_in_organisation,
      country: data?.data?.country,
    });

    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="h-screen flex lg:flex-row flex-col">
      <div className="flex-grow lg:p-8 p-3 xl:mx-32 lg:mx-16">
        <img src={Logo} alt="evolve credit logo" className="w-full h-20" />
        {isLoading === false && (
          <div className="my-18">
            <h1 className="text-2xl font-semibold  mt-20">Sign up</h1>
            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={(values) => handleSubmit(values)}
              ref={inviteRef}
            >
              <Row gutter={[16]}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required!",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Last Name"
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required!",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item label="Email Address" name="email">
                    <Input
                      placeholder="you@example.com"
                      size="large"
                      type="email"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required!",
                      },
                    ]}
                  >
                    <PhoneInput
                      international
                      placeholder="Enter phone number"
                      defaultCountry={"NG"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Country is required!",
                      },
                    ]}
                  >
                    <Select size="large" showSearch>
                      {countries.map((country) => (
                        <Option
                          value={country.name}
                          key={`${country.code}${country.name}`}
                        >
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Role In Organisation"
                    name="role_in_organistaion"
                    rules={[
                      {
                        required: true,
                        message: "Role is required!",
                      },
                    ]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  loading={isProcessing}
                  style={{ width: "100%" }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {isLoading === true && (
          <div className="mt-10">
            <Card className="w-full h-48" loading={isLoading} />
          </div>
        )}
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative hidden lg:block"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default InviteSignUp;
