import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { useQuery } from "react-query";
import { useAuth } from "../context/AuthContext";
import Requests from "../utils/request";
import { useCurrenncyFormatter } from "../utils/currencyFormatter";
import moment from "moment";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
function useTemplater({ id }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const { session } = useAuth();

  let date = new Date();
  date = date.toDateString();

  const { data } = useQuery("", () =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/offer_letter`,
      "GET",
      session.session.token
    )
  );

  // query here
  const generateDocument = (template, product) => {
    loadFile(template, function (error, content) {
      if (error) {
        throw error;
      }

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.render({
        Name: data.data.borrower_name,
        Date: date,
        Product: product,
        Amount: currencyFormatter(data.data.loan_amount),
        Purpose: data.data.purpose,
        Tenure: data.data.tenure,
        AccountNumber: data.data.bank_account_number,
        Bank: data.data.bank_name,
        Loans: data.data.repayment_info.map((item) => ({
          ...item,
          due_date: moment(item.due_date).format("MMM Do YYYY"),
        })),
        OrgName: session.admin.name,
        OrgRep: session.user.firstname + " " + session.user.lastname,
      });

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "offer.docx");
    });
  };
  return { generateDocument };
}

export default useTemplater;
