import { Card, Tabs } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { SETCREDITSTATUSTOSEARCHFOR } from "../../redux/Types/tableTypes";
import CreditOrdersTable from "../Table/CreditOrders/CreditOrderTable";
import TableHeader from "./TableHeader";

const { TabPane } = Tabs;

function CreditOrderTable({ isLoading }) {
  const dispatch = useDispatch();

  const handleOrderFilterByStatus = (val) => {
    dispatch({ type: SETCREDITSTATUSTOSEARCHFOR, value: val });
  };
  return (
    <Card>
      <TableHeader />
      <Tabs
        size="default"
        type="card"
        onTabClick={(key) => handleOrderFilterByStatus(key)}
        style={{ marginTop: "20px", marginBottom: "0" }}
      >
        <TabPane tab="All Credit Orders" key="">
          <CreditOrdersTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Pending Credit Orders" key="saved">
          <CreditOrdersTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Approved Credit Orders" key="approved">
          <CreditOrdersTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Declined Credit Orders" key="rejected">
          <CreditOrdersTable isLoading={isLoading} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default CreditOrderTable;