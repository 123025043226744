import React, { useContext, useState, createContext } from "react";
import styles from "./pricing.module.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const PlanContext = createContext();

const PlanContainer = ({ children }) => {
	const { active, toggle } = useContext(PlanContext);
	return (
		<div className={styles.plan_card}>
			{" "}
			{children}{" "}
			<button onClick={() => toggle()}>
				{active ? (
					<UpOutlined style={{ marginLeft: "20px" }} />
				) : (
					<DownOutlined style={{ marginLeft: "20px" }} />
				)}
			</button>
		</div>
	);
};

const PlanHeading = ({ children }) => {
	return <div className={styles.plan_heading}> {children} </div>;
};

const PlanContent = ({ children }) => {
	const { active } = useContext(PlanContext);
	return active ? (
		<div className={styles.common_answer}> {children} </div>
	) : null;
};

function PlanCard(props) {
	const [active, setActive] = useState(true);

	const toggle = () => {
		setActive(!active);
	};

	return (
		<PlanContext.Provider
			value={{
				active,
				toggle,
			}}
		>
			<div>{props.children}</div>
		</PlanContext.Provider>
	);
}

export default PlanCard;
export { PlanContent, PlanHeading, PlanContainer };
