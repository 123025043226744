import React, { useMemo } from "react";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { Title } from "../../../Typography";
import { LockFilled } from "@ant-design/icons";
import MonoConnect from "@mono.co/connect.js";
import { notification, Button, Card, message } from "antd";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";

function Mono({ currentNumber, id, setCurrentNumber }) {
  const { session } = useAuth();

  const { updateProgress } = useLoanApplication();
  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      key: process.env.REACT_APP_MONO_KEY,
      onSuccess: ({ code }) => handleSendCode(code),
    });
    monoInstance.setup();
    return monoInstance;
    // eslint-disable-next-line
  }, []);

  const handleSendCode = async (code) => {
    const sendCode = await Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${id}/tokenize_mono`,
      "POST",
      session?.admin?.token,
      {
        code,
      }
    );
    if (sendCode.status !== "error") {
      monoConnect.close();
      message.success(sendCode.message, 2);
      setCurrentNumber((prev) => {
        const current = Number(prev) + 1;
        return current.toString();
      });
      updateProgress(
        { progress: String(Number(currentNumber) + 1), loan_requestID: id },
        (data) => data
      );

      return;
    }

    // error
    notification.error({ message: sendCode.message });
    monoConnect.close();
  };
  return (
    <div className="my-10 lg:mx-60">
      <Card>
        <Title level={3}>Bank Statement (via mono)</Title>
        <div className="my-10 flex justify-center">
          <Button
            type="primary"
            onClick={() => monoConnect.open()}
            icon={<LockFilled />}
            style={{
              height: "48px",
              borderRadius: "6px",
            }}
          >
            Link your bank statement securely
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Mono;
