import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Form, Button, Row, Col, Card, message, Select } from "antd";
import { useHistory } from "react-router";
import useGenerateComponent from "../../utils/componentGenerator";
import Requests from "../../utils/request";
import useLoanApplication from "../../hooks/useLoanApplication";
import { useAuth } from "../../context/AuthContext";
const { Option } = Select;
const loanForm = React.createRef();

function CreateLoanApplication({ userID }) {
  const { session } = useAuth();
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [slug, setSlug] = useState("");
  const { createLoan } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();

  const { data, isLoading } = useQuery(`loan-form-${slug}`, () =>
    Requests.fetchWithAuthToken(`/lms/user/product?slug=${slug}`, "GET")
  );

  const { data: all_products } = useQuery("all-products", () =>
    Requests.fetchWithAuthToken("/lms/product", "GET", session.session.token)
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);

      message.success(data.message, 2);
      history.push(
        `/borrowers/${data?.data?.id}/${slug}/request?uid=${userID}`
      );
      return;
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);

    createLoan(
      { ...value, product_slug: value.slug, userID },
      handleSubmitCallback
    );
  };

  useEffect(() => {
    loanForm?.current?.setFieldsValue({
      slug,
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <section>
      <Card loading={isLoading}>
        <div>
          <Form ref={loanForm} layout="vertical" onFinish={handleSubmit}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Product"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Select
                    initialValues={slug}
                    size="large"
                    onChange={(val) => setSlug(val)}
                  >
                    {all_products?.data?.map((product) => (
                      <Option value={product.slug} key={product.id}>
                        {product.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {data?.data?.segments[0]?.fields.map((field, index) => (
                <Col xs={24} md={24} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(field, {
                      ref: loanForm,
                      fieldName: field?.field,
                    })}
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Button
              size="large"
              className="w-full"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Start
            </Button>
          </Form>
        </div>
      </Card>
    </section>
  );
}

export default CreateLoanApplication;
