import React, { useState } from "react";
import timeDate from "../../../utils/TimeDate";
import { Table, Button, Tag, Popconfirm } from "antd";
import { useSelector } from "react-redux";
import UpdatePrivileges from "../../Team/UpdatePrivileges";

function AgentTable({ isLoading, handleDelete }) {
  const [idOfUserToUpdate, setIdOfUserToUpdate] = useState(null);
  const [email, setEmail] = useState("");
  const { teams } = useSelector((state) => state.agentTableReducer);
  const { profile } = useSelector((state) => state.profileReducer);
  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      defaultSortOrder: "descend",
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "name",
      render: (_, record) => (
        <div>
          <span className="mx-2">{record.lastname}</span>
          <span>{record.firstname}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      defaultSortOder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "activated",
      key: "activated",
      render: (_, record) => {
        let color = record.activated === "no" ? "volcano" : "success";
        let text = record.activated === "no" ? "Pending" : "Activated";

        return (
          <Tag color={color} key={record.activated}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button>Delete</Button>
          </Popconfirm>
          <Button style={{ marginLeft: "10px" }}>Resend</Button>
          <Button
            onClick={() => {
              setEmail(record.email);
              setIdOfUserToUpdate(record.id);
            }}
            style={{ marginLeft: "10px" }}
            type="primary"
          >
            Manage
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={teams}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
        rowKey="reference"
      />
      <UpdatePrivileges
        visible={idOfUserToUpdate}
        toggleVisible={setIdOfUserToUpdate}
        email={email}
      />
    </>
  );
}

export default AgentTable;
