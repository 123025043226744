import { Tabs, Card, Button } from "antd";
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import BankStatements from "../../BankStatements";
import ActivityLog from "./details/ActivityLog";
import Approvals from "./details/Approvals";
import BankDetails from "./details/BankDetails";
import BusinessInformation from "./details/BusinessInformation";
import BVNDetails from "./details/BVNDetails";
import Collaterals from "./details/Collaterals";
import Fields from "./details/CustomSegments";
import DocumentUploads from "./details/DocumentUploads";
import EmploymentDetails from "./details/EmploymentInfo";
import FacilityExpenses from "./details/FacilityExpenses";
import FacilityMetrics from "./details/FacilityMetrics";
import Guarantors from "./details/Guarantors";
import HealthFacility from "./details/HealthFacility";
import NextOfKin from "./details/NextOfKin";
import Notes from "./details/Notes";
import PersonalInformation from "./details/PersonalInfo";

const { TabPane } = Tabs;

const Information = ({
  product_template,
  approval,
  config,
  data,
  setVisible,
  business_information,
  health_facility,
}) => {
  const { session } = useAuth();

  const mergeTemplate = (template) => {
    if (template?.custom_segments) {
      return {
        ...template,
        segments: [...template?.segments, ...template?.custom_segments],
      };
    }
    return template;
  };

  const template =
    session?.admin?.category === "merchant"
      ? product_template?.data
      : mergeTemplate(product_template);

  const removeFromArray = ["valid-identity", "loan-request"];
  const indexSet = new Set(removeFromArray);
  const filteredProducts = template?.segments?.filter(
    (value) => !indexSet.has(value.slug)
  );

  /* eslint-disable */
  const mapTrees = {
    ["document-uploads"]: <DocumentUploads />,
    ["bank-details"]: <BankDetails />,
    ["additional-information"]: (
      <PersonalInformation template={filteredProducts} />
    ),
    ["next-of-kin"]: <NextOfKin template={filteredProducts} />,
    ["business-information"]: (
      <BusinessInformation
        business_information={business_information}
        template={filteredProducts}
      />
    ),
    ["employment-information"]: (
      <EmploymentDetails template={filteredProducts} />
    ),
    ["valid-identity"]: null,
    ["health-facility-information"]: (
      <HealthFacility
        health_facility={health_facility}
        template={filteredProducts}
      />
    ),
    ["facility-expenses"]: <FacilityExpenses template={filteredProducts} />,
    ["numbers-&-metrics"]: <FacilityMetrics template={filteredProducts} />,
  };

  return (
    <Card loading={!template} style={{ marginTop: "20px" }}>
      <div className="flex justify-end">
        <Button size="large" type="primary" onClick={setVisible}>
          Additional Actions
        </Button>
      </div>

      <Tabs>
        {filteredProducts?.map(
          (details, index) =>
            !details?.deactivated && (
              <TabPane tab={details?.segment} key={index}>
                {details?.is_custom ? (
                  <Fields slug={details?.slug} key={index} />
                ) : (
                  mapTrees[details?.slug]
                )}
              </TabPane>
            )
        )}

        {config?.use_mono && (
          <TabPane tab="Mono" key="Mono">
            <div className="my-5">
              <BankStatements data={data} />
            </div>
          </TabPane>
        )}
        <TabPane tab="BVN" key="BVN">
          <BVNDetails />
        </TabPane>
        <TabPane tab="Notes" key="Notes">
          <Notes />
        </TabPane>
        <TabPane tab="Guarantors" key="Guarantors">
          <Guarantors />
        </TabPane>
        <TabPane tab="Collaterals" key="Collaterals">
          <Collaterals data={data} />
        </TabPane>

        <TabPane tab="Approvals" key="Approvals">
          <Approvals approval={approval} />
        </TabPane>

        <TabPane tab="Activity Logs" key="activity_logs">
          <ActivityLog />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default Information;
