import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row, Tooltip } from "antd";
import React from "react";
import { Title } from "../../Typography";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

const Overview = ({
  data,
  debit_mandate,
  health_facility,
  business_information,
  product_template,
}) => {
  const { currencyFormatter } = useCurrenncyFormatter();

  return (
    <Card className="mt-5">
      <Row>
        <>
          <Col span={14}>
            <div className="flex space-x-8 ml-5">
              <div className="flex space-x-4 mt-8">
                <Avatar size={130} icon={<UserOutlined />} />
              </div>
              <div className="mt-8">
                <Title level={3}>{data?.fullname}</Title>
                <div>Amount Requested: {currencyFormatter(data?.amount)}</div>
                <div>Product Name: {data?.product_name}</div>
                {data?.lender_status === "approved" && (
                  <div>
                    Amount Approved:
                    {currencyFormatter(data?.terms?.approved_amount)}
                  </div>
                )}
                {product_template?.data?.type === "health-loan" && (
                  <div>Business Name : {health_facility?.data?.name}</div>
                )}
                {product_template?.data?.type === "business-loan" && (
                  <div>Business Name : {business_information?.data?.name}</div>
                )}
                {data?.invoice_number &&(
                  <div>Invoice Number: {data?.invoice_number}</div>
                )}
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mt-8">
              <div className="capitalize">Loan Status: {data?.status}</div>
              <div className="capitalize">
                Lender Status: {data?.lender_status}
              </div>
              <div className="capitalize">User Status: {data?.user_status}</div>
            </div>
            {debit_mandate && (
              <>
                <Tooltip placement="left" title="Create a Debit mandate for your customers to be debited over a set period of time using the Remita Debit Mandate Setup">
                  <div>
                    Debit Mandate: {""}
                    {debit_mandate?.data1?.activated === true ? "Active" : "Inactive"}
                  </div>
                </Tooltip>
                <div className="capitalize">
                  Activated:{" "}
                  {debit_mandate?.data1?.activated === true ? (
                    <span className="space-x-4">
                      <Avatar
                        style={{
                          backgroundColor: "green",
                          marginTop: "-8px",
                        }}
                        size={15}
                      />
                      {/* <Button icon={<RedoOutlined />} /> */}
                    </span>
                  ) : (
                    <Avatar
                      style={{ backgroundColor: "gray", marginTop: "-8px" }}
                      size={15}
                    />
                  )}
                </div>
              </>
            )}
          </Col>
        </>
      </Row>
    </Card>
  );
};

export default Overview;
