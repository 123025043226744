import { Avatar, Col, Row } from "antd";
import React from "react";
import { Text } from "../Typography";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useQuery } from "react-query";

function Badge() {
  const styles = {
    background: "#FBFBFC",
    border: "1px solid #EDEDEE",
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "10px",
  };
  const { session } = useAuth();

  const { data: metrics } = useQuery("invitee-metrics", () =>
    Requests.fetchWithAuthToken(
      "/lms/team/metrics",
      "GET",
      session.session.token
    )
  );

  const name = `${session?.admin?.name}`;
  const acronym = name.charAt(0);

  return (
    <div className="px-2 py-5">
      <div style={styles}>
        <Row className="h-full" gutter={35}>
          <Col md={4}>
            <Avatar
              size={30}
              style={{ background: "#4090A2", marginTop: "8px" }}
            >
              <Text style={{ fontSize: 15, color: "#fff" }}>{acronym}</Text>
            </Avatar>
          </Col>
          <Col md={20}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ color: "#000", fontWeight: "bold" }}>{name}</Text>
              <Text>{metrics?.data?.active_users} {metrics?.data?.active_users === 1 ? "Member" : "Members"}</Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Badge;
