import { Row, Col, Divider, Empty, Form } from "antd";
import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import Requests from "../../../../utils/request";
import AddFacilityExpenses from "./UpdateDetails/AddFacilityExpenses";

const FacilityExpenses = ({ template }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const { handleCustomField } = UseDisplayCustom();
  const { generateInputType } = UseGenerateComponentNew();
  const expensesRef = React.createRef();

  const { data: facility_expenses, refetch: refetchFacilityExpenses } =
    useQuery(
      "facility_expenses",
      () =>
        Requests.fetchWithAuthToken(
          `/lms/loan_request/${id}/data?data=facility-expenses`,
          "GET",
          session.session.token
        ),
      { refetchOnWindowFocus: false }
    );

  useEffect(() => {
    expensesRef?.current?.setFieldsValue({
      annual_rent_cost: facility_expenses?.data?.annual_rent_cost,
      internet_and_others: facility_expenses?.data?.internet_and_others,
      monthly_electricity_cost:
        facility_expenses?.data?.monthly_electricity_cost,
      licence_and_insurance_monthly:
        facility_expenses?.data?.licence_and_insurance_monthly,
      average_monthly_revenue: facility_expenses?.data?.average_monthly_revenue,
      average_monthly_expenses:
        facility_expenses?.data?.average_monthly_expenses,
      monthly_fuel_cost: facility_expenses?.data?.monthly_fuel_cost,
      monthly_loan_servicing_amount:
        facility_expenses?.monthly_loan_servicing_amount,
      phone_and_cost: facility_expenses?.data?.phone_cost,
      revenue_in_past_12_months:
        facility_expenses?.data?.revenue_in_past_12_months,
      total_salaries: facility_expenses?.data?.total_salaries,
      service_cost: facility_expenses?.data?.service_cost,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility_expenses]);

  return (
    <>
      <div className="mt-5">
        <Form layout="vertical" ref={expensesRef}>
          <Row gutter={[16, 16]}>
            {template?.map((data) =>
              data?.slug === "facility-metrics"
                ? data?.fields?.map((field) =>
                    field?.is_required || field?.is_needed ? (
                      <Col span={8}>
                        <Form.Item name={field?.field} label={field?.label}>
                          {generateInputType(field)}
                        </Form.Item>
                        <Divider />
                      </Col>
                    ) : null
                  )
                : null
            )}
            {facility_expenses?.data?.custom_fields &&
              facility_expenses?.data?.custom_fields?.map((fields) => (
                <Col span={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500">{fields?.label}: </h3>
                    <h3 className="font-bold text-base">
                      {handleCustomField(fields)}
                    </h3>
                  </div>
                  <Divider />
                </Col>
              ))}
            {facility_expenses?.data === null && (
              <Col span={24}>
                <Empty />
              </Col>
            )}
          </Row>
        </Form>
        <div className="flex justify-end">
          <AddFacilityExpenses
            data={facility_expenses?.data}
            refetchFacilityExpenses={refetchFacilityExpenses}
          />
        </div>
      </div>
    </>
  );
};

export default FacilityExpenses;
