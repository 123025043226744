import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function NotificationsRoute({ children, ...rest }) {
  const { session } = useAuth();
  return (
    <>
      <Route
        {...rest}
        render={({ location }) =>
          session.access_to.notifications ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/access_denied",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
}
