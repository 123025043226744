import React, { useEffect, useState } from "react";
import { message, Drawer, Button, Form, Col, Row, Input, Select } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";
import { LGA } from "../../../../../utils/LGA";

const { Option } = Select;

const AddHealthFacility = ({ data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const healthFacilityRef = React.createRef();
  const [userState, setState] = useState("");

  const mutationHealthFacility = useMutation((health_facility) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=health-facility`,
      "POST",
      session.session.token,
      health_facility
    )
  );

  useEffect(() => {
    healthFacilityRef?.current?.setFieldsValue({
      name: data?.name,
      facility_type : data?.facility_type,
      local_government: data?.local_government,
      state_of_primary_operations: data?.state_of_primary_operations,
      email: data?.email,
      operating_hours: data?.operating_hours,
    });
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationHealthFacility.mutate(values, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Health Facility
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Health Facility Details</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={healthFacilityRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Name"
                name="name"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
              >
                <Input size="large" type="email" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Operating Hours"
                name="operating_hours"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Facility Type"
                name="facility_type"
              >
                <Select>
                  <Option value="laboratory">Laboratory</Option>
                  <Option value="clinic">Clinic</Option>
                  <Option value="pharmacy">Pharmacy</Option>
                  <Option value="hospital">Hospital</Option>
                  <Option value="imaging_center">Imaging Center</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="State of Primary Operations"
                name="state_of_primary_operations"
              >
                 <Select onChange={(val) => setState(val)}>
                  {LGA?.map((state) =>
                    <Option key={state.state} value={state.state}>{state.state}</Option>
                  )}
                 </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Local government"
                name="local_government"
              >
                 <Select>
                  {LGA.find((state) => state.state === userState)?.lgas.map(
                    (lga) => (
                      <Option value={lga} key={lga}>
                        {lga}
                      </Option>
                    )
                  )}
                 </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddHealthFacility;