import { Avatar, Tabs } from "antd";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NextOfKin from "./details/NextOfKin";
import Profile from "./details/Profile";
import ValidID from "./details/ValidID";
import DocUpload from "./details/DocsUpload";
import Bank from "./details/Bank";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import Gurantor from "./details/Gurantor";
import Mono from "./details/Mono";
import Employment from "./details/Employment";
import SubmitCard from "./details/SubmitCard";
import FacilityExpenses from "./details/FacilityExpenses";
import HealthFacility from "./details/HealthFacility";
import FacilityMetrics from "./details/FacilityMetrics";
import Custom from "./details/Custom";
const { TabPane } = Tabs;

function HealthLoan({ id, showGuarantor, mono, userID }) {
  const { session } = useAuth();
  const [currentNumber, setCurrentNumber] = useState("1");

  const { data: loan } = useQuery(`loan`, () =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request?id=${id}`,
      "GET",
      session?.admin?.token
    )
  );
  const tree = {
    "additional-information": (
      <Profile
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
        userID={userID}
      />
    ),
    "next-of-kin": (
      <NextOfKin
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
    "valid-identity": (
      <ValidID
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
    "document-uploads": (
      <DocUpload
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
    "bank-details": (
      <Bank
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
    "employment-information": (
      <Employment
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),

    "health-facility-information": (
      <HealthFacility
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),

    "facility-expenses": (
      <FacilityExpenses
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),

    "numbers-&-metrics": (
      <FacilityMetrics
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
  };

  const [segmentTitles, setSegmentTitles] = useState([]);
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);

  const { data } = useQuery(
    "progress",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/user/loan_request/${id}/progress`,
        "GET",
        session?.admin?.token
      ),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    setSegmentTitles(Object.values(currentLoan?.segments || {}));
  }, [currentLoan]);

  useEffect(() => {
    if (data?.status === "error") {
      setCurrentNumber("1");
      return;
    }

    if (data?.data === "") {
      setCurrentNumber("1");
      return;
    }

    if (data?.data === "end") {
      setCurrentNumber("end");
      return;
    }

    if (data?.data) {
      setCurrentNumber(data?.data);
    }
  }, [data]);

  const getKeyForSubmitTab = (segments) => {
    if (mono && showGuarantor) {
      return segments?.slice(1)?.length + 3;
    }

    if (mono || showGuarantor) {
      return segments?.slice(1)?.length + 2;
    }

    if (mono === false && showGuarantor === false) {
      return segments?.slice(1)?.length + 1;
    }
  };
  const isMono = mono ? segmentTitles?.length + 2 : segmentTitles?.length + 1;

  if (currentLoan === null) {
    return "loading";
  }

  return (
    <section>
      <Tabs
        type="card"
        onChange={(val) => setCurrentNumber(val)}
        defaultActiveKey="1"
        centered
      >
        {segmentTitles?.slice(1).map((segment, index) => (
          <TabPane
            tab={
              <>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor:
                      currentNumber === String(index + 1) ? "green" : "black",
                  }}
                >
                  {index + 1}
                </Avatar>{" "}
                {segment?.segment}
              </>
            }
            key={index + 1}
          >
            {segment?.is_custom ? (
              <div className="my-12 lg:mx-60">
                <Custom
                  status={loan?.data?.user_status}
                  setCurrentNumber={setCurrentNumber}
                  currentNumber={currentNumber}
                  id={id}
                  slug={segment.slug}
                />
              </div>
            ) : (
              <div className="my-12 lg:mx-60"> {tree[segment?.slug]} </div>
            )}
          </TabPane>
        ))}

        {mono && !!segmentTitles?.length && (
          <TabPane
            tab={
              <>
                <Avatar
                  style={{
                    backgroundColor:
                      currentNumber === segmentTitles?.slice(1)?.length + 1
                        ? "green"
                        : "black",
                  }}
                  size="small"
                >
                  {segmentTitles?.slice(1)?.length + 1}
                </Avatar>{" "}
                Bank Statement (via mono)
              </>
            }
            key={segmentTitles?.slice(1)?.length + 1}
          >
            <div className="my-12">
              <Mono
                status={loan?.data?.user_status}
                setCurrentNumber={setCurrentNumber}
                currentNumber={showGuarantor ? currentNumber : 0}
                id={id}
              />
            </div>
          </TabPane>
        )}

        {showGuarantor && !!segmentTitles?.length && (
          <TabPane
            tab={
              <>
                <Avatar
                  style={{
                    backgroundColor:
                      currentNumber === isMono ? "green" : "black",
                  }}
                  size="small"
                >
                  {mono
                    ? segmentTitles?.slice(1)?.length + 2
                    : segmentTitles?.slice(1)?.length + 1}
                </Avatar>{" "}
                Guarantor
              </>
            }
            key={
              mono
                ? segmentTitles?.slice(1)?.length + 2
                : segmentTitles?.slice(1)?.length + 1
            }
          >
            <div className="my-12">
              <Gurantor
                status={loan?.data?.user_status}
                setCurrentNumber={setCurrentNumber}
                currentNumber={currentNumber}
                id={id}
              />
            </div>
          </TabPane>
        )}

        {!!segmentTitles?.length && (
          <TabPane
            tab={
              <>
                <Avatar
                  style={{
                    backgroundColor:
                      currentNumber === getKeyForSubmitTab(segmentTitles)
                        ? "green"
                        : "black",
                  }}
                  size="small"
                >
                  {getKeyForSubmitTab(segmentTitles)}
                </Avatar>{" "}
                Submit
              </>
            }
            key={getKeyForSubmitTab(segmentTitles)}
          >
            <SubmitCard id={id} />
          </TabPane>
        )}
      </Tabs>
      )
    </section>
  );
}

export default HealthLoan;
