import React from "react";
import { Button, message, Table } from "antd";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";
import { CSVLink } from "react-csv";

function cleanDataSet(data) {
	const cleanDataSet = [];
	for (let officer in data) {
		const array = [{ loan_officer: officer }, ...data[officer]];

		cleanDataSet.push(...array);
	}

	return cleanDataSet;
}

function DelinquencyTable({ isLoading }) {
	const { Column, ColumnGroup } = Table;
	const { profile } = useSelector((state) => state.profileReducer);
	const { delinquency } = useSelector((state) => state.delinquencyTableReducer);
	const { currencyFormatter } = useCurrenncyFormatter();

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="primary">
					<CSVLink
						filename={"Delinquency_Report.csv"}
						data={cleanDataSet(delinquency) || ""}
						onClick={() => {
							message.success("The file is downloading");
						}}
					>
						Export to CSV
					</CSVLink>
				</Button>
			</div>
			<Table
				dataSource={cleanDataSet(delinquency)}
				className="mt-5"
				loading={isLoading}
				pagination={{ pageSize: 10, position: ["bottomLeft"] }}
				size="middle"
				scroll={{ x: "max-content" }}
				bordered
			>
				<Column
					title="Loan Officer"
					dataIndex="loan_officer"
					key="loan_officer"
					render={(text, record) => (
						<p style={{ fontSize: "18px", fontWeight: "700", color: "#000" }}>
							{record.loan_officer}
						</p>
					)}
				/>
				<ColumnGroup>
					<Column
						title="Borrower Name"
						dataIndex="borrower_name"
						key="borrower_name"
					/>
					<Column title="ID" dataIndex="borrower_id" key="borrower_id" />
					<Column
						title="Disbursed date"
						dataIndex="date_disbursed"
						key="date_disbursed"
						sorter={(a, b) =>
							new Date(a.date_disbursed).valueOf() >
							new Date(b.date_disbursed).valueOf()
						}
						render={(text, record) => (
							<div>
								{record.date_disbursed
									? `${timeDate(record.date_disbursed, profile.date_format)}`
									: ""}
							</div>
						)}
					/>
					<Column
						title="Loan Maturity Date"
						dataIndex="loan_maturity_date"
						key="loan_maturity_date"
						sorter={(a, b) =>
							new Date(a.loan_maturity_date).valueOf() -
							new Date(b.loan_maturity_date).valueOf()
						}
						render={(text, record) => (
							<div>
								{record.loan_maturity_date
									? `${timeDate(
											record.loan_maturity_date,
											profile.date_format
									  )}`
									: ""}
							</div>
						)}
					/>
					<Column
						title="Principal Amount Disbursed"
						dataIndex="principal_amount_disbursed"
						key="principal_amount_disbursed"
						render={(_, record) => (
							<div>
								{record.principal_amount_disbursed ? (
									<span>
										{currencyFormatter(record.principal_amount_disbursed)}
									</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column
						title="Total Repayment Amount"
						dataIndex="total_repayment_amount"
						key="total_repayment_amount"
						render={(_, record) => (
							<div>
								{record.total_repayment_amount ? (
									<span>
										{currencyFormatter(record.total_repayment_amount)}
									</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column
						title="Portfolio At Risk"
						dataIndex="portfolio_at_risk"
						key="portfolio_at_risk"
						render={(_, record) => (
							<div>
								{record.portfolio_at_risk ? (
									<span>{currencyFormatter(record.portfolio_at_risk)}</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column title="Interest" dataIndex="interest" key="interest" />
					<Column
						title="Interest Amount Due Per Instalment"
						dataIndex="interest_amount_due_per_instalment"
						key="interest_amount_due_per_instalment"
						render={(_, record) => (
							<div>
								{record.interest_amount_due_per_instalment ? (
									<span>
										{currencyFormatter(
											record.interest_amount_due_per_instalment
										)}
									</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column
						title="Loan Balance Outstanding"
						dataIndex="loan_balance_outstanding"
						key="loan_balance_outstanding"
						render={(_, record) => (
							<div>
								{record.loan_balance_outstanding ? (
									<span>
										{currencyFormatter(record.loan_balance_outstanding)}
									</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column
						title="Loan Installment Missed Count"
						dataIndex="loan_installment_missed_count"
						key="loan_installment_missed_count"
					/>
					<Column
						title="No of Days Overdue (Arrears)"
						dataIndex="days_overdue"
						key="days_overdue"
						render={(_, record) =>
							record.days_overdue ? (
								<span>{Number(record.days_overdue).toFixed(2)}</span>
							) : (
								""
							)
						}
					/>
					<Column
						title="Security Deposit / Cash Collateral"
						dataIndex="security_deposit"
						key="security_deposit"
						render={(_, record) => (
							<div>
								{record.security_deposit ? (
									<span>{currencyFormatter(record.security_deposit)}</span>
								) : (
									""
								)}
							</div>
						)}
					/>
					<Column
						title="Exposed Amount"
						dataIndex="exposed_amount"
						key="exposed_amount"
					/>
				</ColumnGroup>
			</Table>
		</div>
	);
}

export default DelinquencyTable;
