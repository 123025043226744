import React, { useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import { AllLoanHeader } from "../Header";
import AllLoanTable from "../../Table/Loan/AllLoanTable";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { ADDALLLOANS, SETALLLOANSTATUS } from "../../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const { TabPane } = Tabs;

function AllLoans() {
	const style = {
		background: "#ffffff",
		padding: "20px",
		borderRadius: "12px",
	};

	const { session } = useAuth();
	const dispatch = useDispatch();
	const { searchValue, endDate, startDate, status } = useSelector(
		(state) => state.allLoanTableReducer
	);
	const dateFormat = "YYYY-MM-DD";

	const { data: allLoan, isLoading } = useQuery(
		`all-loans-start=${startDate}&end=${endDate}`,
		() =>
			Requests.fetchWithAuthToken(
				`/lms/loan?id=all&start=${moment(startDate).format(
					dateFormat
				)}&end=${moment(endDate).format(dateFormat)}`,
				"GET",
				session.session.token
			)
	);

	const handleFilterByStatus = (val) => {
		dispatch({ type: SETALLLOANSTATUS, value: val });
	};
	const SearchQuery = (data) => {
		const firstQuery = data?.filter((allLoan) => {
			const name = `${allLoan?.borrower?.firstname} ${allLoan?.borrower?.lastname}`;
			if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
				return allLoan;
			}
			return null;
		});

		return firstQuery?.filter((data) => {
			if (status && status !== "all") {
				return data?.borrower?.is_active.toString() === status;
			}
			return true;
		});
	};

	useEffect(() => {
		if (allLoan?.status === "success") {
			dispatch({
				type: ADDALLLOANS,
				value: SearchQuery(allLoan?.data),
			});
		}
		// eslint-disable-next-line
	}, [allLoan]);

	useEffect(() => {
		dispatch({
			type: ADDALLLOANS,
			value: SearchQuery(allLoan?.data),
		});

		// eslint-disable-next-line
	}, [searchValue, status]);

	return (
		<Row style={{ marginTop: "30px" }}>
			<Col span={24}>
				<div style={style}>
					<AllLoanHeader />
					<Tabs size="default" type="card" onTabClick={(key) => handleFilterByStatus(key)} style={{marginTop:'20px', marginBottom:'0'}}>
          				
						<TabPane tab="Fully Paid" key="false">
							<div className="my-10">
								<AllLoanTable isLoading={isLoading} />
							</div>
						</TabPane>
						<TabPane tab="Active Loans" key="true">
							<div className="my-10">
								<AllLoanTable isLoading={isLoading} />
							</div>
						</TabPane>
						<TabPane tab="All Loans" key="all" >
							<div className="my-10">
								<AllLoanTable isLoading={isLoading} />
							</div>
						</TabPane>
					</Tabs>
				</div>
			</Col>
		</Row>
	);
}

export default AllLoans;
