import moment from "moment";
import {
  ADDALLGURANTORS,
  GURANTORSERACHVALUE,
  SETGURANTORENDDATE,
  SETGURANTORSTARTDATE,
} from "../../Types/tableTypes";

const initialState = {
  gurantors: [],
  searchValue: "",
  endDate: moment().endOf("month").format("YYYY-MM-DD"),
  startDate: moment().max("2021-05-01").format("YYYY-MM-DD"),
};

export const gurantorsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLGURANTORS) {
    return { ...state, gurantors: actions.value };
  }

  if (actions.type === GURANTORSERACHVALUE) {
    return {
      ...state,
      searchValue: actions.value,
    };
  }

  if (actions.type === SETGURANTORENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETGURANTORSTARTDATE) {
    return { ...state, startDate: actions.value };
  }
  return state;
};
