import { Card, Form, Input, Divider, Row, Col, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import AddCustomFieldBox from "../../components/LoanProducts/AddCustomFieldBox";
import { Title } from "../../components/Typography";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router";
import Requests from "../../utils/request";
import { REESTFIELDS } from "../../redux/Types/generalTypes";
import BackBtn from "../../utils/BackButton";
import useLocationQuery from "../../utils/useQuery";

function AddCustomField(props) {
  let query = useLocationQuery();
  const customSegment = query.get("custom-segment");
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { slug, segment } = useParams();

  const { fields } = useSelector((state) => state.CustomFieldsReducer);
  const [catName, setCatName] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const mutationKey = useMutation((fields) =>
    Requests.fetchWithAuthToken(
      `/lms/product/${slug}/custom_segment`,
      "PUT",
      session.session.token,
      fields
    )
  );

  const mutationNewField = useMutation((fields) =>
    Requests.fetchWithAuthToken(
      `/lms/product/${slug}/custom_field?action=add&segment-slug=${segment}&segment-type=${
        customSegment ? "custom" : ""
      }`,
      "PUT",
      session.session.token,
      fields
    )
  );

  const handleSubmit = () => {
    const payload = {
      segment: catName,
      slug: catName?.trim().replaceAll(" ", "-").toLowerCase(),
      is_custom: true,
      fields: Object.values(fields),
    };

    setProcessing(true);

    mutationKey.mutate(payload, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
        setProcessing(false);
        setCatName("");
        dispatch({ type: REESTFIELDS });
      },
      onError: (err) => {},
    });
  };

  const handleSubmitField = () => {
    const payload = Object.values(fields);

    setProcessing(true);

    mutationNewField.mutate(payload, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
        setProcessing(false);
        setCatName("");
        dispatch({ type: REESTFIELDS });
      },
      onError: (err) => {},
    });
  };

  return (
    <div>
      <div className="my-2">
        <BackBtn />
      </div>
      <Title level={2}>Add Custom Fields</Title>
      <Card>
        <Form.Item label="Section Name">
          <Input
            value={segment ? segment : catName}
            onChange={(e) => setCatName(e.target.value)}
          />
        </Form.Item>
        <Divider />
        <Row gutter={[10, 10]}>
          {Object.keys(fields).map((id) => (
            <Col md={12} key={id}>
              <AddCustomFieldBox ID={id} />
            </Col>
          ))}
        </Row>

        <div className="my-5 flex justify-end space-x-4">
          <Button
            onClick={() => dispatch({ type: "ADDNEWFIELDID" })}
            icon={<PlusCircleOutlined />}
          >
            Add more
          </Button>
          {!!Object.keys(fields).length && (
            <Button
              type="primary"
              loading={isProcessing}
              disabled={
                segment === "loan-request" || segment === "valid-identity"
              }
              onClick={segment ? handleSubmitField : handleSubmit}
            >
              Create
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default AddCustomField;
