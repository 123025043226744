import React, { useState } from "react";
import { Table, Button } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import ProductModal from "../../Modals/ProductModal";

function AllProductTable({ isLoading }) {
  const { products } = useSelector((state) => state.loanProductTableReducer);
  const [openModal, setOpenModal] = useState(false);

  const [slug, setSlug] = useState(null);
  const history = useHistory();
  const { session } = useAuth();

  const columns = [
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Product Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-4 items-center">
          <Button
            onClick={() => {
              history.push(`/products/${record.id}?slug=${record.slug}`);
            }}
          >
            Configure
          </Button>

          <Button
            onClick={() => {
              history.push(`/products/${record.slug}/update`);
            }}
          >
            Update
          </Button>

          {session?.admin?.category === "merchant" ? (
            <Button
              type="dashed"
              target="_blank"
              onClick={() => {
                setSlug(record.slug);
                setOpenModal(true);
              }}
            >
              Share
            </Button>
          ) : (
            <Button
              type="dashed"
              target="_blank"
              href={`https://${session.admin.slug}.${process.env.REACT_APP_WEBFORM}/p/${record.slug}`}
            >
              View
            </Button>
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={products}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
        rowKey="slug"
      />
      <ProductModal
        openModal={openModal}
        slug={slug}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AllProductTable;
