import { DatePicker } from "antd";
import React from "react";
import { DropdownBtn } from "../Loan/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  SETREPAYMENTSMETRICSENDDATE,
  SETREPAYMENTSMETRICSSTARTDATE,
} from "../../redux/Types/generalTypes";
import { Title } from "../Typography";

const { RangePicker } = DatePicker;

export function RepaymentHeader({ title, overdue, repaid }) {
  const dispatch = useDispatch();
  const { metricsEndDate, metricsStartDate } = useSelector(
    (state) => state.repaymentsMetricsReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const startDateFilter = (e) => {
    dispatch({
      type: SETREPAYMENTSMETRICSSTARTDATE,
      value: e.metricsStartDate,
    });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETREPAYMENTSMETRICSENDDATE, value: e.metricsEndDate });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Title level={2}>Repayments</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignContent: "center",
          width: "40%",
        }}
      >
        {overdue || repaid ? (
          <DropdownBtn
            border="1.01441px solid #D5D5DE"
            color="#757580"
            background="#fff"
            text="All Loan Products"
            size="large"
            width="45%"
          />
        ) : (
          ""
        )}
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { metricsStartDate: value[0] },
              endDateFilter({ metricsEndDate: value[1] })
            )
          }
          defaultValue={[metricsStartDate, metricsEndDate]}
          format={dateFormat}
          on
        />
      </div>
    </div>
  );
}
