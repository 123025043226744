import moment from "moment";
import { SETDISBURSEMENTMETRICSENDDATE, SETDISBURSEMENTMETRICSSTARTDATE } from "../Types/generalTypes";

const initialState = { 
  name: 0,
  metricsEndDate: moment().endOf("month"),
  metricsStartDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const disbursementReducer = (state = initialState, actions) => {
  if (actions.type === "ADD") {
    return { name: state.name + 1 };
  }

  if (actions.type === SETDISBURSEMENTMETRICSENDDATE) {
    return { ...state, metricsEndDate: actions.value };
  }

  if (actions.type === SETDISBURSEMENTMETRICSSTARTDATE) {
    return { ...state, metricsStartDate: actions.value };
  }

  return state;
};
