import moment from "moment";
import {
  ADDALLLOANREQUEST,
  LOANREQUESTSEARCHVALUE,
  SETLOANREQUESTENDDATE,
  SETLOANREQUESTSTARTDATE,
  SETLOANSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  loanrequests: [],
  searchValue: "",
  status: "saved",
  endDate: moment().add(7, 'days').endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const loanRequestsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANREQUEST) {
    return { ...state, loanrequests: actions.value };
  }

  if (actions.type === LOANREQUESTSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETLOANSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETLOANREQUESTENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETLOANREQUESTSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
