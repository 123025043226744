import moment from "moment";
import {
  ADDACTIVITYLOG,
  ALLACTIVITYLOGSEARCHVALUE,
  SETACTIVITYLOGENDDATE,
  SETACTIVITYLOGSTARTDATE,
  SETACTIVITYLOGSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  logs: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"),
};

export const activityLogTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDACTIVITYLOG) {
    return { ...state, logs: actions.value };
  }

  if (actions.type === ALLACTIVITYLOGSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETACTIVITYLOGSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETACTIVITYLOGENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETACTIVITYLOGSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
