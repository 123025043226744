import { 
    ADDSINGLEREPAYMENTS, 
    SINGLEREPAYMENTSEARCHVALUE,
    SETSINGLEREPAYMENTENDDATE,
    SETSINGLEREPAYMENTSTARTDATE,
    SETSINGLEREPAYMENTSTATUS
  } from '../../Types/tableTypes';
  
  import moment from "moment";
  
  const initialState = { 
    singleRepayments: [],
    searchValue: "",
    status: "",
    endDate: moment().endOf("month"),
    startDate: moment().max("2021-05-01T20:00:00+0800"), 
  };
  
  export const singleRepaymentTableReducer = (state = initialState, actions) => {
    if (actions.type === ADDSINGLEREPAYMENTS ) {
      return { ...state, singleRepayments: actions.value }
    }
  
    if (actions.type === SINGLEREPAYMENTSEARCHVALUE ) {
      return { ...state, searchValue: actions.value }
    }
  
    if (actions.type === SETSINGLEREPAYMENTENDDATE) {
      return { ...state, endDate: actions.value };
    }
  
    if (actions.type === SETSINGLEREPAYMENTSTARTDATE) {
      return { ...state, startDate: actions.value };
    }
    if (actions.type === SETSINGLEREPAYMENTSTATUS) {
      return { ...state, status: actions.value };
    }
    return state;
  }
  
  export default singleRepaymentTableReducer;