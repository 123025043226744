import React, { useEffect } from "react";
import { Card, Tabs } from "antd";
import moment from "moment";
import {
  Heading,
  MiddleCards,
  TopCards,
  EndCard,
  TableHeader,
} from "../../components/Borrowers";
import BorrowersTable from "../../components/Table/Borrowers/BorrowersTable";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDALLBORROWER,
  SETBORROWERSTATUS,
} from "../../redux/Types/tableTypes";
import Requests from "../../utils/request";

const { TabPane } = Tabs;

function Borrowers(props) {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue, startDate, endDate, status } = useSelector(
    (state) => state.borrowersTableReducer
  );
  const { metricsStartDate, metricsEndDate } = useSelector(
    (state) => state.borrowersMetricsReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETBORROWERSTATUS, value: val });
  };

  const { data: borrowers, isLoading } = useQuery(
    `all_borrowers-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: borrowers_metrics } = useQuery(
    `all_borrowers-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/metrics?&start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(dateFormat)}&section=main`,
        "GET",
        session.session.token
      )
  );

  const { data: borrowers_metrics_overdue } = useQuery(
    `overdue_borrowers-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/metrics?&start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(dateFormat)}&section=overdue`,
        "GET",
        session.session.token
      )
  );

  const { data: borrowers_metrics_growth } = useQuery(
    `borrowers-growth-metrics=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/metrics?&start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(
          dateFormat
        )}&section=borrower-growth`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((borrower) => {
      const name = `${borrower.firstname} ${borrower.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return borrower;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.is_active?.toString() === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (borrowers?.status === "success") {
      dispatch({
        type: ADDALLBORROWER,
        value: SearchQuery(borrowers?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading, status]);

  useEffect(() => {
    dispatch({
      type: ADDALLBORROWER,
      value: SearchQuery(borrowers?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <Heading />
      <TopCards data={borrowers_metrics?.data} />
      <MiddleCards data={borrowers_metrics?.data} />
      <EndCard
        data={borrowers_metrics_overdue?.data}
        data1={borrowers_metrics_growth?.data}
      />
      <div className="mt-10">
        <Card>
          <TableHeader />
          <Tabs
            size="default"
            type="card"
            onTabClick={(key) => handleFilterByStatus(key)}
            style={{ marginTop: "20px", marginBottom: "0" }}
          >
            <TabPane tab="All Borrowers" key="">
              <BorrowersTable isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Active Borrowers" key="true">
              <BorrowersTable isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Inactive Borrowers" key="false">
              <BorrowersTable isLoading={isLoading} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </>
  );
}

export default Borrowers;
