import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker } from "antd";

const { RangePicker } = DatePicker;

function TableHeader(props) {
  const dateFormat = "YYYY-MM-DD";

  return (
    <div className="mb-5">
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        Easily manage all the collaterals across your loans.
      </p>
      <div className="flex items-center justify-between">
        <div className="flex space-x-4">
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "75%" }}
            prefix={<SearchOutlined />}
          />

          <RangePicker
            style={{ width: "75%" }}
            format={dateFormat}
            on
          />
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
