import React, { useEffect, useState } from "react";
import {
  Col,
  Radio,
  Row,
  Card,
  message,
  Input,
  Form,
  Select,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../context/AuthContext";
import ButtonComponent from "./ButtonComponent";
import { useMutation, useQuery } from "react-query";
import Request from "../../utils/request";
import { Title } from "../Typography";

const { Option } = Select;
function ChooseRoleType({ isModal }) {
  const { session } = useAuth();
  const roleRef = React.createRef();
  const history = useHistory();
  const [roleType, setRoleType] = useState();
  const [roleName, setRoleName] = useState("");
  const [roleTypeName, setRoleTypeName] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const [loadingDefault, setLoadingDefault] = useState(false);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [isProcessingForAddMore, setIsProcessingForAddMore] = useState(false);

  const { data: config } = useQuery("config", () =>
    Request.fetchWithAuthToken("/lms/config", "GET", session.session.token)
  );

  const { data } = useQuery("defaultRole", () =>
    Request.fetchWithAuthToken(
      "/lms/utils/def_hierarchies",
      "GET",
      session.session.token
    )
  );

  const mutationKey = useMutation((type) =>
    Request.fetchWithAuthToken(
      `/lms/config/appr_hierarchy?method=${type}`,
      "PUT",
      session.session.token
    )
  );

  const mutationCustomRoles = useMutation((roles) =>
    Request.fetchWithAuthToken(
      `/lms/config/custom_role`,
      "POST",
      session.session.token,
      roles
    )
  );

  const handleSubmitCustomRoles = (values, type) => {
    if (values.name && values.approval_hierarchy) {
      type === "save_continue"
        ? setProcessing(true)
        : setIsProcessingForAddMore(true);
      mutationCustomRoles.mutate(values, {
        onSuccess: (data) => {
          type === "save_continue"
            ? setProcessing(false)
            : setIsProcessingForAddMore(false);

          if (data.status !== "success") {
            message.error(data.message, 3);
            return;
          }

          type === "save_continue"
            ? history.push("/adminsetup/teammembers")
            : message.success(data.message, 3);
        },
        onError: (error) => console.log(error),
      });
    }
  };

  const handleChange = (type) => {
    setRoleType(type);
    type === "custom" ? setLoadingCustom(true) : setLoadingDefault(true);
    mutationKey.mutate(type, {
      onSuccess: (data) => {
        type === "custom" ? setLoadingCustom(false) : setLoadingDefault(false);

        if (data.status !== "success") {
          setRoleType(null);
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    if (config) {
      setRoleType(config?.data?.approval_hierarchy_type);
    }
  }, [config]);

  return (
    <div>
      <div>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <div
              className="border rounded-md border-purple-300 p-2 cursor-pointer"
              onClick={() => handleChange("default")}
            >
              <Radio
                checked={roleType === "default"}
                onClick={() => handleChange("default")}
              >
                Default Role
              </Radio>
              {loadingDefault && <LoadingOutlined />}
            </div>
          </Col>
          <Col span={12}>
            <div
              className="border rounded-md border-purple-300 p-2 cursor-pointer"
              onClick={() => handleChange("custom")}
            >
              <Radio
                checked={roleType === "custom"}
                onClick={() => handleChange("custom")}
              >
                Custom Role
              </Radio>
              {loadingCustom && <LoadingOutlined />}
            </div>
          </Col>
        </Row>
      </div>

      {roleType === "default" && (
        <div>
          <div className="my-6">
            <span className="block text-black font-bold text-md">
              These are the three roles that govern your lending process
            </span>
            <span className="text-gray-400 text-sm">
              You can rename these roles by selecting custom
            </span>
          </div>
          <Row>
            {data?.data?.map((role) => (
              <Col span={8} key={role.name}>
                <Card title={role.name} key={role.slug} bordered={false}>
                  <p>
                    Can Approve{" "}
                    <span>
                      {role.can_approve ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </span>
                  </p>
                  <p>
                    Can Disburse
                    <span>
                      {role.can_disburse ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </span>
                  </p>
                  <p>
                    Can Mark Good{" "}
                    <span>
                      {role.can_mark_good ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </span>
                  </p>
                </Card>
              </Col>
            ))}
          </Row>

          {!isModal && (
            <ButtonComponent
              loading={isProcessing}
              onClick={() => history.push("/adminsetup/teammembers")}
            />
          )}
        </div>
      )}

      {roleType === "custom" && (
        <div className="my-6">
          <Title level={4}>Approval Role Hierarchy</Title>
          <Form layout="vertical" ref={roleRef}>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Form.Item label="Role Name" name="name">
                  <Input
                    placeholder="CEO"
                    size="large"
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Add Hierarchy" name="approval_hierarchy">
                  <Select size="large" onChange={(e) => setRoleTypeName(e)}>
                    {data?.data?.map((role) => (
                      <Option value={role.slug}>{role.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            loading={isProcessingForAddMore}
            onClick={() =>
              handleSubmitCustomRoles(
                {
                  name: roleName,
                  approval_hierarchy: roleTypeName,
                },
                "save_and_more"
              )
            }
          >
            Save and add more
          </Button>

          <ButtonComponent
            loading={isProcessing}
            onClick={() =>
              handleSubmitCustomRoles(
                {
                  name: roleName,
                  approval_hierarchy: roleTypeName,
                },
                "save_continue"
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export default ChooseRoleType;
