import React from "react";
import { Select, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  SETTRANSACTIONSTARTDATE,
  SETTRANSACTIONENDDATE,
  TRANSACTIONSEARCHVALUE,
  SETTRANSACTIONSTATUS,
} from "../../redux/Types/tableTypes";

function TransactionsTableHeader() {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.allLoanTableReducer
  );

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETTRANSACTIONSTATUS, value: val });
  };

  const handleSearch = (e) => {
    dispatch({ type: TRANSACTIONSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETTRANSACTIONSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETTRANSACTIONENDDATE, value: e.endDate });
  };

  const { Option } = Select;

  return (
    <div>
      <p style={{ fontSize: "15px", marginBottom: "30px", marginTop: "0" }}>
        Track & Manage every payment & transaction history on Configure.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            showSearch
            style={{ width: 200, cursor: "pointer" }}
            size="large"
            placeholder="Filter"
            optionFilterProp="children"
            allowClear
            onChange={(e) => handleFilterByStatus(e)}
          >
            <Option value="true">Success</Option>
            <Option value="false">Failed</Option>
          </Select>
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "75%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
        </div>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          size="large"
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
          style={{ marginRight: "10px", width: "250px" }}
        />
      </div>
    </div>
  );
}

export default TransactionsTableHeader;
