import React, { useState } from "react";
import { Card, Checkbox, Input, Table, Tooltip, Row, Col } from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import { currencyFormatter as currency } from "../../../utils/currencyFormatter";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { currencyFormatter } from "../../../utils/currencyFormatter";
import BankDetailsCard from "../../BSA/BankDetailsCard";

const { Column, ColumnGroup } = Table;

function MonthlyTransactionTable({ account_name, requestID, method }) {
  const { session } = useAuth();
  const [iskeywordView, setIsKeyWordView] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const { data, isLoading } = useQuery(
    `monthly-flow`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${requestID}/analysis?method=${method}&analysis=monthly-flow`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: stats, isLoading: keywordLoading } = useQuery(
    `keySearch-${keyWord}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/2/analysis?method=${method}&analysis=keyword-search&keyword=${keyWord}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const cleanData = (data = []) =>
    data?.map((res, index) => ({
      month: `${res?.month} ${res?.year}`,
      balance: res?.balance,
      total_credit: res?.total_credit,
      average_credit: res?.average_credit,
      median_credit: res?.median_credit,
      max_credit: res?.max_credit,
      total_debit: res?.total_debit,
      average_debit: res?.average_debit,
      median_debit: res?.median_debit,
      max_debit: res?.max_debit,
      color_balance:
        data[index].balance === 0
          ? "gray"
          : index > 0
          ? data[index - 1].balance > data[index].balance
            ? "red"
            : "green"
          : "green",

      color_total_credit:
        data[index].total_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].total_credit > data[index].total_credit
            ? "red"
            : "green"
          : "green",

      color_avg_credit:
        data[index].average_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].average_credit > data[index].average_credit
            ? "red"
            : "green"
          : "green",

      color_median_credit:
        data[index].median_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].median_credit > data[index].median_credit
            ? "red"
            : "green"
          : "green",

      color_max_credit:
        data[index].max_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].max_credit > data[index].max_credit
            ? "red"
            : "green"
          : "green",

      color_total_debit:
        data[index].total_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].total_debit > data[index].total_debit
            ? "red"
            : "green"
          : "green",

      color_avg_debit:
        data[index].average_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].average_debit > data[index].average_debit
            ? "red"
            : "green"
          : "green",

      color_median_debit:
        data[index].median_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].median_debit > data[index].median_debit
            ? "red"
            : "green"
          : "green",

      color_max_debit:
        data[index].max_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].max_debit > data[index].max_debit
            ? "red"
            : "green"
          : "green",
    }));

  return (
    <Card style={{ borderRadius: "12px" }}>
      <span
        className="px-2 text-lg"
        style={{ fontFamily: "Cera Pro Bold", color: "#2E2C34" }}
      >
        Monthly Transactions
      </span>

      <div className="flex justify-between items-center mb-5 ">
        <div className="flex space-x-5 items-center  flex-auto">
          <span>
            <Checkbox onChange={(val) => setIsKeyWordView(val.target.checked)}>
              use keyword search
            </Checkbox>
            <Tooltip title="Search keyword">
              <InfoCircleOutlined style={{ color: "#09099F" }} />
            </Tooltip>
          </span>
          {iskeywordView && (
            <div>
              <Input
                onChange={(val) => setKeyWord(val.target.value)}
                prefix={<SearchOutlined />}
                placeholder="Search..."
              />
            </div>
          )}
        </div>
      </div>
      {!iskeywordView && (
        <section>
          <div className="items-center flex justify-end py-2">
            <CSVLink
              style={{
                backgroundColor: "#5152A6",
                color: "#fff",
                borderRadius: 5,
                padding: 6,
                fontSize: 12,
                fontFamily: "Cera Pro Bold",
              }}
              data={data?.data || []}
              filename={`${account_name}.csv`}
            >
              Export to CSV
            </CSVLink>
          </div>
          <Table
            loading={isLoading}
            dataSource={cleanData(data?.data)}
            size="small"
            bordered
            style={{ borderColor: "black" }}
          >
            <Column title="Month" dataIndex="month" key="month" />
            <ColumnGroup title="Inflows">
              <Column
                title="Total Credit"
                dataIndex="total_credit"
                key="total_credit"
                render={(text, record) => (
                  <span style={{ color: record.color_total_credit }}>
                    {currency(text)}
                  </span>
                )}
              />

              <Column
                title="Avg. Credit"
                dataIndex="average_credit"
                key="average_credit"
                render={(text, record) => (
                  <span style={{ color: record.color_avg_credit }}>
                    {currency(text)}
                  </span>
                )}
              />
            </ColumnGroup>

            <ColumnGroup title="Outflows">
              <Column
                title="Total Debit"
                dataIndex="total_debit"
                key="total_debit"
                render={(text, record) => (
                  <span style={{ color: record.color_total_debit }}>
                    {currency(text)}
                  </span>
                )}
              />
              <Column
                title="Avg. Debit"
                dataIndex="average_debit"
                key="average_debit"
                render={(text, record) => (
                  <span style={{ color: record.color_avg_debit }}>
                    {currency(text)}
                  </span>
                )}
              />
            </ColumnGroup>
          </Table>
        </section>
      )}

      {/* Table for keyword search */}
      {iskeywordView && (
        <section className="mt-10">
          <Row>
            <Col xs={24} md={24} lg={12}>
              <BankDetailsCard
                details={[
                  {
                    label: "Total Inflow",
                    value: currencyFormatter(stats?.data?.total_credits?.value),
                  },

                  {
                    label: "Total Outflow",
                    value: currencyFormatter(stats?.data?.total_debits?.value),
                  },
                  {
                    label: "Average Monthly Inflow",
                    value: currencyFormatter(stats?.data?.average_credit),
                  },
                  {
                    label: "Average Monthly Outflow",
                    value: currencyFormatter(stats?.data?.average_debit),
                  },
                  {
                    label: "Median Debit",
                    value: currencyFormatter(stats?.data?.median_debit),
                  },
                  {
                    label: "Maximum Credit",
                    value: currencyFormatter(stats?.data?.maximum_credit),
                  },
                  {
                    label: "Maximum Debit",
                    value: currencyFormatter(stats?.data?.maximum_debit),
                  },
                ]}
              />
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className=" items-end  h-full flex justify-end py-2 ">
                <CSVLink
                  style={{
                    backgroundColor: "#5152A6",
                    color: "#fff",
                    borderRadius: 5,
                    padding: 6,
                    fontSize: 12,
                    fontFamily: "Cera Pro Bold",
                  }}
                  data={stats?.data1 || []}
                  filename={`${account_name}.csv`}
                >
                  Export to CSV
                </CSVLink>
              </div>
            </Col>
          </Row>

          <Table
            loading={keywordLoading}
            dataSource={cleanData(stats?.data1)}
            size="small"
            bordered
            style={{ borderColor: "black", marginTop: 15 }}
          >
            <Column title="Month" dataIndex="month" key="month" />
            <ColumnGroup title="Inflows">
              <Column
                title="Total Credit"
                dataIndex="total_credit"
                key="total_credit"
                render={(text, record) => (
                  <span style={{ color: record.color_total_credit }}>
                    {currency(text)}
                  </span>
                )}
              />

              <Column
                title="Avg. Credit"
                dataIndex="average_credit"
                key="average_credit"
                render={(text, record) => (
                  <span style={{ color: record.color_avg_credit }}>
                    {currency(text)}
                  </span>
                )}
              />
            </ColumnGroup>

            <ColumnGroup title="Outflows">
              <Column
                title="Total Debit"
                dataIndex="total_debit"
                key="total_debit"
                render={(text, record) => (
                  <span style={{ color: record.color_total_debit }}>
                    {currency(text)}
                  </span>
                )}
              />
              <Column
                title="Avg. Debit"
                dataIndex="average_debit"
                key="average_debit"
                render={(text, record) => (
                  <span style={{ color: record.color_avg_debit }}>
                    {currency(text)}
                  </span>
                )}
              />
            </ColumnGroup>
          </Table>
        </section>
      )}
    </Card>
  );
}

export default MonthlyTransactionTable;
