import React from "react";
import { Select, Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DropdownBtn } from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  ALLLOANSEARCHVALUE,
  SETLOANENDDATE,
  SETLOANSTARTDATE,
  SINGLEREPAYMENTSEARCHVALUE,
  SETSINGLEREPAYMENTSTATUS,
  SETSINGLEREPAYMENTENDDATE,
  SETSINGLEREPAYMENTSTARTDATE,
} from "../../redux/Types/tableTypes";
import { Title } from "../Typography";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export function LoanHeader({ title, overdue, repaid }) {
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.allLoanTableReducer
  );

  const startDateFilter = (e) => {
    dispatch({ type: SETLOANSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETLOANENDDATE, value: e.endDate });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Title level={2}>Loans</Title>
      <div>
        <RangePicker
          size="large"
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
          style={{ width: "250px" }}
        />
      </div>
    </div>
  );
}

export function AllLoanHeader({ title, overdue, repaid }) {
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.allLoanTableReducer
  );

  const handleSearch = (e) => {
    dispatch({ type: ALLLOANSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETLOANSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETLOANENDDATE, value: e.endDate });
  };

  return (
    <div>
      <h3
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
      >
        All {title} Loans
      </h3>
      <p style={{ fontSize: "15px", marginBottom: "30px", marginTop: "0" }}>
        Get insights into all of your loan activities, from active loans to
        repaid loans.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          {/* <Select
            showSearch
            allowClear
            style={{ width: 200, cursor:'pointer' }}
            size="large"
            placeholder="Filter"
            optionFilterProp="children"
            onChange={(val) => handleFilterByStatus(val)}
          >
          <Option value="true">Active</Option>
          <Option value="false">Pending</Option>
          </Select> */}
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "50%", marginRight: "10px" }}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
          <RangePicker
            onChange={(value) =>
              startDateFilter(
                { startDate: value[0] },
                endDateFilter({ endDate: value[1] })
              )
            }
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            on
            size="large"
            style={{ width: "250px" }}
          />
          {overdue || repaid ? (
            <DropdownBtn
              border="1.01441px solid #D5D5DE"
              color="#757580"
              background="#fff"
              text="All Products"
              size="large"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export function SingleRepayment({ overdue, repaid }) {
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.singleRepaymentTableReducer
  );

  const handleSearch = (e) => {
    dispatch({ type: SINGLEREPAYMENTSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETSINGLEREPAYMENTSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETSINGLEREPAYMENTENDDATE, value: e.endDate });
  };

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETSINGLEREPAYMENTSTATUS, value: val });
  };

  const { Option } = Select;

  return (
    <div>
      <h3
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
      >
        Repayments
      </h3>
      <p style={{ marginBottom: "30px", marginTop: "0", fontSize: "15px" }}>
        Repayments lets you track every single repayment on all your loans &
        credit for ease of recovery.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            showSearch
            allowClear
            style={{ width: 200, cursor: "pointer" }}
            size="large"
            placeholder="Filter"
            optionFilterProp="children"
            onChange={(val) => handleFilterByStatus(val)}
          >
            <Option value="pending">Pending</Option>
            <Option value="paid">Paid</Option>
          </Select>
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "50%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
          <RangePicker
            onChange={(value) =>
              startDateFilter(
                { startDate: value[0] },
                endDateFilter({ endDate: value[1] })
              )
            }
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            on
            size="large"
            style={{ width: "250px" }}
          />
          {overdue || repaid ? (
            <DropdownBtn
              border="1.01441px solid #D5D5DE"
              color="#757580"
              background="#fff"
              text="All Products"
              size="large"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
