import moment from "moment";
import {
  ADDALLBORROWERSREPAYMENT,
  ALLBORROWERSREPAYMENTSEARCHVALUE,
  SETBORROWERSREPAYMENTSENDDATE,
  SETBORROWERSREPAYMENTSTARTDATE,
  SETBORROWERSREPAYMENTSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  borrowersrepyaments: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const borrowersRepaymentTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLBORROWERSREPAYMENT) {
    return { ...state, borrowersrepayments: actions.value };
  }

  if (actions.type === ALLBORROWERSREPAYMENTSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETBORROWERSREPAYMENTSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETBORROWERSREPAYMENTSENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETBORROWERSREPAYMENTSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
