import { schema, superUser } from "../../../utils";

export const initialState = {
  team1: schema,
};

export function reducer(state, action) {
  switch (action.type) {
    case "invitee":
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          invitee: {
            ...state[action.formName]["invitee"],
            [action.field]: action.value,
          },
        },
      };
    case "cat_one_prev":
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          cat_one_prev: {
            ...state[action.formName]["cat_one_prev"],
            [action.field]: action.value,
          },
        },
      };
    case "cat_two_prev":
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          cat_two_prev: {
            ...state[action.formName]["cat_two_prev"],
            [action.field]: action.value,
          },
        },
      };

    case "remove_super_user":
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          cat_one_prev: schema.cat_one_prev,
          cat_two_prev: schema.cat_two_prev,
        },
      };

    case "super":
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          ...superUser,
        },
      };

    case "initState":
      return { ...state, [action.formName]: schema };

    case 'removeForm':
        delete state[action.formName]
        return state;
    default:
      throw new Error();
  }
}

export default reducer;
