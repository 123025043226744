import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  message,
  Input,
  notification,
  Select,
} from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import { Title } from "../../../Typography";
import { SETBANKCODE } from "../../../../redux/Types/generalTypes";
const { Option } = Select;
const { Search } = Input;
const bankform = React.createRef();

function Bank({ currentNumber, id, setCurrentNumber, status }) {
  const { bankCode } = useSelector((state) => state.LoanApplicationReducer);
  const dispatch = useDispatch();
  const { session } = useAuth();

  const [userBankDetails, setUserBankDetails] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { addBank, updateProgress } = useLoanApplication();

  const { data } = useQuery(
    `bankdetails`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=bank-account`,
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: bank } = useQuery(
    `banksinNigeria`,
    () =>
      Requests.fetchWithAuthoutBaseURL(`https://api.paystack.co/bank`, "GET"),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      setCurrentNumber((prev) => {
        const current = Number(prev) + 1;
        return current.toString();
      });
      updateProgress(
        { progress: String(Number(currentNumber) + 1), loan_requestID: id },
        (data) => data
      );
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleCheckBankAccount = async (e) => {
    const accountnumber = e.target.value;
    setIsSearching(true);
    if (!bankCode) {
      setIsSearching(false);
      return notification.error({ message: "Please select your bank" });
    }

    // Make a call to paystack to verify the account number
    const bankDetails = await Requests.fetchWithAuthoutBaseURL(
      `https://api.paystack.co/bank/resolve?account_number=${accountnumber}&bank_code=${bankCode}`,
      "GET",
      `sk_test_02bb0eac3b5ede66471af0b877e9253ed4367c04`
    );
    if (bankDetails.status) {
      setIsSearching(false);
      return setUserBankDetails(bankDetails.data);
    }
    setIsSearching(false);
    notification.error({ message: bankDetails.message });
  };

  const handleSubmit = (value) => {
    setIsLoading(true);

    const bankdetails = {
      loan_requestID: id,
      bank: {
        account_number: value.account_number,
        code: value.bank_name,
        bank_name: value.bank_name,
        account_name: value.account_name,
      },
    };

    addBank(bankdetails, handleSubmitCallback);
  };

  const generateFormObject = (fields, profile) => {
    const formObject = {};
    fields.forEach((field) => {
      formObject[field] = profile[field];
    });

    return formObject;
  };

  useEffect(() => {
    bankform?.current?.setFieldsValue({
      bank_code: bankCode,
    });
  }, [bankCode]);

  useEffect(() => {
    bankform?.current?.setFieldsValue({
      account_name: userBankDetails?.account_name,
    });
  }, [userBankDetails]);

  useEffect(() => {
    if (data) {
      const fields = Object.keys(data?.data ? data?.data[0] : {});
      const profile = data?.data ? data?.data[0] : {};
      const formObj = generateFormObject(fields, profile);

      bankform?.current?.setFieldsValue({
        ...formObj,
      });
    }
  }, [data]);

  return (
    <Card>
      <Title level={3}>Bank Details</Title>
      <Form ref={bankform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Bank Name"
              name="bank_name"
              rules={[
                {
                  required: true,
                  message: "required",
                },
              ]}
            >
              <Select
                size="large"
                onChange={(val) => dispatch({ type: SETBANKCODE, value: val })}
              >
                {bank?.data?.map((bank) => (
                  <Option key={bank.code} value={bank.code}>
                    {bank.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {bankCode || data?.data ? (
            <Col xs={24} md={12}>
              <Form.Item
                label="Account Number"
                name="account_number"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Search
                  size="large"
                  loading={isSearching}
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      handleCheckBankAccount(e);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}

          {userBankDetails || data?.data ? (
            <Col xs={24} md={12}>
              <Form.Item
                label="Account Name"
                name="account_name"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <Button
          className="lg:w-auto w-full"
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!userBankDetails}
        >
          Save
        </Button>
      </Form>
    </Card>
  );
}

export default Bank;
