import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { AppProvider } from "../../context/FetchContext";
import BackBtn from "../../utils/BackButton";
import RepaymentOverview from "./RepaymentOverview";

const SingleRepayment = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: repayment, refetch } = useQuery(
    "single_repayment",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/repayment?id=${id}&start=2021-05-01&end=2021-12-31`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: debit_instructions } = useQuery(
    "debit_instructions",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/repayment/${id}/data?data=debit-instructions
        `,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: partialRepayment, refetch: refectPartial } = useQuery(
    "partialRepayment",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/repayment/${id}/data?data=partial-repayments`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <AppProvider
        value={{ refetch, otherRefect: { refectPartial }, request: repayment }}
      >
        <div className="mb-20">
          <div className="flex justify-between mt-2">
            <BackBtn />
          </div>
          <div className="mt-5">
            <RepaymentOverview
              repayment={repayment}
              partialRepayment={partialRepayment}
              debit_instructions={debit_instructions}
            />
          </div>
        </div>
      </AppProvider>
    </>
  );
};

export default SingleRepayment;
