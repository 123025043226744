import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

function Breadcrumbs({link, name}) {

    return (
        <div style={{ marginBottom: '20px' }}>
            <Breadcrumb separator=">">
                <Breadcrumb.Item><Link to={{ pathname: "/loan" }} style={{ marginRight:'10px' }}>Loan</Link></Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={{ pathname: `/loan/${link}` }} style={{marginLeft:'10px'}}>{name} Loan Analytics</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
}

export default Breadcrumbs;
