import { Col } from "antd";
import React from "react";
import { Title } from "../Typography";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";

function WalletCard({ name, amount, icon, color }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const style = {
    display: "flex",
    border: "1px solid rgba(149, 149, 172, 0.16)",
    boxShadow: "0px 0.971248px 3.88499px rgba(21, 34, 50, 0.08)",
    borderRadius: "11.4894px",
    padding: "20px",
  };
  return (
    <Col span={8}>
      <div style={style}>
        <div
          style={{ marginRight: "10px", fontSize: "16px", color: `${color}` }}
        >
          {icon}
        </div>
        <div>
          <p style={{ fontSize: "16px", color: `${color}` }}>{name} Wallet</p>
          <Title level={3}>{currencyFormatter(amount)}</Title>
        </div>
      </div>
    </Col>
  );
}

export default WalletCard;
