import { SETALLTASK, ADDTASK } from "../Types/generalTypes";

const initialState = {
  started: [],
  inProgress: [],
  completed: [],
};

export const TaskReducer = (state = initialState, actions) => {
  if (actions.type === SETALLTASK) {
    return { ...actions.tasks };
  }

  if (actions.type === ADDTASK) {
    return { ...state, started: [...state.started, actions.task] };
  }

  return state;
};

export default TaskReducer;
