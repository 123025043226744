import React, { useReducer, useEffect, useState } from "react";
import Requests from "../../utils/request";
import { useAuth } from "../../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import { Drawer, Tabs, Switch, Divider, Card, message, Button } from "antd";
import {
  initialState,
  UpdatePriviledgeReducer,
} from "../AdminSetup/reducers/UpdatePriviledgeReducer";
import { access_features, allowed_features } from "../../utils";

const { TabPane } = Tabs;
function UpdatePrivileges({ visible, toggleVisible, email }) {
  const { session, signOutAux } = useAuth();
  const [state, dispatch] = useReducer(UpdatePriviledgeReducer, initialState);
  const [loadingCat_one_update, setLoadingCat_one_update] = useState(false);
  const [loadingCat_two_update, setLoadingCat_two_update] = useState(false);
  const UpdateCanSeeMutation = useMutation((body) =>
    Requests.fetchWithAuthToken(
      `/lms/team/${visible}/priviledges/update?section=can-see`,
      "PUT",
      session.session.token,
      body
    )
  );

  const UpdateCanDoMutation = useMutation((body) =>
    Requests.fetchWithAuthToken(
      `/lms/team/${visible}/priviledges/update?section=can-do`,
      "PUT",
      session.session.token,
      body
    )
  );

  const {
    data: singleteam,
    isLoading: isLoadingSingle,
    refetch,
  } = useQuery(
    `single-team-${visible}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/team?data=single&id=${visible}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleToggleCatOneSuperUser = (e) => {
    if (e) {
      dispatch({
        type: "super-cat_one_prev",
      });

      return;
    }

    dispatch({
      type: "remove_super-cat_one_prev",
    });
  };

  const handleToggleCatTwoSuperUser = (e) => {
    if (e) {
      dispatch({
        type: "super-cat_two_prev",
      });

      return;
    }

    dispatch({
      type: "remove_super-cat_two_prev",
    });
  };

  const handleUpdateCanSee = () => {
    setLoadingCat_one_update(true);
    UpdateCanSeeMutation.mutate(state.cat_one_prev, {
      onSuccess: (data) => {
        setLoadingCat_one_update(false);
        message.success(data.message);
        refetch();

        signOutAux(
          { all: true, email, by_ref: false, ref: "" },
          (data) => data
        );
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  };

  const handleUpdateCanDo = () => {
    setLoadingCat_two_update(true);
    UpdateCanDoMutation.mutate(state.cat_two_prev, {
      onSuccess: (data) => {
        setLoadingCat_two_update(false);
        message.success(data.message);
        refetch();

        signOutAux(
          { all: true, email, by_ref: false, ref: "" },
          (data) => data
        );
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  };

  useEffect(() => {
    if (singleteam?.data1 && singleteam?.data2) {
      dispatch({
        type: "init",
        cat_one_prev: singleteam?.data1,
        cat_two_prev: singleteam?.data2,
      });
    }
  }, [singleteam]);

  return (
    <Drawer visible={visible} width="100%" onClose={() => toggleVisible(null)}>
      <section className="w-9/12 m-auto">
        <Tabs type="card">
          <TabPane tab="Access To" key="access_to">
            {isLoadingSingle ? (
              <Card loading></Card>
            ) : (
              <>
                <div className="flex justify-end my-5">
                  <Button
                    loading={loadingCat_one_update}
                    onClick={handleUpdateCanSee}
                    type="primary"
                  >
                    Update
                  </Button>
                </div>
                <Divider />
                <section className="my-5">
                  <div className="flex justify-between mt-2">
                    <span className="text-md font-bold text-black">
                      Give access to all features
                    </span>
                    <Switch
                      onChange={handleToggleCatOneSuperUser}
                      checked={state?.cat_one_prev.super === true}
                      size="small"
                    />
                  </div>
                </section>
                <Divider />
                {access_features.map((feature) => (
                  <section className="my-5" key={feature.slug}>
                    <div className="flex justify-between mt-2">
                      <span className="text-md font-bold text-black">
                        {feature.name}
                      </span>
                      <Switch
                        onChange={(e) =>
                          dispatch({
                            type: "cat_one_prev",
                            field: feature.slug,
                            value: e,
                          })
                        }
                        checked={state?.cat_one_prev[feature?.slug] === true}
                        size="small"
                      />
                    </div>
                    <Divider />
                  </section>
                ))}
              </>
            )}
          </TabPane>
          <TabPane tab="Allowed To" key="allowed_to">
            {isLoadingSingle ? (
              <Card loading></Card>
            ) : (
              <>
                <div className="flex justify-end my-5">
                  <Button
                    loading={loadingCat_two_update}
                    onClick={handleUpdateCanDo}
                    type="primary"
                  >
                    Update
                  </Button>
                </div>
                <Divider />
                <section className="my-5">
                  <div className="flex justify-between mt-2">
                    <span className="text-md font-bold text-black">
                      Give access to all features
                    </span>
                    <Switch
                      onChange={handleToggleCatTwoSuperUser}
                      checked={state?.cat_two_prev.super === true}
                      size="small"
                    />
                  </div>
                </section>
                <Divider />
                {allowed_features.map((feature) => (
                  <div className="my-5" key={feature.slug}>
                    <div
                      className="flex justify-between space-x-4"
                      key={feature.slug}
                    >
                      <span className="text-md font-bold text-black">
                        {feature.name}
                      </span>
                      <Switch
                        onChange={(e) =>
                          dispatch({
                            type: "cat_two_prev",
                            field: feature.slug,
                            value: e,
                          })
                        }
                        checked={state?.cat_two_prev[feature?.slug] === true}
                        size="small"
                      />
                    </div>
                    <Divider />
                  </div>
                ))}
              </>
            )}
          </TabPane>
        </Tabs>
      </section>
    </Drawer>
  );
}

export default UpdatePrivileges;
