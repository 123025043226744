import moment from "moment";
import {
  ADDALLBORROWER,
  ALLBORROWERSEARCHVALUE,
  SETBORROWERENDDATE,
  SETBORROWERSTARTDATE,
  SETBORROWERSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  borrowers: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const borrowersTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLBORROWER) {
    return { ...state, borrowers: actions.value };
  }

  if (actions.type === ALLBORROWERSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETBORROWERSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETBORROWERENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETBORROWERSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
