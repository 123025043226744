import React from "react";
import { useParams } from "react-router";
import UpdateProduct from "../../components/LoanProducts/UpdateProduct";
import BackBtn from "../../utils/BackButton";

function UpdateProductPage(props) {
  const { slug } = useParams();
  return (
    <div>
      <div className="mb-2">
        <BackBtn />
      </div>
      <UpdateProduct slug={slug} />
    </div>
  );
}

export default UpdateProductPage;
