import React from "react";
import { Button, message, Table } from "antd";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";
import { CSVLink } from "react-csv";

function ListingTable({ isLoading }) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { loanListing } = useSelector((state) => state.loanListingTableReducer);
  const { currencyFormatter } = useCurrenncyFormatter();

  const columns = [
    {
      title: "Borrower Name",
      dataIndex: "borrower_name",
      key: "borrower_name",
    },
    {
      title: "ID",
      dataIndex: "borrower_id",
      key: "borrower_id",
    },
    {
      title: "Tenure",
      dataIndex: "tenure",
      key: "tenure",
    },
    {
      title: "Repayment Frequency",
      dataIndex: "repayment_frequency",
      key: "repayment_frequency",
    },
    {
      title: "Cycle",
      dataIndex: "cycle",
      key: "cycle",
    },
    {
      title: "Disbursed date",
      dataIndex: "date_disbursed",
      key: "date_disbursed",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.date_disbursed).valueOf() >
        new Date(b.date_disbursed).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.date_disbursed, profile.date_format)}`}</div>
      ),
    },
    {
      title: "Loan Maturity Date",
      dataIndex: "loan_maturity_date",
      key: "loan_maturity_date",
      defaultSortOder: "descend",
      sorter: (a, b) =>
        new Date(a.loan_maturity_date).valueOf() -
        new Date(b.loan_maturity_date).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(
          record.loan_maturity_date,
          profile.date_format
        )}`}</div>
      ),
    },
    {
      title: "Principal Amount Disbursed",
      dataIndex: "principal_amount_disbursed",
      key: "principal_amount_disbursed",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.principal_amount_disbursed)}</span>
        </div>
      ),
    },
    {
      title: "Interest",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Principal Amount Due per Instalment",
      dataIndex: "principal_amount_due_per_instalment",
      key: "principal_amount_due_per_instalment",
      render: (_, record) => (
        <div>
          <span>
            {currencyFormatter(record.principal_amount_due_per_instalment)}
          </span>
        </div>
      ),
    },
    {
      title: "Interest Amount Due per Instalment",
      dataIndex: "interest_amount_due_per_instalment",
      key: "interest_amount_due_per_instalment",
      render: (_, record) => (
        <div>
          <span>
            {currencyFormatter(record.interest_amount_due_per_instalment)}
          </span>
        </div>
      ),
    },
    {
      title: "Repayment Amount per Instalment",
      dataIndex: "repayment_amount_per_instalment",
      key: "repayment_amount_per_instalment",
      render: (_, record) => (
        <div>
          <span>
            {currencyFormatter(record.repayment_amount_per_instalment)}
          </span>
        </div>
      ),
    },
    {
      title: "Total Interest Amount",
      dataIndex: "total_interest_amount",
      key: "total_interest_amount",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.total_interest_amount)}</span>
        </div>
      ),
    },
    {
      title: "Total Repayment Amount",
      dataIndex: "total_repayment_amount",
      key: "total_repayment_amount",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.total_repayment_amount)}</span>
        </div>
      ),
    },
    {
      title: "Loan Balance Outstanding",
      dataIndex: "loan_balance_outstanding",
      key: "loan_balance_outstanding",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.loan_balance_outstanding)}</span>
        </div>
      ),
    },
    {
      title: "Amount Overdue (Arrears)",
      dataIndex: "amount_overdue",
      key: "amount_overdue",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.amount_overdue)}</span>
        </div>
      ),
    },
    {
      title: "Portfolio At Risk",
      dataIndex: "portfolio_at_risk",
      key: "portfolio_at_risk",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.portfolio_at_risk)}</span>
        </div>
      ),
    },
    {
      title: "Security Deposit / Cash Collateral",
      dataIndex: "security_deposit",
      key: "security_deposit",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.security_deposit)}</span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary">
          <CSVLink
            filename={"Loan_Listing.csv"}
            data={loanListing || ""}
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </div>
      <Table
        className="mt-5"
        loading={isLoading}
        columns={columns}
        dataSource={loanListing}
        pagination={{ pageSize: 10, position: ["bottomLeft"] }}
        size="middle"
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}

export default ListingTable;
