import { Card, Divider, Col, Row, Button } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import Requests from "../../utils/request";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import BackBtn from "../../utils/BackButton";

const SingleDisbursement = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const history = useHistory();
  const { currencyFormatter } = useCurrenncyFormatter();

  const { data: disbursement } = useQuery(
    "single_disbursement",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/disbursement?id=${id}&start=2021-05-01&end=2021-12-31`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: fulfilment } = useQuery(
    "single_fulfilment",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/fulfilement?id=${id}&start=2021-05-01&end=2021-12-31`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className="mb-20">
      <div className="flex justify-between mt-2">
        <BackBtn />
      </div>
      <div className="mt-5">
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} lg={12}>
            <Card loading={!disbursement} style={{ width: " 634px" }} >
              <div className="flex justify-between">
                <div>
                  <Title level={3} className="font-bold text-lg">
                    {session?.admin?.category === "merchant" ?
                      "Fulfilment Details" :
                      " Disbursement Details"  
                    }
                  </Title>
                  <Paragraph className="text-gray-500 mt-2 font-bold text-lg">
                    Review the transaction details
                  </Paragraph>
                </div>
                <div>
                 {session?.admin?.category === "merchant" ?
                   <Button type="link" disabled onClick={() => history.push(`/order/${id}`)}>
                    <span className="text-blue-500 text-lg font-bold">View Order</span>
                   </Button>:
                    <Button type="link" onClick={() => history.push(`/loan/${id}`)}>
                      <span className="text-blue-500 text-lg font-bold">View Loan</span>
                  </Button> 
                }
                </div>
              </div>
              <Divider />
              <div className="flex justify-between">
                <Title level={4} className="text-lg font-bold capitalize">Summary</Title>
                <Button
                  type="default"
                  className="capitalize font-bold bg-green-300"
                  style={{backgroundColor: '#f6ffed', color: 'green', fontWeight: "bold"}}
                >
                    {session?.admin?.category === "merchant" ? 
                      fulfilment?.data?.fulfilled === true ? "Fulfilled" : "Pending" :
                      disbursement?.data?.status
                  }
                </Button>
              </div>
              <Divider />
              <div>
                <div className="flex justify-between">
                  {session?.admin?.category === "merchant" ? 
                    <>
                     <Title level={4} className="text-gray-500">Transaction Reference</Title>
                     <h4 className="text-gray-500 font-bold uppercase">{fulfilment?.data?.ref}</h4>
                     </>:
                     <>
                     <Title level={4} className="text-gray-500">Transaction ID</Title>
                     <h4 className="text-gray-500 font-bold">{disbursement?.data?.id}
                     </h4>
                     </>
                  }
                </div>
              </div>
              <div>
                {session?.admin?.category === "merchant" ? 
                  <div className="flex justify-between">
                    <Title level={4} className="text-gray-500">Product Name</Title>
                    <h4 className="text-gray-500 font-bold">{fulfilment?.data?.product_name}</h4>
                </div> :
                <div className="flex justify-between">
                  <Title level={4} className="text-gray-500">Loan Reference</Title>
                  <h4 className="text-gray-500 font-bold">{disbursement?.data?.id}</h4>
                </div>
                }
              </div>
              <Divider />
              <div className="flex justify-between mt-5 mb-">
                <Title level={4} className="font-bold">Amount</Title>
                <h4 className="font-bold text-blue-700 text-lg">
                  {currencyFormatter(session?.admin?.category === "merchant" ? fulfilment?.data?.terms.approved_amount : disbursement?.data?.amount)}
                </h4>
              </div>
              <div className="mt-5 mb-3">
                <Title level={4}>Note</Title>
                <Paragraph className="font-bold text-lg">
                  {session?.admin?.category === "merchant" ? fulfilment?.data?.terms?.note : disbursement?.data?.manual_disbursement_note}
                </Paragraph>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    </>
  )
}

export default SingleDisbursement;