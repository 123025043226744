import { Row, Col, Divider, Empty } from "antd";
import React from "react";

const PersonalDetails = ({profile_data}) => {
    return(
        <>
            <div className="mt-5">
                <Row gutter={[16,16]}>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">FirstName: </h3>
                            <h3 className="capitalize font-bold text-base">{profile_data?.firstname}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                        <h3 className="text-gray-500">LastName: </h3>
                        <h3 className="capitalize font-bold text-base">{profile_data?.lastname}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">Email: </h3>
                            <h3 className="font-bold text-base">{profile_data?.email}</h3>
                       </div>
                       <Divider />
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">Phone: </h3>
                            <h3 className="font-bold text-base">{profile_data?.phone}</h3>
                       </div>
                       <Divider />
                    </Col>
                    {profile_data === null && (
                        <Col span={24}>
                            <Empty />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    )
}

export default PersonalDetails;