import {
  ADDNEWFIELDID,
  REMOVEFIELD,
  EDITFIELD,
  EDITFIELDNAME,
  EDITDROPDATA,
  REESTFIELDS,
} from "../Types/generalTypes";
import { v4 as uuidv4 } from "uuid";
import commaStringsToArray from "../../utils/commaStringsToArray";
const defualt = {
  data_type: "",
  label: "",
  field: "",
  count: 1,
  is_dropdown: false,
  is_required: false,
  is_money: false,
  is_upload: false,
  is_needed: true,
};
const initialState = {
  fields: { [uuidv4()]: defualt },
};

export const CustomFieldsReducer = (state = initialState, actions) => {
  if (actions.type === ADDNEWFIELDID) {
    return {
      fields: {
        ...state.fields,
        [uuidv4()]: defualt,
      },
    };
  }

  if (actions.type === EDITFIELD) {
    return {
      fields: {
        ...state.fields,
        [actions.ID]: {
          ...state.fields[actions.ID],
          [actions.key]: actions.value,
        },
      },
    };
  }

  if (actions.type === EDITFIELDNAME) {
    return {
      fields: {
        ...state.fields,
        [actions.ID]: {
          ...state.fields[actions.ID],
          field: actions.value.replaceAll(" ", "_").toLowerCase(),
          label: actions.value,
        },
      },
    };
  }

  if (actions.type === EDITDROPDATA) {
    return {
      fields: {
        ...state.fields,
        [actions.ID]: {
          ...state.fields[actions.ID],
          items: commaStringsToArray(actions.value),
        },
      },
    };
  }

  if (actions.type === REMOVEFIELD) {
    delete state.fields[actions.key];
    return { fields: state.fields };
  }

  if (actions.type === REESTFIELDS) {
    return { fields: { [uuidv4()]: defualt } };
  }

  return state;
};
