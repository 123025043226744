import React from "react";
import { Switch, Modal, Tabs, Checkbox, Divider } from "antd";
import { access_features, allowed_features } from "../../utils";

const { TabPane } = Tabs;

function Privileges({
  handleCancel,
  isModalVisible,
  dispatch,
  formName,
  state,
}) {
  return (
    <div>
      <Modal
        className="text-2xl font-semibold my-5 text-center capitalize"
        width="70%"
        maskStyle={{ background: "#28284D" }}
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={null}
      >
        <div className="flex space-x-4">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                dispatch({ type: "super", formName });
                return;
              }
              dispatch({ type: "remove_super_user", formName });
            }}
          />
          <p>click to give this user access to all features</p>
        </div>
        <Tabs size="small">
          <TabPane tab="Access to" key="1">
            {access_features.map((feature) => (
              <>
                <div className="flex justify-between mt-2" key={feature.slug}>
                  <span className="text-md font-bold text-black">
                    {feature.name}
                  </span>
                  <Switch
                    onChange={(e) =>
                      dispatch({
                        type: "cat_one_prev",
                        formName,
                        field: feature.slug,
                        value: e,
                      })
                    }
                    size="small"
                    checked={
                      state[formName]["cat_one_prev"][feature.slug] === true
                    }
                  />
                </div>
                <Divider />
              </>
            ))}
          </TabPane>
          <TabPane tab="Allowed to" key="2">
            {allowed_features.map((feature) => (
              <>
                <div
                  className="flex justify-between space-x-4"
                  key={feature.slug}
                >
                  <span className="text-md font-bold text-black">
                    {feature.name}
                  </span>
                  <Switch
                    onChange={(e) =>
                      dispatch({
                        type: "cat_two_prev",
                        formName,
                        field: feature.slug,
                        value: e,
                      })
                    }
                    size="small"
                    checked={
                      state[formName]["cat_two_prev"][feature.slug] === true
                    }
                  />
                </div>
                <Divider />
              </>
            ))}
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

export default Privileges;
