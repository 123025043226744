import { Button, Card, message } from "antd";
import { Title } from "../../../Typography";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLoanApplication from "../../../../hooks/useLoanApplication";

function SubmitCard({ id }) {
  const [loaing, setLoading] = useState(false);
  const history = useHistory();
  const { submitLoanApp } = useLoanApplication();
  const handleFinalSubmission = (data) => {
    if (data.status === "success") {
      message.success(data.message, 2);
      setLoading(false);
      history.goBack();
      return;
    }

    setLoading(false);
    message.error(`${data.message}-${data?.data.join()}`, 5);
  };

  const handleSubmit = () => {
    setLoading(true);
    submitLoanApp(id, handleFinalSubmission);
  };
  return (
    <section className="my-20 lg:w-4/12 m-auto lg:my-32">
      <Card>
        <Title level={4} className="text-center mb-5">
          Submit this application
        </Title>
        <div className="flex justify-center items-center">
          <Button
            loading={loaing}
            onClick={handleSubmit}
            type="primary"
            size="large"
            className="lg:w-4/12 w-full"
          >
            Submit
          </Button>
        </div>
      </Card>
    </section>
  );
}

export default SubmitCard;
