import React, { useEffect, useState } from "react";
import { message, Drawer, Button, Form, Col, Row, InputNumber, Input, Select, DatePicker } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";
import { currencyParser, useCurrenncyFormatter } from "../../../../../utils/currencyFormatter";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

const { Option } = Select;

const AddBusinessInformation = ({ data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const businessInformationRef = React.createRef();
  const { currencyFormatter } = useCurrenncyFormatter();

  const mutationBusinessInformation = useMutation((business_information) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=business-information`,
      "POST",
      session.session.token,
      business_information
    )
  );

  useEffect(() => {
    businessInformationRef?.current?.setFieldsValue({
      name: data?.name,
      role: data?.role,
      industry: data?.industry,
      description: data?.description,
      registered: data?.registered,
      registration_number: data?.registration_number,
      established_on: data?.established_on,
      physical_location: data?.physical_location,
      number_of_locations: data?.number_of_locations,
      location_status: data?.location_status,
      average_monthly_income: data?.average_monthly_income,
      last_twelve_months_income: data?.last_twelve_months_income,
      primary_payments_collection_method: data?.primary_payments_collection_method,
      number_of_employees: data?.number_of_employees,
      monthly_salary_cost: data?.monthly_salary_cost,
      annual_rent_cost: data?.monthly_rent_cost,
      monthly_electricity_cost: data?.monthly_electricity_cost,
      monthly_ads_cost: data?.monthly_ads_cost,
      monthly_marketing_cost: data?.monthly_marketing_cost
    });
  }, [businessInformationRef, data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationBusinessInformation.mutate(values, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Business Information
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="70%"
      >
        <Title level={3}>Add Business Information</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={businessInformationRef}>
          <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
              <Form.Item
                label="Name"
                name="name"
              >
                <Input size="large" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Status"
                name="role"
              >
                <Select>
                  <Option value="owner, founder">Owner, Founder</Option>
                  <Option value="executive_officer">Executive Officer</Option>
                  <Option value="employee">Employee</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Industry"
                name="industry"
              >
                <Select>
                  <Option value="finance">Finance</Option>
                  <Option value="health_care">Health Care</Option>
                  <Option value="supply_chain">Supply Chain</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Description"
                name="description"
              >
                <TextArea size="large" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Registered"
                name="registered"
              >
                <Select>
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Registration Number"
                name="registration_number"
              >
                <Input size="large" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Established on"
                name="established_on"
              >
                <DatePicker
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue={moment()}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Physical Location"
                name="physical_location"
              >
                <Select>
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Number of Locations"
                name="number_of_locationd"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Location Status"
                name="location_status"
              >
                <Select>
                  <Option value="owned">Owned</Option>
                  <Option value="rented">Rented</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Average Monthly Income"
                name="average_monthly_income"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Primary payments Collection Method"
                name="primary_payments_collection_method"
              >
                <Select>
                  <Option value="cash">Cash</Option>
                  <Option value="pos">POS</Option>
                  <Option value="bank_transfer">Bank Transfer</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Annual Rent Cost"
                name="annual_rent_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Monthly Electricity Cost"
                name="monthly_electricity_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Number Of Employees"
                name="number_of_employees"
              >
                <InputNumber size="large" style={{ width: "100%" }}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Monthly Salary Cost"
                name="monthly_salary_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Last 12 Months Income"
                name="last_twelve_months_income"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Monthly Ads Cost"
                name="monthly_ads_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Monthly Marketing Cost"
                name="monthly_marketing_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddBusinessInformation;