import React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { KoboToNaira } from "../../../utils/currencyFormatter";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { Table, Card } from "antd";
import { useTable } from "../../../context/useTable";

function AllTransaction({ requestID, account_name, method }) {
  const { requestTableSorter, setRequestTableSorters } = useTable();
  const { session } = useAuth();

  const { data, isLoading } = useQuery(
    `all-transaction`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${requestID}/data?data=${method}
        `,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      type: res.type,
      amount: KoboToNaira(res.amount),

      okay: res.okay,
      narration: res.narration,
      date: moment(res.date).format("YYYY-MM-DD"),
    }));

  const columns = [
    {
      title: "Narration",
      width: 300,
      dataIndex: "narration",
      key: "1",
      fixed: "left",
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },

    {
      title: "Type",
      width: 150,
      dataIndex: "type",
      key: "type",
      filteredValue: requestTableSorter.type || null,
      filters: [
        {
          text: "Credit",
          value: "credit",
        },
        {
          text: "Debit",
          value: "debit",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },

    {
      title: "Amount",
      width: 150,
      dataIndex: "amount",
      key: "4",
      render: (text, record) => (
        <div className="flex items-center">
          <span>{text}</span>
        </div>
      ),
    },
  ];

  const handleChange = (
    pagination,
    filters,
    sorter,
    selectedRowKeys,
    selectedRows
  ) => {
    setRequestTableSorters((prevState) => ({ ...prevState, ...filters }));
  };

  return (
    <Card style={{ borderRadius: "12px" }}>
      <span
        className="px-2 text-lg"
        style={{ fontFamily: "Cera Pro Bold", color: "#2E2C34" }}
      >
        All Transactions
      </span>
      <div className="flex justify-end items-center mb-5">
        <CSVLink
          style={{
            backgroundColor: "#5152A6",
            color: "#fff",
            borderRadius: 5,
            padding: 6,
            fontSize: 12,
            fontFamily: "Cera Pro Bold",
          }}
          data={data?.data?.statement?.data || []}
          filename={`${account_name}.csv`}
        >
          Export to CSV
        </CSVLink>
      </div>

      <Table
        loading={isLoading}
        onChange={handleChange}
        columns={columns}
        rowKey="key"
        dataSource={cleanData(data?.data?.statement?.data)}
        size="small"
      />
    </Card>
  );
}

export default AllTransaction;
