import { Card, Tabs } from "antd";
import React from "react";
import ChooseRoleType from "../../components/AdminSetup/ChooseRoleType";
import Kyc from "../../components/AdminSetup/Kyc";
import Profile from "../../components/AdminSetup/Profile";
// import UserProfile from "../../components/Settings/UserProfile";
import { Title } from "../../components/Typography";
import { useAuth } from "../../context/AuthContext";

const { TabPane } = Tabs;

function Settings(props) {
  const { session } = useAuth();
  return (
    <div>
      <Title level={2}>Settings</Title>

      <Tabs size="default" type="card">
        <TabPane tab="Company Profile" key="profile">
          <div className="my-10">
            <Profile isModal={true} />
          </div>
        </TabPane>

        <TabPane tab="Company KYC" key="key">
          <div className="my-10">
            <Kyc isModal={true} />
          </div>
        </TabPane>

        {session.admin.category === "lender" && (
          <TabPane
            tab={
              session.admin.category === "lender"
                ? `Loan Approval Structure`
                : `Credit Order Approval Structure`
            }
            key="approval"
          >
            <div className="my-10">
              <Card>
                <ChooseRoleType isModal={true} />
              </Card>
            </div>
          </TabPane>
        )}

        {/* <TabPane tab="User Profile" key="user">
          <div className="my-10">
            <UserProfile />
          </div>
        </TabPane> */}
      </Tabs>
    </div>
  );
}

export default Settings;
