import { Button, Form, Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { useApp } from "../../../context/FetchContext";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { Title } from "../../Typography";

const FulfilmentButton = ({ data }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const { refetch } = useApp();

  const fulfillMutation = useMutation((fulfillBody) =>
    Requests.fetchWithAuthToken(
      `/lms/credit_order/${id}?action=fulfil`,
      "PUT",
      session.session.token,
      fulfillBody
    )
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values) => {
    const query = {
      note: values.note,
    };

    setProcessing(true);
    fulfillMutation.mutate(query, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={showModal}
        loading={isProcessing}
        disabled={data?.fulfilled}
      >
        {!data?.fulfilled ? "Fulfill" : "Fulfilled"}
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={false}
        width="30%"
      >
        <Title level={4}>You are about to fulfill this loan</Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                message: "Note is required!",
              },
            ]}
          >
            <TextArea className="w-full" type="text" width={100} />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              className="w-full"
              htmlType="submit"
              type="primary"
              loading={isProcessing}
            >
              Fulfill
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FulfilmentButton;
