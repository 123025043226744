import React from "react";
import { Button, Card, Divider } from "antd";
import { Text, Title } from "../../../components/Typography";
import { RiseOutlined } from "@ant-design/icons";
import { Money } from "../../Borrowers/icons";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import Requests from "../../../utils/request";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

function WalletBalance({ session }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const { metricsStartDate, metricsEndDate } = useSelector((state) => state.overviewMetricsReducer);

  const { data: main } = useQuery(
    `main-metrics-start=${metricsStartDate}&end=${metricsEndDate}`, 
    () =>
    Requests.fetchWithAuthToken(
      `/lms/metrics?start=${metricsStartDate}&end=${metricsEndDate}&section=main`,
      "GET",
      session.session.token
    )
  );

  const style = {
    background: "linear-gradient(180deg, #4244B3 0%, #09099F 100%)",
    padding: "15px",
  };
  return (
    <>
      <Card className="h-full" size="small" style={style}>
        <div className="flex-col justify-between">
          <div>
            <div className="flex justify-between">
              <Text style={{ color: "#fff" }} className="flex justify-between">
                <Money />
                Total Wallet Balance
              </Text>
              <Button
                type="ghost"
                className="border-white"
                style={{ color: "#fff" }}
              >
                Fund Wallet
              </Button>
            </div>
            <Title level={3} style={{ color: "#fff" }}>
              {currencyFormatter(main?.data?.disbursement_wallet)}
            </Title>
            <Divider dashed style={{ backgroundColor: "#fff" }} />
          </div>
          <div className="flex justify-between p-1">
            <div>
              <Text style={{ color: "#45FFD2" }}>{session?.admin?.category === "merchant" ? "Fulfiled" : "Disbursed"}</Text>
              <div className="flex space-x-2" style={{ color: "#fff" }}>
                <Title style={{ color: "#fff" }} level={4}>
                  {currencyFormatter(main?.data?.total_disbursed?.value)}
                </Title>
                <RiseOutlined />
                <Text style={{ color: "#fff" }}>{main?.data?.total_disbursed?.percentage}%</Text>
              </div>
            </div>
            <div>
              <Text style={{ color: "#FFEE58" }}>Repayments</Text>
              <div className="flex space-x-2" style={{ color: "#fff" }}>
                <Title style={{ color: "#fff" }} level={4}>
                  {currencyFormatter(main?.data?.total_repayment?.value)}
                </Title>
                <RiseOutlined />
                <Text style={{ color: "#fff" }}>{main?.data?.total_repayment?.percentage}%</Text>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default WalletBalance;
