import React, { useState } from "react";
import Requests from "../../utils/request";
import {
  Modal,
  Col,
  Input,
  Row,
  Form,
  Select,
  Button,
  message,
  Radio,
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { useMutation } from "react-query";
import { Title } from "../Typography";

const { Option } = Select;
const gurantorform = React.createRef();

function AddNewGuarantors({ id, isIDAvaliable, refetch }) {
  const { session } = useAuth();
  const [loading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const guarantorMutation = useMutation((data) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${data.loanID}/guarantor`,
      "POST",
      session.session.token,
      data.guarantor
    )
  );

  const handleSubmit = (value) => {
    const data = {
      loanID: id,
      guarantor: {
        guarantor: {
          ...value,
        },
        verify: value.verify,
      },
    };

    setIsLoading(true);
    guarantorMutation.mutate(data, {
      onSuccess: (data) => {
        setIsLoading(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
        setIsModalVisible(false);
      },
      onError: (err) => {},
    });
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        Get started
      </Button>
      <Modal
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <Title level={3}>New Guarantor</Title>
        <Form ref={gurantorform} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Select size="large">
                  <Option value="female">Female</Option>
                  <Option value="Male">Male</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Relationship"
                name="relationship"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item
                label="Do you want us to verify this guarantor?"
                name="verify"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Radio.Group
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            className="lg:w-auto w-full"
            loading={loading}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default AddNewGuarantors;
