export function Monitor(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0h18a2 2 0 012 2v12a2 2 0 01-2 2h-7v2h2v2H7v-2h2v-2H2a2 2 0 01-2-2V2a2 2 0 012-2zm0 14h18V2H2v12z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={-11}
          y1={10}
          x2={8.909}
          y2={31.901}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5152A6" />
          <stop offset={1} stopColor="#5152A6" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Home(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.match ? "#fff" : "#9595AC"}
      >
        <path d="M19 11.793A5.5 5.5 0 0112.207 5H6a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3v-6.207z" />
        <path opacity={0.3} d="M17.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={props.match ? "#fff" : "#9595AC"} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Overview(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Customer(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0a2 2 0 00-2 2v16a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2H2zm11 7a3 3 0 11-6 0 3 3 0 116 0zm-9 7.667C4 12.893 7.997 12 10 12c2.002 0 6 .893 6 2.667V16H4v-1.333z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Loans(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.319 5.688a1.617 1.617 0 00-2.287 0L5.758 15.945a1.629 1.629 0 102.303 2.303L18.32 7.975c.63-.632.63-1.656-.001-2.287z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93 9.857A2.927 2.927 0 009.857 6.93 2.93 2.93 0 106.93 9.858zM17.073 20A2.927 2.927 0 0020 17.071a2.925 2.925 0 00-2.927-2.928 2.927 2.927 0 100 5.857z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Sales(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.022l-3.526 1.707-1.605-3.575-3.798-.959 1.068-3.77-2.293-3.176 3.241-2.2.285-3.908 3.898.398L12 1.729l2.73 2.81 3.898-.398.285 3.908 3.241 2.2-2.293 3.177 1.069 3.77-3.799.958-1.604 3.575L12 20.022z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.09 8.603L8.36 14.7 9.71 16.05l5.728-6.099-1.348-1.347z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.854 9.175a1.718 1.718 0 11-3.435.002 1.718 1.718 0 013.436-.002zM14.864 16.622a1.719 1.719 0 110-3.437 1.719 1.719 0 010 3.437z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Subscription(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5A1.5 1.5 0 009 6.5v3a1.5 1.5 0 001.5 1.5h10A1.5 1.5 0 0022 9.5v-3A1.5 1.5 0 0020.5 5h-10zm0 8A1.5 1.5 0 009 14.5v3a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5h-10z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={2}
        y={5}
        width={5}
        height={14}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Clipboard(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2h-2v.5A1.5 1.5 0 0114.5 4h-5A1.5 1.5 0 018 2.5V2z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11a1 1 0 112 0z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={10}
        y={8}
        width={7}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={7}
        y={8}
        width={2}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={7}
        y={12}
        width={2}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={10}
        y={12}
        width={7}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={7}
        y={16}
        width={2}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <rect
        opacity={0.3}
        x={10}
        y={16}
        width={7}
        height={2}
        rx={1}
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Shield(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3l7.631-1.431a2 2 0 01.738 0L20 2.999v7.904a11 11 0 01-5.17 9.328l-2.3 1.438a1 1 0 01-1.06 0l-2.3-1.438A11 11 0 014 10.903V3z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.175 13.75a.946.946 0 01-.67-.287l-1.917-1.917a.926.926 0 010-1.342c.383-.383 1.006-.383 1.341 0l1.246 1.246 3.162-3.162a.926.926 0 011.342 0 .926.926 0 010 1.341l-3.833 3.834a.946.946 0 01-.671.287z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Branches(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6a1.5 1.5 0 100 3h5a1.5 1.5 0 000-3h-5zm9 8a1.5 1.5 0 000 3h5a1.5 1.5 0 000-3h-5z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7a3 3 0 11-6 0 3 3 0 016 0zM9 15a3 3 0 11-6 0 3 3 0 016 0z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function Products(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2a2 2 0 012-2h16a2 2 0 012 2v16a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm14.739 3.266a1.213 1.213 0 00-1.715 0l-7.706 7.693a1.221 1.221 0 101.728 1.728L14.74 6.98a1.213 1.213 0 00-.001-1.715zm-6.346.93A2.197 2.197 0 114 6.195a2.197 2.197 0 014.392.001zM16 13.804a2.197 2.197 0 11-4.392-.001 2.197 2.197 0 014.392 0z"
        fill={props.match ? "#fff" : "#9595AC"}
      />
    </svg>
  );
}

export function BadgeDropdown(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.798 8.97l1.798 1.799.8-.8-2.598-2.603-2.603 2.603.805.8 1.798-1.798zm0 7L11 14.172l-.8.8 2.598 2.604 2.604-2.604-.806-.8-1.798 1.798z"
        fill="#181819"
        fillOpacity={0.9}
      />
    </svg>
  );
}

export function Star(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.021 2.506c-6.915 0-12.515 5.612-12.515 12.528 0 6.915 5.6 12.528 12.515 12.528 6.928 0 12.54-5.613 12.54-12.528 0-6.916-5.612-12.528-12.54-12.528zm5.312 20.045l-5.3-3.195-5.299 3.195 1.403-6.026-4.673-4.047 6.164-.526 2.406-5.688 2.405 5.675 6.164.526-4.673 4.047 1.403 6.039z"
        fill="#84818A"
      />
    </svg>
  );
}

export function Money(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={12} fill="#FBFAFC" fillOpacity={0.1} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1H8zm3.5 6.5h1V14h.5c.275 0 .5-.225.5-.5V12c0-.275-.225-.5-.5-.5h-1.5V11h2v-1h-1v-.5h-1v.5H11c-.275 0-.5.225-.5.5V12c0 .275.225.5.5.5h1.5v.5h-2v1h1v.5z"
        fill="#fff"
      />
    </svg>
  );
}

export function Mask(props) {
  return (
    <svg
      width={14}
      height={10}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3 0c.42 0 .7.28.7.7 0 .42-.28.7-.7.7H.7c-.42 0-.7-.28-.7-.7C0 .28.28 0 .7 0h12.6zM.7 4.2h9.8c.42 0 .7-.28.7-.7 0-.42-.28-.7-.7-.7H.7c-.42 0-.7.28-.7.7 0 .42.28.7.7.7zM14 6.3c0-.42-.28-.7-.7-.7H.7c-.42 0-.7.28-.7.7 0 .42.28.7.7.7h12.6c.42 0 .7-.28.7-.7zm-3.5 2.1c.42 0 .7.28.7.7 0 .42-.28.7-.7.7H.7c-.42 0-.7-.28-.7-.7 0-.42.28-.7.7-.7h9.8z"
        fill="#9595AC"
      />
    </svg>
  );
}

export function SwithAccount(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity={0.1}
        x={0.5}
        width={24}
        height={24}
        rx={12}
        fill="#11C096"
      />
      <path
        d="M8.5 9h-1v7c0 .55.45 1 1 1h7v-1h-7V9zm8-2h-6c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1zm-3 1c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5zm3 6h-6v-.75c0-.995 2-1.5 3-1.5s3 .505 3 1.5V14z"
        fill="#11C096"
      />
    </svg>
  );
}

export function Receipt(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={12} fill="#5542F6" fillOpacity={0.1} />
      <path
        d="M15 14.5H9v-1h6v1zm0-2H9v-1h6v1zm0-2H9v-1h6v1zM7.5 17l.75-.75L9 17l.75-.75.75.75.75-.75.75.75.75-.75.75.75.75-.75.75.75.75-.75.75.75V7l-.75.75L15 7l-.75.75L13.5 7l-.75.75L12 7l-.75.75L10.5 7l-.75.75L9 7l-.75.75L7.5 7v10z"
        fill="#5542F6"
      />
    </svg>
  );
}

export function Undo(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        width={24}
        height={24}
        rx={12}
        fill="#FF4B4B"
        fillOpacity={0.1}
      />
      <path
        d="M12.75 10a5.232 5.232 0 00-3.45 1.3L7.5 9.5V14H12l-1.81-1.81c.695-.58 1.58-.94 2.56-.94a4.006 4.006 0 013.8 2.75l1.185-.39A5.257 5.257 0 0012.75 10z"
        fill="#FF4B4B"
      />
    </svg>
  );
}
