import React from 'react';
import { Col } from 'antd';
import { Text } from "../../Typography";
import PercentageChart from '../PercentageChart';

function RecoveryRate({data}) {
    const style = { background: '#ffffff', padding: '20px', borderRadius: '12px', height: '60vh' };

    return (
        <>
            <Col span={8}>
                <div style={style}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: '16px', fontWeight: 'bold', color: '#2E2C34' }}>Recovery Rate</Text>
                    </div>
                    <PercentageChart data={0}/>
                    <p style={{ textAlign: 'center', position: 'absolute', top: '65%', left:'20%' }}>Your activity rate is on 0%. <br /> It depends on your response time.</p>
                </div>
            </Col>
        </>
    )
}

export default RecoveryRate;
