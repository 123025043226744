import { PaperClipOutlined } from "@ant-design/icons";
import React from "react";
import styles from "../Task/notes.module.css";
import { Title, Text } from "../Typography";

function NoteCard({ message, time, author }) {
  return (
    <div className={`${styles.notes} my-5 p-3`}>
      <PaperClipOutlined
        style={{
          color: "#708090",
          fontSize: "20px",
          position: "absolute",
          top: "-10px",
          transform: "scaleX(-1)",
          left: "0",
        }}
      />

      <Title className="mt-2" level={5}>
        {message}
      </Title>

      <div className="flex justify-between">
        <Text>{time}</Text>
        <Text>{author}</Text>
      </div>
    </div>
  );
}

export default NoteCard;
