import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Row, Col, Form, Button, Input, message } from "antd";
import Logo from "../../assets/svg/logo.svg";
import Pattern from "../../assets/images/patterns.png";
import useLocationQuery from "../../utils/useQuery";

function ResetPassword(props) {
  const formReset = React.createRef();
  const { resetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let query = useLocationQuery();
  const email = query.get("email");

  const resetPasswordCallBack = (data) => {
    if (data.status !== "success") {
      message.error(data.message, 3);
      setLoading(false);
      return;
    }
    setLoading(false);
    message.success(data.message, 3);
    history.push(`/sgin-in`);
  };

  useEffect(() => {
    formReset?.current?.setFieldsValue({
      email,
    });
  });

  return (
    <div className="h-screen flex">
      <div className="flex-grow p-10 mx-32">
        <img src={Logo} alt="evolve credit logo" className="w-full h-20" />

        <div className="my-20">
          <h1 className="text-2xl font-semibold my-5">Reset Your password</h1>
          <Form
            layout="vertical"
            ref={formReset}
            onFinish={(value) => {
              setLoading(true);
              resetPassword(value, resetPasswordCallBack);
            }}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Reset token"
                  name="reset_token"
                  rules={[
                    {
                      required: true,
                      message: "Reset Token is required!",
                    },
                  ]}
                >
                  <Input placeholder="H7210" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  name="new_password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "password is required!",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                loading={loading}
                style={{ width: "100%" }}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Reset password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default ResetPassword;
