import React from "react";
import Profile from "../../components/AdminSetup/Profile";

function CompanyProfile() {
  return (
    <div>
      <Profile />
    </div>
  );
}

export default CompanyProfile;
