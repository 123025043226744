import React, { useEffect } from "react";
import moment from "moment";
import { Row, Col } from "antd";
import Card from "../../components/LoanRequests/Card";
import totalLoan from "../../assets/svg/TotalLoan.svg";
import overdue from "../../assets/svg/Overdue.svg";
import approved from "../../assets/svg/Approved.svg";
import pending from "../../assets/svg/pendingRequest.svg";
import { LoanHeader } from "../../components/LoanRequests/Header";
import { ProgressCards, RequestTable } from "../../components/LoanRequests";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import {
  ADDALLCREDITORDER,
  ADDALLLOANREQUEST,
} from "../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import CreditOrderTable from "../../components/LoanRequests/CreditOrderTable";

function LoanRequests(props) {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { currencyFormatter } = useCurrenncyFormatter();

  const { searchValue, endDate, startDate, status } = useSelector((state) =>
    session.admin.category === "merchant"
      ? state.creditOrdersTableReducer
      : state.loanRequestsTableReducer
  );

  const { metricsStartDate, metricsEndDate } = useSelector(
    (state) => state.requestMetricsReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: loan_requests, isLoading } = useQuery(
    `all_loan_requests-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: credit_orders, isLoading: isLoadingOrders } = useQuery(
    `all_credit_orders-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: loan_request_metrics } = useQuery(
    `loan_request_metrics-start=${metricsStartDate}&end=${metricsEndDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/metrics?start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: credit_order_metrics } = useQuery(
    `credit_order_metrics-start=${metricsStartDate}&end=${metricsEndDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order/metrics?start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((loan_request) => {
      const name = `${loan_request?.fullname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return loan_request;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.lender_status === status;
      }

      return true;
    });
  };

  const OrderSearchQuery = (data) => {
    const firstQuery = data?.filter((credit_order) => {
      const name = `${credit_order?.fullname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return credit_order;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.lender_status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (
      credit_orders?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLCREDITORDER,
        value: OrderSearchQuery(credit_orders?.data),
      });

      return;
    }

    if (
      loan_requests?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLLOANREQUEST,
        value: SearchQuery(loan_requests?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoadingOrders, isLoading]);

  useEffect(() => {
    if (
      credit_orders?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLCREDITORDER,
        value: OrderSearchQuery(credit_orders?.data),
      });
    }

    if (
      loan_requests?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLLOANREQUEST,
        value: SearchQuery(loan_requests?.data),
      });
    }

    // eslint-disable-next-line
  }, [searchValue, status]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <LoanHeader />
        </Col>
        <Card
          heading={
            session?.admin?.category === "merchant"
              ? "All Credit Orders"
              : "All Requests"
          }
          amount={currencyFormatter(
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_credit_orders?.value || 0
              : loan_request_metrics?.data?.total_requests?.value || 0
          )}
          people={
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_credit_orders?.count || 0
              : loan_request_metrics?.data?.total_requests?.count || 0
          }
          textColor="#3C4066"
          icon={totalLoan}
          name="allrequests"
        />
        <Card
          heading={
            session?.admin?.category === "merchant"
              ? "Declined Credit Orders"
              : "Declined Requests"
          }
          amount={currencyFormatter(
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_declined_credit_orders
                  ?.value || 0
              : loan_request_metrics?.data?.total_declined_loans?.value || 0
          )}
          people={
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_declined_credit_orders
                  ?.count || 0
              : loan_request_metrics?.data?.total_declined_loans?.count || 0
          }
          textColor="#FF4B4B"
          icon={overdue}
          name="declinedloans"
        />
        <Card
          heading={
            session?.admin?.category === "merchant"
              ? "Approved Credit Orders"
              : "Approved Requests"
          }
          amount={currencyFormatter(
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_approved_credit_orders
                  ?.value || 0
              : loan_request_metrics?.data?.total_approved_loans?.value || 0
          )}
          people={
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_approved_credit_orders
                  ?.count || 0
              : loan_request_metrics?.data?.total_approved_loans?.count || 0
          }
          textColor="#5152A6"
          icon={approved}
          name="approvedloans"
        />
        <Card
          heading={
            session?.admin?.category === "merchant"
              ? "Pending Credit Orders"
              : "Pending Requests"
          }
          amount={currencyFormatter(
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_pending_credit_orders
                  ?.value || 0
              : loan_request_metrics?.data?.total_pending_loans?.value || 0
          )}
          people={
            session?.admin?.category === "merchant"
              ? credit_order_metrics?.data?.total_pending_credit_orders
                  ?.count || 0
              : loan_request_metrics?.data?.total_pending_loans?.count || 0
          }
          textColor="#F99600"
          icon={pending}
          name="pendingrequests"
        />
      </Row>
      <ProgressCards />
      <div className="mt-10">
        {session?.admin?.category === "merchant" ? (
          <CreditOrderTable isLoading={isLoadingOrders} />
        ) : (
          <RequestTable isLoading={isLoading} />
        )}
      </div>
    </>
  );
}

export default LoanRequests;
