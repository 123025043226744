import React, { useState, useEffect } from "react";
import { Form, Select, Row, Col, message, Button, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { useAuth } from "../../../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import Request from "../../../utils/request";
import { useParams } from "react-router-dom";

const {Option} = Select;


function ApprovalRules() {
    const { session } = useAuth();
    const [isProcessing, setProcessing] = useState(false);
    const [ approvalOrder, setApprovalOrder ] = useState("");
    const approvalConfigRef = React.createRef();

    const {id : product_id} = useParams();

      const { data: product_config } = useQuery(
        "product_config",
        () =>
          Request.fetchWithAuthToken(
            `/lms/product/${product_id}/config`,
            "GET",
            session.session.token
          ),
      );

    const UpdateConfigApproval = useMutation((approvalConfigBody) =>
      Request.fetchWithAuthToken(
        `/lms/product/${product_id}/config?reference=approval`,
        "PUT",
        session?.session?.token,
        approvalConfigBody
      )
    );

    const handleChangeApprovalOrder = (order) => {
        setApprovalOrder(order)
        return;
      }

      const handleUpdateApproval = (values) => {
        setProcessing(true);
        UpdateConfigApproval.mutate(values, {
          onSuccess: (data) => onUpdateConfig(data),
          onError: () => {},
        });
      };
    
      const onUpdateConfig = (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 3);
          return;
        }
    
        setProcessing(false);
        message.success(data.message, 3);
      };


      useEffect(() => {
        approvalConfigRef?.current?.setFieldsValue({
            use_approval_layers: product_config?.data?.use_approval_layers,
            approval_layers: product_config?.data?.approval_layers,
            first_layer_approval: product_config?.data?.first_layer_approval,
            second_layer_approval: product_config?.data?.second_layer_approval,
            third_layer_approval: product_config?.data?.third_layer_approval,
            layered_approval_order: product_config?.data?.layered_approval_order,
        });
        // eslint-disable-next-line
      }, [product_config]);

    return (
        <>
            <Form layout="vertical" ref={approvalConfigRef} onFinish={handleUpdateApproval}>
              <Row gutter={16}> 
                <Col span={10}>
                    <Form.Item
                        label="Approval Layers"
                        name="approval_layers"
                        style={{ marginBottom: "10px" }}
                    >
                    <Select>
                        <Option value={2} key="2">2</Option>
                        <Option value={3} key="3">3</Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        label="Approval Order"
                        name="layered_approval_order"
                        style={{ marginBottom: "10px" }}
                    >
                    <Select defaultValue="flexible" onChange={handleChangeApprovalOrder}>
                        <Option value="strict" key="strict">Strict</Option>
                        <Option value="flexible" key="flexible">Flexible</Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col span={2}>
                <div className="mt-10">
                    <Tooltip title={approvalOrder === "strict" ? "Approval needs to follow a specific order" : "Anyone can approve first or last"} >
                       <QuestionCircleFilled className="animate-bounce" />
                      </Tooltip>
                  </div>
                </Col>
              </Row>
              <Row className="space-x-4 mt-5">
                <Form.Item>
                <Button 
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isProcessing}>Save</Button>
                </Form.Item>
             </Row>
            </Form>
        </>
    )
}

export default ApprovalRules;