import {
  ADDALLCUSTOMFIELDS,
  ADDNEWFIELD,
} from "../Types/generalTypes";

const initialState = {
  fields: [ 
    { field_name: "", field_type: "", dropdown_item: [] }
  ],
}

export const collateralCustomFieldsReducer = (state = initialState, actions) => {
  if (actions.type === ADDNEWFIELD) {
    return {
        ...state,
        fields: [
          ...state.fields,
          {
            field_name: actions.value.field_name,
            field_type: actions.value.field_type,
            dropdown_item: actions.value.dropdown,
          }
        ]
    };
  }

  if(actions.type === ADDALLCUSTOMFIELDS) {
    return { ...state, fields: actions.value };
  }
  return state;
};
