import React from "react";
import { Row, Col, Empty, Divider, Form } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import AddEmploymentInformation from "./UpdateDetails/AddEmploymentInformation";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import { useEffect } from "react";

const EmploymentDetails = ({ template }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const { generateInputType } = UseGenerateComponentNew();
  const employmentRef = React.createRef();

  const { handleCustomField } = UseDisplayCustom();

  const {
    data: employment_information,
    refetch: refetchEmploymentInformation,
  } = useQuery(
    `employment-information-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=employment-information`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    employmentRef?.current?.setFieldsValue({
      company_address: employment_information?.data?.company_address,
      company_name: employment_information?.data?.company_name,
      manager: employment_information?.data?.manager,
      manager_email: employment_information?.data?.manager_email,
      manager_phone: employment_information?.data?.manager_phone,
      position: employment_information?.data?.position,
      status: employment_information?.data?.status,
      salary: employment_information?.data?.salary,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employment_information]);

  return (
    <div className="mt-5">
      <Form layout="vertical" ref={employmentRef}>
        <Row gutter={[16, 16]}>
          {template?.map((data) =>
            data?.slug === "employment-information"
              ? data?.fields?.map((field) =>
                  field?.is_required || field?.is_needed ? (
                    <Col span={8}>
                      <Form.Item name={field?.field} label={field?.label}>
                        {generateInputType(field)}
                      </Form.Item>
                      <Divider />
                    </Col>
                  ) : null
                )
              : null
          )}
          {employment_information?.data?.custom_fields &&
            employment_information?.data?.custom_fields?.map((fields) => (
              <Col span={8}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">{fields?.label}: </h3>
                  <h3 className="font-bold text-base">
                    {handleCustomField(fields)}
                  </h3>
                </div>
                <Divider />
              </Col>
            ))}
        </Row>
      </Form>
      {employment_information?.data === null && (
        <Col span={24}>
          <Empty />
        </Col>
      )}
      <div className="flex justify-end">
        <AddEmploymentInformation
          refetchEmploymentInformation={refetchEmploymentInformation}
          data={employment_information?.data}
        />
      </div>
    </div>
  );
};

export default EmploymentDetails;
