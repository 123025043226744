import { Switch, message } from "antd";
import React, { useState }  from "react";
import { useMutation } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";

const SwitchButton = ({notification}) => {
  const { session } = useAuth();
  const [isProcessing, setProcessing] = useState();

  const updateMutation = useMutation((updateBody) =>
    Requests.fetchWithAuthToken(
      `/lms/notification?id=${notification?.id}`,
      "PUT",
      session.session.token,
      updateBody
    )
  );

  const handleToggle = (value) => {
    const mark = {
      seen: value ? false : true,
    };

    setProcessing(true);
    updateMutation.mutate(mark, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
  };
  return (
    <Switch
      onChange={handleToggle}
      defaultChecked={notification?.seen ? false : true}
      loading={isProcessing}
    />
  )
}

export default SwitchButton;