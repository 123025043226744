import { Card } from "antd";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../components/Collateral/TableHeader";
import AllCollateralsTable from "../../components/Table/Collateral/AllCollaterals";
import { Title } from "../../components/Typography";
import { useAuth } from "../../context/AuthContext";
import { ADDALLCOLLATERALS } from "../../redux/Types/tableTypes";
import Requests from "../../utils/request";

const Collateral = () => {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { status, searchValue } = useSelector((state) => state.allCollateralsTableReducer );

  const { data: all_collaterals, isLoading } = useQuery(
    "all_collaterals",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/collateral`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((collateral) => {
      const name = `${collateral?.name}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return collateral;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (all_collaterals?.status === "success") {
      dispatch({
        type: ADDALLCOLLATERALS,
        value: SearchQuery(all_collaterals?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading, all_collaterals]);

  useEffect(() => {
    dispatch({
      type: ADDALLCOLLATERALS,
      value: SearchQuery(all_collaterals?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <Title level={2}>Collaterals</Title>
      <Card>
        <TableHeader />
        <AllCollateralsTable isLoading={isLoading} />
      </Card>
    </>
  )
}

export default Collateral;