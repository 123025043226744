import { Row, Col, Card, Empty } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { Text } from "../../../Typography";
import AddBankDetails from "./UpdateDetails/AddBankDetails";

const BankDetails = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: bank_details, refetch: refetchBankDetails } = useQuery(
    "bank_details",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=bank-account`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: order_bank_details } = useQuery(
    "order_bank_details",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order/${id}/data?data=bank-account`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const bank_dets =
    session?.admin?.category === "merchant" ? order_bank_details : bank_details;

  return (
    <>
      <div className="mt-5">
        <Row gutter={[16, 16]}>
          {bank_dets?.data?.map((details) => (
            <>
              <Col span={12}>
                <Card className="w-full space-x-4">
                  <div className=" flex space-x-2">
                    <Text className="text-gray-500">Account Name: </Text>
                    <h3 className="font-bold text-base">
                      {details?.account_name}
                    </h3>
                  </div>
                  <div className="flex space-x-2">
                    <Text className="text-gray-500">Account Number: </Text>
                    <h3 className="font-bold text-base">
                      {details?.account_number}
                    </h3>
                  </div>
                  <div className="flex space-x-2">
                    <Text className="text-gray-500">Bank Name: </Text>
                    <h3 className="font-bold text-base" level={5}>
                      {details?.bank_name}
                    </h3>
                  </div>
                </Card>
              </Col>
            </>
          ))}
        </Row>
          {!bank_dets?.data && (
            <Col span={24}>
              <Empty />
            </Col>
          )}
          <div className="flex justify-end">
            <AddBankDetails refetchBankDetails={refetchBankDetails} data={bank_dets?.data?.map((details) => details)} />
          </div>
      </div>
    </>
  );
};

export default BankDetails;
