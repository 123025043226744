import { Row, Col, Progress } from "antd";
import React from "react";

function ProgressStep({ step }) {
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>Progress</h2>
              <p
                style={{
                  background: "#FFE2C2",
                  borderRadius: "13px",
                  color: "#C97F31",
                  padding: "2px 15px",
                }}
              >
                In Progress
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div>
                <Progress
                  type="circle"
                  percent={0}
                  strokeColor="#219653"
                  width={80}
                />
              </div>
              <div>
                <h3>Current Step:</h3>
                <p>{step}</p>
              </div>
            </div>
            <p>
              <span style={{ color: "#219653" }}>0/5</span> Questions Answered
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <h2>Tips</h2>
            <ul style={{ listStyle: "disc", paddingLeft: "15px" }}>
              <li style={{ marginBottom: "20px" }}>
                Fill out the required information correctly.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Your product name should be instantly recognisable or related to the purpose of the loan.
              </li>
              <li style={{ marginBottom: "20px" }}>
                Ensure your website has adequate information about your loan products.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ProgressStep;
