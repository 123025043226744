import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Row,
  Col,
  message,
  Tooltip,
  InputNumber,
  Checkbox,
  Divider,
} from "antd";
import {
  currencyParser,
  useCurrenncyFormatter,
} from "../../../utils/currencyFormatter";

import { useAuth } from "../../../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import Request from "../../../utils/request";
import { useParams } from "react-router-dom";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Title } from "../../Typography";
import AddLoanPuropses from "./AddLoanPuropses";

const { Option } = Select;

function ProductConfiguration() {
  const { currencyFormatter } = useCurrenncyFormatter();
  const { session } = useAuth();
  const [useLateFee, setUseLateFee] = useState(false);
  const [useProcessingFee, setUseProcessingFee] = useState(false);
  const [useFixedAmount, setUseFixedAmount] = useState(false);
  const [usePercentage, setUsePercentage] = useState(false);
  const [useLateFeeFixedAmount, setUseLateFeeFixedAmount] = useState(false);
  const [useLateFeePercentage, setUseLateFeePercentage] = useState(false);
  const [disbursementMethod, setDisbursementMethod] = useState("");
  const configRef = React.createRef();

  const { id: product_id } = useParams();

  const { data: disbursement_methods } = useQuery(
    "disbursement-method",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/config/disbursement_methods",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: processing_fee_types } = useQuery(
    "processing_fees_type",
    () =>
      Request.fetchWithAuthToken(
        "/lms/product/setup/config/processing_fee_types",
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: purpose, refetch: refetchData } = useQuery(
    `purpose-${product_id}`,
    () =>
      Request.fetchWithAuthToken(
        `/lms/product/loan_purpose?id-type=product-id&id=${product_id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: product_config, refetch } = useQuery(
    "product_config",
    () =>
      Request.fetchWithAuthToken(
        `/lms/product/${product_id}/config`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const UpdateConfigGeneral = useMutation((generalConfigBody) =>
    Request.fetchWithAuthToken(
      `/lms/product/${product_id}/config`,
      "PUT",
      session?.session?.token,
      generalConfigBody
    )
  );

  const UpdateConfigBoolean = useMutation((generalConfigBody) =>
    Request.fetchWithAuthToken(
      `/lms/product/${product_id}/config?reference=bool-fields&field=${
        Object.keys(generalConfigBody)[0]
      }`,
      "PUT",
      session?.session?.token,
      generalConfigBody
    )
  );

  const checkConditions = (values) => {
    values?.use_late_fees === true ? setUseLateFee(true) : setUseLateFee(false);
    values?.has_processing_fee === true
      ? setUseProcessingFee(true)
      : setUseProcessingFee(false);
    if (values?.disbursement_method) {
      setDisbursementMethod(values?.disbursement_method);
    }
    values?.late_fees_type === "fixed-amount"
      ? setUseLateFeeFixedAmount(true)
      : setUseLateFeeFixedAmount(false);

    values?.late_fees_type === "percentage"
      ? setUseLateFeePercentage(true)
      : setUseLateFeePercentage(false);

    values?.processing_fee_type === "fixed-amount"
      ? setUseFixedAmount(true)
      : setUseFixedAmount(false);

    values?.processing_fee_type === "percentage"
      ? setUsePercentage(true)
      : setUsePercentage(false);
  };

  const handleUpdateGeneral = (values) => {
    checkConditions(values);
    UpdateConfigGeneral.mutate(values, {
      onSuccess: (data) => onUpdateConfig(data),
      onError: () => {},
    });
  };

  const handleUpdateBoolean = (values) => {
    checkConditions(values);
    UpdateConfigBoolean.mutate(values, {
      onSuccess: (data) => onUpdateConfig(data),
      onError: () => {},
    });
  };

  const onUpdateConfig = (data) => {
    if (data.status !== "success") {
      message.error(data.message, 3);
      return;
    }
    refetch();
    message.success(data.message, 3);
  };

  useEffect(() => {
    configRef?.current?.setFieldsValue({
      disbursement_method: product_config?.data?.disbursement_method,
      minimum_tenure: product_config?.data?.minimum_tenure,
      maximum_tenure: product_config?.data?.maximum_tenure,
      minimum_amount: product_config?.data?.minimum_amount,
      maximum_amount: product_config?.data?.maximum_amount,
      has_processing_fee: product_config?.data?.has_processing_fee,
      processing_fee_type: product_config?.data?.processing_fee_type,
      processing_fee: product_config?.data?.processing_fee,
      processing_fee_percentage:
        product_config?.data?.processing_fee_percentage,
      late_fee: product_config?.data?.late_fee,
      late_fee_percentage: product_config?.data?.late_fee_percentage,
      late_fees_type: product_config?.data?.late_fees_type,
      use_late_fees: product_config?.data?.use_late_fees,
    });
    // eslint-disable-next-line
  }, [product_config]);

  return (
    <>
      <Form layout="vertical" ref={configRef}>
        <Row gutter={16}>
          <Col span={10} className="flex">
            <Form.Item
              label="Loan to be disbursed by"
              name="disbursement_method"
              style={{ marginBottom: "10px" }}
            >
              <Select
                defaultValue="Automated Transfer"
                onChange={(val) =>
                  handleUpdateGeneral({ disbursement_method: val })
                }
              >
                {disbursement_methods?.data?.map((methods) => (
                  <Option value={methods?.slug} key={methods?.slug}>
                    {methods?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="mt-10">
              <Tooltip
                title={disbursement_methods?.data?.map((method) =>
                  disbursementMethod === method?.slug ? method?.description : ""
                )}
              >
                <QuestionCircleFilled className="animate-bounce" />
              </Tooltip>
            </div>
          </Col>
          <Col span={10} className="flex">
            <Form.Item
              placeholder="0"
              label="Minimum Tenure"
              name="minimum_tenure"
              style={{ marginBottom: "10px" }}
            >
              <InputNumber
                placeholder="Months"
                style={{ width: "100%" }}
                onChange={(val) => handleUpdateGeneral({ minimum_tenure: val })}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="mt-10">
              <Tooltip
                title="Would you like to set a minimum tenure on this product? 
                                When you publish your product, we make sure no one submits
                                  applications that don't meet the requirements. If you 
                                  don't want this restriction, set the value to 0"
              >
                <QuestionCircleFilled className="animate-bounce" />
              </Tooltip>
            </div>
          </Col>
          <Col span={10} className="flex">
            <Form.Item
              label="Maximum Tenure"
              name="maximum_tenure"
              style={{ marginBottom: "10px" }}
              placeholder="0"
            >
              <InputNumber
                placeholder="Months"
                style={{ width: "100%" }}
                onChange={(val) => handleUpdateGeneral({ maximum_tenure: val })}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="mt-10">
              <Tooltip
                title="Would you like to set a maximum tenure on this product?
                     When you publish your product, we make sure no one submits applications 
                     that don't meet the requirements. If you don't want this restriction, set the value to 0"
              >
                <QuestionCircleFilled className="animate-bounce" />
              </Tooltip>
            </div>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Maximum Principal Amount"
              name="maximum_amount"
              style={{ marginBottom: "10px" }}
              placeholder="0"
            >
              <InputNumber
                formatter={currencyFormatter}
                parser={currencyParser}
                style={{ width: "100%" }}
                onChange={(val) => handleUpdateGeneral({ maximum_amount: val })}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <div className="mt-10">
              <Tooltip
                title="Would you like to set a maximum amount on this product?
                     When you publish your product, we make sure no one submits applications 
                     that don't meet the requirements. If you don't want this restriction, set the value to 0"
              >
                <QuestionCircleFilled className="animate-bounce" />
              </Tooltip>
            </div>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Minimum Principal Amount"
              name="minimum_amount"
              style={{ marginBottom: "10px" }}
              placeholder="0"
            >
              <InputNumber
                formatter={currencyFormatter}
                parser={currencyParser}
                placeholder="NGN"
                style={{ width: "100%" }}
                onChange={(val) => handleUpdateGeneral({ minimum_amount: val })}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="mt-10">
              <Tooltip
                title="Would you like to set a minimum principal amount on this product?
                     We make sure these guidelines are strictly followed while you are evaluating"
              >
                <QuestionCircleFilled className="animate-bounce" />
              </Tooltip>
            </div>
          </Col>
          <Col span={10} className="flex">
            <Form.Item label="Loan Purposes" style={{ marginBottom: "10px" }}>
              <Select
                mode="multiple"
                placeholder="Please select"
                disabled
                value={purpose?.data?.purposes}
                onChange={(val) => handleUpdateGeneral({ loan_purposes: val })}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <div className="mt-10">
              <Tooltip title="Add all loan purpose">
                <AddLoanPuropses
                  product_id={product_id}
                  dataPurpose={purpose?.data?.purposes}
                  refetch={refetchData}
                />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Divider />
        <Title level={5}>Additional settings</Title>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item name="use_late_fees" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateBoolean({ [val.target.id]: val.target.checked })
                }
              >
                Use Late Fees
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>

            <Row>
              {useLateFee || product_config?.data?.use_late_fees ? (
                <Col span={24}>
                  <Form.Item
                    label="Late Fees Type"
                    name="late_fees_type"
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      onChange={(val) =>
                        handleUpdateGeneral({ late_fees_type: val })
                      }
                    >
                      {processing_fee_types?.data?.map((types) => (
                        <Option value={types?.slug} key={types?.slug}>
                          {types?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}

              {useLateFeeFixedAmount ||
              product_config?.data?.late_fees_type === "fixed-amount" ? (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="Late Fee Amount"
                      name="late_fee"
                      style={{ marginBottom: "10px" }}
                    >
                      <InputNumber
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        placeholder="NGN"
                        style={{ width: "100%" }}
                        onChange={(val) =>
                          handleUpdateGeneral({ late_fee: val })
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={4}>
                    <div className="mt-10 ml-2">
                      <Tooltip
                        title={processing_fee_types?.data?.map((type) =>
                          proccessingFeesType === type?.slug
                            ? type?.description
                            : ""
                        )}
                      >
                        <QuestionCircleFilled className="animate-bounce" />
                      </Tooltip>
                    </div>
                  </Col> */}
                </>
              ) : null}
              {useLateFeePercentage ||
              product_config?.data?.late_fees_type === "percentage" ? (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="Late Fee Percentage"
                      name="late_fee_percentage"
                      style={{ marginBottom: "10px" }}
                    >
                      <InputNumber
                        placeholder="%"
                        onChange={(val) =>
                          handleUpdateGeneral({ late_fee_percentage: val })
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={4}>
                    <div className="mt-10 ml-2">
                      <Tooltip
                        title={processing_fee_types?.data?.map((type) =>
                          proccessingFeesType === type?.slug
                            ? type?.description
                            : ""
                        )}
                      >
                        <QuestionCircleFilled className="animate-bounce" />
                      </Tooltip>
                    </div>
                  </Col> */}
                </>
              ) : null}
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item name="has_processing_fee" valuePropName="checked">
              <Checkbox
                style={{ borderRadius: 2 }}
                onChange={(val) =>
                  handleUpdateBoolean({ [val.target.id]: val.target.checked })
                }
              >
                Use Processing Fees
                <Tooltip title="">
                  <QuestionCircleFilled className="mx-2" />
                </Tooltip>
              </Checkbox>
            </Form.Item>

            <Row>
              {useProcessingFee || product_config?.data?.has_processing_fee ? (
                <Col span={24}>
                  <Form.Item
                    label="Proccessng Fee Type"
                    name="processing_fee_type"
                    style={{ marginBottom: "10px" }}
                  >
                    <Select
                      onChange={(val) =>
                        handleUpdateGeneral({ processing_fee_type: val })
                      }
                    >
                      {processing_fee_types?.data?.map((types) => (
                        <Option value={types?.slug} key={types?.slug}>
                          {types?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
              {useFixedAmount ||
              product_config?.data?.processing_fee_type === "fixed-amount" ? (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="Processing Fee Amount"
                      name="proccessing_fee"
                      style={{ marginBottom: "10px" }}
                    >
                      <InputNumber
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        placeholder="NGN"
                        style={{ width: "100%" }}
                        onChange={(val) =>
                          handleUpdateGeneral({ proccessing_fee: val })
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              {usePercentage ||
              product_config?.data?.processing_fee_type === "percentage" ? (
                <>
                  <Col span={24}>
                    <Form.Item
                      label="Processing Fee Percentage"
                      name="processing_fee_percentage"
                      style={{ marginBottom: "10px" }}
                    >
                      <InputNumber
                        placeholder="%"
                        style={{ width: "100%" }}
                        onChange={(val) =>
                          handleUpdateGeneral({
                            processing_fee_percentage: val,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ProductConfiguration;
