import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row } from "antd";
import React from "react";
import { Title } from "../../Typography";

const Overview = ({ data }) => {
  return (
    <Card className="mt-5">
      <Row>
        <Col span={14}>
          <div className="flex space-x-8 ml-5">
            <div className="flex space-x-2 mt-8">
              <Avatar size={130} icon={<UserOutlined />} />
            </div>
            <div className="mt-14">
              <Title level={3}>{data?.firstname} {data?.lastname}</Title>
              <div className="font-bold text-black">
                Phone: {data?.phone}
              </div>
              <div className="font-bold text-black">Status: {data?.is_active === true ? "Active" : "Inactive"}</div>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  ) 
};

export default Overview;
