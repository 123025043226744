import { SETNOTESTARTDATE, SETNOTEENDDATE } from "../Types/generalTypes";
import moment from "moment";

const initialState = { 
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const notesReducer = (state = initialState, actions) => {
  if (actions.type === SETNOTEENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETNOTESTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
}

export default notesReducer;