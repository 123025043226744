import moment from "moment";
import {
  ADDALLFULFILMENT,
  FULFILMENTSEARCHVALUE,
  SETFULFILMENTENDDATE,
  SETFULFILMENTSTARTDATE,
  SETFULFILMENTSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  fulfilments: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const fulfilmentsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLFULFILMENT) {
    return { ...state, fulfilments: actions.value };
  }

  if (actions.type === FULFILMENTSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETFULFILMENTSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETFULFILMENTENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETFULFILMENTSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
