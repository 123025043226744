import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "antd";
import LoanCollateralsTable from "../../../Table/LoanRequests/LoanRequestCollaterals";
import AddNewCollateral from "../../../Collateral/AddNewCollateral";
import { useAuth } from "../../../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { ADDALLLOANREQUESTCOLLATERAL } from "../../../../redux/Types/tableTypes";
import Requests from "../../../../utils/request";
import { useQuery } from "react-query";

const Collaterals = ({ data }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { searchValue, status } = useSelector(
    (state) => state.loanRequestsCollateralsTableReducer
  );

  const {
    data: collaterals,
    isLoading,
    refetch,
  } = useQuery(
    "collaterals",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/collateral?id=${id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((collateral) => {
      const name = `${collateral.name}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return collateral;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (collaterals?.status === "success") {
      dispatch({
        type: ADDALLLOANREQUESTCOLLATERAL,
        value: SearchQuery(collaterals?.data),
      });
    }
    // eslint-disable-next-line
  }, [collaterals, status]);

  useEffect(() => {
    dispatch({
      type: ADDALLLOANREQUESTCOLLATERAL,
      value: SearchQuery(collaterals?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <LoanCollateralsTable isLoading={isLoading} />
      <Row className="my-5">
        <Col span={24}>
          <div className="flex justify-end space-x-4">
            <h3 className="text-base">Want to add a new collateral?</h3>
            <AddNewCollateral id={id} data={data} refetch={refetch} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Collaterals;
