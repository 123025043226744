import moment from "moment";
import {
  ADDALLLOANS,
  ALLLOANSEARCHVALUE,
  SETLOANENDDATE,
  SETLOANSTARTDATE,
  SETALLLOANSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  loans: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"),
};

export const allLoanTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANS) {
    return { ...state, loans: actions.value };
  }

  if (actions.type === ALLLOANSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETALLLOANSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETLOANENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETLOANSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
