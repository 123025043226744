import React from "react";
import { Card, Divider, Row, Col } from "antd";
import { Text, Title } from "../../../components/Typography";
import { VictoryChart, VictoryBar, VictoryAxis } from "victory";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function LoanPortfolioHealth({ session }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const data = [
    { quarter: 1, earnings: 0 },
    { quarter: 2, earnings: 0 },
    { quarter: 3, earnings: 0 },
    { quarter: 4, earnings: 0 },
    { quarter: 5, earnings: 0 },
    { quarter: 6, earnings: 0 },
  ];
  const style = { padding: "15px" };
  return (
    <Card className="h-full" size="small" style={style}>
      <div className="flex justify-left -my-3">
        <Text>{session?.admin?.category === "merchant" ? "Order Portfolio Health" : "Loan Portfolio Health"}</Text>
      </div>
      <Divider />
      <Row className="flex justify-between">
        <Col className="-my-4">
          <Title level={3} className="text-red-500">
            0%
          </Title>
          <Text>Portfolio at Risk (PAR)</Text>
        </Col>
        <Col className="-my-4">
          <Title level={3}>0</Title>
          <Text>{session?.admin?.category === "merchant" ? "Abandoned Orders" : "Abandoned Loans"}</Text>
        </Col>
        <Col className="-my-4">
          <Title level={3}>{currencyFormatter(0)}</Title>
          <Text>Unpaid Late Fees</Text>
        </Col>
      </Row>
      <div className="-my-3">
        <VictoryChart width={500} height={150}>
          <VictoryAxis
            tickFormat={(x) => `${x}`}
            tickValues={["N/a"]}
            style={{ axis: { stroke: "none" } }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(y) => `${y}`}
            style={{
              axis: { stroke: "none" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "transparent" },
            }}
          />
          <VictoryBar
            data={data}
            x="quarter"
            y="earnings"
            style={{ data: { fill: "#5152A6" } }}
          />
        </VictoryChart>
      </div>
    </Card>
  );
}

export default LoanPortfolioHealth;
