import moment from "moment";
import {
  ADDDELINQUENCY,
  SETDELINQUENCYENDDATE,
  SETDELINQUENCYSTARTDATE,
  SETDELINQENCYLOANOFFICER
} from "../../Types/tableTypes";

const initialState = {
  delinquency: {},
  officerId: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const delinquencyTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDDELINQUENCY) {
    return { ...state, delinquency: actions.value };
  }
  if (actions.type === SETDELINQUENCYENDDATE) {
    return { ...state, endDate: actions.value };
  }
  if (actions.type === SETDELINQUENCYSTARTDATE) {
    return { ...state, startDate: actions.value };
  }
  if (actions.type === SETDELINQENCYLOANOFFICER) {
    return { ...state, officerId: actions.value };
  }

  return state;
};
