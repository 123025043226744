import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Input, DatePicker } from "antd";
import { GURANTORSERACHVALUE } from "../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  SETGURANTORSTARTDATE,
  SETGURANTORENDDATE,
} from "../../redux/Types/tableTypes";

const { RangePicker } = DatePicker;

function TableHeader(props) {
  const dispatch = useDispatch();
  const dateFormat = "YYYY-MM-DD";
  const { endDate, startDate } = useSelector(
    (state) => state.loanRequestsTableReducer
  );

  const startDateFilter = (e) => {
    dispatch({
      type: SETGURANTORSTARTDATE,
      value: moment(e.startDate).format(dateFormat),
    });
  };

  const endDateFilter = (e) => {
    dispatch({
      type: SETGURANTORENDDATE,
      value: moment(e.endDate).format(dateFormat),
    });
  };

  const handleSearch = (e) => {
    dispatch({ type: GURANTORSERACHVALUE, value: e.target.value });
  };
  return (
    <div className="mb-5">
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        Easily manage all the guarantors across your loans.
      </p>
      <div className="flex items-center justify-between">
        <div className="flex space-x-4">
          <Input
            size="large"
            placeholder="Search..."
            onChange={handleSearch}
            style={{ width: "75%" }}
            prefix={<SearchOutlined />}
          />

          <RangePicker
            style={{ width: "75%" }}
            onChange={(value) =>
              startDateFilter(
                { startDate: value[0] },
                endDateFilter({ endDate: value[1] })
              )
            }
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            on
          />
        </div>
        {/* <div>
          <Button type="primary" size="large" style={{ fontSize: "12px" }}>
            Create new guarantor
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default TableHeader;
