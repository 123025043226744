import React from "react";
import { Button, message, Table } from "antd";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function ParReprtTable({ isLoading }) {
	const { par } = useSelector((state) => state.parTableReducer);
	const { currencyFormatter } = useCurrenncyFormatter();

	const columns = [
		{
			title: "Days Overdue",
			dataIndex: "days_overdue",
			key: "days_overdue",
		},
		{
			title: "Borrowers Count",
			dataIndex: "borrowers_count",
			key: "borrowers_count",
		},
		{
			title: "Percent Active Borrowers",
			dataIndex: "percent_active_borrowers",
			key: "percent_active_borrowers",
		},
		{
			title: "Loan Amount Overdue",
			dataIndex: "loan_amount_overdue",
			key: "loan_amount_overdue",
			render: (_, record) => (
				<span>{currencyFormatter(record.loan_amount_overdue)}</span>
			),
		},
		{
			title: "Percent Overdue",
			dataIndex: "percent_overdue",
			key: "percent_overdue",
			render: (_, record) => (
				<span>{Number(record.percent_overdue).toFixed(2)}</span>
			),
		},
		{
			title: "Loan Balance Outstanding",
			dataIndex: "loan_balance_outstanding",
			key: "loan_balance_outstanding",
		},
		{
			title: "Percent Poan Balance Outstanding",
			dataIndex: "percent_loan_balance_outstanding",
			key: "percent_loan_balance_outstanding",
			render: (_, record) => (
				<span>
					{Number(record.percent_loan_balance_outstanding).toFixed(2)}
				</span>
			),
		},
	];

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="primary">
					<CSVLink
						filename={"Par_Report.csv"}
						data={par || ""}
						onClick={() => {
							message.success("The file is downloading");
						}}
					>
						Export to CSV
					</CSVLink>
				</Button>
			</div>
			<Table
				className="mt-5"
				loading={isLoading}
				columns={columns}
				dataSource={par}
				pagination={{ pageSize: 10, position: ["bottomLeft"] }}
				size="middle"
				scroll={{ x: "max-content" }}
			/>
		</div>
	);
}

export default ParReprtTable;
