import moment from "moment";
import {
  SETOVERVIEWENDDATE,
  SETOVERVIEWSTARTDATE,
  ADDALLOVERVIEWCREDITORDER,
  OVERVIEWCREDITORDERSEARCHVALUE,
  SETOVERVIEWORDERSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  creditorders: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const overviewOrderTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLOVERVIEWCREDITORDER) {
    return { ...state, creditorders: actions.value };
  }

  if (actions.type === OVERVIEWCREDITORDERSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETOVERVIEWORDERSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETOVERVIEWENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETOVERVIEWSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
