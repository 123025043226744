import React from "react";
import { Text, Title } from "../../../components/Typography";
import { Row, Col, Card, Divider, Button } from "antd";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTheme } from "victory";

function EndCard({ data, data1 }) {
  const graph = data1?.map((val) => val);

  return (
    <>
      <Row gutter={16} className="mt-10">
        <Col span={16} push={6}>
          <Card
            className="space-y-2 h-full"
            style={{ padding: "15px", height: "440px" }}
          >
            <Title level={4}>Borrowers Growth</Title>
            <div className="-mt-12 w-full">
              <VictoryChart
                domainPadding={{ x: 10, y: 10 }}
                height={300}
                theme={VictoryTheme.material}
                width={500}
              >
                <VictoryAxis
                  tickFormat={(x) => `${x}`}
                  tickValues={data1?.map((val) => val?.month)}
                  style={{
                    axis: { stroke: "#84818A", strokeWidth: 0.5 },
                    tickLabels: { fontSize: "8px" },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={(y) => `${y}`}
                  tickValues={data1?.map((val) => val?.count_new_borrowers)}
                  style={{
                    axis: { stroke: "none" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fontSize: "8px" },
                  }}
                />
                <VictoryBar
                  data={graph}
                  style={{ data: { fill: "#5152A6" } }}
                  x="month"
                  y="count_new_borrowers"
                />
              </VictoryChart>
            </div>
          </Card>
        </Col>
        <Col span={6} pull={16}>
          <Card
            className="h-full"
            size="small"
            style={{ padding: "15px" }}
          >
            <div className="space-y-1  -my-3">
              <Title level={5}>Overdue Loans</Title>
              <Divider />
              <Text>Value of overdue 90 days Loans</Text>
              <Title level={4} style={{ color: "#FC3400" }}>
                {data?.last_90_days?.value || 0}
              </Title>
              <Divider type="dashed" />
              <Text>Borrowers</Text>
              <div className="flex justify-between">
                <Title level={5}>{data?.last_90_days?.count || 0}</Title>
                <Button type="link">view all</Button>
              </div>
              <Divider />
            </div>
            <div className="space-y-1 mt-3">
              <Text>Value of overdue 60 days Loans</Text>
              <Title level={4} style={{ color: "#FF4B4B" }}>
                {data?.last_60_days?.value || 0}
              </Title>
              <Divider type="dashed" />
              <Text>Borrowers</Text>
              <div className="flex justify-between">
                <Title level={5}>{data?.last_60_days?.count || 0}</Title>
                <Button type="link">view all</Button>
              </div>
              <Divider />
            </div>
            <div className="space-y-1 mt-3">
              <Text>Value of overdue 30 days Loans</Text>
              <Title level={4} style={{ color: "#FF4B4B" }}>
                {data?.last_30_days?.value || 0}
              </Title>
              <Divider type="dashed" />
              <Text>Borrowers</Text>
              <div className="flex justify-between">
                <Title level={5}>{data?.last_30_days?.count || 0}</Title>
                <Button type="link">view all</Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default EndCard;
