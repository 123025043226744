import React from "react";
import CreditApplicationTable from "../../../Table/Borrowers/CreditApplication";
import TableHeader from "../Header";
 
const CreditOrder = ({ isLoading }) => {
  return (
    <>
      <TableHeader />
        <CreditApplicationTable isLoading={isLoading} />
    </>
  )
}

export default CreditOrder;