import { SETPROFILE } from "../Types/generalTypes";

const initialState = { 
    profile: {}
};

export const profileReducer = (state = initialState, actions) => {
  if (actions.type === SETPROFILE) {
    return { ...state, profile: actions.value };
  }

  return state;
}

export default profileReducer;