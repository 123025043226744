import React from 'react';
import {Link} from 'react-router-dom';
import AllLoanTable from '../../Table/Loan/AllLoanTable';
import { AllLoanHeader } from '../Header';

function AllRepaidLoans(props) {
    const style = { background: '#ffffff', padding: '20px', borderRadius: '12px' };


    return (
        <div style={style}>
        <AllLoanHeader title="Repaid" repaid={true}/>  
            <AllLoanTable amount="0" repayment={true} title="Repayment" />
            <div style={{ textAlign: 'center', marginTop: '20PX', textDecoration: 'underline', color: '#5A607F', fontSize: '18px' }}>
                <Link>
                    View All Repaid Loans
                </Link>
            </div>
        </div>
    )
}

export default AllRepaidLoans;
