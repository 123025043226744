import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Button, Input, Select, message } from "antd";
import { useMutation } from "react-query";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { phoneLocale } from "../../utils/Currency";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

function Register({ closeModal, useAllFeature, setUserID }) {
  const [isProcessing, setProcessing] = useState(false);
  const [mode, setMode] = useState("new");
  const [findby, setFindBy] = useState("email");
  const { profile } = useSelector((state) => state.profileReducer);
  const { session } = useAuth();
  const findForm = React.createRef();
  const searchBy = [
    { label: "Phone number", value: "phone-number" },
    { label: "Email", value: "email" },
  ];
  const registerMutation = useMutation((user) =>
    Requests.fetchWithAuthToken(
      "/lms/borrower",
      "POST",
      session?.session?.token,
      user
    )
  );

  const findBorrowerMutation = useMutation((user) =>
    Requests.fetchWithAuthToken(
      `/lms/search?search-term=borrower&search-by=${user.searchBy}&data=${user.data}`,
      "GET",
      session?.session?.token
    )
  );

  const handleSearchBorrower = (value) => {
    const user = {
      searchBy: value.findby,
      data: value.phone ? value.phone : value.email.toLowerCase(),
    };

    setProcessing(true);
    findBorrowerMutation.mutate(user, {
      onSuccess: (data) => onUserRegister(data),
      onError: () => {},
    });
  };

  const onUserRegister = (data) => {
    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    setProcessing(false);
    message.success(data.message, 3);

    if (useAllFeature) {
      closeModal(false);
      return setUserID(data?.data1?.id);
    }
    closeModal(false);
  };

  const handleSubmit = (values) => {
    const user = {
      user: {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        phone: values.phone,
        gender: values.gender,
      },
    };

    setProcessing(true);
    registerMutation.mutate(user, {
      onSuccess: (data) => onUserRegister(data),
      onError: () => {},
    });
  };

  useEffect(() => {
    findForm?.current?.setFieldsValue({
      findby,
    });
    // eslint-disable-next-line
  }, [mode]);

  return (
    <>
      <div className="p-5 flex space-x-4 justify-between">
        {/* <Button
          className="w-full"
          size="large"
          type={mode === "new" ? "primary" : "dashed"}
          onClick={() => setMode("new")}
        >
          Create new borrower
        </Button> */}
        {useAllFeature && (
          <Button
            onClick={() => setMode("old")}
            className="w-full"
            size="large"
            type={mode === "old" ? "primary" : "dashed"}
          >
            Find borrower
          </Button>
        )}
      </div>
      {mode === "new" && (
        <div className="bg-white m-auto p-5">
          <p className="text-xl text-black font-semibold ">
            Add a new borrower
          </p>
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(values) => handleSubmit(values)}
          >
            <Row gutter={[16]}>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone Number is required!",
                    },
                  ]}
                >
                  <PhoneInput
                    international
                    placeholder="Enter phone number"
                    defaultCountry={phoneLocale[profile?.default_currency]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required!",
                    },
                  ]}
                >
                  <Select size="large">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col xs={24} md={12} lg={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col> */}
            </Row>
            <Form.Item>
              <Button
                loading={isProcessing}
                style={{ width: "100%" }}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Add Borrower
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      {mode === "old" && (
        <div className="bg-white m-auto p-5">
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleSearchBorrower}
            ref={findForm}
            onFieldsChange={(val) => {
              if (val[0]?.name[0] === "findby") {
                return setFindBy(val[0]?.value);
              }
            }}
          >
            <Row gutter={[16]}>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Find borrower by ?"
                  name="findby"
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  <Select size="large">
                    {searchBy.map((search) => (
                      <Option value={search.value}>{search.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {findby === "phone-number" && (
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required!",
                      },
                    ]}
                  >
                    <PhoneInput
                      international
                      placeholder="Enter phone number"
                      defaultCountry={phoneLocale[profile?.default_currency]}
                    />
                  </Form.Item>
                </Col>
              )}

              {findby === "email" && (
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="you@example.com"
                      size="large"
                      type="email"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Form.Item>
              <Button
                loading={isProcessing}
                icon={<SearchOutlined />}
                style={{ width: "100%" }}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Find borrower
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}

export default Register;
