import React, { useState } from "react";
import { useQuery } from "react-query";
import Requests from "../../../utils/request";
import Heading from "./Heading";
import Overview from "./Overview";
import Information from "./Information";
import Terms from "./Terms";
import AdditionalActions from "./AdditionalActions.js";

const LenderView = ({ session, loan_request, id }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { data: debit_mandate } = useQuery(
    `request-debit_mandate-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/debit_mandate/r/${id}?action=activation-status&vendor=remita&resource=loan-request`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!loan_request,
    }
  );

  const { data: product_template } = useQuery(
    ["product_template", loan_request],
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product?slug=${loan_request?.data?.product_slug}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!loan_request,
    }
  );

  const { data: product_config } = useQuery(
    `product_config-${product_template?.data?.id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product/${product_template?.data?.id}/config`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!product_template,
    }
  );

  const { data: business_information } = useQuery(
    "business-information",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=business-information`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: health_facility } = useQuery(
    "health_facility",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=health-facility`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <Heading data={loan_request?.data} />
      <Overview
        data={loan_request?.data}
        debit_mandate={debit_mandate}
        business_information={business_information}
        health_facility={health_facility}
        product_template={product_template}
      />

      {loan_request?.data?.lender_status === "approved" && (
        <Terms data={loan_request?.data} />
      )}

      <Information
        product_template={product_template?.data}
        approval={loan_request?.data1}
        config={product_config?.data}
        data={loan_request?.data}
        setVisible={() => setIsVisible(true)}
        business_information={business_information}
        health_facility={health_facility}
      />

      <AdditionalActions
        isVisible={isVisible}
        id={id}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

export default LenderView;
