import { Row, Col, Divider, Empty } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { useCurrenncyFormatter } from "../../../../utils/currencyFormatter";

const Fields = ({ slug}) => {
    const { session } = useAuth();
    const { id } = useParams();
    const { currencyFormatter } = useCurrenncyFormatter();

    const { data: fields } = useQuery(
        `fields-${slug}`,
        () => Requests.fetchWithAuthToken(`/lms/loan_request/${id}/custom_data?initial-slug=${slug}`, 
        "GET",
        session.session.token),
        { refetchOnWindowFocus: false },
    )

    const getDataType = (dataType) => {
      if (dataType === "string") {
        return "string_value";
      }
  
      if (dataType === "number") {
        return "number_value";
      }
  
      if (dataType === "boolean") {
        return "boolean_value";
      }
    };

    return(
        <>
            <div className="mt-5">
                <Row gutter={[16,16]}>
                    {fields?.data?.fields?.map((field) => 
                      <Col span={8} key={field?.field}>
                        <div className="flex flex-col justify-between h-10">
                            <h3 className="text-gray-500">{field?.label}</h3>
                            <h3 className="font-bold text-base">
                              {field?.is_money ? currencyFormatter(field[getDataType(field?.data_type)]) :
                                field?.data_type === "boolean" ? field[getDataType(field?.data_type)] === true ? "Yes" : "No" :
                                field[getDataType(field?.data_type)]}
                            </h3>
                        </div>
                        <Divider />
                      </Col>
                    )}
                    {fields === null && (
                        <Col span={24}>
                            <Empty />
                        </Col>
                    )}
                </Row>
            </div>
        </>
    )
}

export default Fields;