import React, { useEffect, useState } from "react";
import { Form, message, Checkbox, Tooltip, Select } from "antd";
import { useAuth } from "../../../context/AuthContext";
import { useQuery, useMutation } from "react-query";
import Request from "../../../utils/request";
import { useParams } from "react-router-dom";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Option } = Select;

function NumberOfGuarantors() {
  const { session } = useAuth();
  const [requireGuarantor, setRequireGuarantor] = useState(false);

  const noOfGuarantorsRef = React.createRef();

  const { id: product_id } = useParams();

  const { data: product_config } = useQuery(
    "product_config",
    () =>
      Request.fetchWithAuthToken(
        `/lms/product/${product_id}/config`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const UpdateConfigBoolean = useMutation((generalConfigBody) =>
    Request.fetchWithAuthToken(
      `/lms/product/${product_id}/config?reference=bool-fields&field=${
        Object.keys(generalConfigBody)[0]
      }`,
      "PUT",
      session?.session?.token,
      generalConfigBody
    )
  );

  const UpdateConfigGeneral = useMutation((generalConfigBody) =>
    Request.fetchWithAuthToken(
      `/lms/product/${product_id}/config?reference=general&field=${
        Object.keys(generalConfigBody)[0]
      }`,
      "PUT",
      session?.session?.token,
      generalConfigBody
    )
  );

  const onUpdateConfig = (data) => {
    if (data.status !== "success") {
      message.error(data.message, 3);
      return;
    }
    message.success(data.message, 3);
  };

  const handleChangeGuarantorNumber = (val) => {
    UpdateConfigGeneral.mutate(val, {
      onSuccess: (data) => {
        onUpdateConfig(data);
      },
      onError: () => {},
    });
  };

  const handleChangeGuarantor = (val) => {
    val?.require_guarantor === true
      ? setRequireGuarantor(true)
      : setRequireGuarantor(false);
    UpdateConfigBoolean.mutate(val, {
      onSuccess: (data) => onUpdateConfig(data),
      onError: () => {},
    });
  };

  useEffect(() => {
    noOfGuarantorsRef?.current?.setFieldsValue({
      require_guarantor: product_config?.data?.require_guarantor,
      number_of_guarantors: product_config?.data?.number_of_guarantors,
    });
    // eslint-disable-next-line
  }, [product_config]);

  return (
    <Form layout="inline" ref={noOfGuarantorsRef}>
      <div className="flex">
        <Form.Item name="require_guarantor" valuePropName="checked">
          <Checkbox
            style={{ borderRadius: 2 }}
            onChange={(val) =>
              handleChangeGuarantor({ [val.target.id]: val.target.checked })
            }
          >
            Guarantor Information
            <Tooltip title="">
              <QuestionCircleFilled className="mx-2" />
            </Tooltip>
          </Checkbox>
        </Form.Item>
        {requireGuarantor || product_config?.data?.require_guarantor ? (
          <Form.Item name="number_of_guarantors" label="Number of guarantors">
            <Select
              onChange={(val) =>
                handleChangeGuarantorNumber({ number_of_guarantors: val })
              }
            >
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
            </Select>
          </Form.Item>
        ) : null}
      </div>
    </Form>
  );
}

export default NumberOfGuarantors;
