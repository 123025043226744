import React, { useState } from "react";
import { Card, Form, Col, Row, Button, message } from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import useGenerateComponent from "../../../../utils/componentGenerator";
import { Title } from "../../../Typography";
const docsform = React.createRef();

function DocUpload({ currentNumber, id, setCurrentNumber }) {
  const dispatch = useDispatch();
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);
  const [loading, setIsLoading] = useState(false);
  const { uploadDocs, updateProgress } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();
  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      if (currentNumber !== "end") {
        setCurrentNumber((prev) => {
          const current = Number(prev) + 1;
          return current.toString();
        });
        message.success(data.message, 2);
        updateProgress(
          { progress: String(Number(currentNumber) + 1), loan_requestID: id },
          (data) => data
        );
      }
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);
    const userData = {
      loan_requestID: id,
      upload: Object.keys(value).map((doc) => ({
        type: doc,
        title: doc,
        document_url: value[doc],
      })),
    };
    uploadDocs(userData, handleSubmitCallback);
  };

  return (
    <Card>
      <Title level={3}>Document Upload</Title>
      <Form ref={docsform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          {currentLoan?.segments["document-uploads"]?.fields?.map(
            (field, index) =>
              field?.is_needed && (
                <Col xs={24} md={12} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(
                      field,
                      {
                        ref: docsform,
                        fieldName: field?.field,
                      },
                      dispatch
                    )}
                  </Form.Item>
                </Col>
              )
          )}
        </Row>

        <Button
          className="lg:w-auto w-full"
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
        >
          Save and Continue
        </Button>
      </Form>
    </Card>
  );
}

export default DocUpload;
