import React from "react";
import ProductSetup from "../../components/LoanProducts/Setup/ProductSetup";
import BackBtn from "../../utils/BackButton";

function SingleProducts() {
  return (
    <div>
      <div className="mb-3">
        <BackBtn />
      </div>
      <ProductSetup />
    </div>
  );
}

export default SingleProducts;
