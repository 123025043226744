import moment from "moment";
import {
  ADDALLBORROWERSLOAN,
  ALLBORROWERSLOANSEARCHVALUE,
  SETBORROWERSLOANSENDDATE,
  SETBORROWERSLOANSTARTDATE,
  SETBORROWERSLOANSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  borrowersloans: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const borrowersLoansTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLBORROWERSLOAN) {
    return { ...state, borrowersloans: actions.value };
  }

  if (actions.type === ALLBORROWERSLOANSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETBORROWERSLOANSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETBORROWERSLOANSENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETBORROWERSLOANSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
