import { useCurrenncyFormatter } from "./currencyFormatter";

const UseDisplayCustom = () => {
  const { currencyFormatter } = useCurrenncyFormatter();
  const handleCustomField = (values) => {

    if(values.data_type === "number" && values.is_money === true) {
       return currencyFormatter(values?.number_value)
    }
  
    if(values.data_type === "number" && values.is_money === false) {
        return values?.number_value
    }
  
    if(values.data_type === "string") {
        return values.string_value
    }
  
    if(values.data_type === "boolean") {
        return values.boolean_value === true ? "Yes" : "No"
    }
  }

  return { handleCustomField };
}

export default UseDisplayCustom;
