import moment from "moment";

const today = moment();
function sortArray(array) {
    if (array) {
      const newArray = array.sort((a, b) => a.due_on - today > b.due_on - today ? 1 : -1);
      return newArray;
    }
  }
  
export default sortArray;
  