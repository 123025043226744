import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, InputNumber, Drawer } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";
import { currencyParser, useCurrenncyFormatter } from "../../../../../utils/currencyFormatter";

const AddFacilityExpenses = ({ refetchFacilityExpenses, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const facilityExpensesRef = React.createRef();
  const { currencyFormatter } = useCurrenncyFormatter();

  const mutationFacilityExpenses = useMutation((facility_expenses) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=facility-expenses`,
      "POST",
      session.session.token,
      facility_expenses
    )
  );

  useEffect(() => {
    facilityExpensesRef?.current?.setFieldsValue({
      average_monthly_revenue: data?.average_monthly_revenue,
      average_monthly_expenses: data?.average_monthly_expenses,
      annual_rent_cost: data?.annual_rent_cost,
      internet_and_others: data?.internet_and_others,
      licence_and_insurance_monthly_cost: data?.licence_and_insurance_monthly_cost,
      monthly_electricity_cost: data?.monthly_electricity_cost,
      monthly_loan_servicing_amount: data?.monthly_loan_servicing_amount,
      revenue_in_past_12_months: data?.revenue_in_past_12_months,
      monthly_fuel_cost: data?.monthly_fuel_cost,
      phone_cost: data?.phone_cost,
      service_cost: data?.service_cost,
      total_salaries: data?.total_salaries,
    });
  }, [facilityExpensesRef, data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationFacilityExpenses.mutate(values, {
      onSuccess: (data) => {
        refetchFacilityExpenses();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Facility Expenses
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Facility Expenses</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={facilityExpensesRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Average Monthly Revenue"
                name="average_monthly_revenue"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Average Monthly Expenses"
                name="average_monthly_expenses"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Annual Rent Cost"
                name="annual_rent_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Internet and Others"
                name="internet_and_others"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="License and Insurance Monthly Cost"
                name="licence_and_insurance_monthly_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Monthly Electricity Cost"
                name="monthly_electricity_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Monthly Fuel Cost"
                name="monthly_fuel_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Monthly Loan Servicing Amount"
                name="monthly_loan_servicing_amount"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Phone and Cost"
                name="phone_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Revenue in Past 12 Months"
                name="revenue_in_past_12_months"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Service Cost"
                name="service_cost"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Total Salaries"
                name="total_salaries"
              >
                <InputNumber size="large" style={{ width: "100%" }} formatter={currencyFormatter}
                  parser={currencyParser} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddFacilityExpenses;