import React, { useState } from "react";
import { Button, Modal, Form, Radio, Input, message } from "antd";
import { Title } from "../Typography";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useForm } from "antd/lib/form/Form";
import { ADDNEWFIELD } from "../../redux/Types/generalTypes";
import { useDispatch } from "react-redux";

const CustomInfo = ({
  setIsDropDown,
  isDropdown,
  id,
  refetchTypes,
  setCustomFields,
  customFields,
}) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState();
  const [isProcessing, setProcessing] = useState();
  const [isNumber, setIsNumber] = useState(false);
  const [isText, setIsText] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();

  const { data: types } = useQuery("data-types", () =>
    Requests.fetchWithAuthToken(
      "/lms/utils/data_types",
      "GET",
      session.session.token
    )
  );

  const formatTypesToFitRadioStructure = (types = []) => {
    const newTypes = [
      ...types,
      { description: "File Upload", value: "upload" },
    ];
    return newTypes?.map((type) => ({
      label: type.description,
      value: type.value,
    }));
  };

  const onChange = (e) => {
    if (e.target.value === "upload") {
      setIsNumber(false);
      setIsText(false);
      setIsDropDown(false);
      return;
    }

    if (e.target.value === "number") {
      setIsNumber(true);
      setIsText(false);
      setIsDropDown(false);
      return;
    }

    if (e.target.value === "string") {
      setIsText(true);
      setIsNumber(false);
      return;
    }

    if (e.target.value === "") setIsDropDown(false);
    setIsNumber(false);
    setIsText(false);
  };

  const onChangeDropDown = (e) => {
    if (e.target.value) {
      setIsDropDown(true);
      return;
    }
    setIsDropDown(false);
  };

  const mutationCustomField = useMutation((custom_field) =>
    Requests.fetchWithAuthToken(
      `/lms/product/collateral_type/${id}`,
      "PUT",
      session.session.token,
      custom_field
    )
  );

  const handleSubmit = (values) => {
    const field = {
      field_name: values.field_name,
      field_type:
        values?.is_dropdown === true
          ? "dropdown"
          : values?.is_money === true
          ? "is_money"
          : values.field_type,
      dropdown_item: [values.dropdown],
    };

    if (customFields) {
      setCustomFields([...customFields, field]);
    } else {
      setCustomFields([field]);
    }

    dispatch({
      type: ADDNEWFIELD,
      value: field,
    });

    setProcessing(true);
    mutationCustomField.mutate(field, {
      onSuccess: (data) => {
        refetchTypes();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
        setIsModalVisible(false);
      },
      onError: (err) => {},
    });
    return;
  };

  return (
    <>
      <Button onClick={() => setIsModalVisible(true)}>
        Add a Custom Field
      </Button>
      <Modal
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width="35%"
      >
        <Title level={3}>What Kind of field do you want to create? </Title>
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item label="Field Name" name="field_name">
            <Input onChange={(e) => e.target.value} />
          </Form.Item>
          <Form.Item label="Field Type" name="field_type">
            <Radio.Group
              onChange={onChange}
              options={formatTypesToFitRadioStructure(types?.data)}
            />
          </Form.Item>
          {isText && (
            <>
              <Form.Item label="Is this a dropdown ?" name="is_dropdown">
                <Radio.Group
                  onChange={onChangeDropDown}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </>
          )}

          {isDropdown && (
            <Form.Item label="Dropdown options" name="dropdown">
              <Input.TextArea
                onChange={(e) => e.target.value}
                placeholder="Separate option with comma"
              />
            </Form.Item>
          )}

          {isNumber && (
            <Form.Item label="Does the field involve money ?" name="is_money">
              <Radio.Group
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Form.Item>
          )}
          <div className="flex space-x-2">
            <Button htmlType="submit" loading={isProcessing}>
              Add field
            </Button>
            <Button htmlType="submit" onClick={() => form.resetFields()}>
              Reset form
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CustomInfo;
