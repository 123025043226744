const superUser = {
  cat_one_prev: {
    super: true,
    get_started: true,
    overview: true,
    customers: true,
    loans: true,
    disbursements: true,
    loan_requests: true,
    guarantors: true,
    blacklist: true,
    wallet: true,
    branches: true,
    products: true,
    transactions: true,
    settlements: true,
    insights_and_reports: true,
    approvals: true,
    audit_logs: true,
    subscription: true,
    settings: true,
    repayments: true,
    borrowers: true,
    activity_log: true,
    notifications: true,
    notes: true,
  },
  cat_two_prev: {
    super: true,
    create_product: true,
    edit_product: true,
    disburse: true,
    approve_loan_request: true,
  },
};

export default superUser;
