import React from "react";
import Progress from "./ProgressStep";
import { Row, Col } from "antd";
import Header from "./Header";
// import { InfoCircleOutlined } from "@ant-design/icons";
// import info from "./adminIcons/info.svg";
import group from "./adminIcons/group.svg";
import ChooseRoleType from "./ChooseRoleType";

function SetupLoan() {
  return (
    <>
      <Header
        title="Setup Approval Structure"
        removeSafeForLater={true}
        next="/adminsetup/teammembers"
      />
      <Row gutter={16}>
        <Col span={17}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <div
              style={{
                background: "rgba(255, 240, 240, 0.31)",
                border: "1px solid #F0D1A3",
                display: "flex",
                borderRadius: "10px",
                alignItems: "center",
                fontSize: "16px",
                lineHeight: "20px",
                justifyContent: "center",
                paddingTop: "10px",
                marginBottom: "20px",
              }}
            >
              <img src={group} alt="group"></img>
              <p style={{ padding: "0 30px 0 10px" }}>
                Set your loan approval structure. You have the choice to change it anytime
              </p>
            </div>

            <div className="my-5">
              <ChooseRoleType />
            </div>
          </div>
        </Col>
        <Col span={7}>
          <Progress step="Approval Structure" />
        </Col>
      </Row>
    </>
  );
}

export default SetupLoan;
