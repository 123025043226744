import React, { useEffect, useState } from "react";
import Progress from "./ProgressStep";
import { useMutation, useQuery } from "react-query";
import Upload from "../Uploader";
import { Form, Input, Row, Col, message, Button, Tooltip } from "antd";
import { SelectOutlined } from "@ant-design/icons";
import Request from "../../utils/request";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import Header from "./Header";

function Kyc({ isModal }) {
  const { session } = useAuth();
  const history = useHistory();
  const [isProcessing, setProcessing] = useState(false);
  const [documents, setDocument] = useState({});
  const formKYC = React.createRef();

  const mutationKey = useMutation((userData) =>
    Request.fetchWithAuthToken(
      "/lms/kyc",
      "PUT",
      session.session.token,
      userData
    )
  );

  const { data } = useQuery(
    "kyc",
    () => Request.fetchWithAuthToken("/lms/kyc", "GET", session.session.token),
    { refetchOnWindowFocus: false }
  );

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationKey.mutate(
      {
        ...values,
        ...documents,
        incorporation_documents: [documents.incorporation_documents],
      },
      {
        onSuccess: (data) => onUpdateKyc(data),
        onError: () => {},
      }
    );
  };

  const onUpdateKyc = (data) => {
    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    setProcessing(false);
    !isModal && history.push("/adminsetup/loanapproval");
    message.success(data.message, 3);
  };

  const noDocumentInfo = () => {
    message.info("No document uploaded, please upload a document below");
  };

  useEffect(() => {
    formKYC?.current?.setFieldsValue({
      business_owner_name: data?.data?.business_owner_name,
      business_owner_email: data?.data?.business_owner_email,
      business_owner_bvn: data?.data?.business_owner_bvn,
      business_owner_bvn_verified: data?.data?.business_owner_bvn_verified,
      business_owner_id: data?.data?.business_owner_id,
      business_owner_id_type: data?.data?.business_owner_id_type,
      business_owner_phone: data?.data?.business_owner_phone,
      designation: data?.data?.designation,
      incorporation_documents: data?.data?.incorporation_documents,
    });

    setDocument({
      incorporation_documents:
        data?.data?.incorporation_documents &&
        data?.data?.incorporation_documents[0],
      lending_licence: data?.data?.lending_licence,
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {!isModal && (
        <Header
          title="Business KYC Information"
          onSave={() => formKYC.current.submit()}
          next="/adminsetup/loanapproval"
        />
      )}
      <Row gutter={16}>
        <Col span={isModal ? 24 : 16}>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <div className="flex justify-between">
              <Row>
                {data?.data?.incorporation_documents?.length &&
                  data?.data?.incorporation_documents?.map((url) =>
                    url > 0 ? (
                      <Col xs={24} lg={8}>
                        <Button
                          key={url}
                          target="_blank"
                          type="link"
                          href={url}
                          icon={<SelectOutlined />}
                        >
                          Incorporation Document
                        </Button>
                      </Col>
                    ) : (
                      <Col xs={24} lg={8}>
                        <Button
                          type="link"
                          icon={<SelectOutlined />}
                          onClick={noDocumentInfo}
                        >
                          Incorporation Document
                        </Button>
                      </Col>
                    )
                  )}
              </Row>
              {data?.data?.lending_licence ? (
                <Button
                  target="_blank"
                  type="link"
                  href={data?.data?.lending_licence}
                  icon={<SelectOutlined />}
                >
                  License Document
                </Button>
              ) : (
                <Button
                  type="link"
                  icon={<SelectOutlined />}
                  onClick={noDocumentInfo}
                >
                  License Document
                </Button>
              )}
            </div>

            <div className="my-5 mb-10">
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <div className="flex flex-col items-start">
                    <label>Upload Incorporation Document *</label>
                    <Button
                      type="link"
                      onClick={() =>
                        message.info(
                          "We use this information to confirm that you are a legally registered business in your country of operation."
                        )
                      }
                    >
                      <Tooltip title="We use this information to confirm that you are a legally registered business in your country of operation.">
                        Why do we need this?
                      </Tooltip>
                    </Button>
                    <Upload
                      text="Click to Browse For PDF, PNG, JPG Files"
                      looading={isProcessing}
                      onUploaded={(url) =>
                        setDocument((prev) => ({
                          ...prev,
                          incorporation_documents: url,
                        }))
                      }
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div className="flex flex-col items-end">
                    <label>Upload Lending License Document *</label>
                    <Button
                      type="link"
                      onClick={() =>
                        message.info(
                          "We use this information to confirm that you are legally licensed to provide lending and/or financial services in your country of operation."
                        )
                      }
                    >
                      <Tooltip title="We use this information to confirm that you are legally licensed to provide lending and/or financial services in your country of operation.">
                        Why do we need this?
                      </Tooltip>
                    </Button>
                    <Upload
                      text="Click to Browse For PDF, PNG, JPG Files"
                      onUploaded={(url) =>
                        setDocument((prev) => ({
                          ...prev,
                          lending_licence: url,
                        }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Form ref={formKYC} layout="vertical" onFinish={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Full Name of Business Owner"
                    name="business_owner_name"
                  >
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>
                  <Form.Item
                    label="Email Address of Business Owner"
                    name="business_owner_email"
                  >
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Enter Designation" name="designation">
                    <Input placeholder="CEO/Director" />
                  </Form.Item>
                  <Form.Item
                    label="Phone number of Business Owner"
                    name="business_owner_phone"
                  >
                    <Input placeholder="+234" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        {!isModal && (
          <Col span={7}>
            <Progress step="KYC" />
          </Col>
        )}
      </Row>
    </>
  );
}

export default Kyc;
