import { Col } from "antd";
import React from "react";

import { Text, Title } from "../Typography";

function TeamCard({ background, logo, title, amount }) {
  const style = {
    background: `${background}`,
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    borderRadius: "16px",
    marginTop: "20px",
  };

  return (
    <Col span={8}>
      <div style={style}>
        <div style={{ display: "flex" }}>
          <img src={logo} alt={title} />
          <div style={{ marginLeft: "15px" }}>
            <Title level={4} style={{ marginBottom: "0" }}>
            {amount}
            </Title>
            <Text style={{ fontSize: "12px" }}>{title}</Text>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default TeamCard;
