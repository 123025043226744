import { Button, Form, Modal, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { useApp } from "../../../context/FetchContext";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { Title } from "../../Typography";
import LoanDisbursed from "./DisbursedModal";
const { Option } = Select;

const DisbursementButton = ({ data }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const { refetch } = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSuccess, setIsModalVisibleSuccess] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const disburseMutation = useMutation((disburseBody) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/disburse?channel=${disburseBody.channel}&note=${disburseBody.note}`,
      "PUT",
      session.session.token,
      disburseBody
    )
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalSuccess = () => {
    setIsModalVisibleSuccess(true);
  }

  const handleCancelSuccess = () => {
    setIsModalVisibleSuccess(false)
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values) => {
    const query = {
      channel: values.channel,
      note: values.note,
    };

    setProcessing(true);
    disburseMutation.mutate(query, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
        showModalSuccess();
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={showModal}
        loading={isProcessing}
        disabled={data?.disbursed}
      >
        {!data?.disbursed ? "Disburse" : "Disbursed"}
      </Button>
      <LoanDisbursed 
        data={data} 
        isModalVisible={isModalVisibleSuccess}
        handleCancel={handleCancelSuccess}
      />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={false}
        width="30%"
      >
        <Title level={4}>You are about to disburse this loan</Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                message: "Note is required!",
              },
            ]}
          >
            <TextArea className="w-full" type="text" width={100} />
          </Form.Item>
          <Form.Item
            label="Channel"
            name="channel"
            rules={[
              {
                required: true,
                message: "Channel is required!",
              },
            ]}
          >
            <Select size="large" className="w-full">
              <Option value="manual" key="manual">
                Manual
              </Option>
              <Option value="disbursement-wallet" key="disbursement-wallet">
                Disbursement Wallet
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              className="w-full"
              htmlType="submit"
              type="primary"
              loading={isProcessing}
            >
              Disburse
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DisbursementButton;
