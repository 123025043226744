import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import Requests from "../../../../utils/request";
import BorrowersLoansTable from "../../../Table/Borrowers/BorrowersLoans";
import { ADDALLBORROWERSLOAN } from "../../../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";
import LoansTableHeader from "./loansHeader";
import { useAuth } from "../../../../context/AuthContext";
 
const Loan = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { searchValue, status } = useSelector(
    (state) => state.borrowersLoansTableReducer
  );

  const { data: loans, isLoading} = useQuery(
    'borrowers_loans',
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/${id}/data?data=loan`,
        "GET",
        session.session.token
      )
    )

    const SearchQuery = (data) => {
      const firstQuery = data?.filter((loan) => {
        const name = `${loan?.borrower?.firstname} ${loan?.borrower?.lastname}`;
        if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
          return loan;
        }
        return null;
      });
  
      return firstQuery?.filter((data) => {
        if (status) {
          return data.status === status;
        }
  
        return true;
      });
    };
  
    useEffect(() => {
      if (loans?.status === "success") {
        dispatch({
          type: ADDALLBORROWERSLOAN,
          value: SearchQuery(loans?.data),
        });
      }
      // eslint-disable-next-line
    }, [isLoading, status]);
  
    useEffect(() => {
      dispatch({
        type: ADDALLBORROWERSLOAN,
        value: SearchQuery(loans?.data),
      });
  
      // eslint-disable-next-line
    }, [searchValue]);

  return (
    <>
      <LoansTableHeader />
      <BorrowersLoansTable isLoading={isLoading} />
    </>
  )
}

export default Loan;