import { INCREMENT } from "../Actions/types";

const initialState = { count: 0 };

export const loanReducer = (state = initialState, actions) => {
  if (actions.type === INCREMENT) {
    return { count: state.count + 1 };
  }
  return state;
};
