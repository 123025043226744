import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Select, InputNumber } from "antd";
import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import { useQuery, useMutation } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Request from "../../utils/request";
import { Title } from "../Typography";
import {
  useCurrenncyFormatter,
  currencyParser,
} from "../../utils/currencyFormatter";

function WalletTopup({ toggle }) {
  const { Option } = Select;
  const [wallet, setWallet] = useState("");
  const [amount, setAmount] = useState();
  const { session } = useAuth();
  const email = session.user.email;
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const { currencyFormatter } = useCurrenncyFormatter();
  const style = {
    border: "1px solid rgba(149, 149, 172, 0.16)",
    boxShadow: "0px 0.971248px 3.88499px rgba(21, 34, 50, 0.08)",
    borderRadius: "11.4894px",
    padding: "30px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "30vw",
  };

  const popStyle = {
    background: "rgba(0, 0, 0, 0.7)",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0",
    left: "0",
    overflow: "hidden",
    zIndex: "99",
  };

  const { data, refetch } = useQuery(
    "wallet",
    () =>
      Request.fetchWithAuthToken("/lms/wallet", "GET", session.session.token),
    { refetchOnWindowFocus: false }
  );

  const topupMutation = useMutation((body) =>
    Request.fetchWithAuthToken(
      `/lms/wallet?walletType=${wallet}`,
      "PUT",
      session.session.token,
      body
    )
  );

  const handleTopUp = (key) => {
    topupMutation.mutate(
      { reference: key },
      {
        onSuccess: () => {
          refetch(data);
        },
      }
    );
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    text: "Add to Wallet",
    onSuccess: (body) => {
      handleTopUp(body.reference);
    },
  };

  const handleClick = () => {
    toggle();
  };

  const handleAmount = (e) => {
    const amountInKobo = e * 100;
    setAmount(amountInKobo);
  };
  return (
    <div style={popStyle}>
      <div style={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <CloseCircleOutlined />
        </div>
        <div style={{ textAlign: "center" }}>
          <Title
            level={3}
            style={{
              marginBottom: "30px",
              marginTop: "20px",
              display: "inline-block",
            }}
          >
            Top Up
          </Title>
        </div>
        <Form
          labelCol={{
            span: 5,
          }}
        >
          <Form.Item label="Wallet">
            <Select showSearch onChange={(e) => setWallet(e)} value={wallet}>
              <Option value="disbursement">Disbursement Wallet</Option>
              <Option value="services">Services Wallet</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Amount">
            <InputNumber
              style={{ width: "100%" }}
              formatter={currencyFormatter}
              parser={currencyParser}
              onChange={(e) => handleAmount(e)}
              placeholder="NGN"
            ></InputNumber>
          </Form.Item>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Button type="primary" size="large" onClick={handleClick}>
              <PaystackButton {...componentProps} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default WalletTopup;
