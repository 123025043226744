import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { useQuery } from "react-query";
import { Card, Col, Input, Row, Form, Button, message } from "antd";
import { Title } from "../../../Typography";
import useLoanApplication from "../../../../hooks/useLoanApplication";

const gurantorform = React.createRef();
function Gurantor({ id, setCurrentNumber, currentNumber, status }) {
  const { session } = useAuth();
  const [loading, setIsLoading] = useState(false);
  const { submitGurantor, updateProgress } = useLoanApplication();

  const { data } = useQuery(
    "guarantor",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=guarantors`,
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      setCurrentNumber((prev) => {
        const current = Number(prev) + 1;
        return current.toString();
      });
      updateProgress(
        { progress: String(Number(currentNumber) + 1), loan_requestID: id },
        (data) => data
      );

      return;
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);
    const userData = {
      loan_requestID: id,
      gurantor: {
        guarantor: {
          firstname: value.firstname,
          lastname: value.lastname,
          email: value.email,
          phone: value.phone,
          relationship: "",
          bvn: "",
        },
        verify: false,
      },
    };
    submitGurantor(userData, handleSubmitCallback);
  };

  const generateFormObject = (fields, profile) => {
    const formObject = {};
    fields.forEach((field) => {
      formObject[field] = profile[field];
    });

    return formObject;
  };

  useEffect(() => {
    if (data?.data) {
      const fields = Object.keys(data?.data[0] || {});
      const profile = data?.data[0];
      const formObj = generateFormObject(fields, profile);

      gurantorform?.current?.setFieldsValue({
        ...formObj,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="my-10 lg:mx-60">
      <Card>
        <Title level={3}>Guarantor</Title>
        <Form ref={gurantorform} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            {/* <Col xs={24} md={12}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Select size="large">
                  <Option value="female">Female</Option>
                  <Option value="Male">Male</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Relationship"
                name="relationship"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col> */}
          </Row>

          {status !== "submitted" && (
            <Button
              className="lg:w-auto w-full"
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save and Continue
            </Button>
          )}
        </Form>
      </Card>
    </div>
  );
}

export default Gurantor;
