import React from "react";
import { Table, Button, Tag } from "antd";
import timeDate from "../../../utils/TimeDate";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function RepaymentTable() {
  const history = useHistory();
  const { singleRepayments } = useSelector(
    (state) => state.singleRepaymentTableReducer
  );
  const { profile } = useSelector((state) => state.profileReducer );
  const { currencyFormatter } = useCurrenncyFormatter();

  const columns = [
    {
      title: "Transaction Reference",
      dataIndex: "reference",
      key: "reference",
      render: (_, record) => (
        <div>
          <span>{record.paystack_ref || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Borrower",
      dataIndex: "firstname",
      key: "name",
      render: (_, record) => (
        <div>
          <span>{record?.borrower?.firstname || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.amount)}</span>
        </div>
      ),
    },
    {
      title: "Disbursed Date",
      dataIndex: "disbursed_date",
      key: "disburde_data",
      render: (_, record) => (
        <div>
          <span>{`${timeDate(record.created_at, profile.date_format)}`}</span>
        </div>
      ),
    },

    {
      title: "Repayment Date",
      dataIndex: "repayment_date",
      key: "repayment_date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.repayment_date).valueOf() -
        new Date(b.repayment_date).valueOf(),
      render: (_, record) => (
        <div>
          <span>{`${timeDate(record.due_on, profile.date_format)}`}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let color = record.status === "pending" ? "volcano" : "success";
        let text = record.status;

        return (
          <Tag color={color} key={record.status}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div>
          <Button
            onClick={() => {
              history.push(`/repayments/${record.id}`);
            }}
          >
            Details
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={singleRepayments}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default RepaymentTable;
