import { Input, InputNumber, Select, DatePicker } from "antd";
import Upload from "../components/Uploader";
import {
  SETBANKCODE,
  SETSTATE,
  USERSELECTCOUNTRY,
} from "../redux/Types/generalTypes";
import countriesandstates from "./countriesandstates";
import { LGA } from "./LGA";
import { currencyParser, useCurrenncyFormatter } from "./currencyFormatter";
const { Option } = Select;
export const useGenerateComponent = () => {
  const { currencyFormatter } = useCurrenncyFormatter();

  const generateComponent = (field, formRef, dispatch, props, disableMoney) => {
    if (field.is_dropdown) {
      const fieldName = field.field;
      switch (fieldName) {
        case "citizenship":
          return (
            <Select
              showSearch
              size="large"
              onChange={(val) =>
                dispatch({ type: USERSELECTCOUNTRY, value: val })
              }
            >
              {countriesandstates.map((country) => (
                <Option
                  value={country.name}
                  key={country.iso2}
                >{`${country.emoji}${country.name}`}</Option>
              ))}
            </Select>
          );
        case "state_of_primary_operations":
          return (
            <Select
              size="large"
              showSearch
              onChange={(val) => dispatch({ type: SETSTATE, value: val })}
            >
              {LGA.map((state) => (
                <Option value={state.state} key={state.state}>
                  {state.state}
                </Option>
              ))}
            </Select>
          );

        case "local_goverment":
          return (
            <Select size="large" showSearch>
              {LGA.find((state) => state.state === props?.userState)?.lgas.map(
                (lga) => (
                  <Option value={lga} key={lga}>
                    {lga}
                  </Option>
                )
              )}
            </Select>
          );

        case "state_of_residence":
          return (
            <Select size="large" showSearch>
              {countriesandstates
                .find((country) => country.name === props?.userCountry)
                ?.states.map((state) => (
                  <Option value={state.name} key={state.id}>
                    {state.name}
                  </Option>
                ))}
            </Select>
          );
        default:
          return (
            <Select size="large" showSearch>
              {field?.items?.map((item) => (
                <Option value={item.slug} key={item.slug}>
                  {item.title}
                </Option>
              ))}
            </Select>
          );
      }
    }

    if (field.is_date) {
      return <DatePicker size="large" style={{ width: "100%" }} />;
    }

    if (field.field === "invoice_number") {
      return <Input size="large" disabled />;
    }

    if (field.is_money) {
      return (
        <InputNumber
          disabled={disableMoney}
          size="large"
          style={{ width: "100%" }}
          formatter={currencyFormatter}
          parser={currencyParser}
        />
      );
    }
    if (field.is_upload) {
      return <Upload formRef={formRef} />;
    }

    if (field.data_type === "boolean") {
      return (
        <Select size="large" showSearch>
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      );
    }

    if (field.field === "purpose") {
      return <Input.TextArea />;
    }

    if (field.field === "bank_name") {
      return (
        <Select
          size="large"
          onChange={(val) => dispatch({ type: SETBANKCODE, value: val })}
        >
          {props?.banks?.map((bank) => (
            <Option value={bank.code}>{bank.name}</Option>
          ))}
        </Select>
      );
    }

    if (field.field === "account_number") {
      return <Input onChange={props.handlSearch} size="large" />;
    }

    if (field.field === "dob") {
      return <DatePicker size="large" style={{ width: "100%" }} />;
    }

    if (!field.is_dropdown && !field.is_upload) {
      return field.data_type === "string" ? (
        <Input size="large" />
      ) : (
        <InputNumber size="large" style={{ width: "100%" }} />
      );
    }
  };

  return { generateComponent };
};

export default useGenerateComponent;
