import React, { useState } from "react";
import { Form, Input, Select, Row, Col, Button } from "antd";
import { CheckOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import Privileges from "./Privileges";

const { Option } = Select;

function AddTeamMember({
  formName,
  dispatch,
  state,
  customRole,
  defaultRole,
  orgConfig,
  deleteForm,
  currentNumber,
  isStaff,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <Form.Item label="First Name">
            <Input
              placeholder="Enter first name"
              onChange={(e) =>
                dispatch({
                  type: "invitee",
                  formName,
                  field: "firstname",
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last Name">
            <Input
              placeholder="Enter last name"
              onChange={(e) =>
                dispatch({
                  type: "invitee",
                  formName,
                  field: "lastname",
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Work Email Address">
            <Input
              placeholder="Enter email"
              onChange={(e) =>
                dispatch({
                  type: "invitee",
                  formName,
                  field: "email",
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Give this user appropriate privileges">
            <Button
              type="dash"
              onClick={showModal}
              icon={<PlusOutlined />}
              style={{ width: "100%" }}
            >
              Add Privileges
            </Button>
          </Form.Item>
        </Col>

        {!isStaff && (
          <Col span={12}>
            <Form.Item label="Select Role">
              {orgConfig === "custom" && (
                <Select placeholder="Loan analyst">
                  {customRole?.data?.map((role) => (
                    <Option value={role.name} key={role.slug}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              )}

              {orgConfig === "default" && (
                <Select placeholder="Loan analyst">
                  {defaultRole?.data?.map((role) => (
                    <Option value={role.name} key={role.slug}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "5px",
              }}
            >
              <ul>
                <li>
                  <span style={{ color: "#39B54A" }}>
                    <CheckOutlined />
                  </span>{" "}
                  Manage Loan Accounts
                </li>
                <li>
                  <span style={{ color: "#39B54A" }}>
                    <CheckOutlined />
                  </span>
                  Review Loans
                </li>
                <li>
                  <span style={{ color: "#39B54A" }}>
                    <CheckOutlined />
                  </span>
                  Export Data
                </li>
                <li>
                  <span style={{ color: "#39B54A" }}>
                    <CheckOutlined />
                  </span>
                  Send user reminders
                </li>
              </ul>
            </div>
          </Col>
        )}
      </Row>

      <Privileges
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        dispatch={dispatch}
        state={state}
        formName={formName}
      />
      {formName !== "team1" && (
        <Button
          style={{
            color: "#5152A6",
            fontSize: "12px",
          }}
          icon={<DeleteOutlined />}
          type="link"
          onClick={() => deleteForm(currentNumber, formName)}
        >
          Cancel
        </Button>
      )}
    </>
  );
}

export default AddTeamMember;
