import { SETLOANAPPLICATIONMETRICSENDDATE, SETLOANAPPLICATIONMETRICSSTARTDATE } from "../Types/generalTypes";
import moment from "moment";

const initialState = { 
  loansEndDate: moment().endOf("month"),
  loansStartDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const loanApplicationMetricsReducer = (state = initialState, actions) => {
  if (actions.type === SETLOANAPPLICATIONMETRICSENDDATE) {
    return { ...state, loansEndDate: actions.value };
  }

  if (actions.type === SETLOANAPPLICATIONMETRICSSTARTDATE) {
    return { ...state, loansStartDate: actions.value };
  }

  return state;
}

export default loanApplicationMetricsReducer;