const schema = {
  invitee: {
    email: "",
    firstname: "",
    lastname: "",
  },
  cat_one_prev: {
    super: false,
    get_started: false,
    overview: false,
    customers: false,
    loans: false,
    disbursements: false,
    loan_requests: false,
    guarantors: false,
    blacklist: false,
    wallet: false,
    branches: false,
    products: false,
    transactions: false,
    settlements: false,
    insights_and_reports: false,
    approvals: false,
    audit_logs: false,
    subscription: false,
    settings: false,
    repayments: false,
    borrowers: false,
    activity_log: false,
    notifications: false,
    notes: false,
  },
  cat_two_prev: {
    super: false,
    create_product: false,
    edit_product: false,
    disburse: false,
    approve_loan_request: false,
  },
};

export default schema;
