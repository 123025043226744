import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Request from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { Card, Row } from "antd";
import totalLoan from "../../assets/svg/TotalLoan.svg";
import approved from "../../assets/svg/Approved.svg";
import pending from "../../assets/svg/pendingRequest.svg";
import TableHeader from "../../components/LoanProducts/TableHeader";
import AllProductTable from "../../components/Table/Product/AllProduct";
import { ADDALLLOANPRODUCT } from "../../redux/Types/tableTypes";
import ProductCard from "../../components/LoanProducts/Cards";
import { Title } from "../../components/Typography";
function Products() {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue } = useSelector((state) => state.loanProductTableReducer);
  const { metricsStartDate, metricsEndDate } = useSelector(
    (state) => state.repaymentsMetricsReducer
  );

  const {
    data: products,
    refetch,
    isLoading,
  } = useQuery("products-slug", () =>
    Request.fetchWithAuthToken("/lms/product", "GET", session.session.token)
  );

  const { data: products_metrics } = useQuery(
    `products-metrics-start=${metricsStartDate}&end=${metricsEndDate}`,
    () =>
      Request.fetchWithAuthToken(
        `/lms/product/metrics`,
        "GET",
        session.session.token
      )
  );

  const BigQuery = (data) => {
    return data?.filter((gurantor) => {
      if (gurantor?.name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return gurantor;
      }
      return null;
    });
  };

  useEffect(() => {
    if (products?.status === "success") {
      dispatch({
        type: ADDALLLOANPRODUCT,
        value: BigQuery(products?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading, products]);

  useEffect(() => {
    dispatch({
      type: ADDALLLOANPRODUCT,
      value: BigQuery(products?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <Title level={2}>Products</Title>
      <Row gutter={[16, 16]}>
        <ProductCard
          heading="Total Product"
          amount={products_metrics?.data?.total_loan_product || "0"}
          icon={totalLoan}
          textColor="#3C4066"
          name="totlaproducts"
        />
        <ProductCard
          heading="Active Products"
          amount={products_metrics?.data?.active_product || "0"}
          icon={approved}
          textColor="#3C4066"
          name="activeproducts"
        />
        <ProductCard
          heading="Inactive Products"
          amount={products_metrics?.data?.inactive_product || "0"}
          icon={pending}
          textColor="#3C4066"
          name="inactiveproducts"
        />
      </Row>
      <div className="mt-10">
        <Card>
          <TableHeader refetch={refetch} />
          <AllProductTable isLoading={isLoading} />
        </Card>
      </div>
    </>
  );
}

export default Products;
