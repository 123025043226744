import React from "react";
import BlacklistPage from "../../components/Blacklist/BlacklistPage";
import { Title } from "../../components/Typography";

function Blacklist() {
  return (
    <div>
      <Title level={2}>Blacklist</Title>
      <BlacklistPage />
    </div>
  );
}

export default Blacklist;
