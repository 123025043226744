import { Card } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADDDELINQUENCY } from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import moment from "moment";
import { useQuery } from "react-query";
import {
  SETDELINQUENCYENDDATE,
  SETDELINQUENCYSTARTDATE,
  SETDELINQENCYLOANOFFICER,
} from "../../redux/Types/tableTypes";
import ReportTableHeader from "./ReportTableHeader";
import DelinquencyTable from "../Table/Report/DelinquencyTable";

function DelinquencyReport() {
	const dateFormat = "YYYY-MM-DD";
	const dispatch = useDispatch();
	const { session } = useAuth();
	const { endDate, startDate, officerId } = useSelector(
		(state) => state.delinquencyTableReducer
	);

    const { data: delinquencyReport, isLoading } = useQuery(
        `delinquency-report-start=${moment(startDate).format(dateFormat)}&end=${moment(
          endDate
        ).format(dateFormat)}-officer=${officerId}`,
        () =>
          Requests.fetchWithAuthToken(
            `/lms/report?loan-officer-id=${officerId}&report-type=delinquency-report&start=${moment(startDate).format(
              dateFormat
            )}&end=${moment(endDate).format(dateFormat)}`,
            "GET",
            session.session.token
          )
      );
      useEffect(() => {
        dispatch({
          type: ADDDELINQUENCY,
          value: delinquencyReport?.data,
        });
        // eslint-disable-next-line
      }, [delinquencyReport]);
	return (
		<div>
			<Card style={{ marginTop: "30px" }}>
                <ReportTableHeader startDate={startDate} endDate={endDate} setStart={SETDELINQUENCYSTARTDATE} setEnd={SETDELINQUENCYENDDATE} officer={SETDELINQENCYLOANOFFICER} title="Delinquency Report"/>
                <DelinquencyTable isLoading={isLoading} />
			</Card>
		</div>
	);
}

export default DelinquencyReport;
