import React, { useState } from "react";
import { Select } from "antd";
import LoanListing from "./LoanListing";
import DelinquencyReport from "./DelinquencyReport";
import ParReport from "./ParReport";

function ReportFilter() {
	const options = [
		{ label: "Loan Listing", value: "loan-listing" },
		{ label: "Delinquency Report", value: "delinquency-report" },
		{ label: "PAR Report", value: "par-report" },
	];
	const [reportType, setType] = useState(["loan-listing"]);

	function handleChange(value) {
		setType(value);
	}

	return (
		<div className="mb-5">
			<h3 style={{ fontSize: "24px", fontWeight: "bold" }}>Reports</h3>
			<Select
				mode="multiple"
				placeholder="Please select Report Type"
				value={reportType}
				onChange={handleChange}
				style={{ width: "50%" }}
				options={options}
			/>
			{reportType.includes("loan-listing") && <LoanListing />}
			{reportType.includes("par-report") && <ParReport />}
			{reportType.includes("delinquency-report") && <DelinquencyReport />}
		</div>
	);
}

export default ReportFilter;
