import React from "react";
import { Col } from "antd";
import { Text } from "../../Typography";

import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryGroup,
} from "victory";

function LoanPerformance(props) {
  const data1 = [{ x: 0, y: 0 }];

  const data2 = [{ x: 0, y: 0 }];

  const style = {
    background: "#ffffff",
    padding: "20px",
    borderRadius: "12px",
    height: "60vh",
  };

  return (
    <Col span={16}>
      <div style={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              width: "45%",
            }}
          >
            <Text
              style={{ fontSize: "16px", fontWeight: "bold", color: "#2E2C34" }}
            >
              Loan Performance
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              width: "40%",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "10px",
                width: "10px",
                backgroundColor: "#5152A6",
                borderRadius: "50%",
              }}
            ></span>
            <Text>Loan Disbursed</Text>
            <span
              style={{
                display: "inline-block",
                height: "10px",
                width: "10px",
                backgroundColor: "#11C096",
                borderRadius: "50%",
              }}
            ></span>
            <Text>Repaid Loans</Text>
          </div>
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VictoryChart
            width={550}
            height={300}
            domain={{ x: [1, 12], y: [1, 7] }}
            theme={VictoryTheme.material}
          >
            <VictoryAxis
              tickFormat={(x) => `${x}`}
              tickValues={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              style={{
                axis: { stroke: "none" },
                tickLabels: { fontSize: "10px", color: "#757580" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y) => `${y}`}
              tickValues={[
                "#0M",
                "#100M",
                "#120M",
                "#140M",
                "#160M",
                "#180M",
                "#200M",
              ]}
              style={{
                axis: { stroke: "none" },
                ticks: { stroke: "transparent" },
                tickLabels: { fontSize: "10px", color: "#757580" },
              }}
            />
            <VictoryGroup offset={20}>
              <VictoryBar
                data={data1}
                style={{ data: { width: 10, fill: "#5152A6" } }}
              />

              <VictoryBar
                data={data2}
                domainPadding={20}
                style={{ data: { width: 10, fill: "#11C096" } }}
              />
            </VictoryGroup>
          </VictoryChart>
        </div>
      </div>
    </Col>
  );
}

export default LoanPerformance;
