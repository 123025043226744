import React from "react";
import moment from "moment";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import { Button } from "antd";

function PartialCard({ data, index }) {
	const { currencyFormatter } = useCurrenncyFormatter();
	return (
		<div style={{marginBottom:"20px"}}>
			<div
				style={{
					background: data?.paid ? "#c4f8e2" : "red",
					borderRadius: "25%",
                    color: data?.paid ? "#06a561" : "#fff",
                    width:"25px",
                    textAlign:'center',
                    margin:'0 auto'
				}}
			>{index + 1}</div>
			<div className="my-3 flex justify-between border-b-2">
				<h2 className="text-gray-500">Paid on: </h2>
				<h2 className="text-gray-500">
					{moment(data?.repaid_on.split(" ")[0]).format(
						"dddd, MMMM Do YYYY, h:mm a"
					)}
				</h2>
			</div>
			<div className="flex justify-between border-b-2">
				<h2 className="text-gray-500">Transaction ID:</h2>
				<h4 className="text-gray-500">{data?.id}</h4>
			</div>
			<div className="flex justify-between mt-2 border-b-2">
				<h2 className="font-bold text-gray-500">Amount:</h2>
				<h4 className="font-bold text-blue-700 text-lg">
					{currencyFormatter(data?.amount)}
				</h4>
			</div>
			<div className="flex justify-between mt-2">
				<h2 className="font-bold text-gray-500">Status:</h2>
					 <Button
                      type="default"
                      style={{
                        backgroundColor: data?.paid
                          ? "#c4f8e2"
                          : "red",
                        color: data?.paid ? "#06a561" : "white",
                      }}
                    >
                      {data?.status}
                    </Button>
				
			</div>
		</div>
	);
}

export default PartialCard;
