const BASE_URL = process.env.REACT_APP_BASE_URL;
const Requests = {};

Requests.fetchWithAuthToken = async (url, method, token, data) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    body: JSON.stringify(data),
  });
  return response.json();
};

Requests.fetchWithAuthoutBaseURL = async (url, method, token, data) => {
  const response = await fetch(`${url}`, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    body: JSON.stringify(data),
  });
  return response.json();
};

Requests.fetchWithOutAuthToken = async function (url, method, data) {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(data),
  });

  if (response.ok) {
    return response.json();
  }

  return { status: response.status, message: response.statusText };
};

export default Requests;
