import React, { useEffect } from "react";
import Overview from "../../components/Loan/cards/OverviewCard";
import Repayment from "../../components/Loan/cards/Repayment";
import RepaymentTable from "../../components/Table/Repayment/RepaymentTable";
import { SingleRepayment } from "../../components/Loan/Header";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { ADDSINGLEREPAYMENTS } from "../../redux/Types/tableTypes";
import { useParams } from "react-router-dom";
import sortArray from "../../utils/Sort";
import ScrollToTop from "../../components/Layout/ScrollRestore";

function SingleLoans(props) {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  const { id } = useParams();
  const dispatch = useDispatch();
  const { session } = useAuth();
  const { searchValue, status } = useSelector(
    (state) => state.singleRepaymentTableReducer
  );
  // const dateFormat = "YYYY-MM-DD";

  const { data: singleRepayment, isLoading } = useQuery(
    `singleRepayment-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan/repayment?section=repayments&loan-id=${id}`,
        "GET",
        session.session.token
      )
  );

  const { data: singleRepaymentMetrics } = useQuery(
    `singleRepaymentMetrics`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan/repayment?section=metrics&loan-id=${id}`,
        "GET",
        session.session.token
      )
  );

  const repayments = singleRepayment?.data && sortArray(singleRepayment?.data);
  const repaymentDate = singleRepayment?.data && repayments[0]?.due_on;
  const repaymentAmount = singleRepayment?.data && repayments[0]?.amount;

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((singleRepayments) => {
      const name = `${singleRepayments?.borrower}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return singleRepayments;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status === status;
      }
      return true;
    });
  };

  useEffect(() => {
    if (singleRepayment?.status === "success") {
      dispatch({
        type: ADDSINGLEREPAYMENTS,
        value: SearchQuery(singleRepayment?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: ADDSINGLEREPAYMENTS,
      value: SearchQuery(singleRepayment?.data),
    });

    // eslint-disable-next-line
  }, [searchValue, status]);

  return (
    <div>
      <ScrollToTop />
      <Overview />
      <Repayment
        data={singleRepaymentMetrics?.data}
        repaymentDate={repaymentDate}
        repaymentAmount={repaymentAmount}
      />
      <div style={style}>
        <div>
          <SingleRepayment />
          <div style={{ marginTop: "30px" }}>
            <RepaymentTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleLoans;
