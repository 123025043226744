import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, Input, Select, Drawer } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";
import { banks } from "../../../../../utils/listOfBanks";

const { Option } = Select;

const AddBankDetails = ({ refetchBankDetails, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const bankDetailsRef = React.createRef();

  const mutationBankDetails = useMutation((bank_details) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=bank-account`,
      "POST",
      session.session.token,
      bank_details
    )
  );

  useEffect(() => {
    bankDetailsRef?.current?.setFieldsValue({
      account_number: data?.account_number,
      account_name: data?.account_name,
      bank_name: data?.bank_name,
    });
  }, [bankDetailsRef, data]);

  const handleSubmit = (values) => {
    const info = {
      code : banks?.find((bank) => bank.name === values?.bank_name).code,
      account_number: values?.account_number,
      account_name: values?.account_name,
      bank_name: values?.bank_name,
    }
    setProcessing(true);
    mutationBankDetails.mutate(info, {
      onSuccess: (data) => {
        refetchBankDetails();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Bank Details
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Bank Details</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={bankDetailsRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Account Name"
                name="account_name"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Account Number"
                name="account_number"
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                label="Bank Name"
                name="bank_name"
              >
                <Select>
                  {banks?.map((bank) => 
                    <Option key={bank?.id} value={bank.name} >{bank.name}</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddBankDetails;