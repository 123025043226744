import React, { useEffect, useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Input, Modal, message } from "antd";
import { useMutation } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import { CommaStringsToArrayofStrings } from "../../../utils/commaStringsToArray";
import Requests from "../../../utils/request";
function AddLoanPuropses({ product_id, dataPurpose, refetch }) {
  const [openModal, setOpenModal] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [isProcessing, setProcessing] = useState(false);
  const { session } = useAuth();

  //Mutation to add new purpose
  const addNewPurposeMutation = useMutation((purpose) =>
    Requests.fetchWithAuthToken(
      `/lms/product/loan_purpose`,
      "POST",
      session?.session?.token,
      purpose
    )
  );

  //Mutation to update purpose
  const updateNewPurposeMutation = useMutation((purpose) =>
    Requests.fetchWithAuthToken(
      `/lms/product/${product_id}/loan_purpose`,
      "PUT",
      session?.session?.token,
      purpose
    )
  );

  const callBack = (data) => {
    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    setProcessing(false);
    refetch();
    message.success(data.message, 3);
  };

  const handleAddNewPurpose = () => {
    const purposePayload = {
      product_id: Number(product_id),
      org_id: session?.admin.id,
      purposes: CommaStringsToArrayofStrings(purpose),
    };
    setProcessing(true);
    addNewPurposeMutation.mutate(purposePayload, {
      onSuccess: (data) => callBack(data),
      onError: (e) => callBack({ status: "error", message: e.message }),
    });
  };

  const handleUpdatePurpose = () => {
    const purposePayload = {
      purposes: CommaStringsToArrayofStrings(purpose),
    };
    setProcessing(true);
    updateNewPurposeMutation.mutate(purposePayload, {
      onSuccess: (data) => callBack(data),
      onError: (e) => callBack({ status: "error", message: e.message }),
    });
  };

  useEffect(() => {
    setPurpose(dataPurpose ? dataPurpose.join() : "");
  }, [dataPurpose]);
  return (
    <div>
      <PlusCircleFilled
        onClick={() => setOpenModal(true)}
        className="text-lg"
      />
      <Modal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="mt-12 flex flex-col">
          <label>separate each purpose with a comma</label>
          <Input.TextArea
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />

          {!dataPurpose && (
            <Button
              type="primary"
              size="large"
              className="my-5  self-end"
              loading={isProcessing}
              onClick={handleAddNewPurpose}
            >
              Save
            </Button>
          )}

          {dataPurpose && (
            <Button
              type="primary"
              className="my-5  self-end"
              size="large"
              loading={isProcessing}
              onClick={handleUpdatePurpose}
            >
              update
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddLoanPuropses;
