import React, { useEffect, useState } from "react";
import { ArrowRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Switch,
  Modal,
  Menu,
  Row,
  Col,
  Divider,
  Avatar,
  Tooltip,
  Button,
} from "antd";

function Pop({
  toggle,
  visible,
  loading,
  dispatch,
  template,
  onClick,
  loanType,
}) {
  const [current, setCurrent] = useState();

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    const currentType =
      loanType === "credit-order" ? "Credit Order" : "Loan Request";

    setCurrent(currentType);
  }, [loanType]);

  return (
    <Modal
      visible={visible}
      onCancel={toggle}
      maskStyle={{ background: "#28284D" }}
      width="90%"
      footer={null}
    >
      <div className="flex justify-between mt-6">
        <h2 className="text-black font-bold text-md">
          The criteria you setup here will determine the details that appears on
          your customer's application form
        </h2>
        <Button loading={loading} onClick={onClick} type="primary" size="large">
          Create Product
        </Button>
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        overflowedIndicator={
          <span>
            More <ArrowRightOutlined />
          </span>
        }
      >
        {Object.keys(template).map((field, index) => (
          <Menu.Item key={field}>{field}</Menu.Item>
        ))}
      </Menu>

      <section className="mt-10">
        {template[current]?.fields?.map((field, index) => (
          <Toggler
            key={index}
            fieldName={field.label}
            is_needed={field.is_needed}
            is_required={field.is_required}
            index={index}
            dispatch={dispatch}
            segment={current}
          />
        ))}
      </section>
    </Modal>
  );
}

export const Toggler = ({
  fieldName,
  is_required,
  is_needed,
  index,
  dispatch,
  segment,
  isCustom,
}) => (
  <div>
    <Row>
      <Col span={12}>
        <div className="flex space-x-4">
          <Avatar size="small">{index + 1}</Avatar>
          <span className="text-md font-bold text-black">{fieldName}</span>
        </div>
      </Col>
      <Col span={6}>
        <div className="flex space-x-4">
          <span className="text-md font-bold text-black">
            Is Needed
            <Tooltip title="Do you want to collect this information ?">
              <QuestionCircleOutlined className="mx-1" />
            </Tooltip>
          </span>
          <Switch
            checked={is_needed}
            onClick={(val) =>
              dispatch({
                type: isCustom ? "switch_custom" : "switch",
                segment,
                fieldName,
                key: "is_needed",
                value: val,
              })
            }
          />
        </div>
      </Col>
      <Col span={6}>
        <div className="flex space-x-4">
          <span className="text-md font-bold text-black">
            Is Required
            <Tooltip title="Must the user fill this field ?">
              <QuestionCircleOutlined className="mx-1" />
            </Tooltip>
          </span>
          <Switch
            checked={is_required}
            onClick={(val) =>
              dispatch({
                type: isCustom ? "switch_custom" : "switch",
                segment,
                fieldName,
                key: "is_required",
                value: val,
              })
            }
          />
        </div>
      </Col>
    </Row>
    <Divider />
  </div>
);

export default Pop;
