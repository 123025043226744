const access_features = [
  {
    name: "Get Started",
    slug: "get_started",
  },
  {
    name: "Overview",
    slug: "overview",
  },
  {
    name: "Customers",
    slug: "customers",
  },
  {
    name: "Branches",
    slug: "branches",
  },
  {
    name: "Products",
    slug: "products",
  },
  {
    name: "Loans",
    slug: "loans",
  },
  {
    name: "Disbusrsements",
    slug: "disbursements",
  },
  {
    name: "Loan Requests",
    slug: "loan_requests",
  },
  {
    name: "Guarantors",
    slug: "guarantors",
  },
  {
    name: "Wallet",
    slug: "wallet",
  },
  {
    name: "Transactions",
    slug: "transactions",
  },
  {
    name: "Approvals",
    slug: "approvals",
  },
  {
    name: "Settings",
    slug: "settings",
  },
  {
    name: "Settlements",
    slug: "settlements",
  },
  {
    name: "Blacklist",
    slug: "blacklist",
  },
  {
    name: "Insights and Reports",
    slug: "insights_and_reports",
  },
  {
    name: "Audit Logs",
    slug: "audit_logs",
  },
  {
    name: "Subscription",
    slug: "subscription",
  },

  {
    name: "Repayments",
    slug: "repayments",
  },

  {
    name: "Borrowers",
    slug: "borrowers",
  },

  {
    name: "Activity logs",
    slug: "activity_log",
  },

  {
    name: "Notifications",
    slug: "notifications",
  },

  {
    name: "Notes",
    slug: "notes",
  },
];

export default access_features;
