import React, { useState } from "react";
import {
	CloseOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { DatePicker, Button, Select } from "antd";
import { useDispatch } from "react-redux";
import styles from "./filter.module.css";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useQuery } from "react-query";

const { RangePicker } = DatePicker;
const { Option } = Select;

function ReportTableHeader({ startDate, endDate, setStart, setEnd, title, officer }) {
	const { session } = useAuth();
	const dateFormat = "YYYY-MM-DD";
	const dispatch = useDispatch();
	const filters = ["Date", "Loan Officer"];
	const [tab, setTab] = useState(0);
	const [value, setValue] = useState([]);
	const [show, setShow] = useState(false);

	const handleRemoveItem = (e) => {
		setValue(value.filter((item) => item !== e));
    handleOfficer('');
	};

	const startDateFilter = (e) => {
		dispatch({ type: setStart, value: e.startDate });
	};

	const endDateFilter = (e) => {
		dispatch({ type: setEnd, value: e.endDate });
	};

  const handleOfficer = (id) => {
    dispatch({ type: officer, value: id})
  }
	const { data: loanOfficers } = useQuery(`loan-officers`, () =>
		Requests.fetchWithAuthToken(
			`/lms/report?report-type=loan-officer`,
			"GET",
			session.session.token
		)
	);
	return (
		<div>
			<h3
				style={{
					fontSize: "24px",
					fontWeight: "bold",
					marginBottom: "10px",
				}}
			>
				{title}
			</h3>
			<p style={{ fontSize: "15px", marginBottom: "30px", marginTop: "0" }}>
				Manage all your reports on Configure
			</p>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					position: "relative",
				}}
			>
				<Button type="primary" onClick={() => setShow(!show)} >
					{show ? <MinusOutlined /> : <PlusOutlined />}
					Add Filter
				</Button>
				<div style={{ marginLeft: "30px" }}>
					{value.length === 0 ? (
						"No filter added"
					) : (
						<ul
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "0",
								fontSize: "16px",
								cursor: "pointer",
							}}
						>
							{value.map((item, index) => (
								<li
									key={index}
									style={{
										border: "1px solid #e5e5e5",
										marginRight: "10px",
										padding: "3px 8px",
										display: "flex",
										alignItems: "center",
										background: "#ffffff",
										borderRadius: "5px",
									}}
								>
									{item}{" "}
									<CloseOutlined
										onClick={() => handleRemoveItem(item)}
										style={{ marginLeft: "5px", fontSize: "12px" }}
									/>
								</li>
							))}
						</ul>
					)}
				</div>
				{show && (
					<div
						className="flex shadow-lg border"
						style={{
							position: "absolute",
							top: "30px",
							zIndex: "99",
							background: "#fff",
							borderRadius: "10px",
							width: "50%",
							marginTop: "20px",
							boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.06)",
						}}
						onMouseLeave={() => setShow(false)}
					>
						<div style={{ width: "45%", background: "#f7f7f7" }}>
							<ul
								style={{
									fontSize: "16px",
									padding: "20px",
									cursor: "pointer",
								}}
							>
								{filters.map((filter, i) => (
									<li
										key={i}
										onClick={() => {
											if (value.includes(filter) === false)
												setValue([...value, filter]);
											setTab(i);
										}}
										style={{ marginBottom: "10px" }}
										className={tab === i ? styles.active : styles.inactive}
									>
										{filter}
									</li>
								))}
							</ul>
						</div>
						<div style={{ width: "50%", marginLeft: "10px", padding: "20px" }}>
							{tab === 0 && (
								<RangePicker
									onChange={(val) => {
										startDateFilter(
											{ startDate: val[0] },
											endDateFilter({ endDate: val[1] })
										);
									}}
									defaultValue={[startDate, endDate]}
									format={dateFormat}
									on
								/>
							)}
							{tab === 1 && (
								<Select
									showSearch
									placeholder="Select a loan officer"
									optionFilterProp="children"
									onChange={handleOfficer}
                  style={{cursor: 'pointer', width:'100%'}}
								>
									{loanOfficers?.data?.map((officer, index) => (
										<Option value={officer.officer_id} key={index}>
											{officer.officer_name}
										</Option>
									))}
								</Select>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default ReportTableHeader;
