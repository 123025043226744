import React from "react";
import { Table } from "antd";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function LoanTable({data}) {
  const { currencyFormatter } = useCurrenncyFormatter();
    const columns = [
        {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
            render: (_, record) => (
              <div>
                <span>{record.product_name}</span>
              </div>
            ),
        },
        {
            title: "Recovery Rate",
            dataIndex: "recovery_rate",
            key: "recovery_rate",
            render: (_, record) => (
              <div>
                <span>{record.recovery_rate}</span>
              </div>
            ),
        },

        {
            title: "Users",
            dataIndex: "user_count",
            key: "users_count",
            render: (_, record) => (
              <div>
                <span>{record.user_count}</span>
              </div>
            ),
        },

        {
            title: "Portfolio Size",
            dataIndex: "portfolio_size",
            key: "portfolio_size",
            render: (_, record) => (
              <div>
                <span>{currencyFormatter(record.portfolio_size)}</span>
              </div>
            ),
        },

        {
            title: "Repaid",
            dataIndex: "repaid_value",
            key: "repaid_value",
            render: (_, record) => (
              <div>
                <span>{record.repaid_value}</span>
              </div>
            ),
        },
        {
            title: "Overdue",
            dataIndex: "overdue_value",
            key: "overdue_value",
            render: (_, record) => (
              <div>
                <span>{record.overdue_value}</span>
              </div>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 10 }}
                size="small"
                scroll={{ y: 240 }}
            />
        </>
    )
}

export default LoanTable;
