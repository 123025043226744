import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { Card, Form, Col, Row, Button, message } from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import useGenerateComponent from "../../../../utils/componentGenerator";
import { Title } from "../../../Typography";

const kinform = React.createRef();

function NextOfKin({ currentNumber, id, setCurrentNumber }) {
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);
  const dispatch = useDispatch();
  const { session } = useAuth();
  const [loading, setIsLoading] = useState(false);
  const { addNextOfKin, updateProgress } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();
  const { data } = useQuery(
    `nextofkin`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=next-of-kin`,
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      if (currentNumber !== "end") {
        setCurrentNumber((prev) => {
          const current = Number(prev) + 1;
          return current.toString();
        });
        updateProgress(
          { progress: String(Number(currentNumber) + 1), loan_requestID: id },
          (data) => data
        );
      }
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);
    addNextOfKin([value], handleSubmitCallback);
  };

  const generateFormObject = (fields, profile) => {
    const formObject = {};
    fields.forEach((field) => {
      formObject[field] = profile[field];
    });

    return formObject;
  };

  useEffect(() => {
    if (data) {
      const fields = Object.keys(data?.data || {});
      const profile = data?.data;
      const formObj = generateFormObject(fields, profile);

      kinform?.current?.setFieldsValue({
        ...formObj[0],
      });
    }
  }, [data]);

  return (
    <Card>
      <Title level={3}>Next Of Kin</Title>
      <Form ref={kinform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          {currentLoan?.segments["next-of-kin"]?.fields?.map(
            (field, index) =>
              field?.is_needed && (
                <Col xs={24} md={12} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(
                      field,
                      {
                        ref: kinform,
                        fieldName: field?.field,
                      },
                      dispatch
                    )}
                  </Form.Item>
                </Col>
              )
          )}
        </Row>

        <Button
          className="lg:w-auto w-full"
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
        >
          Save and Continue
        </Button>
      </Form>
    </Card>
  );
}

export default NextOfKin;
