import React from "react";
import { Card, Tabs } from "antd";
import PersonalDetails from "./PersonalDetails";

const { TabPane } = Tabs;
function callback(key) {
  return key;
}

function Summary({ data, isLoading }) {
  return (
    <div className="h-100 mt-5">
      <Card className="mt-5 h-full">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Personal Details" key="1">
            <PersonalDetails profile_data={data} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default Summary;
