import React from 'react';
import { ReactComponent as Action } from '../../../assets/svg/action.svg';
import { Row, Col } from 'antd';
import Insight from '../Insight';
import Chartprincipal from '../../../assets/svg/Chartprincipal.svg';
import Chartinterest from '../../../assets/svg/Chartinterest.svg';
import Chartprofit from '../../../assets/svg/Chartprofit.svg';
import Chartpenalty from '../../../assets/svg/Chartpenalty.svg';

function DefaultLoans(props) {
    const style = { background: '#ffffff', padding: '20px 20px 35px 20px', borderRadius: '12px', height: '60vh' };

    return (
        <div style={style}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{color:'#2E2C34', fontSize:'16px', marginBottom:'0', fontWeight:'bold'}}>Defaulted Loans Insights</p>
                <Action />
            </div>
            <p>890,344 Products</p>
            <Row gutter={[16, 12]}>
                <Col span={12}>
                    <Insight
                        amount="0"
                        heading="Total Principal of Repaid Loans"
                        text="No. of fully repaid loans on all lending products"
                        image={Chartprincipal}
                        textColor="#5152A6" />
                </Col>
                <Col span={12}>
                    <Insight
                        amount="0%"
                        heading="Total Interest of Repaid Loans"
                        text="No. of clicks to ad that consist of a single impression"
                        image={Chartinterest}
                        textColor="#5152A6" />
                </Col>
                <Col span={12}>
                    <Insight
                        amount="0"
                        heading="Total Profit of Repaid Loans"
                        text="No. of clicks to ad that consist of a single impression"
                        image={Chartprofit}
                        textColor="#11C096" />
                </Col>
                <Col span={12}>
                    <Insight
                        amount="0"
                        heading="Total Penalty of Defaulted Loans"
                        text="No. of clicks to ad that consist of a single impression"
                        image={Chartpenalty}
                        textColor="#8A53A4" />
                </Col>
            </Row>

        </div>
    )
}

export default DefaultLoans;
