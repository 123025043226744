import { Row, Col, Divider, Empty, Card } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import Requests from "../../../../utils/request";
import AddNextOfKin from "./UpdateDetails/AddNextOfKIn";

const NextOfKin = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const { handleCustomField } = UseDisplayCustom();

  const { data: next_of_kin, refetch: refetchNextofKin } = useQuery(
    `next_of_kin_${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=next-of-kin`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );
  return (
    <>
      <div className="mt-5">
        {next_of_kin?.data?.map((kin) => (
          <Card style={{ marginBottom: "20px", mergingTop: "10px" }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">Firstname: </h3>
                  <h3 className="font-bold text-base">{kin?.firstname}</h3>
                </div>
                <Divider />
              </Col>
              <Col span={12}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">Lastname: </h3>
                  <h3 className="font-bold text-base">{kin?.lastname}</h3>
                </div>
                <Divider />
              </Col>
              <Col span={12}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">Address: </h3>
                  <h3 className="font-bold text-base">{kin?.home_address}</h3>
                </div>
                <Divider />
              </Col>
              <Col span={12}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">Phone: </h3>
                  <h3 className="font-bold text-base">{kin?.phone}</h3>
                </div>
                <Divider />
              </Col>
              {kin?.custom_fields &&
                kin?.custom_fields?.map((fields) => (
                  <Col span={8}>
                    <div className="flex flex-col justify-between  h-10 ">
                      <h3 className="text-gray-500">{fields?.label}: </h3>
                      <h3 className="font-bold text-base">
                        {handleCustomField(fields)}
                      </h3>
                    </div>
                    <Divider />
                  </Col>
                ))}
            </Row>
          </Card>
        ))}
        <div className="flex justify-end">
          <AddNextOfKin
            refetchNextofKin={refetchNextofKin}
            data={next_of_kin?.data?.map((kin) => kin)}
          />
        </div>

        {next_of_kin?.data === null && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Empty />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default NextOfKin;
