import React, { useState } from "react";
import {
  Button,
  Modal,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import {
  currencyFormatter,
  currencyParser,
} from "../../utils/currencyFormatter";
import { useAuth } from "../../context/AuthContext";

const { Option } = Select;

const ProductModal = ({ slug, openModal, setOpenModal }) => {
  const [inv_num, setInv_num] = useState("");
  const [amt, setAmt] = useState(0);
  const [ten, setTen] = useState(0);
  const [ten_in, setTenIn] = useState("months");
  const { session } = useAuth();
  return (
    <Modal
        visible={openModal}
        footer={null}
        width="30%"
        onCancel={() => setOpenModal(false)}
      >
        <div className="my-5">
          <label>Invoice number</label>
          <Input onChange={(e) => setInv_num(e.target.value)} value={inv_num} />
        </div>

        <div className="my-5">
          <label>Amount</label>
          <InputNumber
            formatter={currencyFormatter}
            parser={currencyParser}
            style={{ width: "100%" }}
            value={amt}
            onChange={(value) => setAmt(value)}
          />
        </div>

        <div className="my-5">
          <label>Tenure</label>
          <InputNumber
            style={{ width: "100%" }}
            value={ten}
            onChange={(value) => setTen(value)}
          />
        </div>

        <div className="my-5">
          <label>Tenure Type</label>
          <Select
            className="w-full"
            value={ten_in}
            onChange={(val) => setTenIn(val)}
          >
            <Option value="months" key="months">
              Months
            </Option>
            <Option value="weeks" key="weeks">
              Weeks
            </Option>
            <Option value="days" key="days">
              Days
            </Option>
          </Select>
        </div>

        <div className="my-5 space-x-4 flex items-center">
          <Button
            disabled={!inv_num || amt < 1}
            className="w-full"
            type="default"
            onClick={async () => {
              await navigator.clipboard.writeText(
                `https://${session.admin.slug}.${process.env.REACT_APP_WEBFORM}/c/${slug}?inv_num=${inv_num}&amt=${amt}&ten=${ten}&ten_in=${ten_in}`
              );

              message.success("copied");
            }}
          >
            Copy link
          </Button>
          <Button
            disabled={!inv_num || amt < 1}
            className="w-full"
            type="primary"
            target="_blank"
            href={`https://${session.admin.slug}.${process.env.REACT_APP_WEBFORM}/c/${slug}?inv_num=${inv_num}&amt=${amt}&ten=${ten}&ten_in=${ten_in}`}
          >
            View
          </Button>
        </div>
      </Modal>
  )
}

export default ProductModal;