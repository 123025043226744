import React from "react";
import { Button, Card, Drawer } from "antd";
import { Link } from "react-router-dom";
import useTemplater from "../../../hooks/useTemplater";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { Title } from "../../Typography";

const OfferTemplate = ({ visible, onClose, data }) => {
  const { id } = useParams();
  const { generateDocument } = useTemplater({ id });
  const { session } = useAuth();

  const sampleTemplate =
    "https://cdn.filestackcontent.com/AggwwHFJQGWxAdpOq6On";

  const { data: offer } = useQuery("offer", () =>
    Requests.fetchWithAuthToken(
      `/lms/product?slug=${data?.product_slug}`,
      "GET",
      session.session.token
    )
  );
  const product = offer?.data?.name || "";
  return (
    <>
      <Drawer
        title="Loan Template"
        placement="right"
        onClose={onClose}
        visible={visible}
        width="60%"
      >
        <Card>
          <div style={{ width: "100%" }}>
            {offer?.data?.offer_letter_templates?.length === 0 ? (
              <div>No Templates</div>
            ) : (
              <div>
                <Title level={3}>{offer?.data?.name}</Title>
                <ul style={{ listStyle: "disc", marginLeft: "10px" }}>
                  {offer?.data?.offer_letter_templates?.map((item, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <li key={i} style={{ width: "60%" }}>
                        {item.template_name}
                      </li>
                      <Button
                        onClick={() =>
                          generateDocument(item.template_url, product)
                        }
                        type="primary"
                      >
                        Generate Template
                      </Button>
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Card>
        <Card style={{ marginTop: "30px" }}>
          <div>
            <h2>View some of our sample offer letters</h2>
            <ul style={{ listStyle: "disc", marginLeft: "10px" }}>
              <li>
                <Link
                  to={{
                    pathname:
                      "https://cdn.filestackcontent.com/MiD8ZIrpRCSJxnIY40kz",
                  }}
                  target="_blank"
                >
                  sample 1
                </Link>
              </li>
            </ul>

            <Button
              onClick={() => generateDocument(sampleTemplate, product)}
              style={{ marginLeft: "20px" }}
            >
              Generate Document
            </Button>
          </div>
        </Card>
      </Drawer>
    </>
  );
};

export default OfferTemplate;
