import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ADDALLBANKSTATEMENTS } from "../../redux/Types/tableTypes";
import Requests from "../../utils/request";
import BankStatementTable from "../Table/BankStatementTable";

const BankStatements = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: mono_bank_statement, isLoading } = useQuery(
    "mono_bank_statement",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=mono`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (mono_bank_statement?.status === "success") {
      dispatch({
        type: ADDALLBANKSTATEMENTS,
        value: mono_bank_statement?.data?.statement?.data,
      });
    }
    // eslint-disable-next-line
  }, [isLoading, mono_bank_statement]);

  return (
    <section>
      <div className="flex justify-end my-3">
        <Button
          onClick={() => history.push(`/requests/${id}/bsa/mono`)}
          type="primary"
        >
          See full analysis
        </Button>
      </div>
      <BankStatementTable isLoading={isLoading} />
    </section>
  );
};

export default BankStatements;
