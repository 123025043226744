import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, InputNumber, Drawer } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";

const AddFacilityMetrics = ({ refetchFacilityMetrics, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const { id } = useParams();
  const facilityMetricsRef = React.createRef();

  const mutationFacilityMetrics = useMutation((facility_metrics) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=facility-metrics`,
      "POST",
      session.session.token,
      facility_metrics
    )
  );

  useEffect(() => {
    facilityMetricsRef?.current?.setFieldsValue({
      number_of_staff : data?.number_of_staff,
      number_of_registered_patients: data?.number_of_registered_patients,
      patient_monthly_visit_count: data?.patient_monthly_visit_count,
      number_of_doctors: data?.number_of_doctors,
      number_of_nurses: data?.number_of_nurses,
      number_of_pharmacists: data?.number_of_pharmacists,
      number_of_lab_technicians: data?.number_of_lab_technicians,
      number_of_locations: data?.number_of_locations,
    });
  }, [facilityMetricsRef, data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationFacilityMetrics.mutate(values, {
      onSuccess: (data) => {
        refetchFacilityMetrics();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Facility Metrics
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Facility Metrics</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={facilityMetricsRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Staff"
                name="number_of_staff"
              >
                <InputNumber size="large" style={{ width: "100%" }} disabled={data} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Registered Patients"
                name="number_of_registered_patients"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Patient Monthly Visit Count"
                name="patient_monthly_visit_count"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Daily Visits"
                name="daily_visits"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Doctors"
                name="number_of_doctors"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Nurses"
                name="number_of_nurses"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Pharmacists"
                name="number_of_pharmacists"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Lab Technicians"
                name="number_of_lab_technicians"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Number of Locations"
                name="number_of_locations"
              >
                <InputNumber size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddFacilityMetrics;