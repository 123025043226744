import React from "react";

import { Col } from "antd";
import { Text } from "../../Typography";
import LoanTable from "../../Table/Loan/LoanTable";

function LoanProduct({ data }) {
  const style = {
    background: "#ffffff",
    padding: "20px",
    borderRadius: "12px",
    height: "60vh",
  };

  return (
    <Col className="gutter-row" span={15}>
      <div style={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text
            style={{ fontSize: "16px", fontWeight: "bold", color: "#2E2C34" }}
          >
            Top Products
          </Text>
        </div>
        <div style={{ marginTop: "20px" }}>
          <LoanTable data={data} />
        </div>
      </div>
    </Col>
  );
}

export default LoanProduct;
