import { 
  ADDALLTRANSACTIONS, 
  SETTRANSACTIONENDDATE,
  SETTRANSACTIONSTARTDATE,
  TRANSACTIONSEARCHVALUE,
  SETTRANSACTIONSTATUS
} from '../../Types/tableTypes';

import moment from "moment";

const initialState = { 
  transactions: [],
  status:"",
  searchValue: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const transactionsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLTRANSACTIONS ) {
    return { ...state, transactions: actions.value }
  }

  if (actions.type === TRANSACTIONSEARCHVALUE ) {
    return { ...state, searchValue: actions.value }
  }

  if (actions.type === SETTRANSACTIONENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETTRANSACTIONSTARTDATE) {
    return { ...state, startDate: actions.value };
  }
  if (actions.type === SETTRANSACTIONSTATUS) {
    return { ...state, status: actions.value };
  }
  return state;
}

export default transactionsTableReducer;