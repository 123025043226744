export function Bag(props) {
    return (
      <svg
        width="49.37"
        height="49.37"
        viewBox="0 0 50 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle opacity={0.1} cx={24.999} cy={25.65} r={24.686} fill="#2772F0" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.002 19.478a2.743 2.743 0 00-2.743 2.743h-.687a2.743 2.743 0 00-2.725 3.052l.466 4.114a2.743 2.743 0 002.726 2.434h5.925a2.743 2.743 0 002.726-2.434l.466-4.114a2.743 2.743 0 00-2.725-3.052h-.687a2.743 2.743 0 00-2.742-2.743zm1.37 4.114v.686a.686.686 0 101.372 0v-.686h.687c.819 0 1.455.713 1.363 1.526l-.466 4.115a1.372 1.372 0 01-1.363 1.217h-5.926a1.371 1.371 0 01-1.362-1.217l-.467-4.115a1.371 1.371 0 011.363-1.526h.686v.686a.686.686 0 101.371 0v-.686h2.743zm0-1.371a1.371 1.371 0 00-2.742 0h2.743z"
          fill="#2772F0"
        />
      </svg>
    )
  }

  export function AtSign(props) {
    return (
      <svg
        width="49.37"
        height="49.37"
        viewBox="0 0 50 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle opacity={0.1} cx={24.999} cy={25.35} r={24.686} fill="#F5B544" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.514 25.35a5.486 5.486 0 0110.971 0c0 .168-.003.755-.179 1.284-.087.26-.2.457-.332.583-.115.11-.268.19-.517.19-.277 0-.41-.075-.501-.168-.114-.117-.236-.34-.33-.755-.194-.849-.198-2.126-.198-3.876a.686.686 0 00-1.371 0 3.429 3.429 0 10.515 5.01c.105.213.237.41.404.58.39.4.9.581 1.48.581.608 0 1.099-.22 1.466-.57.35-.335.558-.76.685-1.142.25-.749.25-1.524.25-1.71v-.007a6.857 6.857 0 10-3.81 6.144.686.686 0 10-.61-1.228 5.486 5.486 0 01-7.923-4.916zm7.543 0a2.057 2.057 0 11-4.115 0 2.057 2.057 0 014.115 0z"
          fill="#F5B544"
        />
      </svg>
    )
  }

  export function CombinedShape(props) {
    return (
      <svg
        width="49.37"
        height="49.37"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle opacity={0.1} cx={24.999} cy={24.701} r={24.686} fill="#4B9F47" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 17.844a.686.686 0 00-.686.685v.729a5.489 5.489 0 00-4.758 4.757h-.728a.686.686 0 000 1.372h.728a5.489 5.489 0 004.758 4.757v.728a.686.686 0 001.371 0v-.728a5.489 5.489 0 004.758-4.757h.728a.686.686 0 000-1.372h-.728a5.489 5.489 0 00-4.758-4.757v-.729a.686.686 0 00-.685-.685zm0 10.971a4.114 4.114 0 110-8.229 4.114 4.114 0 010 8.23zm0-2.743a1.371 1.371 0 100-2.743 1.371 1.371 0 000 2.743z"
          fill="#4B9F47"
        />
      </svg>
    )
  }
  