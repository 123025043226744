import React, { useState } from "react";
import Heading from "./Heading";
import Overview from "./Overview";
import Information from "./Information";
import Terms from "./Terms";
import AdditionalActions from "./AdditionalActions.js";
import Requests from "../../../utils/request";
import { useQuery } from "react-query";

const MerchantView = ({ session, credit_order, id }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { data: order_debit_mandate } = useQuery(
    `debit_mandate-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/debit_mandate/r/${id}?action=activation-status&vendor=remita&resource=credit-order`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!credit_order,
    }
  );

  const { data: product_template_merchant } = useQuery(
    ["product_template", credit_order],
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product?slug=${credit_order?.data?.product_slug}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!credit_order,
    }
  );

  const { data: product_config } = useQuery(
    `product_config-${product_template_merchant?.data?.id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product/${product_template_merchant?.data?.id}/config`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!product_template_merchant,
    }
  );

  const { data: business_information } = useQuery(
    "business-information",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=business-information`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: health_facility } = useQuery(
    "health_facility",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=health-facility`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <Heading data={credit_order?.data} />
      <Overview
        data={credit_order?.data}
        debit_mandate={order_debit_mandate}
        business_information={business_information}
        health_facility={health_facility}
      />

      {credit_order?.data?.lender_status === "approved" && (
        <Terms order_data={credit_order?.data} />
      )}

      <Information
        product_template={product_template_merchant}
        config={product_config?.data}
        setVisible={() => setIsVisible(true)}
        business_information={business_information}
        health_facility={health_facility}
      />

      <AdditionalActions
        isVisible={isVisible}
        id={id}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

export default MerchantView;
