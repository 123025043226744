import React from "react";
import { Row, Col, Card, Progress } from "antd";
import { Text, Title } from "../Typography";

function ProgressCards() {
  const style = {padding: "15px"}
  const textStyle = {fontSize: "12px", fontWeight: "bold"}
  return (
    <>
      <Row gutter={16} className="mt-5">
        <Col span={6}>
          <Card bodyStyle={style} size="small">
              <Text style={textStyle}>NEW USERS</Text>
           <div className="flex justify-between mt-5">
                <Title level={4}>0</Title>
                <Text>from 0</Text>
            </div>`
            <Progress percent={0} showInfo={false} strokeColor="#5542F6" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>PAGE VIEWS</Text>
            </div>
           <div className="flex justify-between mt-5">
                <Title level={4}>0</Title> 
                <Text>from 0</Text>
            </div>`
            <Progress percent={0} showInfo={false} />
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>PAGE SESSIONS</Text>
            </div>
           <div className="flex justify-between mt-5">
                <Title level={4}>0</Title> 
                <Text>from 0</Text>
            </div>`
            <Progress percent={0} showInfo={false} strokeColor="#20C986" />
          </Card>
        </Col>
        <Col span={6}>
          <Card className="space-y-4" bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>BOUNCE RATE</Text>
            </div>
           <div className="flex justify-between mt-5">
             <Title level={4}>0.00%</Title>
                <Text>from 00.00%</Text>
            </div>`
            <Progress percent={0} showInfo={false} strokeColor="#E666E5" />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProgressCards;