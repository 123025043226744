import { Col, Button } from "antd";
import React from "react";
import { Text } from "../Typography";
import styles from "./pricing.module.css";
import { useHistory } from "react-router-dom";

function PlansCard({ title, text, features }) {
  const history = useHistory();
  let featureList = features.map((item) => {
    return <li className={styles.plans_list_item}>{item}</li>;
  });
  return (
    <Col xs={24} md={8} lg={6}>
      <div
        className={
          title === "Enterprise"
            ? `${styles.popup} ${styles.plans_card}`
            : `${styles.plans_card}`
        }
      >
        <div className={styles.plans_card_heading}>
          {title === "Enterprise" ? (
            <Text className={styles.plans_banner}>MOST POPULAR</Text>
          ) : (
            ""
          )}
          <h2 className={styles.plans_card_title}>{title}</h2>
          <Text className={styles.plans_card_p}>{text}</Text>
        </div>
        <hr className={styles.hr} />
        <ul className={styles.plans_list}>{featureList}</ul>
        {/* <div className={styles.plans_price}>
					<Text>{price}</Text>
					<Text>(&#x20A6;{priceNg})</Text>
				</div> */}
        <div className={styles.plans_price}>
          <Button
            type="primary"
            size="middle"
            onClick={() => history.push(`/sign-up?plan=${title}`)}
          >
            Get started
          </Button>
        </div>
      </div>
    </Col>
  );
}

export default PlansCard;
