import React from "react";
import {
  Modal,
} from "antd";
import Success from "../../../assets/images/success.png";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

const LoanDisbursed = ({ data, isModalVisible, handleCancel }) => {
  const { currencyFormatter } = useCurrenncyFormatter();
  return (
    <>
      <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <div className="grid justify-items-center">
          <img
            alt="success"
            src={Success}
            className="object-center justify-self-auto"
          />
          <h2 className="font-bold text-2xl text-center -mt-5">
            Loan Disbursed!
          </h2>
          <p className="text-gray-500 text-center mt-3 w-56">
            {`You successfully disbursed “${currencyFormatter(
              data?.terms.approved_amount
            )}” "${data?.fullname}” `}
          </p>
          {/* <div>
            <Button type="primary" className="mt-5 font-bold" block 
              onClick={() => {
                history.push(`/loan/${data?.user_id}`);
              }}>
              View Repayment
            </Button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default LoanDisbursed;
