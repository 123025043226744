import React, { useContext, useState, createContext } from "react";
import styles from "./pricing.module.css";

const LendingContext = createContext();

const LendingLink = ({ children, item }) => {
	const { active, toggle } = useContext(LendingContext);
	return (
		<button
			key={item}
			onClick={() => toggle(item)}
			className={`${
				active === item ? styles.lending_link_active : styles.lending_link
			}`}
		>
			{" "}
			{children}{" "}
		</button>
	);
};

const LendingContent = ({children, item}) => {
	const { active } = useContext(LendingContext);
	return active === item ? <div>{children}</div> : ""

}

function LendingNav(props) {
	const [active, setActive] = useState(1);

	const toggle = (id) => {
		const value = id === active ? -1 : id;
		setActive(value);
	};

	return (
		<LendingContext.Provider
			value={{
				active,
				toggle,
			}}
		>
			<div className={styles.lending_nav}>{props.children}</div>
		</LendingContext.Provider>
	);
}

export default LendingNav;
export { LendingLink, LendingContent };
