import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button, DatePicker, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  LOANREQUESTSEARCHVALUE,
  SETLOANREQUESTSTARTDATE,
  SETLOANREQUESTENDDATE,
  CREDITORDERSEARCHVALUE,
  SETCREDITORDERSTARTDATE,
  SETCREDITORDERENDDATE,
} from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";
import Register from "../Borrowers/Register";
import CreateLoanApplication from "../Borrowers/CreateLoanApplication";

const { RangePicker } = DatePicker;

function TableHeader() {
  const { session } = useAuth();
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const [userID, setUserID] = useState(null);
  const { endDate, startDate } = useSelector(
    (state) => session?.admin?.category === "merchant" ? state.creditOrdersTableReducer : state.loanRequestsTableReducer
  );

  const handleSearch = (e) => {
    dispatch({ type: LOANREQUESTSEARCHVALUE, value: e.target.value });
  };

  const handleOrderSearch = (e) => {
    dispatch({ type: CREDITORDERSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: session?.admin?.category === "merchant" ? SETCREDITORDERSTARTDATE : SETLOANREQUESTSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: session?.admin?.category === "merchant" ? SETCREDITORDERENDDATE : SETLOANREQUESTENDDATE, value: e.endDate });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="mb-5">
      <h3 style={{ fontSize: "24px", fontWeight: "bold" }}>
        {session?.admin?.category === "merchant"
          ? "All Credit Orders"
          : "All Requests"}
      </h3>
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        {session?.admin?.category === "merchant"
          ? "Credit Orders allows you to track and manage all orders received across all of your products."
          : "Loan requests allows you to track and manage all requests received across all of your products."}
      </p>
      <div className="flex items-center justify-between">
        <div className="flex justify-between">
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "40%", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={
              session?.admin?.category === "merchant"
                ? handleOrderSearch
                : handleSearch
            }
          />
          <RangePicker
            onChange={(value) =>
              startDateFilter(
                { startDate: value[0] },
                endDateFilter({ endDate: value[1] })
              )
            }
            defaultValue={[startDate, endDate]}
            format={dateFormat}
          />
        </div>
        <div>
          <Button
            type="primary"
            size="large"
            style={{ fontSize: "12px" }}
            onClick={showModal}
          >
            {session?.admin?.category === "merchant"
              ? "Create New Order"
              : "Create Loan Offer"}
          </Button>

          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}
          >
            <Register
              closeModal={setIsModalVisible}
              useAllFeature={true}
              setUserID={setUserID}
            />
          </Modal>

          <Modal
            visible={userID}
            footer={null}
            onCancel={() => setUserID(null)}
          >
            <div className="my-12">
              <CreateLoanApplication userID={userID} />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
