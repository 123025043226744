import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import { useAuth } from "../../context/AuthContext";
import { Form, Button, Row, Col, Input, Alert, message } from "antd";
import Logo from "../../assets/svg/logo.svg";
import Pattern from "../../assets/images/patterns.png";
const securedStorage = new SecureLS();

function Login(props) {
  const history = useHistory();
  const signupForm = useRef();
  const { signIn, signOut } = useAuth();
  const [isProcessing, setProcessing] = useState(false);
  const [email, setEmail] = useState(null);
  const [isLoggingOut, setIsLogout] = useState(false);
  const [proccessingMessage, setProcessingMessage] = useState(null);

  const loginCallBack = (data) => {
    if (data.status === "active-login") {
      setProcessing(false);
      setProcessingMessage(data.message);
      return;
    }

    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    securedStorage.set("operator", {
      allowed_to: data.data4,
      access_to: data.data3,
      session: data.data2,
      user: data.data1,
      admin: data.data,
    });
    message.success(data.message, 3);
    window.location.replace("/");
  };

  const logoutCallBack = (data) => {
    if (data.status !== "success") {
      setIsLogout(false);
      message.error(data.message, 3);
      return;
    }
    signupForm.current.submit();
    message.success(data.message, 3);
    setProcessingMessage(null);
  };

  return (
    <div className="h-screen flex lg:flex-row flex-col">
      <div className="flex-grow lg:p-10 p-5 xl:mx-32 lg:mx-16">
        <img src={Logo} alt="evolve credit logo" className="w-full h-20" />

        <div className="my-20">
          {proccessingMessage && (
            <Alert
              message="Warning"
              description={
                <>
                  <p>{`${proccessingMessage} \n Logout all sources ?`}</p>{" "}
                  <Button
                    loading={isLoggingOut}
                    onClick={() => {
                      setIsLogout(true);
                      signOut(
                        {
                          all: true,
                          email: email.toLowerCase(),
                          by_ref: false,
                          ref: "",
                        },
                        logoutCallBack
                      );
                    }}
                    type="primary"
                  >
                    Yes Logout
                  </Button>
                </>
              }
              type="warning"
              showIcon
              closable
              onClose={() => setProcessingMessage(null)}
            />
          )}
          <h1 className="text-2xl font-semibold my-5">
            Sign in to Evolve Credit Dashboard
          </h1>
          <Form
            ref={signupForm}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={(values) => {
              setProcessingMessage(null);
              setProcessing(true);
              signIn(
                { ...values, email: values.email.toLowerCase() },
                loginCallBack
              );
            }}
            data-testid="form"
          >
            <Row gutter={[2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    data-testid="email-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={isProcessing}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Sign in
              </Button>
            </Form.Item>
            <div className="flex w-full justify-center">
              <Button type="link" onClick={() => history.push("/recover")}>
                Forgot password?
              </Button>
              <Button type="link" onClick={() => history.push("/plans")}>
                Don't have an account?
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative hidden lg:block"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default Login;
