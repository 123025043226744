import React, { useState } from "react";
import { Button, Divider, Drawer } from "antd";
import { Title } from "../Typography";
import CollateralInfo from "./CollateralInfo";

const AddNewCollateral = ({ data, refetch }) => {
  const [visible, setVisible] = useState(false);
  const [isProcessing, setProcessing] = useState();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Get started
      </Button>
      <Drawer footer={null} visible={visible} onClose={onClose} width="75%">
        <Title level={3}>Add a New Collateral</Title>
        <Divider />
        <CollateralInfo
          data={data}
          refetch={refetch}
          setProcessing={setProcessing}
          isProcessing={isProcessing}
          setVisible={setVisible}
        />
      </Drawer>
    </>
  );
};

export default AddNewCollateral;
