import moment from "moment";
import { SETNOTIFICATIONSENDDATE, SETNOTIFICATIONSSTARTDATE } from "../Types/generalTypes";

const initialState = { 
  name: 0,
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const notificationsReducer = (state = initialState, actions) => {
  if (actions.type === SETNOTIFICATIONSENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETNOTIFICATIONSSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
