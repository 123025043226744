import { Tabs, Avatar } from "antd";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import BankForCreditOrder from "./details/BankForCreditOrder";
import SubmitCardCreditOrder from "./details/SubmitCardCreditOrder";
const { TabPane } = Tabs;

function CreditOrderType({ id, isLoading }) {
  const { session } = useAuth();
  const [currentNumber, setCurrentNumber] = useState("1");
  const { data: loan } = useQuery(`loan`, () =>
    Requests.fetchWithAuthToken(
      `/lms/user/credit_order?id=${id}`,
      "GET",
      session?.admin?.token
    )
  );
  const tree = {
    "bank-details": (
      <BankForCreditOrder
        status={loan?.data?.user_status}
        setCurrentNumber={setCurrentNumber}
        currentNumber={currentNumber}
        id={id}
      />
    ),
  };

  const [segmentTitles, setSegmentTitles] = useState([]);
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);

  useEffect(() => {
    setSegmentTitles(Object.values(currentLoan?.segments || {}));
  }, [currentLoan]);

  useEffect(() => {
    if (loan?.status === "error") {
      setCurrentNumber("1");
      return;
    }

    if (loan?.data?.progress === "") {
      setCurrentNumber("1");
      return;
    }

    if (loan?.data?.progress === "end") {
      setCurrentNumber("end");
      return;
    }

    if (loan?.data?.progress) {
      setCurrentNumber(loan?.data?.progress);
    }
  }, [loan]);

  return (
    <section>
      <Tabs type="card" centered>
        {segmentTitles?.slice(1).map((segment) => (
          <TabPane
            tab={
              <>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor:
                      currentNumber === segment?.heirarchy.toString()
                        ? "green"
                        : "black",
                  }}
                >
                  {segment?.heirarchy}
                </Avatar>{" "}
                {segment?.segment}
              </>
            }
            key={segment?.heirarchy}
          >
            <div className="my-10 lg:mx-60"> {tree[segment?.slug]} </div>
          </TabPane>
        ))}

        <TabPane
          tab={
            <>
              <Avatar
                style={{
                  backgroundColor: currentNumber === "2" ? "green" : "black",
                }}
                size="small"
              >
                2
              </Avatar>{" "}
              Submit
            </>
          }
          key="2"
        >
          <SubmitCardCreditOrder id={id} />
        </TabPane>
      </Tabs>
      )
    </section>
  );
}

export default CreditOrderType;
