import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import Requests from "../../../utils/request";
import { useAuth } from "../../../context/AuthContext";
import totalLoan from "../../../assets/svg/TotalLoan.svg";
import overdue from "../../../assets/svg/Overdue.svg";
import approved from "../../../assets/svg/Approved.svg";
import pending from "../../../assets/svg/pendingRequest.svg";
import Card from "./Card";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import { Button, message, Row } from "antd";
import { CSVLink } from "react-csv";
 
const BorrowersMetrics = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const { currencyFormatter } = useCurrenncyFormatter();

  const { data: borrowers_metrics} = useQuery(
    'single_borrowers_metrics',
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/${id}/data?data=metric`,
        "GET",
        session.session.token
      )
    )

    const headers = [
      { label: 'Total Loan Request Count', key: 'total_loan_request.count' },
      { label: 'Total Loan Request Value', key: 'total_loan_request.value' },
      { label: 'Total Approved Loan Request Count', key: 'total_approved_loan_request.count' },
      { label: 'Total Approved Loan Request Value', key: 'total_approved_loan_request.count' },
      { label: 'Total Loans Count', key: 'total_loans.count' },
      { label: 'Total Loans Value', key: 'total_loans.value' },
      { label: 'Total Active Loans Count', key: 'total_active_loans.count' },
      { label: 'Total Active Loans Value', key: 'total_active_loans.value' },
      { label: 'Total Overdue Loans Count', key: 'total_overdue_loans.count' },
      { label: 'Total Overdue Loans Value', key: 'total_overdue_loans.value' },
      { label: 'Total Paid Loans Count', key: 'total_paid_loans.count' },
      { label: 'Total Paid Loans Value', key: 'total_paid_loans.value' },
      { label: 'Total Repayment Count', key: 'total_repayment.count' },
      { label: 'Total Repayment Value', key: 'total_repayment.value' },
      { label: 'Total Overdue Repayment Count', key: 'total_overdue_repayment.count' },
      { label: 'Total Overdue Repayment Value', key: 'total_overdue_repayment.value' },
      { label: 'Total Pending Repayment Count', key: 'total_pending_repayment.count' },
      { label: 'Total Pending Repayment Value', key: 'total_pending_repayment.value' },
      { label: 'Total Paid Repayment Count', key: 'total_paid_repayment.count' },
      { label: 'Total Paid Repayment Value', key: 'total_paid_repayment.value' },
  ];

    const metrics = {
      ...borrowers_metrics?.data, ...borrowers_metrics?.data1, ...borrowers_metrics?.data2,
    };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className="mt-10">
        <Button type="primary">
          <CSVLink
            filename={"Borrowers_Metrics.csv"}
            headers={headers}
            data={[metrics ]|| ""}
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </div>
      <Row gutter={[16, 16]} className="mt-10">
        <Card
          heading="Total Loan Request"
          amount={currencyFormatter(borrowers_metrics?.data?.total_loan_request?.value || 0)}
          people={borrowers_metrics?.data?.total_loan_request?.count || 0   }
          textColor="#3C4066"
          icon={totalLoan}
          name="allrequests"
        />
        <Card
          heading="Total Approved Request"
          amount={currencyFormatter(borrowers_metrics?.data?.total_appproved_loan_request?.value || 0)}
          people={borrowers_metrics?.data?.total_loan_request?.count || 0   }
          textColor="#5152A6"
          icon={approved}
          name="allapprovedrequests"
        />
        <Card
          heading="Total Loans"
          amount={currencyFormatter(borrowers_metrics?.data1?.total_loans?.value || 0)}
          people={borrowers_metrics?.data1?.total_loans?.count || 0   }
          textColor="#3C4066"
          icon={totalLoan}
          name="allloans"
        />
        <Card
          heading="Total Active Loans"
          amount={currencyFormatter(borrowers_metrics?.data1?.total_active_loans?.value || 0)}
          people={borrowers_metrics?.data1?.total_active_loans?.count || 0   }
          textColor="#5152A6"
          icon={approved}
          name="allactiveloans"
        />
        <Card
          heading="Total Overdue Loans"
          amount={currencyFormatter(borrowers_metrics?.data1?.total_overdue_loans?.value || 0)}
          people={borrowers_metrics?.data1?.total_overdue_loans?.count || 0   }
          textColor="#FF4B4B"
          icon={overdue}
          name="alloverdueloans"
        />
        <Card
          heading="Total Paid Loans"
          amount={currencyFormatter(borrowers_metrics?.data1?.total_paid_loans?.value || 0)}
          people={borrowers_metrics?.data1?.total_paid_loans?.count || 0   }
          textColor="#5152A6"
          icon={approved}
          name="allrepaidloans"
        />
                <Card
          heading="Total Repayments"
          amount={currencyFormatter(borrowers_metrics?.data2?.total_repayment?.value || 0)}
          people={borrowers_metrics?.data2?.total_repayment?.count || 0   }
          textColor="#3C4066"
          icon={totalLoan}
          name="allrepayment"
        />
        <Card
          heading="Total Pending Repayments"
          amount={currencyFormatter(borrowers_metrics?.data2?.total_pending_repayment?.value || 0)}
          people={borrowers_metrics?.data2?.total_pending_repayment?.count || 0   }
          textColor="#F99600"
          icon={pending}
          name="allpendingrepayment"
        />
        <Card
          heading="Total Overdue Repayment"
          amount={currencyFormatter(borrowers_metrics?.data2?.total_overdue_repayment?.value || 0)}
          people={borrowers_metrics?.data2?.total_overdue_repayment?.count || 0   }
          textColor="#FF4B4B"
          icon={overdue}
          name="alloverduerepayment"
        />
        <Card
          heading="Total Paid Reayment"
          amount={currencyFormatter(borrowers_metrics?.data2?.total_paid_repayment?.value || 0)}
          people={borrowers_metrics?.data2?.total_paid_repayment?.count || 0   }
          textColor="#5152A6"
          icon={approved}
          name="allrepaidrepayment"
        />
      </Row>
    </>
  )
}

export default BorrowersMetrics;