import React, { useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { ADDALLTRANSACTIONS } from "../../redux/Types/tableTypes";
import Requests from "../../utils/request";
import TransactionsTable from "../../components/Table/Transactions/TransactionsTable";
import TransactionsTableHeader from "../../components/Transaction/TransactionsTableHeader";
import { Title } from "../../components/Typography";
function Transaction() {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue, startDate, endDate, status } = useSelector(
    (state) => state.transactionsTableReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: transactions, isLoading } = useQuery(
    `all_transactions-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/transaction?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((transaction) => {
      const name = `${transaction?.borrower}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return transaction;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status && status !== "all") {
        return data?.paystack?.status.toString() === status;
      }
      return true;
    });
  };

  useEffect(() => {
    if (transactions?.status === "success") {
      dispatch({
        type: ADDALLTRANSACTIONS,
        value: SearchQuery(transactions?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: ADDALLTRANSACTIONS,
      value: SearchQuery(transactions?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <div>
      <Col span={24}>
        <Title level={2}> Transaction History</Title>
        <div style={style}>
          <TransactionsTableHeader />
          <TransactionsTable isLoading={isLoading} />
        </div>
      </Col>
    </div>
  );
}

export default Transaction;
