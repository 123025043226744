import React from 'react';
import { CalenderIcon } from './LoanIcons';

function Calender({width, margin, height, background}) {

    const d = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = months[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();

    return (
        <div style={{ display: 'flex', width: `${width}`, borderRadius: '6px', border: '1px solid #EBEAED', alignItems: 'center', padding: '8px 20px', marginTop: `${margin}`, height: `${height}`, backgroundColor:`${background}` }}>
            <CalenderIcon />
            <p style={{ margin: '0', marginLeft: '10px' }}>{month} {day}, {year}</p>
        </div>
    )
}

export default Calender;
