import React from 'react'
import { Plans } from '../../components/Pricing'

function Pricing() {
    return (
        <Plans/>
    )
}

export default Pricing
