import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Input, DatePicker } from "antd"; 
import { useDispatch, useSelector } from "react-redux";
import { ALLBORROWERSCREDITSEARCHVALUE, ALLLOANAPPLICATIONSEARCHVALUE, SETBORROWERSCREDITENDDATE, SETBORROWERSCREDITSTARTDATE, SETBORROWERSCREDITSTATUS, SETLOANAPPLICATIONSENDDATE, SETLOANAPPLICATIONSSTARTDATE, SETLOANAPPLICATIONSTATUS } from "../../../redux/Types/tableTypes";
import { useAuth } from "../../../context/AuthContext";

const { RangePicker } = DatePicker;

function TableHeader(props) {
  const { Option } = Select;
  const { session } = useAuth();

  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) => 
    session?.admin?.category === "merchant" ?
    state.borrowersCreditTableReducer :
    state.loanApplicationsTableReducer);

  const handleSearch = (e) => {
    dispatch({ type: session?.admin?.category === "merchant" ? 
    ALLBORROWERSCREDITSEARCHVALUE :
    ALLLOANAPPLICATIONSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: session?.admin?.category === "merchant" ? 
    SETBORROWERSCREDITSTARTDATE :
    SETLOANAPPLICATIONSSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: session?.admin?.category === "merchant" ? 
    SETBORROWERSCREDITENDDATE :
    SETLOANAPPLICATIONSENDDATE, value: e.endDate });
  };
  
  const handleFilterByStatus = (val) => {
    dispatch({ type: session?.admin?.category === "merchant" ? 
    SETBORROWERSCREDITSTATUS :
    SETLOANAPPLICATIONSTATUS, value: val });
  };

  return (
      <div className="mb-5 mt-5"> 
        <div className="flex items-center justify-between">
          <div className="flex">
            <Select
                showSearch
                allowClear
                style={{ width: 200 }}
                size="large"
                placeholder="Filter"
                optionFilterProp="children"
                onChange={(val) => handleFilterByStatus(val)}
              >
                <Option value="saved">Saved</Option>
                <Option value="submitted">Submitted</Option>
              </Select>
              <Input
                  size="large"
                  placeholder="Search..."
                  style={{ width: '75%', margin:'0px 10px' }}
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
              />
              </div>
              <RangePicker
                onChange={(value) =>
                  startDateFilter(
                    { startDate: value[0] },
                    endDateFilter({ endDate: value[1] })
                  )
                }
                defaultValue={[startDate, endDate]}
                format={dateFormat}
                on
              />
      </div>
    </div>
  )
}

export default TableHeader
