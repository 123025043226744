import React, { useEffect, useReducer, useState } from "react";
import { Menu, Button, Card, message, Tabs, Switch } from "antd";
import {
  ArrowRightOutlined,
  EditFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { Toggler } from "../AdminSetup/Pop";
import {
  initialState,
  reducer,
} from "../AdminSetup/reducers/LoanTemplateReducer";

import {
  initialStateCustom,
  reducerCustom,
} from "../AdminSetup/reducers/CustomReducer";

import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import strip from "../../utils/StripWords";
import { Title } from "../Typography";
import { useHistory } from "react-router";
const { TabPane } = Tabs;

function UpdateProduct({ slug }) {
  const { session } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [customState, dispatchCustom] = useReducer(
    reducerCustom,
    initialStateCustom
  );
  const [current, setCurrent] = useState();
  const [currentCustom, setCurrentCustom] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const history = useHistory();
  const mutationUpdateProduct = useMutation((product) =>
    Requests.fetchWithAuthToken(
      "/lms/product",
      "PUT",
      session.session.token,
      product
    )
  );

  const { data, isLoading } = useQuery(
    `product-${slug}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product?slug=${slug}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const handleClickCustom = (e) => {
    setCurrentCustom(e.key);
  };

  const handleSetCurrent = (product) => {
    if (product.type === "credit-order") {
      return setCurrent("Credit Order");
    }

    setCurrent("Loan Request");
  };

  const cleanTemplateToFitState = (template = []) => {
    const templateObject = {};
    template
      ?.filter((temp) => temp.segment)
      ?.forEach((field) => {
        templateObject[field.segment] = field;
      });

    return templateObject;
  };

  const SaveTemplate = () => {
    setProcessing(true);
    const payload = {
      ...data?.data,
      custom_segments: Object.values(customState),
      segments: Object.values(state),
    };
    mutationUpdateProduct.mutate(payload, {
      onSuccess: (data) => {
        if (data.status !== "success") {
          setProcessing(false);
          message.error(data.message, 3);
          return;
        }

        setProcessing(false);
        message.success(data.message, 3);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    dispatch({
      type: "init",
      template: data?.data ? cleanTemplateToFitState(data?.data?.segments) : {},
    });

    dispatchCustom({
      type: "init",
      template: data?.data?.custom_segments
        ? cleanTemplateToFitState(data?.data?.custom_segments)
        : {},
    });
    if (data?.data) {
      handleSetCurrent(data?.data);
    }

    if (data?.data?.custom_segments) {
      const name = data?.data?.custom_segments[0]?.segment
        ? data?.data?.custom_segments[0]?.segment
        : data?.data?.custom_segments[1]?.segment;
      setCurrentCustom(name);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : (
        <Card>
          <section className=" w-11/12 m-auto">
            <div className="flex justify-between mt-6">
              <div>
                <Title level={3} className="uppercase">
                  {`${data?.data?.name} - ${strip(data?.data?.type)}`}
                  <EditFilled
                    onClick={() =>
                      history.push(`/products/${data?.data?.id}/custom_fields`)
                    }
                  />
                </Title>
                <h2 className="text-black font-bold text-md">
                  The criteria you setup here will determine the details that
                  appears on your customer's application form
                </h2>
              </div>
            </div>
            <Tabs type="card">
              <TabPane tab="Default Segments" key="default">
                <Menu
                  onClick={handleClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                  overflowedIndicator={
                    <span>
                      More <ArrowRightOutlined />
                    </span>
                  }
                >
                  {Object.keys(state).map((field, index) => (
                    <Menu.Item key={field}>{field}</Menu.Item>
                  ))}
                </Menu>
                <div className="mt-5 flex justify-end">
                  <Button
                    loading={isProcessing}
                    onClick={SaveTemplate}
                    type="primary"
                    size="large"
                  >
                    Update Product
                  </Button>
                </div>
                <section className="mt-5">
                  <div className="mb-5 flex space-x-5">
                    <div className="flex space-x-2">
                      <Switch
                        onChange={(val) =>
                          dispatch({
                            type: "deactivate",
                            segment: current,
                            val: !val,
                          })
                        }
                        checked={!state[current]?.deactivated}
                      />
                      <p>Deactivate Segment</p>
                    </div>

                    {current === "Loan Request" ||
                    current === "Valid Identity" ? null : (
                      <div
                        className="flex space-x-2 cursor-pointer"
                        onClick={() =>
                          history.push(
                            `/products/${data?.data?.id}/custom_fields/${state[current]?.slug}`
                          )
                        }
                      >
                        <PlusCircleFilled className="text-lg cursor-pointer" />
                        <p>Add new field</p>
                      </div>
                    )}
                  </div>
                  {state[current]?.fields?.map((field, index) => (
                    <>
                      <Toggler
                        key={index}
                        fieldName={field.label}
                        is_needed={field.is_needed}
                        is_required={field.is_required}
                        index={index}
                        dispatch={dispatch}
                        segment={current}
                      />
                    </>
                  ))}

                  {state[current]?.custom_fields && (
                    <div>
                      {state[current]?.custom_fields?.map((field, index) => (
                        <>
                          <Toggler
                            key={state[current]?.fields?.length + index}
                            fieldName={field.label}
                            is_needed={field.is_needed}
                            is_required={field.is_required}
                            index={state[current]?.fields?.length + index}
                            dispatch={dispatch}
                            segment={current}
                            isCustom={true}
                          />
                        </>
                      ))}
                    </div>
                  )}
                </section>
              </TabPane>

              {currentCustom && (
                <TabPane tab="Custom Segments" key="custom">
                  <Menu
                    onClick={handleClickCustom}
                    selectedKeys={[currentCustom]}
                    mode="horizontal"
                    overflowedIndicator={
                      <span>
                        More <ArrowRightOutlined />
                      </span>
                    }
                  >
                    {Object.keys(customState).map((field, index) => (
                      <Menu.Item key={field}>{field}</Menu.Item>
                    ))}
                  </Menu>
                  <div className="mt-2 flex justify-end">
                    <Button
                      loading={isProcessing}
                      onClick={SaveTemplate}
                      type="primary"
                      size="large"
                    >
                      Update Product
                    </Button>
                  </div>

                  <section className="mt-10">
                    <div className="flex space-x-3">
                      <div className="mb-5 flex space-x-2">
                        <Switch
                          onChange={(val) =>
                            dispatchCustom({
                              type: "deactivate",
                              segment: currentCustom,
                              val: !val,
                            })
                          }
                          checked={!customState[currentCustom]?.deactivated}
                        />
                        <p>Deactivate Segment</p>
                      </div>

                      <div
                        className="flex space-x-2 cursor-pointer"
                        onClick={() =>
                          history.push(
                            `/products/${data?.data?.id}/custom_fields/${customState[currentCustom]?.slug}?custom-segment=true`
                          )
                        }
                      >
                        <PlusCircleFilled className="text-lg cursor-pointer" />
                        <p>Add new field</p>
                      </div>
                    </div>
                    {customState[currentCustom]?.fields?.map((field, index) => (
                      <div>
                        <Toggler
                          key={index}
                          fieldName={field.label}
                          is_needed={field.is_needed}
                          is_required={field.is_required}
                          index={index}
                          dispatch={dispatchCustom}
                          segment={currentCustom}
                        />
                      </div>
                    ))}

                    {customState[currentCustom]?.custom_fields && (
                      <div>
                        {customState[currentCustom]?.custom_fields?.map(
                          (field, index) => (
                            <>
                              <Toggler
                                key={
                                  customState[currentCustom]?.fields?.length +
                                  index
                                }
                                fieldName={field.label}
                                is_needed={field.is_needed}
                                is_required={field.is_required}
                                index={
                                  customState[currentCustom]?.fields?.length +
                                  index
                                }
                                dispatch={dispatchCustom}
                                segment={currentCustom}
                                isCustom={true}
                              />
                            </>
                          )
                        )}
                      </div>
                    )}
                  </section>
                </TabPane>
              )}
            </Tabs>
          </section>
        </Card>
      )}
    </>
  );
}

export default UpdateProduct;
