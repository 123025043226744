import {
  ADDALLAGENT,
  ALLAGENTSEARCHVALUE,
  SETAGENTSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  agents: [],
  searchValue: "",
  status: "",
};

export const agentTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLAGENT) {
    return { ...state, teams: actions.value };
  }

  if (actions.type === ALLAGENTSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETAGENTSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  return state;
};
