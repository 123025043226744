import React from "react";
import BlacklistCard from "./BlacklistCard";
import { Col, Row } from "antd";
import { BlacklistTableHeader } from "./BlacklistHeader";
import BlacklistTable from "../Table/Blacklist/BlacklistTable";
import red from "../../assets/svg/InsightRed.svg";

function BlacklistPage() {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  return (
    <Row gutter={[30, 30]}>
      <Col span={24}>
        <div style={style}>
          <Row gutter={16}>
            <BlacklistCard amount="0" title="Blacklisted Users" logo={red} />
          </Row>
        </div>
      </Col>
      <Col span={24}>
        <div style={style}>
          <BlacklistTableHeader />
          <BlacklistTable />
        </div>
      </Col>
    </Row>
  );
}

export default BlacklistPage;
