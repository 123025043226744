import React from "react";
import { Card, Tabs } from "antd";
import PersonalDetails from "./details/PersonalDetails";
import LoanApplications from "./details/LoanApplications";
import { useAuth } from "../../../context/AuthContext";
import CreditOrder from "./details/CreditOrder";
import Loan from "./details/loans";
import Repayments from "./details/Repayments";

const { TabPane } = Tabs;
function callback(key) {
  return key;
}

function Summary({ data, isLoading, isLoadingOrders }) {
  const { session } = useAuth();
  return (
    <div className="h-100 mt-5">
      <Card className="mt-5 h-full">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Personal Details" key="1">
            <PersonalDetails profile_data={data} />
          </TabPane>
          {session?.admin?.category === "merchant" ?
            <TabPane tab="Credit Orders" key="4">
              <CreditOrder isLoading={isLoadingOrders} />
            </TabPane> :
            <TabPane tab="Loan Applications" key="4">
              <LoanApplications isLoading={isLoading} />
            </TabPane>
          }
          <TabPane tab="Loans" key="5">
            <Loan isLoading={isLoading} />
          </TabPane>
          <TabPane tab="Repayments" key="6">
            <Repayments isLoading={isLoading} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default Summary;
