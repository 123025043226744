import React from "react";
import { Table, Button, Tag } from "antd";

function BlacklistTable() {
const dataSource = [
  
];
  
const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
      {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let color = "volcano";
        let text = record.status === "Blacklisted" ? "Blocked" : "Activated";

        return (
            <Tag color={color} key={record.status}>
                {text.toUpperCase()}
            </Tag>
        );
    },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
  <>
    <Table
      className="mt-5"
      columns={columns}
      dataSource={dataSource}
      pagination={{ position: ["bottomLeft"] }}
      rowSelection={rowSelection}
      size="small"
    />
  </>
  )
}

export default BlacklistTable;
