import { Card } from "antd";
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import UpdateLoanTerms from "./UpdateLoanTerms";

const Terms = ({ data, order_data}) => {
  const { session } = useAuth();
  const { currencyFormatter } = useCurrenncyFormatter();

  const termData = session?.admin?.category === "merchant" ? order_data : data;

  return (
    <div className="mt-5">
        <Card className="mt-2">
        <div className="flex justify-end">
          <UpdateLoanTerms />
        </div>
        <div className="flex space-x-8 my-3 justify-center">
          <h3 className="text-black text-base">
            Approved Amount:
            <span className="ml-5 text-base">
              {currencyFormatter(termData?.terms?.approved_amount || 0)}
            </span>
          </h3>

          <h3 className="text-black text-base">
            Approved Rate:
            <span className="text-black ml-5 text-base">
              {`${termData?.terms?.rate}%` || 0}
            </span>
          </h3>

          <h3 className="text-black text-base">
            Approved Tenure:
            <span className="text-black ml-5 text-base">
              {`${termData?.terms?.tenure}` || 0}
            </span>
          </h3>

          <h3 className="text-black text-base">
            Tenured In:
            <span className="text-black ml-5 text-base">
              {`${termData?.terms?.tenured_in}`}
            </span>
          </h3>

          <h3 className="text-black text-base">
            Estimated Repayment:
            <span className="text-black ml-5 text-base">
              {currencyFormatter(
                termData?.terms?.estimated_repayment || 0
              )}
            </span>
          </h3>
        </div>
      </Card>
    </div>
  )
}

export default Terms;