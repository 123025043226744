import moment from "moment";
import {
  ADDALLCREDITORDER,
  CREDITORDERSEARCHVALUE,
  SETCREDITORDERENDDATE,
  SETCREDITORDERSTARTDATE,
  SETCREDITSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  creditorders: [],
  searchValue: "",
  status: "",
  endDate: moment().add(7, 'days').endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const creditOrdersTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLCREDITORDER) {
    return { ...state, creditorders: actions.value };
  }

  if (actions.type === CREDITORDERSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETCREDITSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETCREDITORDERENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETCREDITORDERSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
