import React from "react";
import { Row, Col, Divider, Empty, Form } from "antd";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import AddBusinessInformation from "./UpdateDetails/AddBusinessInformation";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import { useEffect } from "react";
import moment from "moment";

const BusinessInformation = ({ business_information, template }) => {
  const { handleCustomField } = UseDisplayCustom();
  const { generateInputType } = UseGenerateComponentNew();
  const businessRef = React.createRef();

  useEffect(() => {
    businessRef?.current?.setFieldsValue({
      name: business_information?.data?.name,
      industry: business_information?.data?.industry,
      established_on: moment(business_information?.data?.established_on).format(
        "YYYY-MM-DD"
      ),
      number_of_employees: business_information?.data?.number_of_employees,
      number_of_locations: business_information?.data?.number_of_locations,
      role: business_information?.data?.role,
      type: business_information?.data?.type,
      annual_rent_cost: business_information?.data?.annual_rent_cost,
      average_monthly_income:
        business_information?.data?.average_monthly_income,
      description: business_information?.data?.description,
      last_twelve_months_income:
        business_information?.data?.last_twelve_months_income,
      location_status: business_information?.data?.location_status,
      monthly_ads_cost: business_information?.data?.monthly_ads_cost,
      monthly_electricity_cost:
        business_information?.data?.monthly_electricity_cost,
      monthly_marketing_cost:
        business_information?.data?.monthly_marketing_cost,
      monthly_salary_cost: business_information?.data?.monthly_salary_cost,
      primary_payments_collection_method:
        business_information?.data?.primary_payments_collection_method,
      registration_number: business_information?.data?.registration_number,
      physical_location:
        business_information?.data?.physical_location === true ? "Yes" : "No",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business_information]);

  return (
    <div className="mt-5">
      <Form layout="vertical" ref={businessRef}>
        <Row gutter={[16, 16]}>
          {template?.map((data) =>
            data?.slug === "business-information"
              ? data?.fields?.map((field) =>
                  field?.is_required || field?.is_needed ? (
                    <Col span={8}>
                      <Form.Item name={field?.field} label={field?.label}>
                        {generateInputType(field)}
                      </Form.Item>
                      <Divider />
                    </Col>
                  ) : null
                )
              : null
          )}
          {business_information?.data?.custom_fields &&
            business_information?.data?.custom_fields?.map((fields) => (
              <Col span={8}>
                <div className="flex flex-col justify-between  h-10 ">
                  <h3 className="text-gray-500">{fields?.label}: </h3>
                  <h3 className="font-bold text-base">
                    {handleCustomField(fields)}
                  </h3>
                </div>
                <Divider />
              </Col>
            ))}
          {!business_information && (
            <Col span={24}>
              <Empty />
            </Col>
          )}
        </Row>
      </Form>
      <div className="flex justify-end">
        <AddBusinessInformation data={business_information?.data} />
      </div>
    </div>
  );
};

export default BusinessInformation;
