import React from "react";
import { Text, Title } from "../../Typography";

function TopInsightCard({ background, logo, title, amount, link }) {
  const style = {
    background: `${background}`,
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderRadius: "16px",
    marginTop: "20px",
  };

  return (
    <div style={style}>
      <div style={{ display: "flex" }}>
        <img src={logo} alt={title} />
        <div style={{ marginLeft: "15px" }}>
          <Title level={4} style={{ marginBottom: "0" }}>
            {amount || 0}
          </Title>
          <Text style={{ fontSize: "12px" }}>{title}</Text>
        </div>
      </div>
      {/* {link ? (
        <Link
          style={{
            display: "flex",
            alignSelf: "flex-end",
            color: "#757580",
            textDecoration: "underline",
          }}
        >
          view
        </Link>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default TopInsightCard;
