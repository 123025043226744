import { Row, Col, Card } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Heading, Portfolio, TopCards, Product } from "../../components/Disbursements";
import { useAuth } from "../../context/AuthContext";
import { useQuery } from "react-query";
import Requests from "../../utils/request";
import { ADDALLDISBURSEMENTS, ADDALLFULFILMENT, ADDALLPORTFOLIOS } from "../../redux/Types/tableTypes";
import DisbursementsTable from "../../components/Disbursements/DisbursementTable";

function Disbursements() {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue, startDate, endDate, status } = useSelector((state) => 
    session.admin.category === "merchant" ?
    state.fulfilmentsTableReducer :
    state.disbursementsTableReducer
  );
  const dateFormat = "YYYY-MM-DD";
  
  const { data: disbursements, isLoading } = useQuery(
    `all_disbursements-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/disbursement?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: fulfilments, isLoading: isLoadingFulfilments } = useQuery(
    `all_fulfilments-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/fulfilement?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: disbursements_metrics, isLoading: isLoadingMetrics } = useQuery(
    `all_disbursements-metrics-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/disbursement/metrics?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: fulfilments_metrics, isLoading: isLoadingOrderMetrics } = useQuery(
    `all_fulfilments-metrics-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/fulfilment/metrics?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((disbursement) => {
      const name = `${disbursement?.recipient}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return disbursement;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status?.toString() === status;
      }

      return true;
    });
  };

  const FulfilmentSearchQuery = (data) => {
    const firstQuery = data?.filter((fulfilment) => {
      const name = `${fulfilment.customer?.firstname} ${fulfilment.customer?.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return fulfilment;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.fulfilled.toString() === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (
      fulfilments?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLFULFILMENT,
        value: FulfilmentSearchQuery(fulfilments?.data),
      });

      return;
    }

    if (
      disbursements?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLDISBURSEMENTS,
        value: SearchQuery(disbursements?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoadingFulfilments, isLoading]);

  useEffect(() => {
    if (
      fulfilments?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLFULFILMENT,
        value: FulfilmentSearchQuery(fulfilments?.data),
      });
    }

    if (
      disbursements?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLDISBURSEMENTS,
        value: SearchQuery(disbursements?.data),
      });
    }

    // eslint-disable-next-line
  }, [searchValue, status]);

  useEffect(() => {
    if (disbursements_metrics?.status === "success") {
      dispatch({
        type: ADDALLPORTFOLIOS,
        value: disbursements_metrics?.data2,
      });
    }
    // eslint-disable-next-line
  }, [isLoadingMetrics]);

    return (
      <>
        <Heading />
        <TopCards data={disbursements_metrics?.data} orderData={fulfilments_metrics?.data} />
        <div className="mt-10">
          <Row gutter={8}>
            <Col span={16}>
              <Portfolio isLoading={isLoadingMetrics} isLoadingOrderMetrics={isLoadingOrderMetrics} />
            </Col>
            <Col span={8}>
              <Product 
                data={disbursements_metrics?.data2} 
                orderData={fulfilments_metrics?.data2} 
                isLoading={isLoading}
                isLoadingFulfilments={isLoadingFulfilments}
              />
            </Col>
          </Row>
        </div>
        <div className="mt-10">
        <Card>
          <DisbursementsTable isLoading={isLoading} isLoadingFulfilments={isLoadingFulfilments} />
        </Card>
      </div>
      </>
    )
}

export default Disbursements;