const dateFormat = [
    {
        "format" : "MM/DD/YYYY",
        "output" : "08/16/2018"
    },
    {
        "format" : "MMMM D, YYYY",
        "output" : "August 16, 2018"
    },
    {
        "format" : "MMMM D, YYYY h:mm A",
        "output" : "August 16, 2018 8:02 PM"
    },
    {
        "format" : "dddd, MMMM D, YYYY h:mm A",
        "output" : "Thursday, August 16, 2018 8:02 PM"
    }
]

export default dateFormat;