import { Card, Tabs } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { SETALLREPAYMENTSTATUSTOSEARCHFOR } from "../../redux/Types/tableTypes";
import RepaymentsTable from "../Table/Repayments/RepaymentsTable";
import TableHeader from "./TableHeader";

const { TabPane } = Tabs;

const RepaymentsBody = ({ isLoading }) => {
  const dispatch = useDispatch();

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETALLREPAYMENTSTATUSTOSEARCHFOR, value: val });
  };
  return (
    <>  
        <Card>
          <TableHeader />
          <Tabs
            size="default"
            type="card"
            onTabClick={(key) => handleFilterByStatus(key)}
            style={{ marginTop: "20px", marginBottom: "0" }}
          >
            <TabPane tab="Pending Repayments" key="pending">
              <RepaymentsTable isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Paid Repayments" key="paid">
              <RepaymentsTable isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Partially Repaid Repayments" key="partialy-repaid">
              <RepaymentsTable isLoading={isLoading} />
            </TabPane>
            <TabPane tab="All Repayments" key="">
              <RepaymentsTable isLoading={isLoading} />
            </TabPane>
          </Tabs>
        </Card>
    </>
  )
}

export default RepaymentsBody;