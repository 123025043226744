import React from "react";
import { Card } from "antd";
function BankDetailsCard({ details = [] }) {
  return (
    <Card style={{ borderRadius: "11.4894px" }}>
      {details.map((detail, index) => (
        <div
          key={`${detail.label}-${index}`}
          className="flex justify-between  border-0  mb-2 border-b "
        >
          <span
            className="text-gray-400 justify-items-center text-base flex-1"
            style={{ fontSize: 14 }}
          >
            {detail.label}
          </span>
          <span
            className=" justify-self-start  flex-1"
            style={{
              fontSize: 14,
              fontFamily: "Cera Pro Bold",
              color: "#2E2C34",
            }}
          >
            {detail.value}
          </span>
        </div>
      ))}
    </Card>
  );
}

export default BankDetailsCard;
