import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Input,
  message,
  Button,
  Radio,
  Drawer,
  Divider,
} from "antd";
import {
  currencyParser,
  useCurrenncyFormatter,
} from "../../utils/currencyFormatter";
import AddNewCollateralType from "./AddNewCollateralType";
import CustomInfo from "./CustomInfo";
import UseGenerateComponent from "../../utils/displayInputTypes";
import { useMutation, useQuery } from "react-query";
import Requests from "../../utils/request";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import string_to_slug from "../../utils/StringToSlug";
import { Title } from "../Typography";

const { Option } = Select;

const UpdateCollateral = ({ data, collateral_id, loan_request_id }) => {
  const [visible, setVisible] = useState(false);
  const { session } = useAuth();
  const { id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [isDropdown, setIsDropDown] = useState(false);
  const [ID, setID] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const collateralRef = React.createRef();
  const { displayInputType } = UseGenerateComponent();
  const { currencyFormatter } = useCurrenncyFormatter();
  const [isProcessing, setProcessing] = useState();
  const { fields } = useSelector(
    (state) => state.collateralCustomFieldsReducer
  );

  const { data: collateral_types } = useQuery(
    `collateral_types`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product/collateral_type`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: single_collateral } = useQuery(
    `single_collateral_${collateral_id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/collateral?id=${collateral_id}&id-type=collateral-id`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleChange = (values) => {
    setIsSelected(true);
    collateral_types?.data?.map((type) => {
      if (type.slug === values) {
        setID(type.id);
        setCustomFields(type.custom_fields);
      }
      return null;
    });
  };

  useEffect(() => {
    collateralRef?.current?.setFieldsValue({
      loan_request_id: parseInt(id),
      type: single_collateral?.data?.type,
      product_name: single_collateral?.data?.product_name,
      product_slug: string_to_slug(single_collateral?.data?.product_name),
      date_purchased: single_collateral?.data?.date_purchased,
      value: single_collateral?.data?.value,
      serial_number: single_collateral?.data?.serial_number,
      model_name: single_collateral?.data?.model_name,
      model_number: single_collateral?.data?.model_number,
      status: single_collateral?.data?.status,
      custom_fields: {
        ...(single_collateral &&
          custom_fields(single_collateral, customFields)),
      },
    });
  }, [collateralRef, id, single_collateral, customFields]);

  const custom_fields = (values, fields) => {
    const obj = {};
    fields.forEach(
      (field) => (obj[field.field_name] = values[field.field_name])
    );
    return obj;
  };

  const mutationUpdateCollateral = useMutation((collateral) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/collateral/${collateral_id}`,
      "PUT",
      session.session.token,
      collateral
    )
  );

  const handleSubmit = (values) => {
    const info = {
      status: values.status,
    };
    setProcessing(true);
    mutationUpdateCollateral.mutate(info, {
      onSuccess: (data) => onUpdateCollateral(data),
      onError: () => {},
    });
  };

  const onUpdateCollateral = (data) => {
    if (data.status !== "success") {
      setProcessing(false);
      message.error(data.message, 3);
      return;
    }

    setProcessing(false);
    message.success(data.message, 3);
  };

  return (
    <>
      <Button
        className="-mt-2 -ml-2"
        onClick={() => setVisible(true)}
        loading={isProcessing}
      >
        Update
      </Button>
      <Drawer
        footer={null}
        visible={visible}
        onClose={() => setVisible(false)}
        width="75%"
      >
        <Title level={2}>Update Collateral</Title>
        <Divider />
        <Form layout="vertical" onFinish={handleSubmit} ref={collateralRef}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={8}>
              <Form.Item
                label="Collateral Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Collateral type is required",
                  },
                ]}
              >
                <Select size="large" onChange={handleChange}>
                  {collateral_types !== undefined &&
                    collateral_types?.data?.map((type) => (
                      <Option value={type.slug} key={type.id}>
                        {type.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <AddNewCollateralType data={data} />
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Product Name"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: "product name is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Date purchased" name="date_purchased">
                <DatePicker size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Value" name="value">
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  size="large"
                  className="100%"
                  style={{ width: "100%" }}
                  placeholder="0"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Product Serial Number" name="serial_number">
                <InputNumber
                  size="large"
                  className="100%"
                  style={{ width: "100%" }}
                  placeholder="0"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Model Name" name="model_name">
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Model Number" name="model_number">
                <InputNumber
                  size="large"
                  className="100%"
                  style={{ width: "100%" }}
                  placeholder="0"
                />
              </Form.Item>
            </Col>
            {isSelected &&
              customFields?.map((field, index) => (
                <Col xs={24} md={8} key={index}>
                  <Form.Item
                    label={field?.field_name}
                    name={field?.field_name}
                    rules={[
                      {
                        required: true,
                        message: "required",
                      },
                    ]}
                  >
                    {fields !== undefined &&
                      displayInputType(field?.field_type, field?.dropdown)}
                  </Form.Item>
                </Col>
              ))}
            <Col xs={24} md={24}>
              <Form.Item label="Status" name="status">
                <Radio.Group size="large" style={{ width: "100%" }}>
                  <Radio value="collateral is repossessed">
                    Collateral is repossesed
                  </Radio>
                  <Radio value="collateral is with borrower">
                    Collateral with Borrower
                  </Radio>
                  <Radio value="collateral has been returned to borrower">
                    {" "}
                    Collateral is returned to Borrower{" "}
                  </Radio>
                  <Radio value="repossession of collateral is inititated">
                    {" "}
                    Collateral Repossession initiated{" "}
                  </Radio>
                  <Radio value="collateral is under auction">
                    Collateral is under Auction{" "}
                  </Radio>
                  <Radio value="collateral is deposited in branch">
                    Collateral is deposited with Lender{" "}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={24} className="mb-5">
              {isSelected && (
                <CustomInfo
                  isDropdown={isDropdown}
                  setIsDropDown={setIsDropDown}
                  id={ID}
                />
              )}
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default UpdateCollateral;
