import moment from "moment";
import {
  ADDALLLOANAPPLICATIONS,
  ALLLOANAPPLICATIONSEARCHVALUE,
  SETLOANAPPLICATIONSENDDATE,
  SETLOANAPPLICATIONSSTARTDATE,
  SETLOANAPPLICATIONSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  loanapplications: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const loanApplicationsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANAPPLICATIONS) {
    return { ...state, loanapplications: actions.value };
  }

  if (actions.type === ALLLOANAPPLICATIONSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETLOANAPPLICATIONSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETLOANAPPLICATIONSENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETLOANAPPLICATIONSSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
