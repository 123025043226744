import { Modal, Button, Form, Row, Col, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useApp } from "../../../context/FetchContext";
import Requests from "../../../utils/request";
import { Title } from "../../Typography";

const DeclineButton = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const { refetch } = useApp();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const declineMutation = useMutation((declineBody) =>
    Requests.fetchWithAuthToken(
      `/lms/${
        session?.admin?.category === "merchant"
          ? "credit_order"
          : "loan_request"
      }/${id}?action=decline`,
      "PUT",
      session.session.token,
      declineBody
    )
  );

  const handleSubmit = (values) => {
    const terms = {
      note: values.note,
    };

    setProcessing(true);
    declineMutation.mutate(terms, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="danger"
        size="large"
        onClick={showModal}
        loading={isProcessing}
      >
        Decline
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={false}
        width="30%"
      >
        <Title level={4}>
          {session?.admin?.category === "merchant"
            ? "You are about to decline this order"
            : "You are about to decline this Loan"}
        </Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Note"
                name="note"
                rules={[
                  {
                    required: true,
                    message: "Note is required!",
                  },
                ]}
              >
                <TextArea type="text" width={100} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              size="large"
              className="w-full"
              htmlType="submit"
              type="primary"
              loading={isProcessing}
            >
              Decline
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DeclineButton;
