import React from 'react'
import { WalletPage } from '../../components/Wallet'
import { WalletHeader } from '../../components/Wallet/WalletHeader'

function Wallet() {
    return (
        <div>
            <WalletHeader/>
            <WalletPage/>
        </div>
    )
}

export default Wallet
