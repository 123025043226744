import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import Overview from "../../components/Guarantors/SingleGuarantor/Overview";
import Summary from "../../components/Guarantors/SingleGuarantor/Summary";
import BackBtn from "../../utils/BackButton";

const SingleGuarantor = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: guarantor } = useQuery(
    `single_guarantor-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/guarantor?id=${id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className="flex justify-between mb-3">
          <BackBtn />
        </div>
        <Overview data={guarantor?.data} />
        <Summary data={guarantor?.data} />
    </>
  );
};

export default SingleGuarantor;
