import React from "react";
import SetupLoan from "../../components/AdminSetup/SetupLoan";

function LoanApproval() {
  return (
    <div>
      <SetupLoan />
    </div>
  );
}

export default LoanApproval;
