import React from "react";
import timeDate from "../../../utils/TimeDate";
import { Table, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

function NoteTable({ isLoading }) {
  const history = useHistory();
  const { notes } = useSelector((state) => state.noteTableReducer);
  const { profile } = useSelector((state) => state.profileReducer );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Reference",
      dataIndex: "ref",
      key: "ref",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      //   render: (_, record) => (
      //     <div>
      //         {record.}
      //     </div>
      //   ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() > new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          onClick={() => {
            history.push(`/requests/${record.loan_request_id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={notes}
      loading={isLoading}
      pagination={{ position: ["bottomLeft"] }}
    />
  );
}

export default NoteTable;
