import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoanProduct from "../AdminSetup/LoanProduct";
import { LOANPRODUCTSEARCHVALUE } from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";

function TableHeader({ refetch }) {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue } = useSelector((state) => state.loanProductTableReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeAble = () => {
    refetch();
    setIsModalOpen(false);
  };

  const handleSearch = (e) => {
    dispatch({ type: LOANPRODUCTSEARCHVALUE, value: e.target.value });
  };
  return (
    <div className="mb-5">
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        Create, Manage & Configure all the products you have created on
        Configure
      </p>
      <div className="flex items-center justify-between">
        <div className="flex">
          <Input
            onChange={handleSearch}
            value={searchValue}
            size="large"
            placeholder="Search by product name"
            style={{ margin: "0px 10px" }}
            prefix={<SearchOutlined />}
          />
        </div>
        <div>
          <Button
            type="primary"
            size="large"
            style={{ fontSize: "12px" }}
            onClick={() => setIsModalOpen(true)}
          >
            {session?.admin?.category === "merchant"
              ? "Create New credit order"
              : "Create new loan product"}
          </Button>
        </div>
      </div>

      <Modal
        width="50%"
        visible={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="my-6">
          <LoanProduct isModal={true} closeAble={closeAble} />
        </div>
      </Modal>
    </div>
  );
}

export default TableHeader;
