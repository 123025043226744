import { Card } from "antd";
import React from "react";
import RequestsTable from "../Table/LoanRequests/AllRequests";
import ApprovedRequestsTable from "../Table/LoanRequests/ApprovedRequests";
import TableHeader from "./TableHeader";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SETLOANSTATUSTOSEARCHFOR } from "../../redux/Types/tableTypes";

const { TabPane } = Tabs;

function RequestTable({ isLoading }) {
  const dispatch = useDispatch();
  const { status } = useSelector(
    (state) => state.loanRequestsTableReducer
  );

  const handleFilterByStatus = (val) => {
    dispatch({ type: SETLOANSTATUSTOSEARCHFOR, value: val });
  };
  return (
    <Card>
      <TableHeader />
      <Tabs
        size="default"
        type="card"
        onTabClick={(key) => handleFilterByStatus(key)}
        style={{ marginTop: "20px", marginBottom: "0" }}
        activeKey={status}
      >
        <TabPane tab="Pending Requests" key="saved">
          <RequestsTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Approved Requests" key="approved">
          <ApprovedRequestsTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="Declined Requests" key="rejected">
          <RequestsTable isLoading={isLoading} />
        </TabPane>
        <TabPane tab="All Requests" key="">
          <RequestsTable isLoading={isLoading} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default RequestTable;