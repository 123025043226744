import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Input,
  message,
  Button,
  Radio,
} from "antd";
import {
  currencyParser,
  useCurrenncyFormatter,
} from "../../utils/currencyFormatter";
import AddNewCollateralType from "./AddNewCollateralType";
import CustomInfo from "./CustomInfo";
import UseGenerateComponent from "../../utils/displayInputTypes";
import { useMutation, useQuery } from "react-query";
import Requests from "../../utils/request";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router";
import string_to_slug from "../../utils/StringToSlug";

const { Option } = Select;

const CollateralInfo = ({
  isProcessing,
  setProcessing,
  data,
  setVisible,
  refetch,
}) => {
  const { session } = useAuth();
  const { id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [isDropdown, setIsDropDown] = useState(false);
  const [ID, setID] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const formReset = React.createRef();
  const { displayInputType } = UseGenerateComponent();
  const { currencyFormatter } = useCurrenncyFormatter();

  const { data: collateral_types, refetch: refetchTypes } = useQuery(
    `collateral_types`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product/collateral_type`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleChange = (values) => {
    setIsSelected(true);

    collateral_types?.data?.forEach((type) => {
      if (type.slug === values) {
        setID(type.id);
        setCustomFields(type.custom_fields);
      }
      return null;
    });
  };

  useEffect(() => {
    formReset?.current?.setFieldsValue({
      collateral_type: "",
      product_name: "",
      date_purchased: "",
      value: "",
      serial_number: "",
      model_name: "",
      model_number: "",
      is_repossesed: "",
      is_deposited_in_branch: "",
      is_collateral_with_borrower: "",
      is_collateral_returned_to_borrower: "",
      is_repossesion_initiated: "",
      is_under_auction: "",
      custom_fields: {},
    });
  }, [formReset]);

  const custom_fields = (values, fields) => {
    const obj = {};
    if (fields) {
      fields.forEach(
        (field) => (obj[field.field_name] = values[field.field_name])
      );
      return obj;
    }

    return obj;
  };

  const mutationCollateralInfo = useMutation((collateral) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/collateral`,
      "POST",
      session.session.token,
      collateral
    )
  );

  const handleSubmit = (values) => {
    const info = {
      loan_request_id: parseInt(id),
      type: values.type,
      product_name: values.product_name,
      product_slug: string_to_slug(values.product_name),
      date_purchased: values.date_purchased,
      value: values.value,
      serial_number: values.serial_number,
      model_name: values.model_name,
      model_number: values.model_number,
      status: values.status,
      custom_fields: {
        ...(values && custom_fields(values, customFields)),
      },
    };

    setProcessing(true);
    mutationCollateralInfo.mutate(info, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
        setVisible(false);
      },
      onError: (err) => {},
    });
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={8}>
            <Form.Item
              label="Collateral Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Collateral type is required",
                },
              ]}
            >
              <Select size="large" onChange={handleChange}>
                {collateral_types !== undefined &&
                  collateral_types?.data?.map((type) => (
                    <Option value={type.slug} key={type.id}>
                      {type.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6}>
            <AddNewCollateralType data={data} refetchTypes={refetchTypes} />
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Product Name"
              name="product_name"
              rules={[
                {
                  required: true,
                  message: "product name is required",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Date purchased"
              name="date_purchased"
              rules={[
                {
                  required: true,
                  message: "date purchased is required",
                },
              ]}
            >
              <DatePicker size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  message: "value is required",
                },
              ]}
            >
              <InputNumber
                formatter={currencyFormatter}
                parser={currencyParser}
                size="large"
                className="100%"
                style={{ width: "100%" }}
                placeholder="0"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Product Serial Number" name="serial_number">
              <Input
                size="large"
                className="100%"
                style={{ width: "100%" }}
                placeholder="0"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Model Name" name="model_name">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Model Number" name="model_number">
              <Input
                size="large"
                className="100%"
                style={{ width: "100%" }}
                placeholder="0"
              />
            </Form.Item>
          </Col>
          {isSelected &&
            customFields?.map((field, index) => (
              <Col xs={24} md={8} key={index}>
                <Form.Item
                  label={field?.field_name}
                  name={field?.field_name}
                  rules={[
                    {
                      required: true,
                      message: "required",
                    },
                  ]}
                >
                  {customFields !== undefined &&
                    displayInputType(field?.field_type, field?.dropdown_item)}
                </Form.Item>
              </Col>
            ))}
          <Col xs={24} md={24}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "status is required",
                },
              ]}
            >
              <Radio.Group size="large" style={{ width: "100%" }}>
                <Radio value="collateral is repossessed">
                  Collateral is repossesed
                </Radio>
                <Radio value="collateral is with borrower">
                  Collateral with Borrower
                </Radio>
                <Radio value="collateral has been returned to borrower">
                  {" "}
                  Collateral is returned to Borrower{" "}
                </Radio>
                <Radio value="repossession of collateral is inititated">
                  {" "}
                  Collateral Repossession initiated{" "}
                </Radio>
                <Radio value="collateral is under auction">
                  Collateral is under Auction{" "}
                </Radio>
                <Radio value="collateral is deposited in branch">
                  Collateral is deposited with Lender{" "}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col md={24} className="mb-5">
            {isSelected && (
              <CustomInfo
                isDropdown={isDropdown}
                setIsDropDown={setIsDropDown}
                id={ID}
                refetchTypes={refetchTypes}
                setCustomFields={setCustomFields}
                customFields={customFields}
              />
            )}
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CollateralInfo;
