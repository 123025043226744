import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { AppProvider } from "../../context/FetchContext";
import Requests from "../../utils/request";
import MerchantView from "../../components/LoanRequests/SingleRequest/MerchantView";
import LenderView from "../../components/LoanRequests/SingleRequest/LenderView";
import ScrollToTop from "../../components/Layout/ScrollRestore";

const SingleRequest = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: loan_request, refetch: refetchLoanRequest } = useQuery(
    `single_loan_requests-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request?id=${id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: credit_order, refetch: refetchCreditOrder } = useQuery(
    `single_credit_order-${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order?id=${id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false },
    {
      enabled: !!loan_request,
    }
  );

  const providerValue = {
    request:
      session?.admin?.category === "merchant"
        ? credit_order?.data
        : loan_request?.data,
    refetch:
      session?.admin?.category === "merchant"
        ? refetchCreditOrder
        : refetchLoanRequest,
  };

  return (
    <>
      <ScrollToTop />
      <AppProvider value={{ ...providerValue }}>
        {session?.admin?.category === "merchant" ? (
          <MerchantView session={session} id={id} credit_order={credit_order} />
        ) : (
          <LenderView session={session} id={id} loan_request={loan_request} />
        )}
      </AppProvider>
    </>
  );
};

export default SingleRequest;
