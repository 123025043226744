import { Card } from "antd";
import React from "react";
import { Title } from "../../Typography";
import { Link } from 'react-router-dom'
import PortfolioTable from "../../Table/Disbursements/PortfolioTable";
import { useAuth } from "../../../context/AuthContext";
import OrderPortfolioTable from "../../Table/Disbursements/OrderPortfolioTable";

function Portfolio({ isLoading, isLoadingOrderMetrics }) {
    const { session } = useAuth();
    const style = { background: '#ffffff', padding: '5px', borderRadius: '12px' };
    return (
        <Card>
            <div style={style}>
                <div className="flex justify-between">
                    <Title level={4} style={{ color: '#2E2C34' }}>Top Portfolios</Title>
                    <Link>View all</Link>
                </div>
                <div style={{marginTop: '20px'}}>
                    {session?.admin?.category === "merchant" ?
                        <OrderPortfolioTable isLoading={isLoadingOrderMetrics} /> :
                        <PortfolioTable isLoading={isLoading} />
                    }
                </div>
            </div>
        </Card>
    )
}

export default Portfolio;