import React from "react";
import { Card, Divider, DatePicker } from "antd";
import { Text, Title } from "../../../components/Typography";
import { ReactComponent as BusinessChart } from "../../../assets/svg/BusinessChart.svg";
import { useDispatch, useSelector } from "react-redux";
import Requests from "../../../utils/request";
import { useQuery } from "react-query";
import { SETOVERVIEWMETRICSENDDATE, SETOVERVIEWMETRICSSTARTDATE } from "../../../redux/Types/generalTypes";

const { RangePicker } = DatePicker;

function BusinessPerformance({ session }) {
  const dispatch = useDispatch();
  const { metricsStartDate, metricsEndDate } = useSelector((state) => state.overviewMetricsReducer);

  
  const { data: business_performance } = useQuery(
    `business-performance-metrics-start=${metricsStartDate}&end=${metricsEndDate}`, 
    () =>
    Requests.fetchWithAuthToken(
      `/lms/metrics?start=${metricsStartDate}&end=${metricsEndDate}&section=business-performance`,
      "GET",
      session.session.token
    )
  );

  const startDateFilter = (e) => {
    dispatch({ type: SETOVERVIEWMETRICSSTARTDATE, value: e.metricsStartDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETOVERVIEWMETRICSENDDATE, value: e.metricsEndDate });
  };

  const style = { padding: "15px" };
  return (
    <Card className="h-full" size="small" style={style}>
      <div className="flex justify-between -my-3">
        <Text>Business Performance</Text>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { metricsStartDate: value[0] },
              endDateFilter({ metricsEndDate: value[1] })
            )
          }
          defaultValue={[metricsStartDate, metricsEndDate]}
          format={"YYYY-MM-DD"}
          on
          size={"small"}
          style={{ width: "178px", fontSize: "9px" }}
        />
      </div>
      <Divider />
      <div className="space-y-2">
        <div className="flex justify-between">
          <div>
            <Title level={4}>{business_performance?.data?.total_users}</Title>
            <Text>Total Users</Text>
          </div>
          <div>
            <BusinessChart />
          </div>
        </div>
        <div className="flex justify-between absolute inset-x-0 bottom-0 p-6">
          <div>
            <Title level={4}>{business_performance?.data?.total_active_loans}</Title>
            <Text>
              {session?.admin?.category === "merchant" ? "Total Active Orders" : "Total Active Loans"}
            </Text>
          </div>
          <div>
            <BusinessChart />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default BusinessPerformance;
