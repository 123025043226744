import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const BackBtn = () => {
  const history = useHistory();
  return(
    <Button
      type="ghost"
      icon={<ArrowLeftOutlined/>}
      onClick={() => history.goBack()}>Back</Button>
  )
}

export default BackBtn;