import { 
  ADDALLBANKSTATEMENTS,
} from '../../Types/tableTypes';

const initialState = { 
  bankstatements: [], 
};

export const bankStatementsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLBANKSTATEMENTS ) {
    return { ...state, bankstatements: actions.value }
  }

  return state;
}

export default bankStatementsTableReducer;