import React from "react";
import { Col } from "antd";
import { Text, Title } from "../../Typography";
import moment from "moment";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function RepaymentCard({ heading, amount, text, logo, repaymentDate, data }) {
	const { currencyFormatter } = useCurrenncyFormatter();
	const style = {
		background: "#ffffff",
		padding: "20px 20px 35px 20px",
		borderRadius: "12px",
		border: "1px solid #f0f0f0",
		height: "23vh",
	};
	return (
		<Col className="gutter-row" span={8}>
			{data?.total_repayment?.value === data?.paid_so_far?.value &&
			heading === "Next Repayment" ? (
				""
			) : (
				<div style={style}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Text>{heading}</Text>
							<Title level={3} style={{ marginTop: "0.8em" }}>
								{currencyFormatter(amount)}
							</Title>
						</div>
						<div>
							<img src={logo} alt={heading} />
						</div>
					</div>
					<hr />
					<div style={{ display: "flex", alignItems: "center" }}>
						<Text style={{ marginTop: "15px", display: "inline-block" }}>
							{text}
						</Text>
						{
							repaymentDate ? (
								<Text
									style={{
										marginTop: "15px",
										display: "inline-block",
										fontWeight: "900",
										marginLeft: "10px",
									}}
								>
									{" "}
									{`${moment(repaymentDate).format("MMM Do YYYY")}`}
								</Text>
							) : (
								""
							)
						}
					</div>
				</div>
			)}
		</Col>
	);
}

export default RepaymentCard;
