import moment from "moment";
import {
  ADDALLLOANREQUESTGUARANTORS,
  LOANREQUESTGUARANTORSSEARCHVALUE,
  SETLOANREQUESTGUARANTORSENDDATE,
  SETLOANREQUESTGUARANTORSSTARTDATE,
  SETLOANREQUESTGUARANTORSSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  guarantors: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const loanRequestsGuarantorsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANREQUESTGUARANTORS) {
    return { ...state, guarantors: actions.value };
  }

  if (actions.type === LOANREQUESTGUARANTORSSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETLOANREQUESTGUARANTORSSTATUS) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETLOANREQUESTGUARANTORSENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETLOANREQUESTGUARANTORSSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
