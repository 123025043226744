import { createContext, useContext, useState } from "react";
const AppContext = createContext();

function AppProvider({ children, value }) {
  const [reFetchs, setRefetch] = useState({});
  return (
    <AppContext.Provider value={{ ...value, reFetchs, setRefetch }}>
      {children}
    </AppContext.Provider>
  );
}

const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within an AppProvider");
  }

  return context;
};

export { AppProvider, useApp };
