import { Input, Row, Col, Badge, Dropdown, Menu, message } from "antd";
import SecureLS from "secure-ls";
import { useAuth } from "../../context/AuthContext";
import {
  SearchOutlined,
  BellFilled,
  CaretDownOutlined,
  LoginOutlined,
} from "@ant-design/icons";
// import { Monitor } from "../CustomIcons";
import { Text } from "../Typography/";
import Avatar from "antd/lib/avatar/avatar";

const securedStorage = new SecureLS();
function Navbar(props) {
  const { session, signOut, setLoggingOut } = useAuth();
  const name = `${session?.user?.firstname} ${session?.user?.lastname}`;
  const matches = name.match(/\b(\w)/g);
  const acronym = matches.join("");

  const logoutCallBack = (data) => {
    if (data.status !== "success") {
      message.error(data.message, 3);
      setLoggingOut(false);
      return;
    }
    message.success(data.message, 3);
    securedStorage.removeAll();
    setLoggingOut(false);
    window.location.replace("/sign-in");
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        icon={<LoginOutlined />}
        onClick={() =>
          signOut(
            {
              all: false,
              email: "",
              by_ref: true,
              ref: session.session.sign_in_ref,
            },
            logoutCallBack
          )
        }
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="h-20 pl-72 pr-12 flex items-center"
      style={{ background: "#F0F0F4" }}
    >
      <Row className="w-full" gutter={[5, 5]}>
        <Col xs={24} lg={5}>
          <Input
            size="large"
            placeholder="Search loan ID, names…"
            width="20px"
            prefix={<SearchOutlined />}
          />
        </Col>
        <Col xs={24} lg={19}>
          <div className="flex justify-end items-center h-full space-x-10">
            {/* <span className="flex space-x-2">
              <Icon component={Monitor} style={{ fontSize: "20px" }} />
              <Text style={{ fontFamily: "Cera Pro Medium" }}>Take a Tour</Text>
            </span> */}

            <div className="flex space-x-8 items-center">
              <Badge>
                <BellFilled style={{ fontSize: "20px", color: "#84818A" }} />
              </Badge>

              <span className="flex items-center space-x-3 cursor-pointer">
                <Avatar size={30} style={{ background: "#4090A2" }}>
                  <Text style={{ fontSize: 15, color: "#fff" }}>{acronym}</Text>
                </Avatar>
                <Dropdown overlay={menu}>
                  <span className="flex items-center space-x-2">
                    <Text style={{ fontFamily: "Cera Pro Medium" }}>
                      {name}
                    </Text>
                    <CaretDownOutlined
                      style={{ fontSize: "15px", color: "#84818A" }}
                    />
                  </span>
                </Dropdown>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Navbar;
