import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker } from "antd"; 
import { ALLBORROWERSEARCHVALUE, SETBORROWERENDDATE, SETBORROWERSTARTDATE } from "../../redux/Types/tableTypes";
import { useDispatch, useSelector } from "react-redux";

const { RangePicker } = DatePicker;

function TableHeader(props) {
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) => state.borrowersTableReducer);

  const handleSearch = (e) => {
    dispatch({ type: ALLBORROWERSEARCHVALUE, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETBORROWERSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETBORROWERENDDATE, value: e.endDate });
  };

  return (
      <div className="mb-5"> 
        <h3 style={{ fontSize: '24px', fontWeight: 'bold' }}>All Borrowers</h3>
        <p style={{ marginBottom: '30px', marginTop: '0' }}>Borrowers allows you to track all your borrowers and their loans.</p>
        <div className="flex items-center justify-between">
          <div className="flex">
              <Input
                  size="large"
                  placeholder="Search..."
                  style={{ width: '75%', margin:'0px 10px' }}
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
              />
              </div>
              <RangePicker
                onChange={(value) =>
                  startDateFilter(
                    { startDate: value[0] },
                    endDateFilter({ endDate: value[1] })
                  )
                }
                defaultValue={[startDate, endDate]}
                format={dateFormat}
                on
              />
      </div>
    </div>
  )
}

export default TableHeader
