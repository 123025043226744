import React from "react";
import { Row, Col } from "antd";
import PercentCard from "../../components/Loan/cards/PercentCard";
import overdue from "../../assets/svg/Overdue.svg";
import DefaultLoans from "../../components/Loan/cards/DefaultLoans";
import AllOverdueLoans from "../../components/Loan/cards/AllOverdueLoans";
import { LoanHeader } from "../../components/Loan/Header";
import Breadcrumbs from "../../components/Loan/Breadcrumb";

function OverdueLoans(props) {
  return (
    <div>
      <Breadcrumbs link="overdue" name="Overdue" />
      <LoanHeader overdue={true} title="Overdue" />
      <Row gutter={[16, 30]}>
        <Col span={6}>
          <PercentCard
            icon={overdue}
            heading="Overdue Loans"
            textColor="#FF4B4B"
            amount="299,214,000"
            count="302,000"
            value="30"
          />
        </Col>
        <Col span={18}>
          <DefaultLoans />
        </Col>
        <Col span={24}>
          <AllOverdueLoans />
        </Col>
      </Row>
    </div>
  );
}

export default OverdueLoans;
