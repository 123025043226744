import React from "react";
import Kyc from "../../components/AdminSetup/Kyc";

function CompanyProfile() {
  return (
    <div>
      <Kyc />
    </div>
  );
}

export default CompanyProfile;
