import React from "react";
import { Table, Button } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import UpdateCollateral from "../../Collateral/UpdateCollateral";

function AllCollateralsTable({ isLoading }) {
  const { all_collaterals } = useSelector(
    (state) => state.allCollateralsTableReducer
  );
  const history = useHistory();

  const columns = [
    {
      title: "Id",
      dataIndex: "loan_request_id",
      key: "loan_request_id",
    },
    {
      title: "Collateral Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },

    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-4 items-center">
          <UpdateCollateral
            collateral_id={record.id}
            loan_request_id={record.loan_request_id}
          />
          <Button
            onClick={() => {
              history.push(`/requests/${record.loan_request_id}`);
            }}
          >
            See Associated Loan Request
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={all_collaterals}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
        rowKey="id"
      />
    </>
  );
}

export default AllCollateralsTable;
