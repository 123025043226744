import React, { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import BackBtn from "../../../utils/BackButton";
import ApproveButton from "./ApproveButton";
import DeclineButton from "./DeclineButton";
import DisbursementButton from "./DisbursementBtn";
import FulfilmentButton from "./FulfillmentBtn";
import { Button } from "antd";
import OfferTemplate from "./OfferTemplate";
import RevertButton from "./RevertButton";

const Heading = ({ data, id }) => {
  const { session } = useAuth();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="flex justify-between mb-5">
      <div>
        <BackBtn />
      </div>
      <div className="space-x-4">
        {data?.lender_status === "saved" && (
          <>
            <ApproveButton />
            <DeclineButton />
          </>
        )}
        {data?.lender_status === "approved" && (
          <>
            {session?.admin?.category === "merchant" ? (
              <FulfilmentButton data={data} />
            ) : (
              <>
                <DisbursementButton data={data} />
                <Button type="primary" size="large" onClick={showDrawer}>
                  Create Offer Letter
                </Button>
                {!data?.disbursed && (
                  <RevertButton />
                )}
                {showDrawer ? (
                  <OfferTemplate
                    visible={visible}
                    onClose={onClose}
                    data={data}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
        {data?.lender_status === "rejected" && (
          <RevertButton />
        )}
      </div>
    </div>
  );
};

export default Heading;
