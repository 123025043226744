import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { Card, Form, Col, Row, Button, message } from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import useGenerateComponent from "../../../../utils/componentGenerator";
import { Title } from "../../../Typography";
const customform = React.createRef();

function Custom({ currentNumber, id, setCurrentNumber, slug }) {
  const dispatch = useDispatch();
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);
  const { session } = useAuth();
  const [loading, setIsLoading] = useState(false);
  const { submitCustom, updateProgress } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();
  const { data } = useQuery(
    `custom_data-slug-${slug}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/user/loan_request/${id}/custom_data?action=read`,
        "POST",
        session?.admin?.token,
        {
          initial_slug: slug,
        }
      ),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      setCurrentNumber((prev) => {
        const current = Number(prev) + 1;
        return current.toString();
      });
      updateProgress(
        { progress: String(Number(currentNumber) + 1), loan_requestID: id },
        (data) => data
      );

      return;
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };
  const getDataType = (dataType) => {
    if (dataType === "string") {
      return "string_value";
    }

    if (dataType === "number") {
      return "number_value";
    }

    if (dataType === "boolean") {
      return "boolean_value";
    }
  };

  const handleSubmit = (value) => {
    const fields = generateState(currentLoan?.segments[slug]?.fields);
    const newObject = Object.keys(value).map((key) => ({
      ...fields[key],
      [getDataType(fields[key].data_type)]: value[key],
    }));

    setIsLoading(true);

    const details = {
      loan_requestID: id,
      fields: {
        initial_slug: slug,
        fields: newObject,
      },
    };

    submitCustom(details, handleSubmitCallback);
  };

  const generateFormObject = (fields) => {
    const formObject = {};

    fields.forEach((field) => {
      formObject[field?.field] = field[getDataType(field?.data_type)];
    });

    return formObject;
  };

  const generateState = (fields) => {
    const object = {};
    fields.forEach((field) => {
      object[field.field] = field;
    });

    return object;
  };

  useEffect(() => {
    if (data) {
      const fields = Object.values(data?.data?.fields || {});

      const formObj = generateFormObject(fields);
      customform.current?.setFieldsValue({
        ...formObj,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Card>
      <Title level={3}>{currentLoan?.segments[slug]?.segment}</Title>
      <Form ref={customform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          {currentLoan?.segments[slug]?.fields?.map(
            (field, index) =>
              field?.is_needed && (
                <Col xs={24} md={12} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(
                      field,
                      {
                        ref: customform,
                        fieldName: field?.field,
                      },
                      dispatch
                    )}
                  </Form.Item>
                </Col>
              )
          )}
        </Row>

        <Button
          className="lg:w-auto w-full"
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
        >
          Save and Continue
        </Button>
      </Form>
    </Card>
  );
}

export default Custom;
