import { FolderOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import strip from "../../../../utils/StripWords";
import { Text } from "../../../Typography";
import AddUploads from "./UpdateDetails/AddUploads";

const DocumentUploads = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: document_uploads, refetch: refetchUploads } = useQuery(
    "document_uploads",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=uploads`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  return (
    <div className="mt-5">
      <Row gutter={[16, 16]}>
        {document_uploads?.data?.map((upload) => (
          <Col span={12} key={upload?.document_url}>
            <Text className="capitalize">{strip(upload?.title)}</Text>
            <Button type="link" target="_blank" href={upload?.document_url}>
              <FolderOutlined /> {upload?.document_url}
            </Button>
            {/* <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              <Document file={upload?.document_url} />
            </Modal> */}
          </Col>
        ))}
        {document_uploads?.data === null && (
          <Col span={24}>
            <Empty />
          </Col>
        )}
      </Row>
      <div className="flex justify-end">
        <AddUploads
          data={document_uploads?.data?.map((upload) => upload)}
          refetchUploads={refetchUploads}
        />
      </div>
    </div>
  );
};

export default DocumentUploads;
