import { Input, InputNumber, Select } from "antd";
import { currencyParser, useCurrenncyFormatter } from "./currencyFormatter";
import Upload from '../components/Uploader'

const { Option } = Select;

const UseGenerateComponent = () => {
  const { currencyFormatter } = useCurrenncyFormatter()
  const displayInputType = (field, dropdown) => {
    if (field === "upload") {
      return (
        <Upload />
      );
    }
  
    if (field === "is_money") {
      return (
        <InputNumber
          size="large"
          style={{ width: "100%" }}
          formatter={currencyFormatter}
          parser={currencyParser}
        />
      );
    }
  
    if (field === "string") {
      return <Input className="capitalize" size="large" />;
    }
  
    if (field === "dropdown") {
      return (
        <Select size="large">  
            {dropdown?.toString().split(",")?.map((list) =>
              <Option value={list} className="capitalize">{list}</Option>
            )}
        </Select>
      );
    }
  
    if (field === "boolean") {
      return (
        <Select size="large">  
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      );
    }
  
    if (field === "number") {
      return (
        <InputNumber
          size="large"
          style={{ width: "100%" }}
        />
      );
    }
  }

  return { displayInputType }
}

export default UseGenerateComponent;
