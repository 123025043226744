import { DeleteFilled } from "@ant-design/icons";
import { Radio, Input, Form, Card } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/AuthContext";

import {
  EDITDROPDATA,
  EDITFIELD,
  EDITFIELDNAME,
  REMOVEFIELD,
} from "../../redux/Types/generalTypes";
import Requests from "../../utils/request";

function AddCustomFieldBox({ ID }) {
  const { session } = useAuth();
  const [isNumber, setIsNumber] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isDropdown, setIsDropDown] = useState(false);
  const dispatch = useDispatch();
  //   const { IDs } = useSelector((state) => state.CustomFieldsReducer);

  const { data: types } = useQuery("data-types", () =>
    Requests.fetchWithAuthToken(
      "/lms/utils/data_types",
      "GET",
      session.session.token
    )
  );

  const formatTypesToFitRadioStructure = (types = []) => {
    const newTypes = [
      ...types,
      { description: "File Upload", value: "upload" },
    ];
    return newTypes?.map((type) => ({
      label: type.description,
      value: type.value,
    }));
  };

  const onChange = (e) => {
    if (e.target.value === "upload") {
      setIsNumber(false);
      setIsText(false);
      setIsDropDown(false);
      dispatch({ type: EDITFIELD, ID, key: "is_upload", value: true });
      dispatch({ type: EDITFIELD, ID, key: "data_type", value: "string" });
      return;
    }

    dispatch({ type: EDITFIELD, ID, key: "data_type", value: e.target.value });
    if (e.target.value === "number") {
      setIsNumber(true);
      setIsText(false);
      setIsDropDown(false);
      dispatch({ type: EDITFIELD, ID, key: "is_upload", value: false });
      return;
    }

    if (e.target.value === "string") {
      setIsText(true);
      setIsNumber(false);
      return;
    }
    setIsDropDown(false);
    setIsNumber(false);
    setIsText(false);
  };

  const onChangeDropDown = (e) => {
    dispatch({
      type: EDITFIELD,
      ID,
      key: "is_dropdown",
      value: e.target.value,
    });
    if (e.target.value) {
      setIsDropDown(true);
      return;
    }
    setIsDropDown(false);
  };

  useEffect(() => {
    if (!isDropdown || !isText) {
      return dispatch({
        type: EDITFIELD,
        ID,
        key: "is_dropdown",
        value: false,
      });
    }

    if (!isNumber) {
      return dispatch({
        type: EDITFIELD,
        ID,
        key: "is_money",
        value: false,
      });
    }
    // eslint-disable-next-line
  }, [isDropdown, isNumber, isText]);
  return (
    <Card hoverable>
      <Form layout="vertical">
        <Form.Item label="Field Name">
          <Input
            onChange={(e) =>
              dispatch({
                type: EDITFIELDNAME,
                ID,
                value: e.target.value,
              })
            }
          />
        </Form.Item>

        <Form.Item label="Required Field?">
          <Radio.Group
            onChange={(e) =>
              dispatch({
                type: EDITFIELD,
                ID,
                key: "is_required",
                value: e.target.value,
              })
            }
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item label="Field Type">
          <Radio.Group
            onChange={onChange}
            options={formatTypesToFitRadioStructure(types?.data)}
          />
        </Form.Item>
        {isText && (
          <>
            <Form.Item label="Is this a dropdown ?">
              <Radio.Group
                onChange={onChangeDropDown}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
              />
            </Form.Item>
          </>
        )}

        {isDropdown && (
          <Form.Item label="Dropdown options">
            <Input.TextArea
              onChange={(e) => {
                dispatch({
                  type: EDITDROPDATA,
                  ID,
                  value: e.target.value,
                });
              }}
              placeholder="Separate option with comma"
            />
          </Form.Item>
        )}

        {isNumber && (
          <Form.Item label="Does the field involve money ?">
            <Radio.Group
              onChange={(e) =>
                dispatch({
                  type: EDITFIELD,
                  ID,
                  key: "is_money",
                  value: e.target.value,
                })
              }
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Form.Item>
        )}
      </Form>

      <div className="flex justify-end">
        <DeleteFilled
          className="text-lg"
          onClick={() => dispatch({ key: ID, type: REMOVEFIELD })}
          style={{ color: "red" }}
        />
      </div>
    </Card>
  );
}

export default AddCustomFieldBox;
