import React from "react";
import Header from "../../components/AdminSetup/Header";
import LoanProduct from "../../components/AdminSetup/LoanProduct";

function FirstLoan() {
  return (
    <div>
      <Header title="Create your first loan product" />
      <LoanProduct />
    </div>
  );
}

export default FirstLoan;
