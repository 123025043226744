import Title from "antd/lib/typography/Title";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
	Branches,
	Clipboard,
	Customer,
	Home,
	Loans,
	Overview,
	Products,
	Sales,
	Shield,
	Subscription,
} from "../CustomIcons";
import Logo from "../../assets/svg/logo.svg";
import {
  BookOutlined,
  CreditCardOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FunnelPlotOutlined,
  MessageOutlined,
  PieChartOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SwapOutlined,
  TeamOutlined,
  UserAddOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import Badge from "./CompanyBadge";
import { useAuth } from "../../context/AuthContext";

function SideBar(props) {
	const { session } = useAuth();
	const businesses = document.getElementsByClassName("businesses");
	const users = document.getElementsByClassName("users");
	const preferences = document.getElementsByClassName("preferences");

	return (
		<div className="flex-shrink-0 w-60 py-5 md:block hidden h-full sidebar-scroll scrollbar">
			<div className="flex-auto w-full flex px-5">
				<img alt="evolve logo" src={Logo} className="w-full" />
			</div>

			<Badge />

			{session.access_to.super && (
				<SideLink
					label="Get Started"
					to="/adminsetup"
					icon="home"
					activeOnlyWhenExact={false}
				/>
			)}

			<SideLink
				label="Overview"
				to="/"
				icon="overview"
				activeOnlyWhenExact={true}
			/>

			{users[0]?.hasChildNodes() && (
				<Title
					style={{ fontSize: "14px" }}
					className="flex px-5 my-5 uppercase"
				>
					Users
				</Title>
			)}
			<div className="users">
				{session.access_to.loan_requests && (
					<SideLink
						label={
							session.admin.category === "merchant"
								? "Credit Order"
								: "Loan Requests"
						}
						to="/requests"
						icon="sales"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.loans && session.admin.category === "lender" && (
					<SideLink
						label="Loans"
						to="/loan"
						icon="loans"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.disbursements && (
					<SideLink
						label={
							session.admin.category === "merchant"
								? "Fulfilment"
								: "Disbursement"
						}
						to={
							session.admin.category === "merchant"
								? "/fulfilment"
								: "/disbursement"
						}
						icon="card"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.repayments && (
					<SideLink
						label="Repayments"
						to="/repayments"
						icon="card"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.borrowers && (
					<SideLink
						label="Borrowers"
						to="/borrowers"
						icon="borrowers"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.guarantors &&
					session.admin.category === "lender" && (
						<SideLink
							label="Guarantors"
							to="/guarantors"
							icon="user"
							activeOnlyWhenExact={false}
						/>
					)}

          { session.admin.category === "lender" && (
            <SideLink
              label="Collaterals"
              to="/collaterals"
              icon="collateral"
              activeOnlyWhenExact={false}
            />
          )}

        {session.access_to.blacklist && session.admin.category === "lender" && (
          <SideLink
            label="Blacklist"
            to="/blacklist"
            icon="blacklist"
            activeOnlyWhenExact={true}
          />
        )}
      </div>
      {businesses[0]?.hasChildNodes() && (
        <Title
          style={{ fontSize: "14px" }}
          className="flex px-5 my-5 uppercase"
        >
          Businesses
        </Title>
      )}
      <div className="businesses">
        {session.access_to.wallet && (
          <SideLink
            label="Wallet"
            to="/wallet"
            icon="wallet"
            activeOnlyWhenExact={true}
          />
        )}

				{session.access_to.products && (
					<SideLink
						label="Products"
						to="/products"
						icon="products"
						activeOnlyWhenExact={false}
					/>
				)}

				{session.access_to.transactions && (
					<SideLink
						label="Transactions"
						to="/transaction"
						icon="transaction"
						activeOnlyWhenExact={false}
					/>
				)}
				{session.access_to.transactions && (
					<SideLink
						label="Reports"
						to="/report"
						icon="report"
						activeOnlyWhenExact={false}
					/>
				)}

        {session.user.is_creator && (
          <SideLink
            label="Team"
            to="/team"
            icon="team"
            activeOnlyWhenExact={true}
          />
        )}

        <SideLink
          label="Agent"
          to="/agent"
          icon="team"
          activeOnlyWhenExact={true}
        />
      </div>

			{preferences[0]?.hasChildNodes() && (
				<Title
					style={{ fontSize: "14px" }}
					className="flex px-5 my-8 uppercase"
				>
					Preferences
				</Title>
			)}
			<div className="preferences">
				{session.access_to.notes && (
					<SideLink
						label="Notes"
						to="/notes"
						icon="notes"
						activeOnlyWhenExact={true}
					/>
				)}
				{session.access_to.settings && (
					<SideLink
						label="Settings"
						to="/settings"
						icon="settings"
						activeOnlyWhenExact={true}
					/>
				)}

				{session.access_to.activity_log && (
					<SideLink
						label="Activity Logs"
						to="/activity_logs"
						icon="clipboard"
						activeOnlyWhenExact={true}
					/>
				)}

				{session.access_to.notifications && (
					<SideLink
						label="Notifications"
						to="/notifications"
						icon="message"
						activeOnlyWhenExact={true}
					/>
				)}
			</div>
			{/* <SideLink
        label="Task"
        to="/task"
        icon="notes"
        activeOnlyWhenExact={true}
      /> */}
		</div>
	);
}

function SideLink({ label, to, icon, activeOnlyWhenExact }) {
	let match = useRouteMatch({
		path: to,
		exact: activeOnlyWhenExact,
	});

	const iconselect = (icon, match) => {
		const icons = {
			home: <Home match={match} />,
			overview: <Overview match={match} />,
			borrowers: <Customer match={match} />,
			loans: <Loans match={match} />,
			subscription: <Subscription match={match} />,
			clipboard: <Clipboard match={match} />,
			sales: <Sales match={match} />,
			shield: (
				<Shield
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			branches: (
				<Branches
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			products: (
				<Products
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			team: (
				<TeamOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			user: (
				<UserAddOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			wallet: (
				<WalletOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			chart: (
				<PieChartOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			card: (
				<CreditCardOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			settings: (
				<SettingOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			blacklist: (
				<FileExcelOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			transaction: (
				<FunnelPlotOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			report: (
				<FileTextOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			approval: (
				<SafetyCertificateOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),
			message: (
				<MessageOutlined
					style={{
						width: "20px",
						height: "20px",
						color: match ? "#fff" : "#9595AC",
					}}
				/>
			),

      collateral: (
        <SwapOutlined
          style={{
            width: "20px",
            height: "20px",
            color: match ? "#fff" : "#9595AC",
          }}
        />
      ),


      notes: (
        <BookOutlined
          style={{
            width: "20px",
            height: "20px",
            color: match ? "#fff" : "#9595AC",
          }}
        />
      ),
    };

		return icons[icon];
	};

	return (
		<div className={`flex items-center ${match ? "match" : ""} px-5 h-8`}>
			<div>{iconselect(icon, match)}</div>
			<Link
				className={`no-underline flex-grow flex justify-start ml-5`}
				style={{ color: match ? "#fff" : "#9595AC" }}
				to={to}
			>
				{label}
			</Link>
		</div>
	);
}

export default SideBar;
