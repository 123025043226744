import React from "react";
import { Row, Col, Card } from "antd";
import { Text, Title } from "../../../components/Typography";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import { Money, Receipt, SwithAccount, Undo } from "../icons";

function TopCards({ data }) {
  const style = {padding: "10px", height: "160px", width: "281.5px"};
  const card1Style = {background: "#5152A6", padding: "10px", height: "160px", width: "281.5px"}
  const textStyle = {color: "#fff"}
  const text1Style = {color: "#000", fontSize: "13px", fontWeight: "bold"}
  return (
    <>
      <Row>
        <Col span={6}>
          <Card style={card1Style} className="h-full" size='small'>
            <div className="flex space-x-2">
              <Money />
              <Text style={textStyle}>Registered Borrowers</Text>
            </div>
            <Title style={textStyle} className="mt-5" level={3}>{data?.registered_borrowers?.count}</Title>
            <div className="flex space-x-2" style={{color: "#fff"}}>
                <RiseOutlined style={{color:" #20C9AC"}} />
                <Title style={textStyle} level={5}>{data?.registered_borrowers?.percentage}%</Title>
                <Text style={textStyle}>+{data?.registered_borrowers?.added_today}</Text>
                <Text style={textStyle}>Added Today</Text>
              </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="h-full" size="small" style={style}>
            <div className="flex space-x-2">
              <SwithAccount />
              <Text style={text1Style}>Borrowers with Active Loans</Text>
            </div>
            <Title className="mt-5" level={3}>{data?.active_loans?.count}</Title>
            <div className="flex space-x-2">
                <RiseOutlined style={{color:" #20C9AC"}} />
                <Title level={5}>{data?.active_loans?.percentage}%</Title>
                <Text>+{data?.active_loans?.added_today}</Text>
                <Text>Added Today</Text>
              </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="h-full" size="small" style={style}>
            <div className="flex space-x-2">
              <Receipt />
              <Text style={text1Style}>Borrowers with Repaid Loans</Text>
            </div>
            <Title className="mt-5" level={3}>{data?.repaid_loans?.count}</Title>
            <div className="flex space-x-2">
                <RiseOutlined style={{color:" #20C9AC"}} />
                <Title level={5}>{data?.repaid_loans?.percentage}%</Title>
                <Text>+{data?.repaid_loans?.added_today}</Text>
                <Text>Added Today</Text>
              </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="h-full" size="small" style={style}>
            <div className="flex space-x-2">
              <Undo />
              <Text style={text1Style}>Borrowers with Abandoned Loans</Text>
            </div>
              <Title className="mt-5" level={3}>{data?.abandoned_loans?.count}</Title>
            <div className="flex space-x-2">
                <FallOutlined style={{color:" #FC3400"}} />
                <Title level={5}>{data?.abandoned_loans?.percentage}%</Title>
                <Text>+{data?.abandoned_loans?.added_today}</Text>
                <Text>today</Text>
              </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default TopCards;