import React from "react";
import { Table, Button, Tag } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";

function BorrowersRepaymentsTable({ isLoading }) {
  const history = useHistory();
  const { borrowersrepayments } = useSelector((state) => state.borrowersRepaymentTableReducer);
  const { profile } = useSelector((state) => state.profileReducer );
  const { currencyFormatter } = useCurrenncyFormatter();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Borrower",
      dataIndex: "borrower",
      key: "borrower",
      render: (text, record) => (
        <p>
          {record.borrower
            ? `${record.borrower?.firstname} ${record.borrower?.lastname}`
            : "N/A"}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <div>{currencyFormatter(text)}</div>,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: "Disbursed date",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() > new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },

    {
      title: "Repayment date",
      dataIndex: "due_on",
      key: "due_on",
      defaultSortOder: "descend",
      sorter: (a, b) =>
        new Date(a.due_on).valueOf() - new Date(b.due_on).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.due_on, profile.date_format)}`}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={
            record.status === "pending"
              ? "#FFD580"
              : record.status === "paid"
              ? "success"
              : "blue"
          }
        >
          <span className="capitalize">{record.status}</span>
        </Tag>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/repayments/${record.id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        className="mt-5"
        loading={isLoading}
        columns={columns}
        dataSource={borrowersrepayments}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default BorrowersRepaymentsTable;
