import moment from "moment";
import {
  ADDALLOVERVIEWLOANREQUEST,
  OVERVIEWLOANREQUESTSEARCHVALUE,
  SETOVERVIEWENDDATE,
  SETOVERVIEWSTARTDATE,
  SETOVERVIEWLOANSTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  loanrequests: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const overViewTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLOVERVIEWLOANREQUEST) {
    return { ...state, loanrequests: actions.value };
  }

  if (actions.type === OVERVIEWLOANREQUESTSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETOVERVIEWLOANSTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  if (actions.type === SETOVERVIEWENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETOVERVIEWSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
};
