import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function PortfolioTable({ isLoading }) {
    const { portfolios } = useSelector((state) => state.portfoliosTableReducer )
    const { currencyFormatter } = useCurrenncyFormatter();

    const columns = [
        {
            title: "Portfolio Title",
            dataIndex: "product_name",
            key: "product_name",
        },
        {
            title: "Size",
            dataIndex: "size",
            key: "size",
        },

        {
            title: "Amount disbursed",
            dataIndex: "amount",
            key: "amount",
            render: (text, record) => <p>{currencyFormatter(text)}</p>,
        },
    ];

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={portfolios}
                pagination={{ pageSize: 5, position: ["bottomLeft"] }}
                size="small"
            />
        </>
    )
}

export default PortfolioTable;
