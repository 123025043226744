import { 
  ADDALLREPAYEMENTS, 
  REPAYMENTSEARCHVALUE,
  SETREPAYMENTENDDATE,
  SETREPAYMENTSTARTDATE,
  SETALLREPAYMENTSTATUSTOSEARCHFOR,
} from '../../Types/tableTypes';

import moment from "moment";

const initialState = { 
  repayments: [],
  searchValue: "",
  status: "pending",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const repaymentsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLREPAYEMENTS ) {
    return { ...state, repayments: actions.value }
  }

  if (actions.type === REPAYMENTSEARCHVALUE ) {
    return { ...state, searchValue: actions.value }
  }

  if (actions.type === SETALLREPAYMENTSTATUSTOSEARCHFOR ) {
    return { ...state, status: actions.value }
  }

  if (actions.type === SETREPAYMENTENDDATE) {
    return { ...state, endDate: actions.value };
  }

  if (actions.type === SETREPAYMENTSTARTDATE) {
    return { ...state, startDate: actions.value };
  }

  return state;
}

export default repaymentsTableReducer;