import React, { useState } from "react";
import AddTeamMember from "../../components/AdminSetup/AddTeamMember";
import Invite from "../../components/AdminSetup/Invite";

function TeamMembers() {
  const [count, setCount] = useState([1]);
  const [form, setForm] = useState(false);

  const addNewMember = () => {
    setForm(true);
  };

  //Dammy ignore this
  if (false) {
    console.log(form, count, setCount, AddTeamMember);
  }

  return (
    <div>
      <Invite addNewMember={addNewMember} />
    </div>
  );
}

export default TeamMembers;
