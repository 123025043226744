import React from "react";
import { Table, Button, Tag } from "antd";
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";

function TransactionsTable({ isLoading }) {
  const history = useHistory();
  const { transactions } = useSelector((state) => state.transactionsTableReducer);
  const { profile } = useSelector((state) => state.profileReducer );
  const { currencyFormatter } = useCurrenncyFormatter();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <div>
          <span>{record.id}</span>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record?.amount)}</span>
        </div>
      ),
    },

    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => (
        <div>
          <span>{record.type}</span>
        </div>
      ),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: (_, record) => (
        <div>
          <span>{record.channel}</span>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "gateway_response",
      key: "gateway_response",
      render: (_, record) => {
        let color = record?.paystack?.data?.gateway_response === "Approved" ? "success" : "volcano";
        let text = record?.paystack?.data?.gateway_response;

        return (
          <Tag color={color} key={text}>
            {text?.toUpperCase()}
          </Tag>
        );
      },
    },

    {
      title: "Time Stamp",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
        onClick={() => {
          history.push(`/transaction/${record.id}`);
        }}
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
  <>
    <Table
      columns={columns}
      loading={isLoading}
      dataSource={transactions}
      pagination={{ position: ["bottomLeft"] }}
      rowSelection={rowSelection}
      size="small"
    />
  </>
  )
}

export default TransactionsTable;
