import moment from "moment";
import {ALLNOTESEARCHVALUE,
  ALLNOTES,
    SETNOTESTATUSTOSEARCHFOR,
} from "../../Types/tableTypes";

const initialState = {
  notes: [],
  searchValue: "",
  status: "",
  endDate: moment().endOf("month"),
  startDate: moment().max("2021-05-01"),
};

export const noteTableReducer = (state = initialState, actions) => {
  if (actions.type ===   ALLNOTES ) {
    return { ...state, notes: actions.value };
  }
  if (actions.type === ALLNOTESEARCHVALUE) {
    return { ...state, notes: actions.value };
  }
  if (actions.type === SETNOTESTATUSTOSEARCHFOR) {
    return { ...state, status: actions.value };
  }

  return state;
};
