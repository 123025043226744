import { SETREQUESTMETRICSSTARTDATE, SETREQUESTMETRICSENDDATE } from "../Types/generalTypes";
import moment from "moment";

const initialState = { 
  metricsEndDate: moment().endOf("month").add(1, 'days'),
  metricsStartDate: moment().max("2021-05-01T20:00:00+0800"), 
};

export const requestMetricsReducer = (state = initialState, actions) => {
  if (actions.type === SETREQUESTMETRICSENDDATE) {
    return { ...state, metricsEndDate: actions.value };
  }

  if (actions.type === SETREQUESTMETRICSSTARTDATE) {
    return { ...state, metricsStartDate: actions.value };
  }

  return state;
}

export default requestMetricsReducer;