import React from "react";
import { Text } from "../../Typography";
import { Col } from "antd";
import TopInsightCard from "./TopInsightCard";
import green from "../../../assets/svg/InsightGreen.svg";
import yellow from "../../../assets/svg/InsightYellow.svg";
import blue from "../../../assets/svg/InsightBlue.svg";
import red from "../../../assets/svg/InsightRed.svg";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function TopInsight({ data }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const style = {
    background: "#ffffff",
    padding: "20px",
    borderRadius: "12px",
    height: "60vh",
  };

  return (
    <>
      <Col className="gutter-row" span={9}>
        <div style={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text
              style={{ fontSize: "16px", fontWeight: "bold", color: "#2E2C34" }}
            >
              Top Insights
            </Text>
          </div>
          <TopInsightCard
            amount={data?.total_active_borrowers}
            title="Active Borrowers"
            logo={green}
            background="#E8FFF7"
            link={true}
          />
          <TopInsightCard
            amount={currencyFormatter(data?.total_disbursed)}
            title="Cumulative Principal Portfolio Disbursed"
            logo={yellow}
            background="#FFFDE8"
          />
          <TopInsightCard
            amount={`${data?.total_fully_paid_loans || 0}/${
              data?.total_unpaid_loans || 0
            }`}
            title="Fully Paid Loans vs Unpaid Loans"
            logo={blue}
            background="#F3F6FF"
          />
          <TopInsightCard
            amount={currencyFormatter(data?.total_penalty_fee)}
            title="Total Penalty Fees"
            logo={red}
            background="#FFF1F1"
            link={true}
          />
        </div>
      </Col>
    </>
  );
}
export default TopInsight;
