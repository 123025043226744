import { Input, InputNumber } from "antd";
import { currencyParser, useCurrenncyFormatter } from "./currencyFormatter";
import Upload from "../components/Uploader";

const UseGenerateComponentNew = () => {
  const { currencyFormatter } = useCurrenncyFormatter();
  const generateInputType = (field) => {
    if (field?.data_type === "upload") {
      return <Upload />;
    }

    if (field?.data_type === "number" && field?.is_money) {
      return (
        <InputNumber
          size="large"
          style={{ width: "100%" }}
          formatter={currencyFormatter}
          parser={currencyParser}
          readOnly
          bordered={false}
        />
      );
    }

    if (
      field?.data_type === "string" ||
      field?.data_type === "dropdown" ||
      field?.data_type === "boolean"
    ) {
      return (
        <Input className="capitalize" size="large" bordered={false} readOnly />
      );
    }

    if (field?.data_type === "number") {
      return (
        <InputNumber
          size="large"
          style={{ width: "100%" }}
          readOnly
          bordered={false}
        />
      );
    }
  };

  return { generateInputType };
};

export default UseGenerateComponentNew;
