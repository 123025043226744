import React from "react";
import { Table, Button } from "antd";
import { useSelector } from "react-redux";

function GuarantorsRequestsTable({ isLoading }) {
  const { guarantors } = useSelector(
    (state) => state.loanRequestsGuarantorsTableReducer
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "fullname",
      render: (text, record) => <p>{`${record.firstname} ${record.lastname}`}</p>,
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "BVN",
      dataIndex: "bvn",
      key: "bvn",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={guarantors}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default GuarantorsRequestsTable;
