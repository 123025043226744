import React from "react";
import { Table, Button, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import timeDate from "../../../utils/TimeDate";

function BorrowersLoansTable({ isLoading }) {
  const { borrowersloans } = useSelector(
    (state) => state.borrowersLoansTableReducer
  );

  const { profile } = useSelector((state) => state.profileReducer );
  const { currencyFormatter } = useCurrenncyFormatter();

  const history = useHistory();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <div>
          <span>{record.id}</span>
        </div>
      ),
    },
    {
      title: "Borrower",
      dataIndex: "borrower",
      key: "borrower",
      render: (_, record) => (
        <div>
          <span>{record.borrower?.lastname}</span>{" "}
          <span>{record.borrower?.firstname}</span>
        </div>
      ),
    },

    {
      title: "Principal Amount",
      dataIndex: "principal_amount",
      key: "principal_amount",
      render: (_, record) => (
        <div>
          <span>{currencyFormatter(record.terms?.approved_amount)}</span>
        </div>
      ),
    },

    {
      title: "Interest",
      dataIndex: "interest",
      key: "interest",
      render: (_, record) => (
        <div>
          <span>{record.terms?.rate}%</span>
        </div>
      ),
    },

    {
      title: "Tenure",
      dataIndex: "tenure",
      key: "tenure",
      render: (_, record) => (
        <div>
          <span>{record.terms?.tenure} </span>
          <span>{record.terms?.tenured_in} </span>
        </div>
      ),
    },
    {
      title: "Loan Product",
      dataIndex: "initial_product_slug",
      key: "initial_product_slug",
      render: (_, record) => (
        <div>
          <span>{record.product_name}</span>
        </div>
      ),
    },
    {
      title: "Time Stamp",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() > new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <div>{`${timeDate(record.created_at, profile.date_format)}`}</div>
      ),
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) => {
        let color = record.borrower?.is_active ? "success" : "volcano";
        let text = record.borrower?.is_active ? "Active" : "Pending";

        return (
          <Tag color={color} key={record.borrower?.is_active}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/loan/${record.id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={borrowersloans}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default BorrowersLoansTable;
