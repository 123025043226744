import React from "react";
import { Table, Button } from "antd";
import { useSelector } from "react-redux";
import { NodeIndexOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

function AllGuarantorsTable({ isLoading }) {
  const { gurantors } = useSelector((state) => state.gurantorsTableReducer);
  const history = useHistory();
  const columns = [
    {
      title: "Loan Request",
      dataIndex: "loan_request_id",
      key: "loan_request_id",
      render: (text, record) => (
        <NodeIndexOutlined
          className="text-2xl cursor-pointer"
          onClick={() => history.push(`/requests/${record.loan_request_id}`)}
        />
      ),
    },
    {
      title: "Guarantor's Name",
      dataIndex: "guarantorName",
      filteredValue: "collins",
      key: "guarantorName",
      render: (text, record) => (
        <p>{`${record.firstname} ${record.lastname}`}</p>
      ),
    },

    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Button onClick={() => history.push(`/guarantors/${record.id}`)}>
            View Guarantor
          </Button>
          <Button
            onClick={() => history.push(`/requests/${record.loan_request_id}`)}
          >
            View Loan Request
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={gurantors}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
        rowKey="id"
      />
    </>
  );
}

export default AllGuarantorsTable;
