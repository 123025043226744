import React, { useState } from "react";
import { CaretUpFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { Title } from "../Typography";
import Register from "./Register";

function Heading() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="flex justify-between">
      <Title level={2}>Borrowers</Title>
      <div className="flex justify-between space-x-3">
        <Button
          icon={<SearchOutlined />}
          size="large"
          className="flex items-center rounded-none"
        />
        <Button
          onClick={() => setModalOpen(true)}
          type="primary"
          size="large"
          style={{ fontSize: "12px" }}
        >
          Create new borrower <CaretUpFilled />
        </Button>
      </div>

      <Modal
        visible={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Register closeModal={setModalOpen} />
      </Modal>
    </div>
  );
}

export default Heading;
