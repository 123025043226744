import { Row, Col, Divider, Empty, Form } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import AddHealthFacility from "./UpdateDetails/AddHealthFacility";

const HealthFacility = ({ health_facility, template }) => {
  const { handleCustomField } = UseDisplayCustom();
  const { generateInputType } = UseGenerateComponentNew();
  const healthRef = React.createRef();

  useEffect(() => {
    healthRef?.current?.setFieldsValue({
      name: health_facility?.data?.name,
      email: health_facility?.data?.email,
      phone: health_facility?.data?.phone,
      business_registration: health_facility?.data?.business_registration,
      facility_type: health_facility?.data?.facility_type,
      state_of_primary_operations:
        health_facility?.data?.state_of_primary_operations,
      local_government: health_facility?.data?.local_government,
      operating_hours: health_facility?.operating_hours,
      ownership_type: health_facility?.ownership_type,
      state_medical_licence: health_facility?.state_medical_licence,
      business_registration_date: moment(
        health_facility?.business_registration_date
      ).format("YYYY MM DD"),
      government_registration_number:
        health_facility?.government_registration_number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [health_facility]);

  return (
    <>
      <div className="mt-5">
        <Form layout="vertical" ref={healthRef}>
          <Row gutter={[16, 16]}>
            {template?.map((data) =>
              data?.slug === "health-facility-information"
                ? data?.fields?.map((field) =>
                    field?.is_required || field?.is_needed ? (
                      <Col span={8}>
                        <Form.Item name={field?.field} label={field?.label}>
                          {generateInputType(field)}
                        </Form.Item>
                        <Divider />
                      </Col>
                    ) : null
                  )
                : null
            )}

            {health_facility?.data?.custom_fields &&
              health_facility?.data?.custom_fields?.map((fields) => (
                <Col span={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500">{fields?.label}: </h3>
                    <h3 className="font-bold text-base">
                      {handleCustomField(fields)}
                    </h3>
                  </div>
                  <Divider />
                </Col>
              ))}
            {health_facility?.data === null && (
              <Col span={24}>
                <Empty />
              </Col>
            )}
          </Row>
        </Form>
        <div className="flex justify-end">
          <AddHealthFacility data={health_facility?.data} />
        </div>
      </div>
    </>
  );
};

export default HealthFacility;
