
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BorrowersMetrics from "../../components/Borrowers/SingleBorrower/BorrowersMetrics";
import Overview from "../../components/Borrowers/SingleBorrower/Overview";
import Summary from "../../components/Borrowers/SingleBorrower/Summary";
import { useAuth } from "../../context/AuthContext";
import { AppProvider } from "../../context/FetchContext";
import { ADDALLBORROWERSCREDIT, ADDALLLOANAPPLICATIONS } from "../../redux/Types/tableTypes";
import BackBtn from "../../utils/BackButton";
import Requests from "../../utils/request";

const SingleBorrower = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { searchValue, status } =  useSelector((state) => 
    session.admin.category === "merchant"
      ? state.borrowersCreditTableReducer
      : state.loanApplicationsTableReducer
  );

  const { data: borrower, isLoading, refetch: refetchBorrower} = useQuery(
    "single_borrower",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower?id=${id}`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: order_borrower, isLoadingOrders, refetch: refetchOrderBorrower } = useQuery(
    "single_order_borrower",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/${id}/data?data=credit-order`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((loan) => {
      const name = `${loan.firstname} ${loan.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return loan;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.user_status === status;
      }

      return true;
    });
  };

  const OrderSearchQuery = (data) => {
    const firstQuery = data?.filter((credit_order) => {
      const name = `${credit_order?.fullname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return credit_order;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.user_status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (
      order_borrower?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLBORROWERSCREDIT,
        value: OrderSearchQuery(order_borrower?.data),
      });

      return;
    }

    if (
      borrower?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLLOANAPPLICATIONS,
        value: SearchQuery(borrower?.data2),
      });
    }
    // eslint-disable-next-line
  }, [isLoadingOrders, isLoading]);

  useEffect(() => {
    if (
      order_borrower?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLBORROWERSCREDIT,
        value: OrderSearchQuery(order_borrower?.data),
      });
    }

    if (
      borrower?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLLOANAPPLICATIONS,
        value: SearchQuery(borrower?.data2),
      });
    }

    // eslint-disable-next-line
  }, [searchValue, status]);

  const providerValue = {
    request:
      session?.admin?.category === "merchant"
        ? order_borrower?.data
        : borrower?.data,
    refetch:
      session?.admin?.category === "merchant"
        ? refetchOrderBorrower
        : refetchBorrower,
  };

  return (
    <>
      <AppProvider value={{ ...providerValue }}>
        <div className="flex justify-between mb-3">
          <BackBtn />
        </div>
        <Overview data={borrower?.data} />
        <BorrowersMetrics />
        <Summary data={borrower} isLoading={isLoading} isLoadingOrders={isLoadingOrders} />
      </AppProvider>
    </>
  );
};

export default SingleBorrower;
