import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Row, Col } from "antd";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import AddNewGuarantors from "../../../Guarantors/AddNewGuarantors";
import { ADDALLLOANREQUESTGUARANTORS } from "../../../../redux/Types/tableTypes";
import GuarantorsRequestsTable from "../../../Table/LoanRequests/LoanRequestGuarantorsTable";
import { useDispatch, useSelector } from "react-redux";

const Guarantors = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { searchValue, status } =  useSelector((state) => 
  state.borrowersTableReducer
);

  const { data: guarantors, refetch, isLoading } = useQuery(
    "guarantors",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=guarantors`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((guarantor) => {
      const name = `${guarantor.firstname} ${guarantor.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return guarantor;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (guarantors?.status === "success") {
      dispatch({
        type: ADDALLLOANREQUESTGUARANTORS,
        value: SearchQuery(guarantors?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading, status]);

  useEffect(() => {
    dispatch({
      type: ADDALLLOANREQUESTGUARANTORS,
      value: SearchQuery(guarantors?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <GuarantorsRequestsTable isLoading={isLoading} />
      <Row className="my-5">
        <Col span={24}>
            <div className="flex justify-end space-x-4">
              <h3 className="text-base">Want to add a new guarantor?</h3>
              <AddNewGuarantors id={id} refetch={refetch} />
            </div>
        </Col>
      </Row>
    </>
  );
};

export default Guarantors;
