import React from "react";
import { CalenderIcon } from "../Loan/LoanIcons";
import { Title } from "../Typography";
import { Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDWALLETTRANSACTIONS,
  SETWALLETENDDATE,
  SETWALLETSTARTDATE,
} from "../../redux/Types/tableTypes";

export function WalletHeader() {
  const d = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[d.getMonth()];
  const day = d.getDate();
  const year = d.getFullYear();

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Title level={2}>Wallet</Title>
      <div
        style={{
          display: "flex",
          borderRadius: "6px",
          border: "1px solid #EBEAED",
          alignItems: "center",
          padding: "8px 20px",
          width: "25%",
          background: "#fff",
          height: "50px",
        }}
      >
        <CalenderIcon />
        <p style={{ margin: "0", marginLeft: "10px" }}>
          {month} {day}, {year}
        </p>
      </div>
    </div>
  );
}

export function WalletTableHeader() {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.walletTableReducer
  );

  const handleSearch = (e) => {
    dispatch({ type: ADDWALLETTRANSACTIONS, value: e.target.value });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETWALLETSTARTDATE, value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETWALLETENDDATE, value: e.endDate });
  };

  return (
    <div>
      <h3
        style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
      >
        Transaction History
      </h3>
      <p style={{ fontSize: "15px", marginBottom: "30px", marginTop: "0" }}>
        Track & Manage your wallet transaction history on Configure.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div style={{ display: "flex" }}>
          {/* <Select
            showSearch
            allowClear
            style={{ width: 200, cursor: "pointer" }}
            size="large"
            placeholder="Filter"
            optionFilterProp="children"
            onChange={(val) => handleFilterByStatus(val)}
          >
            <Option value="true">Success</Option>
            <Option value="false">Failed</Option>
          </Select> */}
          <Input
            size="large"
            placeholder="Search..."
            style={{ width: "300px", margin: "0px 10px" }}
            prefix={<SearchOutlined />}
            onChange={handleSearch}
          />
        </div>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { startDate: value[0] },
              endDateFilter({ endDate: value[1] })
            )
          }
          defaultValue={[startDate, endDate]}
          format={dateFormat}
          on
          style={{ marginRight: "10px", width: "250px" }}
          size="large"
        />
      </div>
    </div>
  );
}
