import React from "react";
import { Col, Button, Tooltip  } from "antd";
import { Text, Title } from "../../Typography";
import { Mask } from "../LoanIcons";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function Card({ icon, textColor, name, data, heading, amount }) {
  const { currencyFormatter } = useCurrenncyFormatter();
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };

  return (
    <Col className="gutter-row" span={6}>
      <div style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={icon} alt={name} />
          <Text style={{ color: `${textColor}`, marginLeft: "8px" }}>
            {heading}
          </Text>
        </div>
        <Title level={3} style={{ marginLeft: "30px", marginTop: "0.8em" }}>
        {currencyFormatter(data?.value)}
        </Title>
        <div
          style={{
            marginLeft: "30px",
            display: "flex",
            justifyContent: "space-between",
            width: "70%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Mask />
            <Text style={{ marginLeft: "5px" }}>{data?.count}</Text>
          </div>
          <Tooltip placement="topLeft" title="We're working on this. It will be available shortly">
        <Button disabled style={{
            background:'#fff',
            border:'none'
          }} >view all</Button>
          </Tooltip>
          {/* <Link to={{pathname: `/loan/${name}`}}>view all</Link> */}
        </div>
      </div>
    </Col>
  );
}

export default Card;
