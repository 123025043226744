import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  Select,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useApp } from "../../../context/FetchContext";
import Requests from "../../../utils/request";
import { Title } from "../../Typography";
import {
  currencyParser,
  useCurrenncyFormatter,
} from "../../../utils/currencyFormatter";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Option } = Select;

const UpdateLoanTerms = () => {
  const { session } = useAuth();
  const formUpdate = React.createRef();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [useTenureDays, setUseTenureDays] = useState(false);
  const [useZeroInterest, setUseZeroInterest] = useState(false);
  const { currencyFormatter } = useCurrenncyFormatter();
  const { refetch, request } = useApp();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeTenureType = (type) => {
    type === "days" ? setUseTenureDays(true) : setUseTenureDays(false);
  };

  const updateMutation = useMutation((updateTermBody) =>
    Requests.fetchWithAuthToken(
      `/lms/${
        session?.admin?.category === "merchant"
          ? "credit_order"
          : "loan_request"
      }/${id}/data?data=loan-term`,
      "POST",
      session.session.token,
      updateTermBody
    )
  );

  const { data: interest_rate_types } = useQuery(`interest_rate_types`, () =>
    Requests.fetchWithAuthToken(
      `/lms/utils/interest_rate_types`,
      "GET",
      session.session.token
    )
  );

  const handleSubmit = (values) => {
    setProcessing(true);
    updateMutation.mutate(values, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        refetch();
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  const zeroInterestChange = (val) => {
    request?.terms?.zero_interest === true ? setUseZeroInterest(val.target.checked) : setUseZeroInterest(val.target.checked)
  }

  useEffect(() => {
    formUpdate?.current?.setFieldsValue({
      approved_amount: request?.terms?.approved_amount,
      tenure: request?.terms?.tenure ? request?.terms?.tenure : 0,
      tenured_in: request?.terms?.tenured_in,
      rate: request.terms.rate,
      rate_type: request?.terms?.rate_type,
      grace_period: request.terms.grace_period,
      exclude_weekends: request.terms?.exclude_weekends,
      note: request.terms.note,
      zero_interest: request?.terms?.zero_interest,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formUpdate]);

  return (
    <>
      <Button
        type="primary"
        size="large"
        onClick={showModal}
        loading={isProcessing}
      >
        Edit Terms
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={false}
        width="55%"
      >
        <Title level={4}>
          {session?.admin?.category === "merchant"
            ? "Edit Order Terms"
            : "Edit Loan Terms"}
        </Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={formUpdate}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="zero_interest" valuePropName="checked">
                <Checkbox style={{ borderRadius: 2 }} onChange={zeroInterestChange}>
                  Zero Interest
                  <Tooltip
                    title="Zero Interest basically means that when 
                      we are creating the repayment schedule, we will 
                      not collect or calculate any interests on this loan"
                  >
                    <QuestionCircleFilled className="mx-2" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Approved Amount"
                name="approved_amount"
                rules={[
                  {
                    required: true,
                    message: "Approved Amount is required!",
                  },
                ]}
              >
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  size="large"
                  className="100%"
                  style={{ width: "100%" }}
                  placeholder="0"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tenure Type"
                name="tenured_in"
                rules={[
                  {
                    required: true,
                    message: "Tenure Type is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(value) => handleChangeTenureType(value)}
                >
                  <Option value="months" key="months">
                    Months
                  </Option>
                  <Option value="weeks" key="weeks">
                    Weeks
                  </Option>
                  <Option value="days" key="days">
                    Days
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tenure"
                name="tenure"
                rules={[
                  {
                    required: true,
                    message: "Tenure is required!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="0"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {!useZeroInterest || request?.terms?.zero_interest === false ? (
              <Col span={12}>
              <Form.Item
                label="Interest Rate Type"
                name="rate_type"
                rules={[
                  {
                    required: true,
                    message: "Rate Type is required!",
                  },
                ]}
              >
                <Select size="large">
                  {interest_rate_types?.data?.map((types) => (
                    <Option value={types?.slug} key={types?.slug}>
                      {types?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            ) : null}
            {useTenureDays && (
              <Col span={12}>
                <Form.Item label="Grace Period?" name="grace_period">
                  <InputNumber
                    placeholder="0"
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {!useZeroInterest || request?.terms?.zero_interest === false ? (
              <Col span={12}>
              <Form.Item
                label="Rate"
                name="rate"
                rules={[
                  {
                    required: true,
                    message: "Rate is required!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="0"
                  size="large"
                  className="100%"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            ) : null}
            <Col span={12}>
              <Form.Item label="Note" name="note">
                <TextArea type="text" width={50} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="exclude_weekends" valuePropName="checked">
                <Checkbox style={{ borderRadius: 2 }}>
                  Exclude Weekends
                  <Tooltip
                    title="Exclude weekends basically means that when 
                      we are creating the repayment schedule, we will 
                      intentionally skip weekends (Saturday & Sunday)"
                  >
                    <QuestionCircleFilled className="mx-2" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              size="large"
              className="w-full"
              htmlType="submit"
              type="primary"
              loading={isProcessing}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateLoanTerms;
