import React from "react";

import { Card, DatePicker, Divider } from "antd";
import { Text, Title } from "../../../components/Typography";
import { VictoryChart, VictoryBar, VictoryAxis } from "victory";
import { useDispatch, useSelector } from "react-redux";

import {
  SETLOANAPPLICATIONMETRICSENDDATE,
  SETLOANAPPLICATIONMETRICSSTARTDATE,
} from "../../../redux/Types/generalTypes";

const { RangePicker } = DatePicker;

function LoanApplications({ session }) {
  const dispatch = useDispatch();
  const { loansStartDate, loansEndDate } = useSelector(
    (state) => state.loanApplicationMetricsReducer
  );

  // const { data: loan_applications } = useQuery(`overview-metrics-loans`, () =>
  //   Requests.fetchWithAuthToken(
  //     `/lms/metrics?start=${moment()
  //       .startOf("week")
  //       .format(dateFormat)}&end=${moment()
  //       .endOf("week")
  //       .format(dateFormat)}&section=loan-application`,
  //     "GET",
  //     session.session.token
  //   )
  // );

  const startDateFilter = (e) => {
    dispatch({
      type: SETLOANAPPLICATIONMETRICSSTARTDATE,
      value: e.loansStartDate,
    });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETLOANAPPLICATIONMETRICSENDDATE, value: e.loansEndDate });
  };

  const data = [
    { x: 1, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ];

  const style = { padding: "15px" };
  return (
    <Card className="h-full -my-3 -mb-5" size="small" style={style}>
      <div className="flex justify-between -my-3">
        <Text>
          {session?.admin?.category === "merchant"
            ? "Credit Orders"
            : "Loan Applications"}
        </Text>
        <RangePicker
          onChange={(value) =>
            startDateFilter(
              { loansStartDate: value[0] },
              endDateFilter({ loansEndDate: value[1] })
            )
          }
          defaultValue={[loansStartDate, loansEndDate]}
          format={"YYYY-MM-DD"}
          on
          size={"small"}
          style={{ width: "178px", fontSize: "9px" }}
        />
      </div>
      <Divider />
      <div className="flex justify-between p-1 ">
        <div>
          <Title>0%</Title>
          <Text>
            {session?.admin?.category === "merchant"
              ? "Average Order Approval Rate"
              : "Average Loan Approval Rate"}
          </Text>
        </div>
        <div>
          <VictoryChart
            width={400}
            height={170}
            domain={{ x: [1, 4], y: [1, 4] }}
          >
            <VictoryAxis
              tickFormat={(x) => `${x}`}
              tickValues={["", "", "0", "N/a"]}
              style={{ ticks: { size: 1 } }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y) => `${y}`}
              style={{
                axis: { stroke: "none" },
                ticks: { stroke: "transparent" },
                tickLabels: { fill: "transparent" },
              }}
            />
            <VictoryBar
              data={data}
              style={{
                data: {
                  width: 50,
                  fill: ({ datum }) => (datum.y < 3 ? "#FF4B4B" : "#11C096"),
                },
              }}
            />
          </VictoryChart>
        </div>
      </div>
    </Card>
  );
}

export default LoanApplications;
