import { Avatar } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Title } from "../Typography";
import Styles from "./start.module.css";
import ListItem from "./ListItem";

function List({ children }) {
  return (
    <div className={`${Styles.listCard} overflow-hidden`}>
      <div>
        <div className="bg-white border-b border-solid border-0 border-gray-200 h-14 flex items-center p-3 space-x-4">
          <Avatar
            size="default"
            style={{ background: "#6C6DCF" }}
            icon={<ArrowRightOutlined />}
          />

          <Title level={3} className="my-3">
            Get the most out of Configure
          </Title>
        </div>
      </div>
      <div className="pl-3">{children}</div>
    </div>
  );
}

List.ListItem = ListItem;

export default List;
