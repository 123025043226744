import React, { useState } from "react";
import { Row, Col, Card, Button, Divider, DatePicker, Tooltip } from "antd";
import { Text, Title } from "../../../components/Typography";
import { RiseOutlined } from "@ant-design/icons";
import moment from "moment";
import { Mask } from "../../CustomIcons";
import { ReactComponent as Approved } from "../../../assets/svg/Approved.svg";
import WalletTopup from "../../Wallet/WalletTopup";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";
import { useAuth } from "../../../context/AuthContext";

const { RangePicker } = DatePicker;

function TopCards({ data, orderData }) {
  const [visible, setVisible] = useState(false);
  const { currencyFormatter } = useCurrenncyFormatter();
  const { session } = useAuth();
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const card1Style = {
    background: "linear-gradient(180deg, #4244B3 0%, #09099F 100%)",
    padding: "15px",
  };
  const textStyle = { color: "#fff" };

  return (
    <Row gutter={16} className="mt-10">
      <Col xs={24} sm={24} md={12} lg={12}>
        <Card className="h-full" size="small" style={card1Style}>
          <div className="flex-col justify-between">
            <div>
              <div className="flex justify-between">
                <Text style={textStyle}>Total Wallet Balance</Text>
              </div>
              <Title level={3} style={textStyle}>
                {currencyFormatter(0)}
              </Title>
              <Divider dashed style={{ backgroundColor: "#fff" }} />
            </div>
            <div className="absolute inset-x-0 bottom-0 flex justify-between p-6">
              <div>
                <Text style={{ color: "#45FFD2" }}>
                  {session?.admin?.category === "merchant" ? "Fulfilled" : "Disbursed"}
                </Text>
                <div className="flex space-x-2" style={{ color: "#fff" }}>
                  <Title style={textStyle} level={4}>
                  {currencyFormatter(0)}
                  </Title>
                  <RiseOutlined />
                  <Text style={textStyle}>0.00%</Text>
                </div>
              </div>
              <div>
                <Text style={{ color: "#FFEE58" }}>Repayments</Text>
                <div className="flex space-x-2" style={{ color: "#fff" }}>
                  <Title style={textStyle} level={4}>
                    {currencyFormatter(0)}
                  </Title>
                  <RiseOutlined />
                  <Text style={textStyle}>0.00%</Text>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Card className="h-full" size="small" style={{ padding: "15px" }}>
          <div className="justify-between">
            <div>
              <div className="flex justify-between -my-3">
                <Text>{session?.admin?.category === "merchant" ? "Fulfilments" : "Disbursements"}</Text>
                <RangePicker
                  defaultValue={[
                    moment("2015/01/01", "ll"),
                    moment("2015/01/01", "ll"),
                  ]}
                  format={"ll"}
                  size={"small"}
                  style={{ width: "150px" }}
                />
              </div>
              <Divider />
            </div>
            <div>
              <Row gutter={16}>
                <Col span={24}>
                  <Card className="space-y-6" size="small">
                    <div className="flex space-x-3">
                      <Approved />
                      <Title style={{ color: "#5152A6" }} level={5}>
                        {session?.admin?.category === "merchant"
                          ? "Total Fulfilments"
                          : "Total Disbursements"}
                      </Title>
                    </div>
                    <Title level={4} className="text-center">
                      {session?.admin?.category === "merchant" ? 
                        currencyFormatter(orderData?.total_fulfilled?.value) :
                        currencyFormatter(data?.total_disbursed?.value)}
                    </Title>
                    <div className="flex space-x-2">
                      <div className="mt-2">
                        <Mask />
                      </div>
                      <Title level={5}>
                        {session?.admin?.category === "merchant" ?
                        orderData?.total_fulfilled?.count : data?.total_disbursed?.count}
                      </Title>
                      <Tooltip title="Not available yet">
                        <Button type="link">view all</Button>
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </Col>
      {visible ? <WalletTopup toggle={toggleVisibility} /> : ""}
    </Row>
  );
}

export default TopCards;
