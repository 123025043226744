import { Card } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADDLOANLISTING } from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import moment from "moment";
import { useQuery } from "react-query";
import ListingTable from "../Table/Report/ListingTable";
import {
  SETLOANLISTINGENDDATE,
  SETLOANLISTINGSTARTDATE,
  SETLISTINGLOANOFFICER
} from "../../redux/Types/tableTypes";
import ReportTableHeader from "./ReportTableHeader";

function LoanListing() {
	const dateFormat = "YYYY-MM-DD";
	const dispatch = useDispatch();
	const { session } = useAuth();
	const { endDate, startDate, officerId } = useSelector(
		(state) => state.loanListingTableReducer
	);

	const { data: loanListing, isLoading } = useQuery(
		`loan-listing-start=${moment(startDate).format(dateFormat)}&end=${moment(
			endDate
		).format(dateFormat)}officer-${officerId}`,
		() =>
			Requests.fetchWithAuthToken(
				`/lms/report?loan-officer-id=${officerId}&report-type=loan-listing&start=${moment(startDate).format(
					dateFormat
				)}&end=${moment(endDate).format(dateFormat)}`,
				"GET",
				session.session.token
			)
	);

	useEffect(() => {
		dispatch({
			type: ADDLOANLISTING,
			value: loanListing?.data,
		});
		// eslint-disable-next-line
	}, [loanListing]);
	return (
		<div>
			<Card style={{ marginTop: "30px" }}>
                <ReportTableHeader startDate={startDate} endDate={endDate} setStart={SETLOANLISTINGSTARTDATE} setEnd={SETLOANLISTINGENDDATE} officer={SETLISTINGLOANOFFICER} title="Loan Listing" />
				<ListingTable isLoading={isLoading} />
			</Card>
		</div>
	);
}

export default LoanListing;
