import React, { useState } from "react";
import { CalenderIcon } from "../Loan/LoanIcons";
import { Title } from "../Typography";
import { Input, Button, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Invite from "../AdminSetup/Invite";
import { useDispatch } from "react-redux";
import { ALLTEAMSEARCHVALUE } from "../../redux/Types/tableTypes";

export function TeamHeader() {
  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Title level={2}>Team</Title>
      <div
        style={{
          display: "flex",
          borderRadius: "6px",
          border: "1px solid #EBEAED",
          alignItems: "center",
          padding: "8px 20px",
          width: "25%",
          background: "#fff",
          height: "50px",
        }}
      >
        <CalenderIcon />
        <p style={{ margin: "0", marginLeft: "10px" }}>-----</p>
      </div>
    </div>
  );
}

export function TeamTableHeader({ refetch, isAgent }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeAble = () => {
    refetch();
    setIsModalOpen(false);
  };
  const handleSearch = (e) => {
    dispatch({ type: ALLTEAMSEARCHVALUE, value: e.target.value });
  };

  return (
    <div>
      <p style={{ marginBottom: "30px", marginTop: "0" }}>
        Manage your agents, invite, set permissions, and, if need be, remove an
        agent.
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div className="flex justify-between w-full">
          <div className="flex space-x-2">
            <Input
              size="large"
              placeholder="Search..."
              style={{ width: "75%", margin: "0px 10px" }}
              prefix={<SearchOutlined />}
              onChange={handleSearch}
            />
          </div>
          <Button
            size="large"
            type="primary"
            onClick={() => setIsModalOpen(true)}
          >
            {isAgent ? "Add a new agent" : "Add a new user"}
          </Button>
        </div>
      </div>

      <Modal
        width="50%"
        visible={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <Invite isModal={true} closeAble={closeAble} isStaff={isAgent} />
      </Modal>
    </div>
  );
}
