import React, { useEffect, useState } from "react";
import { message, Button, Form, Col, Row, Input, Select, Drawer } from "antd";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthContext";
import Requests from "../../../../../utils/request";
import { Title } from "../../../../Typography";
import Upload from "../../../../Uploader";
import strip from "../../../../../utils/StripWords";

const { Option } = Select;

const AddUploads = ({ refetchUploads, data }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();
  const [document_url, setDocument] = useState("")
  const { id } = useParams();
  const uploadRef = React.createRef();

  const mutationUploads = useMutation((uploads) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=uploads`,
      "POST",
      session.session.token,
      uploads
    )
  );

  useEffect(() => {
    uploadRef?.current?.setFieldsValue({
      type: data?.type,
      title: data?.title,
      number: data?.number,
      document_url: data?.document_url
    });
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = (values) => {
    setProcessing(true);
    mutationUploads.mutate([
      {
        ...values, 
        document_url,
        title : strip(values?.type)
      }
    ], {
      onSuccess: (data) => {
        refetchUploads();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <Button
          type="primary"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          Update Uploads
        </Button>
      </div>
      <Drawer
        footer={null}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        width="60%"
      >
        <Title level={3}>Add Documents</Title>
        <Form layout="vertical" onFinish={handleSubmit} ref={uploadRef}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Document Type"
                name="type"
              >
                <Select>
                  <Option value="drivers_licence">Drivers Licence</Option>
                  <Option value="international-passport">International Passport</Option>
                  <Option value="national-id">National ID</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Document"
              >
                 <Upload 
                  formRef={{ ref: uploadRef, fieldName: "document_url" }} 
                  onUploaded={(url) => setDocument((url))}
                  />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Document Number"
                name="number"
              >
                 <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AddUploads;