import { UserOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Card, Col, Row } from "antd";
import React from "react";
import { Title } from "../../Typography";
import { useQuery } from "react-query";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
import { useParams } from "react-router-dom";
import BackBtn from "../../../utils/BackButton";

const Overview = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data } = useQuery("invitee", () =>
    Requests.fetchWithAuthToken(
      `/lms/loan?id=${id}`,
      "GET",
      session.session.token
    )
  );

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", color: "rgb(81, 82, 166)" }}>
        <Breadcrumb.Item>
          <BackBtn />
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mt-5">
        <Row>
          <Col span={14}>
            <div className="flex space-x-8 ml-5">
              <div className="flex space-x-4 mt-8">
                <Avatar size={130} icon={<UserOutlined />} />
              </div>
              <div className="mt-8">
                <Title level={3}>
                  {data?.data?.borrower?.firstname}{" "}
                  {data?.data?.borrower?.lastname}
                </Title>
                <div>
                  Amount Requested: {data?.data?.terms?.approved_amount}
                </div>
                <div>
                  Product Name: {data?.data?.borrower?.initial_product_slug}
                </div>
                <div>Loan Type:</div>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="flex">
              <div>
                <div style={{ marginTop: "70px" }}>
                  Status:{" "}
                  {data?.data?.borrower?.is_active ? "Active" : "not active"}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Overview;
