import React, { useState } from "react";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/AuthContext";
import { useMutation } from "react-query";
import Requests from "../../utils/request";
import string_to_slug from "../../utils/StringToSlug";

const AddNewCollateralType = ({ data, refetchTypes }) => {
  const { session } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ isProcessing, setProcessing] = useState();

  const mutationCreatCollateral = useMutation((collateral) =>
    Requests.fetchWithAuthToken(
      "/lms/product/collateral_type",
      "POST",
      session.session.token,
      collateral
    )
  );

  const handleSubmit = (values) => {
    const info = {
      org_id: session?.user?.org_id,
      name: values.collateral_type,
      slug: string_to_slug(values.collateral_type),
      product_id: data?.product_id,
      product_slug: data?.product_slug,
    };

    setProcessing(true);
    mutationCreatCollateral.mutate(info, {
      onSuccess: (data) => {
        refetchTypes();
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex mt-10 -ml-3">
        <PlusCircleOutlined />
        <Button
          type="link"
          className="-mt-2 -ml-2"
          onClick={() => setIsModalVisible(true)}
          loading={isProcessing}
        >
          create collateral type
        </Button>
      </div>
      <Modal
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width="17%"
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24}>
              <Form.Item
                label="Collateral Type"
                name="collateral_type"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewCollateralType;
