const CommaStringsToArrayofObjects = (data) => {
  const splitData = data.split(",");
  return splitData.map((data) => ({
    title: data,
    slug: data.trim().replaceAll(" ", "_").toLowerCase(),
  }));
};

export const CommaStringsToArrayofStrings = (data) => {
  const splitData = data.split(",");
  return splitData.map((data) => data);
};

export default CommaStringsToArrayofObjects;
