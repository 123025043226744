import { Button, Card, Modal } from "antd";
import moment from "moment";
import React, {useState} from "react";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import { Title } from "../Typography";

const DebitInstructions = ({debit_instructions}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { currencyFormatter } = useCurrenncyFormatter();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
     <Card>
        <div className="mt-8 space-y-4">
          <Title level={3}>Remitta Debit instructions</Title>
          {debit_instructions?.map((instructions) => 
            <>
            <div>Ref: {instructions?.ref}</div>
            <div>Stat: {instructions?.stat}</div>
            <div>Message: {instructions?.message}</div>
            <div>Created at: {instructions?.created_at}</div>
            <div>status: {instructions?.status}</div>
            <Button type="primary" className="w-1/4" onClick={showModal}>Log</Button>
            <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
              {instructions?.log && instructions?.debit_instruction_status_log.map((log) => 
                <>
                <div>Status Code: {log?.statuscode}</div>
                <div>Amount: {currencyFormatter(log?.amount)}</div>
                <div>Request ID: {log?.requestId}</div>
                <div>Mandate ID: {log?.mandateId}</div>
                <div>Last Status Update Time: {moment(log?.last_status_update_time).format( "dddd, MMMM Do YYYY, h:mm a")}</div>
                <div>Transaction Ref: {log?.transactionRef}</div>
                <div>Status: {log?.status}</div>
                </>
              )}
            </Modal>
            </>
          )}
        </div>
     </Card>
    </>
  )
}

export default DebitInstructions;