import { Card, Col, Empty, Row } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import NoteCard from "../../../Notes/NoteCard";
import { useApp } from "../../../../context/FetchContext";

const Notes = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const { setRefetch } = useApp();
  const {
    data: note,
    isLoading,
    refetch: refetchNotes,
  } = useQuery(`notes-${id}`, () =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/data?data=notes`,
      "GET",
      session.session.token
    )
  );

  useEffect(() => {
    setRefetch({ refetchNotes });
    // eslint-disable-next-line
  }, []);
  return (
    <section>
      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : (
        <>
          <div className="mt-5">
            {note?.data === null && (
              <Col span={24}>
                <Empty />
              </Col>
            )}
            <Row>
              {note?.data?.map((comment) => (
                <Col xs={24} md={8}>
                  <NoteCard
                    message={comment?.message}
                    time={moment(comment?.created_at).startOf("hour").fromNow()}
                    author={comment?.created_by}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </section>
  );
};

export default Notes;
