import React, { useEffect } from "react";
import {
  LogHeader,
  LogTableHeader,
} from "../../components/ActivityLogs/LogHeader";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LogTable from "../../components/Table/ActivityLog/LogTable";
import { ADDACTIVITYLOG } from "../../redux/Types/tableTypes";

function ActivityLog() {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { endDate, startDate, searchValue, status } = useSelector(
    (state) => state.activityLogTableReducer
  );
  const dateFormat = "YYYY-MM-DD";

  const { data: logs, isLoading } = useQuery(
    `activity_logs=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/activity_logs?start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}&section=main`,
        "GET",
        session.session.token
      )
  );
  const SearchQuery = (data) => {
    const firstQuery = data?.filter((logs) => {
      const name = `${logs?.who}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return logs;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.action === status;
      }
      return true;
    });
  };

  useEffect(() => {
    if (logs?.status === "success") {
      dispatch({
        type: ADDACTIVITYLOG,
        value: SearchQuery(logs?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (logs?.data) {
      dispatch({
        type: ADDACTIVITYLOG,
        value: SearchQuery(logs?.data),
      });
    }
    // eslint-disable-next-line
  }, [searchValue, status]);

  return (
    <div>
      <LogHeader />
      <div style={style}>
        <LogTableHeader />
        <LogTable isLoading={isLoading} />
      </div>
    </div>
  );
}

export default ActivityLog;
