import React from "react";
import { Title } from "../Typography";

function Heading() {
  return (
    <div className="flex justify-between">
      <Title level={2}>Overview</Title>
    </div>
  );
}

export default Heading;
