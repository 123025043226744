import { Row, Col, Divider } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import moment from "moment";

const BVNDetails = () => {
  const { session } = useAuth();
  const { id } = useParams();

  const { data: bvn_details } = useQuery(
    "bvn2",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=bvn2`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: order_bvn_details } = useQuery(
    "order_bvn2",
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order/${id}/data?data=bvn`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const bvn_dets =
    session?.admin?.category === "merchant" ? order_bvn_details : bvn_details;

  return (
    <>
      <div className="mt-5">
        <Row gutter={[16, 16]}>
          <>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Full Name: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.full_name}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Email: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.email}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Phone: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.phone}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Country: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.country}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">State Of Origin: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.state_of_origin}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Marital Status: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.marital_status}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Nationality: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.nationality}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">NIN: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.nin}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">BVN match bank account: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.match_bank_account === true ? "Yes" : "No"}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">verified: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.verified === true ? "Yes" : "No"}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Watch Listed: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.watch_listed === true ? "Yes" : "No"}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Registration Date: </h3>
                <h3 className="capitalize font-bold text-base">
                  {moment(bvn_dets?.data?.registration_date).format(
                    "YYYY-MM-DD"
                  )}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Enrollment Bank: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.enrollment_bank}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Account level: </h3>
                <h3 className="capitalize font-bold text-base">
                  {bvn_dets?.data?.account_level}
                </h3>
              </div>
              <Divider />
            </Col>
          </>
        </Row>
      </div>
    </>
  );
};

export default BVNDetails;
