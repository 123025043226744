import React from 'react';
import Calender from './Calender';
import { VictoryPie } from 'victory';
import { Title } from "../Typography";

function PercentageChart({data}) {


    return (
        <>
            <Calender margin="20px" width="100%" />
            <div style={{ position: 'relative', paddingTop: '20px' }}>
                <VictoryPie
                    width={500}
                    height={500}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={170}
                    labels={() => null} cornerRadius={5}
                    data={[
                        { x: 1, y: `${data}` }, { x: 2, y: 100 - `${data}` }
                    ]}
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                const color = datum.y > 30 ? "#11C096" : "red";
                                const color2 = datum.y < 70 ? "rgba(17, 192, 150, 0.1)" : "rgba(255,0,0, 0.1)";
                                return datum.x === 1 ? color : color2;
                            }
                        }
                    }}
                />
                <div style={{ width:'100%', position: 'absolute', top: '40%' }}>
                    <Title level={2} style={{textAlign:'center'}}>{data}%</Title>
                </div>
            </div>
        </>
    )
}

export default PercentageChart;
