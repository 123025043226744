import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker } from "antd"; 
import { useDispatch, useSelector } from "react-redux";
import { ALLBORROWERSREPAYMENTSEARCHVALUE, SETBORROWERSREPAYMENTSENDDATE, SETBORROWERSREPAYMENTSTARTDATE } from "../../../../redux/Types/tableTypes";

const { RangePicker } = DatePicker;

function RepaymentsTableHeader(props) {
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) =>  state.borrowersLoansTableReducer);

  const handleSearch = (e) => {
    dispatch({ type:ALLBORROWERSREPAYMENTSEARCHVALUE, value: e.target.value  });
  };

  const startDateFilter = (e) => {
    dispatch({ type: SETBORROWERSREPAYMENTSTARTDATE , value: e.startDate });
  };

  const endDateFilter = (e) => {
    dispatch({ type: SETBORROWERSREPAYMENTSENDDATE, value: e.endDate });
  };
  
  return (
      <div className="mb-5 mt-5"> 
        <div className="flex items-center justify-between">
          <div className="flex">
              <Input
                  size="large"
                  placeholder="Search..."
                  style={{ width: '75%', margin:'0px 10px' }}
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
              />
              </div>
              <RangePicker
                onChange={(value) =>
                  startDateFilter(
                    { startDate: value[0] },
                    endDateFilter({ endDate: value[1] })
                  )
                }
                defaultValue={[startDate, endDate]}
                format={dateFormat}
                on
              />
      </div>
    </div>
  )
}

export default RepaymentsTableHeader
