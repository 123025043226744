import React, { useEffect, useState } from "react";
import SecureLS from "secure-ls";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  message,
  notification,
} from "antd";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Logo from "../../assets/svg/logo.svg";
import Pattern from "../../assets/images/patterns.png";
import countries from "../../utils/Country";
import { useQuery } from "react-query";
import Requests from "../../utils/request";
import useLocationQuery from "../../utils/useQuery";

const securedStorage = new SecureLS();

const { Option } = Select;

function SignUp(props) {
  const history = useHistory();
  const { signUp } = useAuth();
  const [isProcessing, setProcessing] = useState(false);

  const formSignup = React.createRef();

  let query = useLocationQuery();
  const plan = query.get("plan");

  const { data: subscription_plan } = useQuery(`subscription_plan`, () =>
    Requests.fetchWithOutAuthToken(`/lms/utils/plans`, "GET")
  );

  const { data: categories } = useQuery(`categories`, () =>
    Requests.fetchWithOutAuthToken(`/lms/utils/org_categories`, "GET")
  );

  const registerCallback = (data) => {
    setProcessing(false);

    if (data.status !== "success") {
      message.error(data.message, 3);
      return;
    }

    message.success(data.message, 3);
    securedStorage.set("operator", {
      allowed_to: data.data4,
      access_to: data.data3,
      session: data.data2,
      user: data.data1,
      admin: data.data,
    });

    window.location.replace("/adminsetup");
  };

  const handleSubmit = (values) => {
    const user = {
      org_user: {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email.toLowerCase(),
        password: values.password,
        phone: values.phone,
        country: values.country,
        role_in_organisation: values.role_in_organistaion,
        plan: values.plan,
      },
      org: {
        name: values.org_name,
        category: values.category,
      },
      plan: values.plan,
    };

    setProcessing(true);
    signUp(user, registerCallback);
  };

  useEffect(() => {
    formSignup?.current?.setFieldsValue({
      plan: plan,
    });

    // eslint-disable-next-line
  }, [plan]);

  return (
    <div className="h-screen flex lg:flex-row flex-col">
      <div className="flex-grow lg:p-8 p-3 xl:mx-32 lg:mx-16">
        <img src={Logo} alt="evolve credit logo" className="w-full h-20" />

        <div className="my-20">
          <h1 className="text-2xl font-semibold my-10">
            Sign up to Evolve Credit
          </h1>
          <Form
            layout="vertical"
            ref={formSignup}
            initialValues={{ remember: true }}
            onFinish={(values) => handleSubmit(values)}
          >
            <Row gutter={[16]}>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone Number is required!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    size="large"
                    name="phone"
                    placeholder="08030000000"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required!",
                    },
                  ]}
                >
                  <Select showSearch size="large" optionFilterProp="children">
                    {countries.map((country) => (
                      <Option
                        value={country.name}
                        key={`${country.code}${country.name}`}
                      >
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Organisation's Name"
                  name="org_name"
                  rules={[
                    {
                      required: true,
                      message: "Organisation name is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Role In Organisation"
                  name="role_in_organistaion"
                  rules={[
                    {
                      required: true,
                      message: "Role is required!",
                    },
                  ]}
                >
                  <Input size="large" type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="Plan"
                  name="plan"
                  rules={[
                    {
                      required: true,
                      message: "Plan is required!",
                    },
                  ]}
                >
                  <Select showSearch size="large" optionFilterProp="children">
                    {subscription_plan?.data?.map((plan) => (
                      <Option value={plan?.slug} key={plan?.slug}>
                        {plan?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="How do you want to use configure?"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Plan is required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    optionFilterProp="children"
                    onChange={(val) => {
                      notification.destroy();
                      notification.open({
                        duration: null,
                        message: (
                          <h3 className="text-base uppercase">
                            {
                              categories?.data?.find((cat) => cat.slug === val)
                                ?.title
                            }
                          </h3>
                        ),
                        placement: "topLeft",
                        description: categories?.data?.find(
                          (cat) => cat.slug === val
                        )?.description,
                      });
                    }}
                  >
                    {categories?.data?.map((plan) => (
                      <Option value={plan?.slug} key={plan?.slug}>
                        {plan?.slug}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                loading={isProcessing}
                style={{ width: "100%" }}
                type="primary"
                size="large"
                htmlType="submit"
              >
                Sign Up
              </Button>
            </Form.Item>
            <div className="flex w-full justify-center">
              <Button type="link" onClick={() => history.push("/sign-in")}>
                Already Registered?
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative hidden lg:block"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default SignUp;
