import React from 'react'
import ReportFilter from '../../components/Report/ReportFilter'

function Report() {
    return (
        <div>
            <ReportFilter />
        </div>
    )
}

export default Report
