import { Card } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADDPAR } from "../../redux/Types/tableTypes";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import moment from "moment";
import { useQuery } from "react-query";
import {
	SETPARENDDATE,
	SETPARSTARTDATE,
    SETPARLOANOFFICER,
} from "../../redux/Types/tableTypes";
import ReportTableHeader from "./ReportTableHeader";
import ParReprtTable from "../Table/Report/ParReportTable";

function ParReport() {
	const dateFormat = "YYYY-MM-DD";
	const dispatch = useDispatch();
	const { session } = useAuth();
	const { endDate, startDate, officerId } = useSelector((state) => state.parTableReducer);

	const { data: parReport, isLoading } = useQuery(
		`par-report-start=${moment(startDate).format(dateFormat)}&end=${moment(
			endDate
		).format(dateFormat)}officer-${officerId}`,
		() =>
			Requests.fetchWithAuthToken(
				`/lms/report?loan-officer-id=${officerId}&report-type=par-report&start=${moment(startDate).format(
					dateFormat
				)}&end=${moment(endDate).format(dateFormat)}`,
				"GET",
				session.session.token
			)
	);
	useEffect(() => {
		dispatch({
			type: ADDPAR,
			value: parReport?.data,
		});
		// eslint-disable-next-line
	}, [parReport]);
	return (
		<div>
			<Card style={{ marginTop: "30px" }}>
				<ReportTableHeader
					startDate={startDate}
					endDate={endDate}
					setStart={SETPARSTARTDATE}
					setEnd={SETPARENDDATE}
                    officer={SETPARLOANOFFICER}
					title="PAR Report"
				/>
                <ParReprtTable isLoading={isLoading} />
			</Card>
		</div>
	);
}

export default ParReport;
