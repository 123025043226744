import { useSelector } from "react-redux";
import { localeVals } from "./Currency";
const locale = "en";

export const useCurrenncyFormatter = () => {
  const { profile } = useSelector((state) => state.profileReducer);
  const currency = profile?.default_currency
    ? profile?.default_currency
    : "NGN";
  const currencyFormatter = (value = 0) => {
    return new Intl.NumberFormat(localeVals[currency], {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
    }).format(value);
  };

  return { currencyFormatter };
};

export const currencyFormatter = (
  value = 0,
  locale = "en-NG",
  currency = "NGN"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(value);
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "0.0";
    }
    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
    var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €
    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21
    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;
    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }
    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

export const KoboToNaira = (val) => {
  return currencyFormatter(val / 100);
};
