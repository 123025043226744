const currencies = [
  {
    symbol: "₦",
    name: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    code: "NGN",
    locale: "en-NG",
    name_plural: "Nigerian nairas",
  },

  {
    symbol: "ZWD",
    name: "Zimbabwe dollar",
    symbol_native: "Z$",
    decimal_digits: 2,
    rounding: 0,
    code: "ZWD",
    locale: "en-ZW",
    name_plural: "Zimbabwe dollars",
  },

  {
    symbol: "ZMW",
    name: "zambia Kwacha",
    symbol_native: "ZK",
    decimal_digits: 2,
    rounding: 0,
    code: "ZMW",
    locale: "en-ZM",
    name_plural: "zambia Kwacha",
  },
];

export const localeVals = { NGN: "en-NG", ZWD: "en-ZW", ZMW: "en-ZM" };
export const phoneLocale = { NGN: "NG", ZWD: "ZW", ZMW: "ZM" };
export default currencies;
