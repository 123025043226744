import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { loanReducer } from "./Reducers/loanReducer";
import { disbursementReducer } from "./Reducers/disbursementReducer";
import { gurantorsTableReducer } from "./Reducers/tables/gurantorsTableReducer";
import { loanProductTableReducer } from "./Reducers/tables/loanProductTableReducer";
import { loanRequestsTableReducer } from "./Reducers/tables/loanRequestTableReducer";
import { allLoanTableReducer } from "./Reducers/tables/allLoanTableReducer";
import { disbursementsTableReducer } from "./Reducers/tables/disbursementsTableReducer";
import { repaymentsTableReducer } from "./Reducers/tables/repaymentsTableReducer";
import { bankStatementsTableReducer } from "./Reducers/tables/bankStatementsTableReducer";
import { allTransactionsTableReducer } from "./Reducers/tables/allTransactionsTableReducer";
import { overViewTableReducer } from "./Reducers/tables/overViewTableReducer";
import { requestMetricsReducer } from "./Reducers/loanMetricsReducer";
import { repaymentsMetricsReducer } from "./Reducers/repaymentsMetricsReducer";
import { transactionsTableReducer } from "./Reducers/tables/transactionsTableReducer";
import { singleRepaymentTableReducer } from "./Reducers/tables/singleRepaymentTableReducer";
import { teamTableReducer } from "./Reducers/tables/teamTableReducer";
import { agentTableReducer } from "./Reducers/tables/agentTableReducer";
import { productsMetricsReducer } from "./Reducers/productsMetricsReducer";
import { overviewMetricsReducer } from "./Reducers/overviewMetricsReducer";
import { portfoliosTableReducer } from "./Reducers/tables/portfolioTableReducer";
import { walletTableReducer } from "./Reducers/tables/walletTableReducer";
import { loanApplicationMetricsReducer } from "./Reducers/loanApplicationMetricsReducer";
import { creditOrdersTableReducer } from "./Reducers/tables/creditOrdersTableReducer";
import { fulfilmentsTableReducer } from "./Reducers/tables/fulfillmentsTableReducer";
import { overviewOrderTableReducer } from "./Reducers/tables/overviewOrderTableReducer";
import { activityLogTableReducer } from "./Reducers/tables/activityLogTableReducer";
import { borrowersTableReducer } from "./Reducers/tables/borrowersTableReducer";
import { loanApplicationsTableReducer } from "./Reducers/tables/loanApplicationsTableReducer";
import { notificationsReducer } from "./Reducers/notificationsReducer";
import { borrowersCreditTableReducer } from "./Reducers/tables/borrowersCreditTableReducer";
import { notesReducer } from "./Reducers/notesReducer";
import { TaskReducer } from "./Reducers/TaskReducer";
import { profileReducer } from "./Reducers/profileReducer";
import { noteTableReducer } from "./Reducers/tables/noteTableReducer";
import { borrowersMetricsReducer } from "./Reducers/borrowersMetricsReducer";
import { loanRequestsGuarantorsTableReducer } from "./Reducers/tables/loanRequestGuarantorsTableReducer";
import { loanListingTableReducer } from "./Reducers/tables/loanListingTableReducer";
import { CustomFieldsReducer } from "./Reducers/CustomFieldsReducer";
import { LoanApplicationReducer } from "./Reducers/LoanApplicationReducer";
import { loanRequestsCollateralsTableReducer } from "./Reducers/tables/loanRequestCollateralTableReducer";
import { collateralCustomFieldsReducer } from "./Reducers/collateralCustomFieldsReducer";
import { allCollateralsTableReducer } from "./Reducers/tables/allCollateralTableReducer";
import { parTableReducer } from "./Reducers/tables/parTableReducer";
import { delinquencyTableReducer } from "./Reducers/tables/delinquencyTableReducer";
import { borrowersRepaymentTableReducer } from "./Reducers/tables/borrowersRepaymentTableReducer";
import { borrowersLoansTableReducer } from "./Reducers/tables/borrowersLoansTableReducer";
import { requestLogsTableReducer } from "./Reducers/tables/requestLogTableReducer";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const allReducers = combineReducers({
  loanReducer,
  disbursementReducer,
  gurantorsTableReducer,
  loanProductTableReducer,
  loanRequestsTableReducer,
  disbursementsTableReducer,
  repaymentsTableReducer,
  bankStatementsTableReducer,
  allLoanTableReducer,
  allTransactionsTableReducer,
  overViewTableReducer,
  requestMetricsReducer,
  repaymentsMetricsReducer,
  transactionsTableReducer,
  teamTableReducer,
  productsMetricsReducer,
  singleRepaymentTableReducer,
  overviewMetricsReducer,
  portfoliosTableReducer,
  walletTableReducer,
  loanApplicationMetricsReducer,
  creditOrdersTableReducer,
  fulfilmentsTableReducer,
  overviewOrderTableReducer,
  activityLogTableReducer,
  borrowersTableReducer,
  loanApplicationsTableReducer,
  notificationsReducer,
  borrowersCreditTableReducer,
  notesReducer,
  TaskReducer,
  profileReducer,
  noteTableReducer,
  borrowersMetricsReducer,
  loanRequestsGuarantorsTableReducer,
  loanListingTableReducer,
  CustomFieldsReducer,
  agentTableReducer,
  LoanApplicationReducer,
  loanRequestsCollateralsTableReducer,
  collateralCustomFieldsReducer,
  allCollateralsTableReducer,
  parTableReducer,
  delinquencyTableReducer,
  borrowersLoansTableReducer,
  borrowersRepaymentTableReducer,
  requestLogsTableReducer,
});
// The store now has the ability to accept thunk functions in `dispatch`
const store = createStore(allReducers, composedEnhancer);
export default store;
