import React, { useState } from "react";
import { Button, Input, Progress, Upload as loader } from "antd";
import * as filestack from "filestack-js";
import Modal from "antd/lib/modal/Modal";
import { CheckCircleFilled } from "@ant-design/icons";
const client = filestack.init(process.env.REACT_APP_FILESTACK_KEY);

const { Dragger } = loader;

function Upload({
  text,
  accept = "*",
  onUploaded = (res) => res,
  formRef = {},
}) {
  const [isShow, setIsShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const { ref, fieldName } = formRef;

  const settings = {
    name: "file",
    accept,
    beforeUpload: () => false,
    async onChange(info) {
      setProgress(20);
      const result = await client.upload(info.file, { onProgress });
      onUploaded(result.url);

      if (formRef.ref) {
        ref?.current?.setFieldsValue({
          [fieldName]: result.url,
        });
      }
    },
  };

  const onProgress = (progress) => {
    if (progress.totalPercent > 0) {
      setProgress(progress.totalPercent);
    }

    if (progress.totalPercent === 100) {
      setIsShow(false);
    }
  };

  return (
    <div>
      <Button type="dashed" onClick={() => setIsShow(true)}>
        {progress === 100 ? (
          <div className="flex space-x-2 items-center">
            <CheckCircleFilled style={{ color: "#11C096" }} />
            <span>Document successfully uploaded</span>
          </div>
        ) : text ? (
          `${text}`
        ) : (
          `Click to Browse For PDF, PNG, JPG Files`
        )}
      </Button>
      <Input type="hidden" />
      <Modal visible={isShow} footer={null} onCancel={() => setIsShow(false)}>
        <div className="h-60 my-5">
          <Dragger {...settings}>
            {progress ? (
              <Progress type="circle" size="small" percent={progress} />
            ) : (
              "Click here or Drag a file"
            )}
          </Dragger>
        </div>
      </Modal>
    </div>
  );
}

export default Upload;
