const allowed_features = [
  {
    name: "Create Product",
    slug: "create_product",
  },
  {
    name: "Edit Product",
    slug: "edit_product",
  },
  {
    name: "Approve Loan Requests",
    slug: "approve_loan_request",
  },

  {
    name: "Disburse",
    slug: "disburse",
  },
];

export default allowed_features;
