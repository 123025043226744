import React, { useEffect } from "react";
import moment from "moment";
import { Card, Row, Col } from "antd";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import {
  Heading,
  WalletBalance,
  LoanApplications,
  LoanPortfolioHealth,
  BusinessPerformance,
  TableHeader,
} from "../../components/Overview";
import RequestsTable from "../../components/Table/Overview/OverviewTable";
import { useDispatch, useSelector } from "react-redux";
import {
  ADDALLOVERVIEWCREDITORDER,
  ADDALLOVERVIEWLOANREQUEST,
} from "../../redux/Types/tableTypes";
import OrderOverviewTable from "../../components/Table/Overview/OrderOverviewTable";

const Overview = () => {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const dateFormat = "YYYY-MM-DD";

  const { searchValue, status } = useSelector((state) =>
    session?.admin?.category === "merchant"
      ? state.overviewOrderTableReducer
      : state.overViewTableReducer
  );

  const { data: loan_requests, isLoading } = useQuery(`over-view`, () =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request?id=all&start=${moment()
        .startOf("week")
        .format(dateFormat)}&end=${moment().endOf("week").format(dateFormat)}`,
      "GET",
      session.session.token
    )
  );

  const { data: credit_orders, isLoading: isLoadingOrders } = useQuery(
    `overview_orders`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/credit_order?id=all&start=${moment()
          .startOf("week")
          .format(dateFormat)}&end=${moment()
          .endOf("week")
          .format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((loan_request) => {
      const name = `${loan_request?.fullname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return loan_request;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.user_status === status;
      }

      return true;
    });
  };

  const OrderSearchQuery = (data) => {
    const firstQuery = data?.filter((credit_order) => {
      const name = `${credit_order?.fullname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return credit_order;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.user_status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (
      credit_orders?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLOVERVIEWCREDITORDER,
        value: OrderSearchQuery(credit_orders?.data),
      });

      return;
    }

    if (
      loan_requests?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLOVERVIEWLOANREQUEST,
        value: SearchQuery(loan_requests?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoadingOrders, isLoading]);

  useEffect(() => {
    if (
      credit_orders?.status === "success" &&
      session?.admin?.category === "merchant"
    ) {
      dispatch({
        type: ADDALLOVERVIEWCREDITORDER,
        value: OrderSearchQuery(credit_orders?.data),
      });
    }

    if (
      loan_requests?.status === "success" &&
      session?.admin?.category === "lender"
    ) {
      dispatch({
        type: ADDALLOVERVIEWLOANREQUEST,
        value: SearchQuery(loan_requests?.data),
      });
    }

    // eslint-disable-next-line
  }, [searchValue, status]);

  return (
    <div>
      <Heading />
      <div className="mt-5">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <WalletBalance session={session} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <LoanApplications session={session} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <BusinessPerformance session={session} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <LoanPortfolioHealth session={session} />
          </Col>
        </Row>
      </div>
      <div className="mt-10">
        <Card className="w-full">
          <TableHeader />
          {session?.admin?.category === "merchant" ? (
            <OrderOverviewTable isLoading={isLoadingOrders} />
          ) : (
            <RequestsTable isLoading={isLoading} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default Overview;
