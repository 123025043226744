const countries = [
  {
    code: "+234",
    name: "Nigeria",
  },

  {
    code: "+263",
    name: "Zimbabwe",
  },

  {
    code: "+260",
    name: "zambia",
  },
];

export default countries;
