import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

function LoanCollateralsTable() {
  const { collaterals } = useSelector(
    (state) => state.loanRequestsCollateralsTableReducer
  );

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Collateral Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={collaterals}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default LoanCollateralsTable;
