import React from "react";
import { Table, Button } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCurrenncyFormatter } from "../../../utils/currencyFormatter";

function OrderOverviewTable({ isLoading }) {
  const { creditorders } = useSelector((state) => state.overViewTableReducer);
  const { currencyFormatter } = useCurrenncyFormatter();
  const history = useHistory();

  const columns = [
    {
      title: "ID",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Borrower",
      dataIndex: "borrower",
      key: "fullname",
      render: (text, record) => <p>{`${record.fullname}`}</p>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <p>{currencyFormatter(text)}</p>,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: "Time Stamp",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOder: "descend",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
      render: (text, record) => (
        <p>{`${moment(record.created_at).format("MMM Do YYYY")}`}</p>
      ),
    },

    {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <p>{record?.user_status}</p>,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/loan_request/${record.id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={creditorders}
        pagination={{ position: ["bottomLeft"] }}
        rowSelection={rowSelection}
        size="small"
      />
    </>
  );
}

export default OrderOverviewTable;
