import { useMutation } from "react-query";
import { useAuth } from "../context/AuthContext";

import Requests from "../utils/request";

function useLoanApplication(props) {
  const { session } = useAuth();

  const createLoanMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/borrower/${userData.userID}/action?action=loan-request`,
      "PUT",
      session?.session?.token,
      userData
    )
  );

  const createOrderMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      "/lms/user/credit_order",
      "POST",
      session?.session?.token,
      userData
    )
  );

  const profileMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/borrower/${userData.loan_requestID}/action?action=edit-profile`,
      "PUT",
      session?.session?.token,
      userData
    )
  );

  const nextOfKinMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=next-of-kin`,
      "POST",
      session?.session?.token,
      userData
    )
  );

  const uploadDocMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=uploads`,
      "POST",
      session?.session?.token,
      userData.upload
    )
  );

  const bankDetailsMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=bank-account`,
      "POST",
      session?.session?.token,
      userData.bank
    )
  );

  const bankDetailsCreditOrderMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/credit_order/${userData.loan_requestID}/data?data=bank-account`,
      "POST",
      session?.session?.token,
      userData.bank
    )
  );

  const businessDetailMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=business-information`,
      "POST",
      session?.session?.token,
      userData.bussiness
    )
  );

  const progressMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${userData.loan_requestID}`,
      "PUT",
      session?.session?.token,
      { progress: userData.progress }
    )
  );

  const progressCreditOrderMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/credit_order/${userData.CREDITID}/action?action=update-progress&progress=${userData.progress}`,
      "GET",
      session?.session?.token
    )
  );

  const submitMutation = useMutation((id) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${id}/action?data=submit`,
      "PUT",
      session?.session?.token
    )
  );

  const submitcreditOrderMutation = useMutation((id) =>
    Requests.fetchWithAuthToken(
      `/lms/user/credit_order/${id}/action?action=submit`,
      "GET",
      session?.session?.token
    )
  );

  const bvnMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=bvn`,
      "POST",
      session?.session?.token,
      { number: userData.number }
    )
  );

  const gurantorMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/guarantor`,
      "POST",
      session?.session?.token,
      userData.gurantor
    )
  );

  const setupMandateMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${
        userData.loan_requestID
      }/debit_mandate?action=setup&vendor=remita&resource=${
        session?.session?.category === "lender"
          ? "loan-request"
          : "credit-order"
      }`,
      "POST",
      session?.session?.token
    )
  );

  const isRequireOTPMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${
        userData.loan_requestID
      }/debit_mandate?action=supports-otp&vendor=remita&resource=${
        session?.session?.category === "lender"
          ? "loan-request"
          : "credit-order"
      }`,
      "POST",
      session?.session?.token
    )
  );

  const requestOTPMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${
        userData.loan_requestID
      }/debit_mandate?action=request-otp&vendor=remita&resource=${
        session?.session?.category === "lender"
          ? "loan-request"
          : "credit-order"
      }`,
      "POST",
      session?.session?.token
    )
  );

  const RequestAuthParamMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${
        userData.loan_requestID
      }/debit_mandate?action=get-mandate&vendor=remita&resource=${
        session?.session?.category === "lender"
          ? "loan-request"
          : "credit-order"
      }`,
      "POST",
      session?.session?.token
    )
  );

  const RequestMandateUrlMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${
        userData.loan_requestID
      }/debit_mandate?action=get-form-url&vendor=remita&resource=${
        session?.session?.category === "lender"
          ? "loan-request"
          : "credit-order"
      }`,
      "POST",
      session?.session?.token
    )
  );

  const EmploymentMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=employment-information`,
      "POST",
      session?.session?.token,
      userData.employment
    )
  );

  const HealthfacilityMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=health-facility`,
      "POST",
      session?.session?.token,
      userData.facilitydata
    )
  );

  const facilityExpensesMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=facility-expenses`,
      "POST",
      session?.session?.token,
      userData.facilitydata
    )
  );

  const facilityMetricsMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/loan_request/${userData.loan_requestID}/data?data=facility-metrics`,
      "POST",
      session?.session?.token,
      userData.facilitydata
    )
  );

  const customMutation = useMutation((userData) =>
    Requests.fetchWithAuthToken(
      `/lms/user/loan_request/${userData.loan_requestID}/custom_data?action=create`,
      "POST",
      session?.session?.token,
      userData.fields
    )
  );

  const createLoan = (loanRequest, callBack) =>
    createLoanMutation.mutate(loanRequest, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitCustom = (userData, callBack) =>
    customMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const createOrder = (orderRequest, callBack) =>
    createOrderMutation.mutate(orderRequest, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const createProfile = (profile, callBack) =>
    profileMutation.mutate(profile, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const addNextOfKin = (kin, callBack) =>
    nextOfKinMutation.mutate(kin, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const uploadDocs = (docs, callBack) =>
    uploadDocMutation.mutate(docs, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const addBank = (bank, callBack) =>
    bankDetailsMutation.mutate(bank, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const addBankCreditOrder = (bank, callBack) =>
    bankDetailsCreditOrderMutation.mutate(bank, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const addBusiness = (details, callBack) =>
    businessDetailMutation.mutate(details, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const updateProgress = (progress, callBack) =>
    progressMutation.mutate(progress, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const updateCreditOrderProgress = (progress, callBack) =>
    progressCreditOrderMutation.mutate(progress, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitLoanApp = (id, callBack) =>
    submitMutation.mutate(id, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitCreditOrderApp = (id, callBack) =>
    submitcreditOrderMutation.mutate(id, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitBVN = (userData, callBack) =>
    bvnMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitGurantor = (userData, callBack) =>
    gurantorMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitEmployment = (userData, callBack) =>
    EmploymentMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const setUpMandate = (userData, callBack) =>
    setupMandateMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const checkIsOtpRequired = (userData, callBack) =>
    isRequireOTPMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const requestOTP = (userData, callBack) =>
    requestOTPMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const requestAuthParam = (userData, callBack) =>
    RequestAuthParamMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const requestMandateUrl = (userData, callBack) =>
    RequestMandateUrlMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitHealthfacility = (userData, callBack) =>
    HealthfacilityMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitfacilityExpenes = (userData, callBack) =>
    facilityExpensesMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  const submitfacilityMetrics = (userData, callBack) =>
    facilityMetricsMutation.mutate(userData, {
      onSuccess: (data) => {
        callBack(data);
      },
      onError: (err) => {
        callBack({ status: "error", message: "something went wrong" });
      },
    });

  return {
    createProfile,
    createLoan,
    createOrder,
    addNextOfKin,
    uploadDocs,
    submitBVN,
    addBank,
    submitGurantor,
    addBusiness,
    updateProgress,
    submitEmployment,
    submitLoanApp,
    setUpMandate,
    checkIsOtpRequired,
    requestOTP,
    requestAuthParam,
    requestMandateUrl,
    addBankCreditOrder,
    submitCreditOrderApp,
    updateCreditOrderProgress,
    submitHealthfacility,
    submitfacilityExpenes,
    submitfacilityMetrics,
    submitCustom,
  };
}

export default useLoanApplication;
