import React from "react";

const Mobile = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center" style={{backgroundColor: "#5152a6"}}>
      <div className="border bg-white p-3 mx-3 rounded-t-lg flex">
       <div className="flex justify-start mr-2">
        <div className="border bg-white p-3 rounded-full" style={{backgroundColor: "#5152a6"}}></div>
        <div className="border bg-white p-3 rounded-full" style={{backgroundColor: "#5152a6"}}></div>
        <div className="border bg-white p-3 rounded-full" style={{backgroundColor: "#5152a6"}}></div>
       </div>
        <div className="border bg-white p-3 rounded-md justify-center w-28 sm:w-96" style={{backgroundColor: "#5152a6"}}></div>
        <div className="border bg-white p-3 rounded-md justify-end w-10 sm:w-12 ml-2" style={{backgroundColor: "#5152a6"}}></div>
      </div>
      <div className="flex flex-col uppercase text-center items-center space-y-4 border bg-white p-10 sm:p-4 mx-3 sm:mx-5 mt-2 rounded-b-lg h-2/5">
        <div className="font-black text-5xl text-black tracking-tight  mt-0 sm:mt-10">
          O<span className="italic">OPS!!!</span>
        </div>
        <div className="font-bold text-black text-xs w-48 sm:w-auto">
          We are sorry, but you cannot view this page on mobile, please use your desktop.
        </div>
      </div>
    </div>
  )
}

export default Mobile;