import { Card, Button, Row, Col, Divider, Avatar } from "antd";
import React from "react";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import moment from "moment";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";
import { AppProvider } from "../../context/FetchContext";

const SingleTransaction = () => {
	const { session } = useAuth();
	const { id } = useParams();
	const history = useHistory();
	const { currencyFormatter } = useCurrenncyFormatter();

	const { data: singleTransaction, refetch } = useQuery(
		"single_transaction",
		() =>
			Requests.fetchWithAuthToken(
				`/lms/transaction?id=${id}&start=2021-05-01&end=2021-12-31`,
				"GET",
				session.session.token
			),
		{ refetchOnWindowFocus: false } 
	);

	return (
		<>
			<AppProvider
				value={{ refetch,  request: singleTransaction }}
			>
				<div className="mb-20">
					<div className="flex justify-between mt-2">
						<span
							className="text-gray-500 cursor-pointer"
							onClick={() => history.goBack()}
						>
							<span>
								<ArrowLeftOutlined />
							</span>
							<span> Back</span>
						</span>
					</div>
					<div className="mt-5">
						<Row gutter={[10, 10]}>
							<Col xs={24} md={12} lg={12}>
								<Card loading={!singleTransaction}>
									<div className="flex justify-between">
										<div>
											<h2 className="font-bold text-lg"> Transaction Details</h2>
											<p className="text-gray-500 mt-2">
												Review the transaction details
											</p>
										</div>
									</div>
									<Divider />
									<div className="flex justify-between">
										<h2 className="text-lg font-bold capitalize">summary</h2>
										<Button
											type="default"
											style={{
												backgroundColor: singleTransaction?.paystack?.status
													? "#c4f8e2"
													: "orange",
												color: singleTransaction?.paystack?.status ? "#06a561" : "white",
											}}
										>
											{singleTransaction?.paystack?.data?.status}
										</Button>
									</div>
									<div className="flex justify-between mt-5">
										<div className="flex">
											<div className="mt-5">
												<Avatar
													shape="square"
													size="large"
													icon={<UserOutlined />}
												/>
											</div>
											<div className="ml-5">
												<h2 className="font-bold">
													Name:{" "}
													{singleTransaction?.paystack?.customer
														? `${singleTransaction?.paystack?.customer?.first_name} ${singleTransaction?.paystack?.customer?.last_name}`
														: "N/A"}
												</h2>
												<h2 className="font-bold">
													Email:{" "}
													{singleTransaction?.paystack?.customer
														? `${singleTransaction?.paystack?.customer?.email}`
														: "N/A"}
												</h2>
											</div>
										</div>
									</div>
									<div className="my-3">
											<h2 className="text-gray-500 text-base">
												Paid on:{" "}
												{moment(
													singleTransaction?.paystack?.data?.transaction_date.split(" ")[0]
												).format("dddd, MMMM Do YYYY, h:mm a")}
											</h2>
                                        <h2 className="text-gray-500 text-base">
												Channel:{" "}
												{singleTransaction?.data?.channel}
											</h2>
									</div>
									<Divider />
									<div>
										<div className="flex justify-between">
											<h2 className="text-gray-500">Transaction ID</h2>
											<h4 className="text-gray-500">{singleTransaction?.paystack?.data?.reference}</h4>
										</div>
									</div>

									<Divider />
									<div className="flex justify-between mt-5 mb-5">
										<h2 className="font-bold">Amount</h2>
										<h4 className="font-bold text-blue-700 text-lg">
											{currencyFormatter(singleTransaction?.data?.amount)}
										</h4>
									</div>
									<div className="my-3">
										<h2 className="text-gray-500 text-base">Note: {singleTransaction?.data?.note} </h2>
									</div>
								</Card>
							</Col>
						</Row>
					</div>
				</div>
			</AppProvider>
		</>
	);
}

export default SingleTransaction;
