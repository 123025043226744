import {
  Button,
  Form,
  Modal,
  message,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { useApp } from "../../context/FetchContext";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import { Title } from "../Typography";
import moment from "moment";
import {
  useCurrenncyFormatter,
  currencyParser,
} from "../../utils/currencyFormatter";

const { Option } = Select;

const UpdatePartialRepaymentButton = ({ paid }) => {
  const { currencyFormatter } = useCurrenncyFormatter();
  const { session } = useAuth();
  const { id } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const { otherRefect, refetch } = useApp();

  const presentDate = moment([]).format("yyyy, M, D");
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  }

  const calculateDays = (date) => {
    let a = moment(date);
    return a.diff(presentDate, "days") - 1;
  };

  const repaidMutation = useMutation((repaidBody) =>
    Requests.fetchWithAuthToken(
      `/lms/repayment/${id}/update?action=partialy-repaid`,
      "PUT",
      session.session.token,
      repaidBody
    )
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values) => {
    const query = {
      note: values.note,
      days: calculateDays(values.days),
      channel: values.channel,
      amount: values.amount,
    };

    setProcessing(true);
    repaidMutation.mutate(query, {
      onSuccess: (data) => {
        setProcessing(false);
        if (data.status !== "success") {
          message.error(data.message, 3);
          return;
        }
        otherRefect.refectPartial();
        refetch();
        message.success(data.message, 3);
      },
      onError: (err) => {},
    });
    setIsModalVisible(false);
  };

  return (
    <>
      {paid ? (
        " "
      ) : (
        <Button type="primary" onClick={showModal} loading={isProcessing}>
          Partial Repayment
        </Button>
      )}
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={false}
        width="30%"
      >
        <Title level={4} className="mt-5" style={{ textAlign: "center" }}>
          You are about to mark this repayment as Partialy repaid
        </Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <TextArea type="text" width={100} />
              </Form.Item>
              <Form.Item
                label="Date"
                name="days"
                rules={[
                  {
                    required: true,
                    message: "Date is required!",
                  },
                ]}
              >
                <DatePicker
                  onChange={(value) => calculateDays(value)}
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Channel"
                name="channel"
                rules={[
                  {
                    required: true,
                    message: "Channel is required!",
                  },
                ]}
              >
                <Select size="large">
                  <Option value="cash" key="cash">
                    Cash
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required!",
                  },
                ]}
              >
                <InputNumber
                  value="amount"
                  size="large"
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              className="w-full"
              type="primary"
              loading={isProcessing}
            >
              Mark as partialy paid
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePartialRepaymentButton;
