import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import { Card, Form, Col, Row, Button, message } from "antd";
import useLoanApplication from "../../../../hooks/useLoanApplication";
import { useSelector, useDispatch } from "react-redux";
import useGenerateComponent from "../../../../utils/componentGenerator";
import { Title } from "../../../Typography";
const businessform = React.createRef();

function FacilityExpenses({ currentNumber, id, setCurrentNumber }) {
  const dispatch = useDispatch();
  const { currentLoan } = useSelector((state) => state.LoanApplicationReducer);
  const { session } = useAuth();
  const [loading, setIsLoading] = useState(false);
  const { submitfacilityExpenes, updateProgress } = useLoanApplication();
  const { generateComponent } = useGenerateComponent();
  const { data } = useQuery(
    `health-expenses`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/user/loan_request/${id}/data?data=facility-expenses`,
        "GET",
        session?.admin?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const handleSubmitCallback = (data) => {
    if (data.status === "success") {
      setIsLoading(false);
      message.success(data.message, 2);
      setCurrentNumber((prev) => {
        const current = Number(prev) + 1;
        return current.toString();
      });
      updateProgress(
        { progress: String(Number(currentNumber) + 1), loan_requestID: id },
        (data) => data
      );

      return;
    }

    setIsLoading(false);
    message.error(data.message, 5);
  };

  const handleSubmit = (value) => {
    setIsLoading(true);

    const details = {
      loan_requestID: id,
      facilitydata: {
        ...value,
      },
    };

    submitfacilityExpenes(details, handleSubmitCallback);
  };

  const generateFormObject = (fields, profile) => {
    const formObject = {};
    fields.forEach((field) => {
      formObject[field] = profile[field];
    });

    return formObject;
  };

  useEffect(() => {
    if (data) {
      const fields = Object.keys(data?.data || {});
      const profile = data?.data;
      const formObj = generateFormObject(fields, profile);

      businessform.current?.setFieldsValue({
        ...formObj,
      });
    }
  }, [data]);

  return (
    <Card>
      <Title level={3}>Facility Expenses</Title>
      <Form ref={businessform} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[20, 0]}>
          {currentLoan?.segments["facility-expenses"]?.fields?.map(
            (field, index) =>
              field?.is_needed && (
                <Col xs={24} md={12} key={`${field?.field}-${index}`}>
                  <Form.Item
                    label={field?.label}
                    name={field?.field}
                    rules={[
                      {
                        required: field?.is_required,
                        message: "required",
                      },
                    ]}
                  >
                    {generateComponent(
                      field,
                      {
                        ref: businessform,
                        fieldName: field?.field,
                      },
                      dispatch
                    )}
                  </Form.Item>
                </Col>
              )
          )}
        </Row>

        {!data?.data && (
          <Button
            className="lg:w-auto w-full"
            loading={loading}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Save and Continue
          </Button>
        )}
      </Form>
    </Card>
  );
}

export default FacilityExpenses;
