import React, { useEffect } from "react";
import { Row, Tabs } from "antd";
import { useQuery, useMutation } from "react-query";
import { TeamHeader } from "../../components/Team/TeamHeader";
import TeamCard from "../../components/Team/TeamCard";
import green from "../../assets/svg/InsightGreen.svg";
import yellow from "../../assets/svg/InsightYellow.svg";
import blue from "../../assets/svg/InsightBlue.svg";
import AgentTable from "../../components/Table/Team/AgentTable";
import { TeamTableHeader } from "../../components/Team/TeamHeader";
import { useAuth } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import Requests from "../../utils/request";
import {
  ADDALLAGENT,
  SETAGENTSTATUSTOSEARCHFOR,
} from "../../redux/Types/tableTypes";

const { TabPane } = Tabs;

function Agent() {
  const style = {
    background: "#ffffff",
    padding: "20px 20px 35px 20px",
    borderRadius: "12px",
  };
  const { session } = useAuth();
  const dispatch = useDispatch();

  const { searchValue, status } = useSelector(
    (state) => state.agentTableReducer
  );

  const {
    data: invitee,
    isLoading: isLoadingInvitee,
    refetch,
  } = useQuery("agent-invitees", () =>
    Requests.fetchWithAuthToken(
      "/lms/agent?data=invitees",
      "GET",
      session.session.token
    )
  );

  const { data: agent, isLoading: isLoadingAgent } = useQuery(
    "agent-activated",
    () =>
      Requests.fetchWithAuthToken(
        "/lms/agent?data=activated",
        "GET",
        session.session.token
      )
  );

  // const { data: metrics } = useQuery("invitee-metrics", () =>
  //   Requests.fetchWithAuthToken(
  //     "/lms/team/metrics",
  //     "GET",
  //     session.session.token
  //   )
  // );

  //delete endpoint
  const deleteMutation = useMutation((ID) =>
    Requests.fetchWithAuthToken(
      `lms/agent/${ID}/action?action=delete`,
      "PUT",
      session.session.token
    )
  );

  const handleDelete = (key) => {
    deleteMutation.mutate(
      { email: key },
      {
        onSuccess: (body) => {
          refetch();
        },
      }
    );
  };

  const SearchQuery = (data) => {
    return data?.filter((team) => {
      const name = `${team?.firstname} ${team?.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return team;
      }
      return null;
    });
  };

  useEffect(() => {
    if (agent?.status === "success") {
      dispatch({
        type: ADDALLAGENT,
        value: SearchQuery(agent?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoadingAgent]);

  useEffect(() => {
    dispatch({
      type: ADDALLAGENT,
      value: SearchQuery(status === "invitees" ? invitee?.data : agent?.data),
    });

    // eslint-disable-next-line
  }, [searchValue, status]);

  return (
    <div>
      <TeamHeader />
      <div className="mb-10" style={style}>
        <Row gutter={16}>
          <TeamCard
            amount={0}
            title="Active Users"
            logo={green}
            background="#E8FFF7"
          />
          <TeamCard
            amount={0}
            title="Invite sent"
            logo={blue}
            background="rgb(243, 246, 255)"
          />
          <TeamCard
            amount={0}
            title="Pending Requests"
            logo={yellow}
            background="#FFFDE8"
          />
        </Row>
      </div>
      <div style={style}>
        <TeamTableHeader refetch={refetch} isAgent={true} />

        <Tabs
          size="default"
          type="card"
          onTabClick={(key) => {
            dispatch({ type: SETAGENTSTATUSTOSEARCHFOR, value: key });
          }}
          style={{ marginTop: "20px", marginBottom: "0" }}
        >
          <TabPane tab="Active Agents" key="activated">
            <div className="my-10">
              <AgentTable
                isLoading={isLoadingInvitee}
                handleDelete={handleDelete}
              />
            </div>
          </TabPane>

          <TabPane tab="Inactive Agents" key="invitees">
            <div className="my-10">
              <AgentTable
                isLoading={isLoadingInvitee}
                handleDelete={handleDelete}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Agent;
