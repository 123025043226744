
export function Mask(props) {
  return (
    <svg
      width={14}
      height={10}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3 0c.42 0 .7.28.7.7 0 .42-.28.7-.7.7H.7c-.42 0-.7-.28-.7-.7C0 .28.28 0 .7 0h12.6zM.7 4.2h9.8c.42 0 .7-.28.7-.7 0-.42-.28-.7-.7-.7H.7c-.42 0-.7.28-.7.7 0 .42.28.7.7.7zM14 6.3c0-.42-.28-.7-.7-.7H.7c-.42 0-.7.28-.7.7 0 .42.28.7.7.7h12.6c.42 0 .7-.28.7-.7zm-3.5 2.1c.42 0 .7.28.7.7 0 .42-.28.7-.7.7H.7c-.42 0-.7-.28-.7-.7 0-.42.28-.7.7-.7h9.8z"
        fill="#9595AC"
      />
    </svg>
  )
}

export function CalenderIcon(props) {
  return (
    <svg
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.847 9.012H7.044v3.803h3.803V9.012zm-.76-8.365v1.52H4.001V.648h-1.52v1.52H1.72c-.844 0-1.514.685-1.514 1.522L.2 14.336a1.52 1.52 0 001.52 1.521h10.648c.836 0 1.52-.684 1.52-1.521V3.689c0-.837-.684-1.521-1.52-1.521h-.76V.647h-1.522zm2.28 13.689H1.722V5.97h10.647v8.366z"
        fill="#84818A"
      />
    </svg>
  )
}

export function Action(props) {
  return (
    <svg
      width={12}
      height={4}
      viewBox="0 0 12 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5.5C.675.5 0 1.175 0 2s.675 1.5 1.5 1.5S3 2.825 3 2 2.325.5 1.5.5zm9 0C9.675.5 9 1.175 9 2s.675 1.5 1.5 1.5S12 2.825 12 2 11.325.5 10.5.5zM6 .5c-.825 0-1.5.675-1.5 1.5S5.175 3.5 6 3.5 7.5 2.825 7.5 2 6.825.5 6 .5z"
        fill="#757580"
      />
    </svg>
  )
}

export function Repayment(props) {
  return (
    <svg
      width={16}
      height={12}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 0a2 2 0 00-2 2v1h16V2a2 2 0 00-2-2H2z" fill="#11C096" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H0v5a2 2 0 002 2h12a2 2 0 002-2V5zM2 9a1 1 0 011-1h1a1 1 0 110 2H3a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H7z"
        fill="#11C096"
      />
    </svg>
  )
}

export function Overdueloan(props) {
  return (
    <svg
      width={16}
      height={12}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 0a2 2 0 00-2 2v1h16V2a2 2 0 00-2-2H2z" fill="#2F80ED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H0v5a2 2 0 002 2h12a2 2 0 002-2V5zM2 9a1 1 0 011-1h1a1 1 0 110 2H3a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H7z"
        fill="#2F80ED"
      />
    </svg>
  )
}