import { Row, Col, Divider, Empty, Form } from "antd";
import React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import Requests from "../../../../utils/request";
import AddFacilityMetrics from "./UpdateDetails/AddFacilitymetrics";

const FacilityMetrics = ({ template }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const { handleCustomField } = UseDisplayCustom();
  const { generateInputType } = UseGenerateComponentNew();
  const metricsRef = React.createRef();

  const { data: facility_metrics, refetch: refetchFacilityMetrics } = useQuery(
    `facility_metrics_${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=facility-metrics`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    metricsRef?.current?.setFieldsValue({
      daily_visits: facility_metrics?.data?.daily_visits,
      number_of_doctors: facility_metrics?.data?.number_of_doctors,
      number_of_lab_technicians:
        facility_metrics?.data?.number_of_lab_technicians,
      number_of_nurses: facility_metrics?.data?.number_of_nurses,
      number_of_pharmacists: facility_metrics?.data?.number_of_pharmacists,
      number_of_locations: facility_metrics?.data?.number_of_locations,
      number_of_registered_patients:
        facility_metrics?.data?.number_of_registred_patients,
      number_of_staff: facility_metrics?.data?.number_of_staff,
      patient_monthly_visit_count:
        facility_metrics?.data?.patient_monthly_visit_count,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility_metrics]);

  return (
    <>
      <div className="mt-5">
        <Form layout="vertical" ref={metricsRef}>
          <Row gutter={[16, 16]}>
            {template?.map((data) =>
              data?.slug === "numbers-&-metrics"
                ? data?.fields?.map((field) =>
                    field?.is_required || field?.is_needed ? (
                      <Col span={8}>
                        <Form.Item name={field?.field} label={field?.label}>
                          {generateInputType(field)}
                        </Form.Item>
                        <Divider />
                      </Col>
                    ) : null
                  )
                : null
            )}
            {facility_metrics?.data?.custom_fields &&
              facility_metrics?.data?.custom_fields?.map((fields) => (
                <Col span={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500">{fields?.label}: </h3>
                    <h3 className="font-bold text-base">
                      {handleCustomField(fields)}
                    </h3>
                  </div>
                  <Divider />
                </Col>
              ))}
            {facility_metrics?.data === null && (
              <Col span={24}>
                <Empty />
              </Col>
            )}
          </Row>
        </Form>
        <div className="flex justify-end">
          <AddFacilityMetrics
            data={facility_metrics?.data}
            refetchFacilityMetrics={refetchFacilityMetrics}
          />
        </div>
      </div>
    </>
  );
};

export default FacilityMetrics;
