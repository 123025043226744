import React, { useEffect } from "react";
import AllGuarantors from "../../components/Table/Guarantors/AllGurantorsTable";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import Requests from "../../utils/request";
import TableHeader from "../../components/Guarantors/TableHeader";
import { Card } from "antd";
import { ADDALLGURANTORS } from "../../redux/Types/tableTypes";
import { Title } from "../../components/Typography";

function Guarantors(props) {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue, startDate, endDate } = useSelector(
    (state) => state.gurantorsTableReducer
  );

  const { data, isLoading } = useQuery(
    `gurantor-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/guarantor?id=all&start=${startDate}&end=${endDate}`,
        "GET",
        session.session.token
      )
  );
  const BigQuery = (data) => {
    return data?.filter((gurantor) => {
      const name = `${gurantor?.firstname} ${gurantor?.lastname}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return gurantor;
      }
      return null;
    });
  };

  useEffect(() => {
    if (data?.status === "success") {
      dispatch({
        type: ADDALLGURANTORS,
        value: BigQuery(data?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: ADDALLGURANTORS,
      value: BigQuery(data?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <Title level={2}>Guarantors</Title>
      <Card>
        <TableHeader />
        <AllGuarantors isLoading={isLoading} />
      </Card>
    </>
  );
}

export default Guarantors;
