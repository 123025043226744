import { Progress, Card, Row, Col, Empty, Button} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Text, Title } from "../../Typography";
import { AtSign, Bag, CombinedShape } from "../icons";

function Product({ data, orderData, isLoading, isLoadingFulfilments }) {
  const { session } = useAuth();
  const [showMore, setShowMore] = useState(false);
  const [ buttonText, setButtonText ] = useState("Show More");
  const limit = 3;

  const LoadMore = () => {
    setShowMore(oldValue => !oldValue);
    showMore ? setButtonText("Show more") : setButtonText("Show Less");
  };

  return (
    <div>
      <Card loading={isLoading || isLoadingFulfilments}>
        {session?.admin?.category === "merchant" ? (
          <>
            <Title level={4}>Fulfilments by Products</Title>
            <Row gutter={[6, 16]} style={{ marginTop: "20px" }}>
              {orderData?.map((product) => (
                <>
                  <Col span={11}>
                    <Progress
                      type="circle"
                      percent={product?.percentage}
                      strokeColor="#2772F0"
                      showInfo={false}
                      width={96}
                    />
                    {product?.percentage >= 50 ? (
                      <div className="absolute inset-6">
                        <Bag />
                      </div>
                    ) : product?.percentage >= 20 ? (
                      <div className="absolute inset-6">
                        <AtSign />
                      </div>
                    ) : (
                      <div className="absolute inset-6">
                        <CombinedShape />
                      </div>
                    )}
                  </Col>
                  <Col span={13}>
                    <Title level={4}>
                      {product?.percentage}
                      {"%"}
                    </Title>
                    <Text>{product?.product_name}</Text>
                  </Col>
                </>
              ))}
            </Row>
          </>
        ) : (
          <>
            <Title level={4}>Disbursement by Products</Title>
            <Row gutter={[6, 16]} style={{ marginTop: "20px" }}>
              {data?.slice(0, showMore ? data?.length : 3).map((product) => (
                <>
                  <Col span={11}>
                    <Progress
                      type="circle"
                      percent={product?.percentage}
                      strokeColor="#2772F0"
                      showInfo={false}
                      width={96}
                    />
                    {product?.percentage >= 50 ? (
                      <div className="absolute inset-6">
                        <Bag />
                      </div>
                    ) : product?.percentage >= 20 ? (
                      <div className="absolute inset-6">
                        <AtSign />
                      </div>
                    ) : (
                      <div className="absolute inset-6">
                        <CombinedShape />
                      </div>
                    )}
                  </Col>
                  <Col span={13}>
                    <Title level={4}>
                      {product?.percentage}
                      {"%"}
                    </Title>
                    <Text>{product?.product_name}</Text>
                  </Col>
                </>
              ))}
            </Row>
              {data?.length > limit ? <Button onClick={() => LoadMore()} style={{marginTop: "20px"}}> {buttonText} </Button> : ""}
          </>
        )}
        {data === null && session?.admin?.category === "lender" && (
          <div>
            <Empty
              description={session?.admin?.category === "merchant" ? "Nothing here yet. Fulfill some orders first" : "Nothing here yet. Disburse some loans first"}
            />
          </div>
        )}

        {data === undefined && session?.admin?.category === "lender" && (
          <div>
            <Empty
              description={session?.admin?.category === "merchant" ? "Nothing here yet. Fulfill some orders first" : "Nothing here yet. Disburse some loans first"}
            />
          </div>
        )}

        {orderData === null && session?.admin?.category === "merchant" && (
          <div>
            <Empty
              description={session?.admin?.category === "merchant" ? "Nothing here yet. Fulfill some orders first" : "Nothing here yet. Disburse some loans first"}
            />
          </div>
        )}

        {orderData === undefined && session?.admin?.category === "merchant" && (
          <div>
            <Empty
              description={session?.admin?.category === "merchant" ? "Nothing here yet. Fulfill some orders first" : "Nothing here yet. Disburse some loans first"}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default Product;
