import { createContext, useContext, useState } from "react";

const TableContext = createContext();

function TableProvider({ children }) {
  const [requestTableSorter, setRequestTableSorters] = useState({
    type: null,
  });

  const value = {
    requestTableSorter,
    setRequestTableSorters,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}

const useTable = () => {
  const context = useContext(TableContext);

  if (context === undefined) {
    throw new Error("useTable must be used within an TableProvider");
  }

  return context;
};

export { TableProvider, useTable };
