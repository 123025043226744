import {
  SETALLLOANS,
  SETCURRENTLOANS,
  USERSELECTCOUNTRY,
  SETCURRENTLOANID,
  SETLOANAPPLICATIONMODE,
  SETBANKCODE,
  SETALLOFFERS,
  SETSTATE,
} from "../Types/generalTypes";

export const applicationMode = {
  EDIT: true,
  PENDING: false,
};
const initialState = {
  allLoans: [],
  currentLoan: null,
  userCountry: "",
  userState: "",
  IdOfCurrentLoanCreated: 0,
  loanApplicationMode: applicationMode.PENDING,
  bankCode: "",
  offers: [],
};

export const LoanApplicationReducer = (state = initialState, actions) => {
  if (actions.type === SETALLLOANS) {
    return { ...state, allLoans: actions.loans };
  }

  if (actions.type === SETCURRENTLOANS) {
    return { ...state, currentLoan: actions.loan };
  }

  if (actions.type === USERSELECTCOUNTRY) {
    return { ...state, userCountry: actions.value };
  }

  if (actions.type === SETSTATE) {
    return { ...state, userState: actions.value };
  }

  if (actions.type === SETCURRENTLOANID) {
    return { ...state, IdOfCurrentLoanCreated: actions.value };
  }

  if (actions.type === SETLOANAPPLICATIONMODE) {
    return { ...state, loanApplicationMode: applicationMode[actions.value] };
  }

  if (actions.type === SETBANKCODE) {
    return { ...state, bankCode: actions.value };
  }

  if (actions.type === SETALLOFFERS) {
    return { ...state, offers: actions.value };
  }

  return state;
};
