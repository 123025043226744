import React from 'react';
import { Text, Title } from "../Typography";

function Insight({amount, textColor, heading, text, image}) {
    const style = {
        display: 'flex', justifyContent: 'space-between', border: '1px solid rgba(149, 149, 172, 0.16)',
        boxShadow: '0px 0.971248px 3.88499px rgba(21, 34, 50, 0.08)',
        borderRadius: '11.4894px', padding: '20px'
    }


    return (
        <div style={style}>
            <div style={{width:'60%'}}>
                <Title level={3}>{amount}</Title>
                <div style={{fontSize:'12px'}}>
                    <Text style={{color:`${textColor}`, fontWeight:'bold'}}>{heading}</Text>
                    <p style={{marginTop:'10px'}}>{text}</p>
                </div>
            </div>
            <div style={{alignSelf:'flex-end', width:'35%'}}>
                <img src={image} style={{width:'100%'}} alt={heading} />
            </div>
        </div>
    )
}

export default Insight;
