import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useDispatch } from "react-redux";
import Requests from "../../../../utils/request";
import { useQuery } from "react-query";
import RequestLogTable from "../../../Table/LoanRequests/RequestLogs";
import { ADDALLREQUESTLOGS } from "../../../../redux/Types/tableTypes";

const ActivityLog = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    data: activity_logs,
    isLoading,
  } = useQuery(
    "request_activity_logs",
    () =>
    Requests.fetchWithAuthToken(`/lms/loan_request/${id}/data?data=activity-log`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (activity_logs?.status === "success") {
      dispatch({
        type: ADDALLREQUESTLOGS,
        value: activity_logs?.data,
      });
    }
    // eslint-disable-next-line
  }, [activity_logs]);

  return (
    <>
      <RequestLogTable isLoading={isLoading} />
    </>
  );
};

export default ActivityLog;
