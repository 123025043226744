import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAuth } from "../../../../context/AuthContext";
import { ADDALLBORROWERSREPAYMENT } from "../../../../redux/Types/tableTypes";
import Requests from "../../../../utils/request";
import BorrowersRepaymentsTable from "../../../Table/Borrowers/BorrowersRepayment";
import RepaymentsTableHeader from "./repaymentHeader";
 
const Repayments = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { searchValue, status } = useSelector(
    (state) => state.borrowersRepaymentTableReducer
  );

  const { data: repayments, isLoading} = useQuery(
    'borrowers_repyments',
    () =>
      Requests.fetchWithAuthToken(
        `/lms/borrower/${id}/data?data=repayment`,
        "GET",
        session.session.token
      )
    )

    const SearchQuery = (data) => {
      const firstQuery = data?.filter((repayment) => {
        const name = `${repayment?.borrower?.firstname} ${repayment?.borrower?.lastname}`;
        if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
          return repayment;
        }
        return null;
      });
  
      return firstQuery?.filter((data) => {
        if (status) {
          return data.status === status;
        }
  
        return true;
      });
    };
  
    useEffect(() => {
      if (repayments?.status === "success") {
        dispatch({
          type: ADDALLBORROWERSREPAYMENT,
          value: SearchQuery(repayments?.data),
        });
      }
      // eslint-disable-next-line
    }, [isLoading, status]);
  
    useEffect(() => {
      dispatch({
        type: ADDALLBORROWERSREPAYMENT,
        value: SearchQuery(repayments?.data),
      });
  
      // eslint-disable-next-line
    }, [searchValue]);


  return (
    <>
      <RepaymentsTableHeader />
      <BorrowersRepaymentsTable isLoading={isLoading} />
    </>
  )
}

export default Repayments;