export const LOGOUT = "LOGOUT";
export const SETREQUESTMETRICSSTARTDATE = "SETREQUESTMETRICSSTARTDATE";
export const SETREQUESTMETRICSENDDATE = "SETREQUESTMETRICSENDDATE";
export const SETREPAYMENTSMETRICSSTARTDATE = "SETREPAYMENTSMETRICSSTARTDATE";
export const SETREPAYMENTSMETRICSENDDATE = "SETREPAYMENTSMETRICSENDDATE";
export const SETPRODUCTSMETRICSSTARTDATE = "SETPRODUCTSMETRICSSTARTDATE";
export const SETPRODUCTSMETRICSENDDATE = "SETPRODUCTSMETRICSENDDATE";
export const SETOVERVIEWMETRICSSTARTDATE = "SETOVERVIEWMETRICSSTARTDATE";
export const SETOVERVIEWMETRICSENDDATE = "SETOVERVIEWMETRICSENDDATE";
export const SETLOANAPPLICATIONMETRICSSTARTDATE =
  "SETLOANAPPLICATIONMETRICSSTARTDATE";
export const SETLOANAPPLICATIONMETRICSENDDATE =
  "SETLOANAPPLICATIONMETRICSENDDATE";
export const SETDISBURSEMENTMETRICSSTARTDATE =
  "SETDISBURSEMENTMETRICSSTARTDATE";
export const SETDISBURSEMENTMETRICSENDDATE = "SETDISBURSEMENTMETRICSENDDATE";
export const SETNOTIFICATIONSSTARTDATE = "SETNOTIFICATIONSSTARTDATE";
export const SETNOTIFICATIONSENDDATE = "SETNOTIFICATIONsENDDATE";
export const SETNOTESTARTDATE = "SETNOTESTARTDATE";
export const SETNOTEENDDATE = "SETNOTEENDDATE";
export const SETALLTASK = "SETALLTASK";
export const ADDTASK = "ADDTASK";
export const SETBORROWERMETRICSTARTDATE = "SETBORROWERMETRICSTARTDATE";
export const SETBORROWERMETRICENDDATE = "SETBORROWERMETRICENDDATE";
export const SETPROFILE = "SETPROFILE";
export const ADDNEWFIELDID = "ADDNEWFIELDID";
export const ADDNEWFIELD = "ADDNEWFIELD";
export const ADDALLCUSTOMFIELDS = "ADDALLCUSTOMFIELDS";
export const REMOVEFIELD = "REMOVEFIELD";
export const EDITFIELD = "EDITFIELD";
export const EDITFIELDNAME = "EDITFIELDNAME";
export const EDITDROPDATA = "EDITDROPDATA";
export const REESTFIELDS = "REESTFIELDS";
export const SETALLLOANS = "SETALLLOANS";
export const SETCURRENTLOANS = "SETCURRENTLOANS";
export const USERSELECTCOUNTRY = "USERSELECTCOUNTRY";
export const SETCURRENTLOANID = "SETCURRENTLOANID";
export const SETLOANAPPLICATIONMODE = "SETLOANAPPLICATIONMODE";
export const SETBANKCODE = "SETBANKCODE";
export const SETALLOFFERS = "SETALLOOFERS";
export const SETSTATE = "SETSTATE";
