import { Row, Col, Divider, Empty, Form } from "antd";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Requests from "../../../../utils/request";
import strip from "../../../../utils/StripWords";
import UseDisplayCustom from "../../../../utils/DisplayCustomField";
import UseGenerateComponentNew from "../../../../utils/generateInputType";
import { useEffect } from "react";

const PersonalInformation = ({ template }) => {
  const { session } = useAuth();
  const { id } = useParams();
  const { handleCustomField } = UseDisplayCustom();
  const { generateInputType } = UseGenerateComponentNew();
  const profileRef = React.createRef();

  const { data: profile_data } = useQuery(
    `profile_data_${id}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${id}/data?data=profile`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    profileRef?.current?.setFieldsValue({
      dob: moment(profile_data?.data?.dob).format("MMMM Do YYYY"),
      marital_status: profile_data?.data?.marital_status,
      nigerian_citizen:
        profile_data?.data?.nigerian_citizen === true ? "Yes" : "No",
      citizenship: profile_data?.data?.citizenship,
      state_of_residence: profile_data?.data?.state_of_residence,
      home_address: profile_data?.data?.home_address,
      highest_level_of_education: strip(
        profile_data?.data?.highest_level_of_education
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_data]);

  return (
    <>
      <div className="mt-5">
        <Form layout="vertical" ref={profileRef}>
          <Row gutter={[16, 16]}>
            {template?.map((data) =>
              data?.slug === "additional-information"
                ? data?.fields?.map((field) =>
                    field?.is_required || field?.is_needed ? (
                      <Col span={8}>
                        <Form.Item name={field?.field} label={field?.label}>
                          {generateInputType(field)}
                        </Form.Item>
                        <Divider />
                      </Col>
                    ) : null
                  )
                : null
            )}
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Phone: </h3>
                <h3 className="font-bold text-base">
                  {profile_data?.data1?.phone}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Phone verified: </h3>
                <h3 className="font-bold text-base">
                  {profile_data?.data?.phone_verified === true ? "Yes" : "No"}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Email: </h3>
                <h3 className="font-bold text-base">
                  {profile_data?.data1?.email}
                </h3>
              </div>
              <Divider />
            </Col>
            <Col span={8}>
              <div className="flex flex-col justify-between h-10">
                <h3 className="text-gray-500">Email verified: </h3>
                <h3 className="font-bold text-base">
                  {profile_data?.data?.email_verified === true ? "Yes" : "No"}
                </h3>
              </div>
              <Divider />
            </Col>
            {profile_data?.data?.custom_fields &&
              profile_data?.data?.custom_fields?.map((fields) => (
                <Col span={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500">{fields?.label}: </h3>
                    <h3 className="font-bold text-base">
                      {handleCustomField(fields)}
                    </h3>
                  </div>
                  <Divider />
                </Col>
              ))}
          </Row>
        </Form>
        {profile_data === null && (
          <Col span={24}>
            <Empty />
          </Col>
        )}
      </div>
    </>
  );
};

export default PersonalInformation;
