import {
  ADDALLPORTFOLIOS,
} from "../../Types/tableTypes";

const initialState = {
  portfolios: [],
};

export const portfoliosTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLPORTFOLIOS) {
    return { ...state, portfolios: actions.value };
  }
  return state;
};
