import React from "react";
import { Card, Table, Tooltip } from "antd";
import { useQuery } from "react-query";
import { currencyFormatter as currency } from "../../../utils/currencyFormatter";
import { useAuth } from "../../../context/AuthContext";
import Requests from "../../../utils/request";
const { Column, ColumnGroup } = Table;

function ChannelBreakDownTable({ requestID, method }) {
  const { session } = useAuth();

  const { data, isLoading } = useQuery(
    `channel-breakdown`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/loan_request/${requestID}/analysis?method=${method}&analysis=channel-breakdown`,
        "GET",
        session.session.token
      ),
    { refetchOnWindowFocus: false }
  );

  const cleanData = (data) =>
    data?.map((res, index) =>
      res?.channel
        ? {
            type: res?.channel,
            inflowCountByChannel: res?.total_inflow.count,
            inflowCountByChannelMsg: res?.total_inflow.message,
            percentTransCountChannel: `${
              res?.total_inflow?.percentage?.toFixed(2) || 0
            }%`,
            totalNGNInflow: res?.total_inflow?.value,
            outflowCountByChannel: res?.total_outflow.count,
            outflowCountByChannelMsg: res?.total_outflow.message,
            outpercentTransCountChannel: res?.total_outflow.percentage,
            outtotalNGNInflow: res?.total_outflow?.value,
          }
        : null
    );

  return (
    <Card style={{ borderRadius: "12px" }}>
      <p
        className="py-1 px-2 text-lg"
        style={{ fontFamily: "Cera Pro Bold", color: "#2E2C34" }}
      >
        Transaction Channel Breakdown
      </p>
      <Table
        loading={isLoading}
        dataSource={cleanData(data?.data)}
        size="small"
        rowKey="type"
        bordered
        // loading={data === undefined && !data}
        style={{ borderColor: "black" }}
      >
        <Column
          title=""
          dataIndex="type"
          key="type"
          width={100}
          render={(text, record) => (
            <span className="uppercase text-purple-800 font-bold">{text}</span>
          )}
        />
        <ColumnGroup title="Inflows">
          <Column
            title="Total inflow count"
            dataIndex="inflowCountByChannel"
            key="inflowCountByChannel"
            render={(text, record) => (
              <Tooltip title={record?.inflowCountByChannelMsg || ""}>
                <span>{text}</span>
              </Tooltip>
            )}
          />

          <Column
            title="% of inflow count"
            dataIndex="percentTransCountChannel"
            key="percentTransCountChannel"
            render={(text, record) => (
              <Tooltip title={record?.percentTransCountChannelMsg || ""}>
                <span>{`${text || 0}`}</span>
              </Tooltip>
            )}
          />
          <Column
            title="Total Inflow NGN value by channel"
            dataIndex="totalNGNInflow"
            key="totalNGNInflow"
            render={(text, record) => (
              <Tooltip title={record?.totalNGNInflowMsg || ""}>
                <span>{currency(text)}</span>
              </Tooltip>
            )}
          />
        </ColumnGroup>

        <ColumnGroup title="Outflows">
          <Column
            title="Total outflow count"
            dataIndex="outflowCountByChannel"
            key="outflowCountByChannel"
            render={(text, record) => (
              <Tooltip title={record?.outflowCountByChannelMsg || ""}>
                <span>{text || 0}</span>{" "}
              </Tooltip>
            )}
          />

          <Column
            title="% of outflow count"
            dataIndex="outpercentTransCountChannel"
            key="outpercentTransCountChannel"
            render={(text, record) => (
              <Tooltip title={record?.outpercentTransCountChannelMsg || ""}>
                <span>{`${text?.toFixed(2) || 0}%`}</span>{" "}
              </Tooltip>
            )}
          />
          <Column
            title="Total outflow value"
            dataIndex="outtotalNGNInflow"
            key="outtotalNGNInflow"
            render={(text, record) => (
              <Tooltip title={record?.outtotalNGNInflowMsg || ""}>
                <span>{currency(text)}</span>
              </Tooltip>
            )}
          />
        </ColumnGroup>
      </Table>
    </Card>
  );
}

export default ChannelBreakDownTable;
