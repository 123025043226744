import React from 'react';
import {Link} from 'react-router-dom';
import AllLoanTable from '../../Table/Loan/AllLoanTable';
import { AllLoanHeader } from '../Header';

function AllOverdueLoans(props) {
    const style = { background: '#ffffff', padding: '20px', borderRadius: '12px' };


    return (
        <div style={style}>   
        <AllLoanHeader title="Overdue" overdue={true}/>         
        <AllLoanTable overdue={true} repayment={true} status="Overdue" amount="0"/>
            <div style={{ textAlign: 'center', marginTop: '20PX', textDecoration: 'underline', color: '#5A607F', fontSize: '18px' }}>
                <Link>
                    View All Overdue Loans
                </Link>
            </div>
        </div>
    )
}
 export default AllOverdueLoans;