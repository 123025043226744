import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

function Breadcrumbs({ link, name }) {
  return (
    <div style={{ marginBottom: "20px" }}>
      <Breadcrumb separator="<">
        <Breadcrumb.Item>
          <Link
            to={{ pathname: "/adminsetup" }}
            style={{ marginRight: "10px", color: "#5152A6" }}
          >
            Back to Admin Setup
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default Breadcrumbs;
