export const initialStateCustom = {};

export function reducerCustom(state, action) {
  switch (action.type) {
    case "init":
      return { ...action.template };
    case "switch":
      return {
        ...state,
        [action.segment]: {
          ...state[action.segment],
          fields: state[action.segment].fields.map((field) => {
            if (action.fieldName === field.label) {
              field[action.key] = action.value;
              return field;
            }

            return field;
          }),
        },
      };

    case "switch_custom":
      return {
        ...state,
        [action.segment]: {
          ...state[action.segment],
          custom_fields: state[action.segment].custom_fields.map((field) => {
            if (action.fieldName === field.label) {
              field[action.key] = action.value;
              return field;
            }

            return field;
          }),
        },
      };

    case "deactivate":
      return {
        ...state,
        [action.segment]: {
          ...state[action.segment],
          deactivated: action.val,
        },
      };

    default:
      return state;
  }
}

export default reducerCustom;
