import React from "react";
import ProductConfiguration from "./ProductConfig";
// import InterestRules from "./InterestRules";
import Notification from "./Notification";
// import DueLoanRules from "./DueLoanRules";
import { Card, Tabs } from "antd";
import ApprovalRules from "./Approvals";
import AddTemplate from "./AddTemplate";

const { TabPane } = Tabs;

function ProductSetup() {
  return (
    <Card>
      <Tabs size="default" type="card">
        <TabPane tab="Product Configuration" key="config">
          <div className="my-4">
            <ProductConfiguration />
          </div>
        </TabPane>
        <TabPane tab="Approval Rules" key="approval">
          <div className="my-4">
            <ApprovalRules />
          </div>
        </TabPane>
        {/* <TabPane tab="Interest Rules" key="interest">
          <div className="my-4">
            <InterestRules />
          </div>
        </TabPane> */}
        <TabPane tab="Offer letter Template" key="template">
          <div className="my-4">
            <AddTemplate />
          </div>
        </TabPane>
        <TabPane tab="Notification & Verification" key="notification">
          <div className="my-4">
            <Notification />
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default ProductSetup;
