import React from 'react'
import { CalenderIcon } from '../Loan/LoanIcons';
import { Title } from '../Typography'
import { Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export function BlacklistHeader() {
    const d = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = months[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();

    return (
        <div style={{ marginBottom: '10px', display:'flex', justifyContent:'space-between' }}>
            <Title>Blacklist</Title>
        <div style={{ display: 'flex', borderRadius: '6px', border: '1px solid #EBEAED', alignItems: 'center', padding: '8px 20px', width:'25%', background:'#fff', height:'50px'}}>
            <CalenderIcon/>
            <p style={{ margin: '0', marginLeft: '10px' }}>{month} {day}, {year}</p>
        </div>
        </div>
    )
}

export function BlacklistTableHeader({title,overdue,repaid, showbtn}) {

    const { Option } = Select;

    return (
        <div>
            <h3 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom:'30px' }}>Blacklisted Users</h3>
            <p style={{ marginBottom: "30px", marginTop: "0" }}>
                We run your borrowers through a database of blacklisted borrowers (past/current loan defaulters, late repayments, etc.). You can manage them right here.
            </p>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'30px'}}>
                <div style={{display:'flex'}}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        size="large"
                        placeholder="Filter"
                        optionFilterProp="children"
                    >
                        <Option value="jack">Jack</Option>
                    </Select>
                    <Input
                        size="large"
                        placeholder="Search..."
                        style={{ width: '75%', margin:'0px 10px' }}
                        prefix={<SearchOutlined/>}
                    />
                    </div>

            </div>
        </div>
    )
}
