import React from "react";
import { Row, Col, Card, Progress } from "antd";
import { Text, Title } from "../../../components/Typography";
import { ArrowRightOutlined } from "@ant-design/icons";

function MiddleCards({ data }) {
  const style = {padding: "15px"}
  const textStyle = {fontSize: "12px", fontWeight: "bold"}
  return (
    <>
      <Row gutter={16} className="mt-10">
        <Col span={6}>
          <Card bodyStyle={style} size="small">
            <div className="flex justify-between">
              <Text style={textStyle}>Defaulted Loans</Text>
              <ArrowRightOutlined style={{color: "#20C9AC"}} />
            </div>
           <div className="flex justify-between mt-5">
                <Title level={4}>{data?.defualted_loans?.count}</Title>
                <Text>from 0</Text>
            </div>`
            <Progress percent={data?.defualted_loans?.percentage} showInfo={false} strokeColor="#5542F6" />
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>Borrowers with missed Repayments</Text>
            </div>
           <div className="flex justify-between mt-5">
                <Title level={4}>{data?.missed_repayment?.count}</Title> 
                <Text>from 0</Text>
            </div>`
            <Progress percent={data?.missed_repayment?.percentage} showInfo={false} />
          </Card>
        </Col>
        <Col span={6}>
          <Card bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>Borrowers with late Repayments</Text>
            </div>
           <div className="flex justify-between mt-5">
                <Title level={4}>{data?.late_repayment?.count}</Title> 
                <Text>from 0</Text>
            </div>`
            <Progress percent={data?.late_repayment?.percentage} showInfo={false} strokeColor="#20C986" />
          </Card>
        </Col>
        <Col span={6}>
          <Card className="space-y-4" bodyStyle={style} size="small">
            <div>
              <Text style={textStyle}>Borrowers with Overdue Loans</Text>
            </div>
           <div className="flex justify-between mt-5">
             <Title level={4}>{data?.overdue_loans?.count}</Title>
                <Text>from 0</Text>
            </div>`
            <Progress percent={data?.overdue_loans?.percentage} showInfo={false} strokeColor="#E666E5" />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default MiddleCards;