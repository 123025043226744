import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { SETCURRENTLOANS } from "../../redux/Types/generalTypes";
import BusinessLoan from "../../components/LoanRequests/loan/BusinessLoan";
import PersonalLoan from "../../components/LoanRequests/loan/PersonalLoan";
import CreditOrderType from "../../components/LoanRequests/loan/CreditOrder";
import useLocationQuery from "../../utils/useQuery";
import HealthLoan from "../../components/LoanRequests/loan/HealthLoans";
import { useAuth } from "../../context/AuthContext/";
import Requests from "../../utils/request";
import BackBtn from "../../utils/BackButton";

function NewLoanRequest(props) {
  const { slug, loanID } = useParams();
  const { session } = useAuth();
  const dispatch = useDispatch();
  let query = useLocationQuery();
  const userID = query.get("uid");

  const { data, isLoading } = useQuery(
    `loan-form-${slug}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/product?slug=${slug}`,
        "GET",
        session?.session?.token
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: config } = useQuery(`product-config-${data?.data?.slug}`, () =>
    Requests.fetchWithAuthToken(
      `/lms/user/product_config?id=${data?.data?.id}`,
      "GET"
    )
  );

  //  This function cleans the loan object so it's easy to update fields in the state
  const cleanTemplateToFitState = (template, arrayOfSegments) => {
    const templateObject = { ...template, segments: {} };

    arrayOfSegments?.forEach((field) => {
      if (!field.deactivated) {
        templateObject.segments[field.slug] = field;
      }
    });

    return templateObject;
  };

  useEffect(() => {
    const custom_segment = data?.data?.custom_segments
      ? data?.data?.custom_segments
      : [];
    if (!isLoading && data?.status === "success") {
      dispatch({
        type: SETCURRENTLOANS,
        loan: cleanTemplateToFitState(data?.data, [
          ...data?.data?.segments,
          ...custom_segment,
        ]),
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <>
      <section className=" mx-auto  my-10">
        <BackBtn />
        <div className="my-8">
          {data?.data?.type === "business-loan" && (
            <BusinessLoan
              id={loanID}
              userID={userID}
              mono={config?.data?.use_mono}
              showGuarantor={config?.data?.require_guarantor}
            />
          )}

          {data?.data?.type === "personal-loan" && (
            <PersonalLoan
              id={loanID}
              userID={userID}
              mono={config?.data?.use_mono}
              showGuarantor={config?.data?.require_guarantor}
            />
          )}

          {data?.data?.type === "health-loan" && (
            <HealthLoan
              id={loanID}
              userID={userID}
              mono={config?.data?.use_mono}
              showGuarantor={config?.data?.require_guarantor}
            />
          )}

          {data?.data?.type === "credit-order" && (
            <CreditOrderType isLoading={isLoading} id={loanID} />
          )}
        </div>
      </section>
    </>
  );
}

export default NewLoanRequest;
