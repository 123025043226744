import {
  ADDALLLOANREQUESTCOLLATERAL,
  LOANREQUESTCOLLATERALSEARCHVALUE,
  SETLOANREQUESTCOLLATERALSTATUS,
} from "../../Types/tableTypes";

const initialState = {
  collaterals: [],
  searchValue: "",
  status: "",
};

export const loanRequestsCollateralsTableReducer = (state = initialState, actions) => {
  if (actions.type === ADDALLLOANREQUESTCOLLATERAL) {
    return { ...state, collaterals: actions.value };
  }

  if (actions.type === LOANREQUESTCOLLATERALSEARCHVALUE) {
    return { ...state, searchValue: actions.value };
  }
  if (actions.type === SETLOANREQUESTCOLLATERALSTATUS) {
    return { ...state, status: actions.value };
  }

  return state;
};
