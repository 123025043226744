import React, { useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { ADDALLREPAYEMENTS } from "../../redux/Types/tableTypes";
import Requests from "../../utils/request";
import Card from "../../components/Repayments/Cards";
import { Col, Row } from "antd";
import totalLoan from "../../assets/svg/TotalLoan.svg";
import approved from "../../assets/svg/Approved.svg";
import pending from "../../assets/svg/pendingRequest.svg";
import RepaymentsBody from "../../components/Repayments/RepaymentsBody";
import { RepaymentHeader } from "../../components/Repayments/RepaymentHeader";
import { useCurrenncyFormatter } from "../../utils/currencyFormatter";

const Repayments = () => {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const { searchValue, endDate, startDate, status } = useSelector(
    (state) => state.repaymentsTableReducer
  );
  const { metricsStartDate, metricsEndDate } = useSelector(
    (state) => state.repaymentsMetricsReducer
  );
  const dateFormat = "YYYY-MM-DD";
  const { currencyFormatter } = useCurrenncyFormatter();

  const { data: repayments, isLoading } = useQuery(
    `all_repayments-start=${startDate}&end=${endDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/repayment?id=all&start=${moment(startDate).format(
          dateFormat
        )}&end=${moment(endDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const { data: repayments_metrics } = useQuery(
    `repayments-metrics-start=${metricsStartDate}&end=${metricsEndDate}`,
    () =>
      Requests.fetchWithAuthToken(
        `/lms/repayment/metrics?start=${moment(metricsStartDate).format(
          dateFormat
        )}&end=${moment(metricsEndDate).format(dateFormat)}`,
        "GET",
        session.session.token
      )
  );

  const SearchQuery = (data) => {
    const firstQuery = data?.filter((repayment) => {
      const name = `${repayment?.borrower}`;
      if (name?.toLowerCase().includes(searchValue.toLowerCase())) {
        return repayment;
      }
      return null;
    });

    return firstQuery?.filter((data) => {
      if (status) {
        return data.status === status;
      }

      return true;
    });
  };

  useEffect(() => {
    if (repayments?.status === "success") {
      dispatch({
        type: ADDALLREPAYEMENTS,
        value: SearchQuery(repayments?.data),
      });
    }
    // eslint-disable-next-line
  }, [isLoading, status]);

  useEffect(() => {
    dispatch({
      type: ADDALLREPAYEMENTS,
      value: SearchQuery(repayments?.data),
    });

    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <RepaymentHeader />
        </Col>
        <Card
          heading="Total Repayments"
          amount={currencyFormatter(
            repayments_metrics?.data?.total_repayment?.value
          )}
          people={repayments_metrics?.data?.total_repayment?.count}
          icon={totalLoan}
          textColor="#3C4066"
          name="allrepayments"
        />
        <Card
          heading="Outstanding Repayments"
          amount={currencyFormatter(
            repayments_metrics?.data?.outstanding_repayment?.value
          )}
          people={repayments_metrics?.data?.outstanding_repayment?.count}
          icon={pending}
          textColor="#3C4066"
          name="outstandingrepayments"
        />
        <Card
          heading="Total Repaid"
          amount={currencyFormatter(
            repayments_metrics?.data?.total_repaid?.value
          )}
          people={repayments_metrics?.data?.total_repaid?.count}
          icon={totalLoan}
          textColor="#3C4066"
          name="allrepaid"
        />
        <Card
          heading="Collection Wallet"
          amount={currencyFormatter(
            repayments_metrics?.data?.collection_wallet?.value
          )}
          people={repayments_metrics?.data?.collection_wallet?.count}
          icon={approved}
          textColor="#3C4066"
          name="collectionwallet"
        />
      </Row>
      <div className="mt-10">
        <RepaymentsBody isLoading={isLoading} />
      </div>
    </>
  );
};

export default Repayments;
